import React, { createContext, useContext, useEffect, useState} from "react"
import SuccessAlert from "../components/Alerts/SuccessAlert";
import ErrorAlert from "../components/Alerts/ErrorAlert";

const AlertContext = createContext();

export function useAlert()
{
	const context = useContext(AlertContext);
	return context;
}

const AlertContextProvider = (props) => {

	const [openAlert, setOpenAlert] = useState(false)
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')

	useEffect(()=>{
		if(openAlert == true)
		{
			setTimeout(()=> setOpenAlert(false),5000)
		}
		if(openErrorAlert)
		{
			setTimeout(()=> setOpenErrorAlert(false),5000)
		}
	},[openAlert,openErrorAlert])
	
	return (
		<AlertContext.Provider value={{
			setOpenAlert,
			setOpenErrorAlert,
			setResponseMessage,
		}}>
			{props.children}
			<SuccessAlert open={openAlert} message={responseMessage} onHide={() => setOpenAlert(false)} />
            <ErrorAlert open={openErrorAlert} message={responseMessage} onHide={() => setOpenErrorAlert(false)} />
		</AlertContext.Provider>
	);

};

export default AlertContextProvider;