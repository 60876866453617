import React from 'react'
import {Dialog,DialogActions, DialogContent, DialogContentText, DialogTitle, Button} from '@mui/material'

function UpdateSocialModal(props) {
    
  return (
    <>
        <Dialog
        open={props.open}
        onClose={props.onClose}
        fullWidth={true}
        maxWidth={'lg'}
      >
        <DialogTitle sx={{marginBottom : '30px', borderBottom: '1px solid black'}}>
          {"Update Social Network"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* You are updating the link of your social platform  */}
            <div className='row m-b30'>
                <div className="col-lg-3 col-md-3">
                    <div className="form-group">
                        <label>Platform</label>
                        <p><b>{props.socialUpdateInfo?.name}</b></p>
                    </div>
                </div>
                <div className="col-lg-9 col-md-9">
                    <div className="form-group">
                        <label>URL</label>
                        <input type='text' className='form-control'
                            onChange={(event) => props.setSocialUpdateInfo({ ...props.socialUpdateInfo, link: event.target.value })}
                            placeholder='Insert Link'
                            defaultValue={props.socialUpdateInfo?.link}
                        />
                    </div>
                </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.updateItem()}>
            Update
          </Button>
          <Button onClick={()=> props.onClose()}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default UpdateSocialModal