import React, { useState } from 'react'
import { updateCandidateBasicInfo } from '../../../../../fetchAPI'
import CandidateUpdateModal from './component/CandidateUpdateModal'
import customStyles from './CandidateUpdateProfile.module.css'
import EditSVG from './component/EditSVG'
import SinglePersonalInfoBlock from './component/SinglePersonalInfoBlock'
import { useAlert } from '../../../../context/AlertContext'
import { RELIGION_LIST } from '../../../../../constants'

function UpdatePersonalInfo(props) {

    const alertContext = useAlert();

    const genderList = [{ label: 'Male', value: 'Male' }, { label: 'Female', value: 'Female' }, { label: 'Other', value: 'Other' }]

    const [updatePersonal, setUpdatePersonal] = useState('')

    const [modalOpen, setModalOpen] = useState(false);

    const handleChange = (name) => (event) => {
        setUpdatePersonal({ ...updatePersonal, [name]: event.target.value })
    }

    const updateCandidatePersonal = (candId, updateParameters) => {
        setModalOpen(false);
        updateCandidateBasicInfo(candId, updateParameters)
            .then(response => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    props.renderState()
                } else {
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                }
            })
    }

    const todayDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        const formattedToday = yyyy + '-' + mm + '-' + dd;

        return formattedToday
    }
    return (
        <div id='personal_box'>
            <div className='card shadow-sm'>
                <div className={customStyles.cardHeader}>
                    <div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
                        <div className={customStyles.sectionHeader}>Personal Info</div>
                        <button className={`btn ${customStyles.editButton}`} onClick={() => setModalOpen(true)}>
                            <div style={{ marginRight: '10px' }}>Edit</div>
                            <EditSVG />
                        </button>
                    </div>
                </div>
                <div className='card-body'>
                    <SinglePersonalInfoBlock title="Father's Name" value={props.candidateInfo.father_name} />
                    <SinglePersonalInfoBlock title="Mother's Name" value={props.candidateInfo.mother_name} />
                    <SinglePersonalInfoBlock title="Gender" value={props.candidateInfo.gender} />
                    <SinglePersonalInfoBlock title="Date of Birth" value={props.candidateInfo.dob} />
                    <SinglePersonalInfoBlock title="Home Town" value={props.candidateInfo.birth_city} />
                    <SinglePersonalInfoBlock title="Availablity" value={props.candidateInfo.available_date} />
                    <SinglePersonalInfoBlock title="Martial Status" value={props.candidateInfo.martial_status} />
                    <SinglePersonalInfoBlock title="Looking for Job" value={props.candidateInfo.searching_for_job_status} />
                    <SinglePersonalInfoBlock title="Nationality" value={props.candidateInfo.nationality} />
                    <SinglePersonalInfoBlock title="Religion" value={props.candidateInfo.religion} />
                    <SinglePersonalInfoBlock title="Current Location  " value={props.candidateInfo.current_location} />
                </div>
            </div>


            <CandidateUpdateModal open={modalOpen} onClose={() => setModalOpen(false)} title='Personal Information' onFormSubmit={() => updateCandidatePersonal(props.candidateInfo.id, updatePersonal)}>
                <div>
                    <div className='row mt-3 m-2'>
                    <div className=' col-lg-6'>
                            <div className='form-group'>
                                <label>Father's Name</label>
                                <input className='form-control' defaultValue={props.candidateInfo.father_name} onChange={handleChange('father_name')} type='text' />
                            </div>

                        </div>
                        <div className=' col-lg-6'>
                            <div className='form-group'>
                                <label>Mother's Name</label>
                                <input className='form-control' defaultValue={props.candidateInfo.mother_name} onChange={handleChange('mother_name')} type='text' />
                            </div>

                        </div>

                        <div className="col-lg-6 ">
                            <div className="form-group">
                                <label>Gender</label>
                                <select onChange={handleChange('gender')} className='form-control' style={{borderRadius: '5px'}}>
                                    <option value=''>Select Gender</option>
                                    {
                                        genderList.map((item) => {
                                            return (<option key={item.value} selected={item.value == props.candidateInfo.gender ? 'selected' : ''} value={item.value}>{item.value}</option>)
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-lg-6 ">
                            <div className="form-group">
                                <label>Date of Birth<div className='d-inline-block' style={{ color: 'red' }}>*</div></label>
                                <input type="date" onChange={handleChange('dob')} defaultValue={props.candidateInfo.dob} className="form-control atb-input-box" />
                            </div>
                        </div>
                        <div className=' col-lg-6'>
                            <div className='form-group'>
                                <label>Home Town</label>
                                <input className='form-control' defaultValue={props.candidateInfo.birth_city} onChange={handleChange('birth_city')} type='text' />
                            </div>

                        </div>
                        <div className=' col-lg-6'>
                            <div className='form-group'>
                                <label>Availability</label>
                                <input className='form-control' min={todayDate()} defaultValue={props.candidateInfo.available_date} onChange={handleChange('available_date')} type='date' />
                            </div>

                        </div>
                        <div className=' col-lg-6'>
                            <div className='form-group'>
                                <label>Marital Status</label>
                                <select onChange={handleChange('martial_status')} className='form-control' style={{borderRadius: '5px'}}>
                                    <option value=''>Select Status</option>
                                    <option selected={props.candidateInfo.martial_status == 'Married' ? 'selected' : ''} value='Married'>Married</option>
                                    <option selected={props.candidateInfo.martial_status == 'Single' ? 'selected' : ''} value='Single'>Single</option>
                                </select>
                            </div>

                        </div>
                        <div className=' col-lg-6'>
                            <div className='form-group'>
                                <label>Looking for Job</label>
                                <select onChange={handleChange('searching_for_job_status')} className='form-control' style={{borderRadius: '5px'}}>
                                    <option value=''>Select</option>
                                    <option selected={props.candidateInfo.searching_for_job_status == 'Actively' ? 'selected' : ''} value='Actively'>Actively</option>
                                    <option selected={props.candidateInfo.searching_for_job_status == 'Yes' ? 'selected' : ''} value='Yes'>Yes</option>
                                    <option selected={props.candidateInfo.searching_for_job_status == 'No' ? 'selected' : ''} value='No'>No</option>
                                </select>
                            </div>

                        </div>
                        <div className=' col-lg-6'>
                            <div className='form-group'>
                                <label>Nationality</label>
                                <input className='form-control' defaultValue={props.candidateInfo.nationality} onChange={handleChange('nationality')} type='text' />
                            </div>

                        </div>

                        <div className="col-lg-6 ">
                            <div className="form-group">
                                <label>Religion</label>
                                <select onChange={handleChange('religion')} className='form-control' style={{borderRadius: '5px'}}>
                                    <option value=''>Select Religion</option>
                                    {
                                        RELIGION_LIST.map((item) => {
                                            return (<option key={item.id} selected={item.value == props.candidateInfo.religion ? 'selected' : ''} value={item.value}>{item.label}</option>)
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                        <div className=' col-lg-12'>
                            <div className='form-group'>
                                <label>Current Location</label>
                                <input className='form-control' defaultValue={props.candidateInfo.current_location} onChange={handleChange('current_location')} type='text' />
                            </div>

                        </div>
                    </div>

                </div>
            </CandidateUpdateModal>
        </div >
    )
}

export default UpdatePersonalInfo