import React from 'react'
import { Grid } from '@mui/material'

function NoResultsFound() {
    return (
        <>
            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <img src='/images/no-results-found.png' alt='No result found!' style={{ height: '250px' }} />
            </Grid>
            <Grid container sx={{ display: 'flex', flexDirection: "column", alignItems: 'center', mt: 3 }}>
                <p style={{fontFamily: "Epilogue", fontWeight: "bold", fontSize: "20px", color: "#424447", marginBottom: "10px"}}>
                    No Results Found
                </p>
            </Grid>
        </>
    )
}

export default NoResultsFound;