import React, { useContext, useState, useEffect } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { registerUser } from '../../fetchAPI'
import { useHistory } from 'react-router-dom'
import { UserContext } from '../context/UserContext'
import { TextField } from '@mui/material'
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { LoadingButton } from '@mui/lab'

function RegisterTab() {
    const history = useHistory()
    const [loader, setLoader] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [candidateRegisterInfo, setCandidateRegisterInfo] = useState({ user_role_id: 2 })
    const [employerRegisterInfo, setEmployerRegisterInfo] = useState({ user_role_id: 3 })
    const [responseMessage, setResponseMessage] = useState(null)
    const [userRoleID, setUserRoleID] = useState(2)

    const { loginData, redirectionLink } = useContext(UserContext)

    /**
     * Submit CANDIDATE registration request through registerUser API
     * 
     * @param {event} e
     * @param {object} candidateRegisterInfo 
     */

    const submitCandidateForm = (e) => {
        e.preventDefault();
        setLoader(true)
        registerUser(candidateRegisterInfo)
            .then(response => {
                if (response && response.success) {
                    loginData(response)
                    history.push(redirectionLink)
                }
                else {
                    setResponseMessage(response?.errors[0].message);
                }
            })
            .catch(err => {
                setLoader(false)
                throw Error('Error: ' + err)
            }).finally(()=>setLoader(false))
    }

    /**
     * Submit EMPLOYER registration request through registerUser API
     * 
     * @param {event} e
     * @param {object} employerRegisterInfo 
     */

    const submitEmployerForm = (e) => {
        e.preventDefault();
        setLoader(true)
        registerUser(employerRegisterInfo)
            .then(response => {
                // console.log(response)
                if (response && response.success) {
                    loginData(response)
                    history.push('/dashboard')

                }
                else {
                    setResponseMessage(response?.errors[0].message)
                }
            })
            .catch(err => {
                throw Error('Error: ' + err)
            }).finally(()=> setLoader(false))
    }

    const handleChangeCandidate = (name) => (event) => {
        setErrorMessage('')
        setCandidateRegisterInfo({ ...candidateRegisterInfo, [name]: event.target.value })
    }

    const handleChangeEmployer = (name) => (event) => {
        setErrorMessage('')
        setEmployerRegisterInfo({ ...employerRegisterInfo, [name]: event.target.value })
    }

    const handleTab = () => (eventKey) => {
        setUserRoleID(eventKey)
        setCandidateRegisterInfo({ user_role_id: 2 })
        setEmployerRegisterInfo({ user_role_id: 3 })
        setErrorMessage('')
        document.getElementById('candidate-form').reset()
        document.getElementById('employer-form').reset()
        setResponseMessage('')
    }

    // Google Auth

    function handleCallback(response) {
        const userOb = jwtDecode(response.credential);

        axios.post(`${process.env.REACT_APP_API_SERVER_BASE_URL}/googleOauthLogin`, {
            credential: response.credential,
            user_role_id: userRoleID
        }).then((response) => {
            if (response && response.status == 200) {
                loginData(response.data)
                setLoader(false)
                history.push('/dashboard')
            }
            else {
                setLoader(false)
                // throw Error('Error: ' + response)
                console.log('Res API: ', response);
            }

        }).catch(function (error) {
            console.log("API err: ", error);
        });
    }

    function signOut() {
        /*global google*/
        google.accounts.id.disableAutoSelect();
        console.log('sign out');
    }

    function setUserRole(response) {
        setUserRoleID(response);
    }

    useEffect(() => {
        /*global google*/
        google.accounts.id.initialize({
            client_id: '723908483827-aquc3ubglvopidh8dant7rt81pv183te.apps.googleusercontent.com',
            callback: handleCallback
        });

        google.accounts.id.renderButton(
            document.getElementById('gsign'),
            {
                theme: 'outline',
                type: 'standard',
                size: 'large',
                width: `${window.innerWidth >= 900 ? '900px' : '100px'}`,
                text: 'signup_with'
            }
        )

    }, [userRoleID])

    return (
        <div>
            <h3 className='m-b30' style={{ color: 'hsl(217, 10%, 50.8%)' }}>
                Welcome! Let's get Started
            </h3>
            {errorMessage ?
                <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
                    <center>
                        <small style={{ color: 'red' }}> {errorMessage} </small>
                    </center>
                </div>
                : ''}
            <Tabs
                defaultActiveKey={2}
                id="uncontrolled-tab-example"
                className="mb-3"
                fill
                onSelect={handleTab()}
            >
                <Tab eventKey={2} title="Candidate">
                    {
                        <form id='candidate-form' onSubmit={(e) => submitCandidateForm(e)}>
                            {responseMessage ?
                                <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
                                    {
                                        responseMessage?.map((item,index)=>{
                                            return(
                                                <center key={index}>
                                                    <small style={{ color: 'red' }}> {item} </small>
                                                </center> 
                                            )
                                        })
                                    }
                                </div>
                                : ''}
                            <div className="form-outline mb-4 text-left">
                                <TextField label='Candidate Full Name' inputProps={{ minLength: 4 }} onChange={handleChangeCandidate("name")} fullWidth required />
                            </div>
                            <div className="form-outline mb-4 text-left">
                                <TextField label='Candidate Email' onChange={handleChangeCandidate("email")} fullWidth required />
                            </div>
                            <div className="form-outline mb-4 text-left">
                                <TextField type='password' label='Password' inputProps={{ minLength: 8 }} onChange={handleChangeCandidate("password")} fullWidth required />
                            </div>

                            <div className='row'>
                                <div className="col-lg-12 mb-6 mb-lg-0">
                                    <LoadingButton type="submit" loading={loader} variant="contained" className="btn btn-primary btn-block mb-4">
                                        Register
                                    </LoadingButton>
                                </div>
                            </div>
                        </form>
                    }
                </Tab>
                <Tab eventKey={3} title="Employer">
                    {
                        <form id='employer-form' onSubmit={(e) => submitEmployerForm(e)}>
                            {responseMessage ?
                                <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>

                                    {
                                        responseMessage?.map((item,index)=>{
                                            return(
                                                <center key={index}>
                                                    <small style={{ color: 'red' }}> {item} </small>
                                                </center> 
                                            )
                                        })
                                    }
                                    
                                </div>
                                : ''}
                            <div className="form-outline mb-4 text-left">
                                <TextField label='Company Name' inputProps={{ minLength: 4 }} onChange={handleChangeEmployer("name")} fullWidth required />
                            </div>

                            <div className="form-outline mb-4 text-left">
                                <TextField label='Company Email' onChange={handleChangeEmployer("email")} fullWidth required />
                            </div>

                            <div className="form-outline mb-4 text-left">
                                <TextField type='password' label='Password' inputProps={{ minLength: 8 }} onChange={handleChangeEmployer("password")} fullWidth required />
                            </div>
                            <div className='row'>
                                <div className="col-lg-12 mb-6 mb-lg-0">
                                    <LoadingButton type="submit" loading={loader} variant="contained" className="btn btn-primary btn-block mb-4">
                                        Register
                                    </LoadingButton>
                                </div>
                            </div>
                        </form>
                    }
                </Tab>
            </Tabs>

            <div className='row justify-content-center'>
                <div className="App">
                    <div id='gsign'></div>
                </div>
            </div>
        </div>
    )
}

export default RegisterTab