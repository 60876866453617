import React from 'react';
import { Route } from 'react-router-dom';
import JobSearchContextProvider from '../context/JobSearchContext';

const JobSearchRoute = ({ component, ...rest }) => {
  const Component = component;

  return (
    <Route {...rest}>
      <JobSearchContextProvider>
        <Component />
      </JobSearchContextProvider>
    </Route>
  );
};

export default JobSearchRoute;