import React from 'react'
import styles from '../../../components/CandidateComponents/CandidateProfile/UpdateProfile/CandidateUpdateProfile.module.css'
import { CircularProgress } from '@mui/material'

function AppliedCandidateCustomQuestion(props) {
    return (
        <div className='card shadow-sm'>
            <div className={styles.cardHeader}>
                <div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
                    <div className={styles.sectionHeader}>Custom Question Answers</div>
                </div>
            </div>
            <div className='card-body'>
                {
                    !props.loading ?
                        <div className='row'>
                            {
                                props.customQuestionInfo ?
                                    props.customQuestionInfo.map((item, index) => {
                                        return (
                                            <div className='col-12 p-t20' key={index} >
                                                <label>{index + 1}. {item.question}</label>
                                                <div> -&gt;&emsp; {item.option || "Not Answered"}</div>
                                            </div>
                                        )
                                    })
                                    :
                                    <h6>Not answered!</h6>
                            }
                        </div>
                        :
                        <div style={{display:'flex',justifyContent:'center'}}><CircularProgress /></div>
                }
            </div>
        </div>
    )
}

export default AppliedCandidateCustomQuestion