import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { getLandingPageJobType } from '../../../fetchAPI'
import moment from 'moment'
import './JobSection.css'
import { JobSearchContext } from '../../context/JobSearchContext'
import { Grid, Chip, Button, Tooltip, Box, Typography } from '@mui/material'
import { DropdownContext } from '../../context/DropdownContext'
import EmployerProfilePicture from '../EmployerComponents/EmployerProfilePicture'
import { useTranslation } from 'react-i18next'

function JobSection() {

    const [jobTypeData, setJobTypeData] = useState([])
    const [jobTypeId, setJobTypeId] = useState(105)

    const { setTypeCheck } = useContext(JobSearchContext)

    const { types, apiCallingConditionSet, setApiCallingConditionSet } = useContext(DropdownContext)

    useEffect(() => {
        setApiCallingConditionSet({ ...apiCallingConditionSet, type: true })
    }, [])

    useEffect(() => {
        gettingJobTypeData()
    }, [jobTypeId])


    const gettingJobTypeData = () => {

        getLandingPageJobType(jobTypeId).then(response => {
            if (response && response.success) {
                setJobTypeData(response?.response?.data)
            }
            else {
                console.log('error: ', response)
            }
        })
    }

    const handleTab = () => (event) => {
        setJobTypeId(event)
    }

    // translations
    const {t} = useTranslation(["homepage", "button"])

    return (
        <div>
            <div className="container">
                <div className='row'>
                    <div className='col-lg-6 col-md-6 text-left'>
                        <h1 className='d-none d-lg-block' style={{ color: '#424447', fontWeight: 700, fontSize: "24px", fontFamily: 'Epilogue,sans-serif'}}>{t("explore-by-jobs.title")}</h1> {/** Desktop View */}
                        <h1 className='d-lg-none text-center mt-3 responsive_header' style={{ color: '#424447', fontWeight: 700, fontFamily: 'Epilogue,sans-serif'}}>{t("homepage:explore-by-jobs.title")}</h1> {/** Mobile View */}
                    </div>
                    <div className='col-lg-6 col-md-6 text-right d-none d-lg-block'>
                        {
                            jobTypeId ?
                                <Link 
                                to={{
                                    pathname: '/search/jobs',
                                    state: { fromHome: true }
                                }} 
                                 onClick={() => setTypeCheck([jobTypeId])}><Button size='large' variant='outlined'>{t("button:explore-all.title")}</Button></Link>
                                :
                                ""
                        }
                    </div>
                </div>
                <div className='row' style={{ justifyContent: 'left' }}>
                    <Grid sx={{ marginLeft: '25px' }} className='m-b20'>
                        {
                            types?.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Chip size='medium' sx={{ marginRight: '10px', padding: '10px', marginBottom: '10px', borderRadius: '5px' }} color="primary" variant={jobTypeId === item.id ? "filled" : "outlined"} key={index} onClick={() => setJobTypeId(item.id)} label={`${item.name}`} />
                                    </React.Fragment>
                                )
                            })
                        }
                    </Grid>
                </div>
                <div className='row'>
                    {
                        jobTypeData?.map((job, index) => {
                            return (
                                <div key={index} className='col-lg-4 col-md-4'>
                                    <Card className='atb-card'>
                                        <Tooltip title="Go to Profile">
                                            <Link to={`/employer/profile/${job?.employer?.slug}`} className='text-dark'> <EmployerProfilePicture className='candidate-card-image' profile_picture={job.employer?.profile_pic_base64} /></Link>
                                        </Tooltip>
                                        <div className='mb-3'>
                                            <Tooltip title="Go to Profile">
                                                <Link to={`/employer/profile/${job?.employer?.slug}`} className='text-dark'> <h4 className='card-title mt-3'>{job?.employer?.employer_name}</h4></Link>
                                            </Tooltip>
                                        </div>
                                        <Link to={`/jobs/${job?.post_id}`}>
                                            <h3 className='display-6 text-truncate' style={{ color: '#006eff' }}>
                                                {job?.job_title}
                                            </h3>
                                        </Link>
                                        <div className='mb-3'>
                                            <span className='candidate-tags d-inline-block text-truncate' style={{ maxWidth: '100px' }}><i className="fa fa-map-marker" aria-hidden="true"></i> &nbsp; {job?.job_address}</span>
                                            <span className='candidate-tags d-inline-block text-truncate' style={{ maxWidth: '100px' }}><i className="fa fa-briefcase" aria-hidden="true"></i> &nbsp; {job?.job_type?.name}</span>
                                            <span className='candidate-tags d-inline-block text-truncate' style={{ maxWidth: '100px' }}><i className="fa fa-money" aria-hidden="true"></i> &nbsp; {job.qualification?.name ? job.qualification?.name : "No Qualification Mentioned"}</span>
                                        </div>
                                        <h5 className='card-title mt-1'> Salary: {job.maximum_salary ? job.minimum_salary + '-' + job.maximum_salary + ' BDT / ' + job.salary_type?.type : "No Salary Mentioned"}</h5>
                                        <div className='intro-text m-1 mb-1'>
                                            {/* <p>The company is looking for a <b>{job.position?.name}</b> for their <b>{job.department?.name}</b> team. Click on "View Details" to learn more about the job and find out if you're fit to apply!</p> */}
                                            <small className='text-body text-center ' style={{ fontWeight: 'bold' }}> Application Deadline: <span style={{ color: '#e54c3c' }}> {moment(job?.job_deadline).format("MMM Do YY")} </span> </small>
                                        </div>
                                        <div className=''>
                                            <Link to={`/jobs/${job?.post_id}`}><button className='candidate-apply-button' style={{ float: 'left' }}>{t("button:view-details")}</button></Link>
                                            <Link to={'/search/jobs'}><button className='employer-save-button' style={{ float: 'right' }}>{t("button:see-more")}</button></Link>

                                            {/* {
                                        savedCandidateIds?.includes(props.candidateInfo?.id) ?
                                            <button onClick={() => unSave(employerData.id, props.candidateInfo?.id)} className='employer-save-button' style={{ float: 'right' }}>Unsave</button>
                                            :
                                            <button onClick={() => saveCandidate(employerData.id, props.candidateInfo.id)} className='employer-save-button' style={{ float: 'right' }}>Save It</button>
                                    } */}
                                        </div>
                                        {/* <SuccessAlert open={openAlert} message={responseMessage} onHide={() => setOpenAlert(false)} /> */}
                                    </Card>
                                </div>
                            )
                        })
                    }
                    {
                        jobTypeData.length == 0 ?

                            <Box
                                component="span"
                                sx={{
                                    width: 1500,
                                    height: 70,
                                    p: 3,
                                    border: '1px dashed grey',
                                    margin: 3
                                }}>

                                <Typography sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>No Jobs Available</Typography>
                            </Box>
                            :
                            ""
                    }
                    {/* <div className='col-12 text-center' style={{ margin: 'auto' }}>
                        <Link to='/search/jobs' onClick={() => setTypeCheck([...typeCheck, 105])} style={{ fontSize: '28px', color: '#2a9df4', padding: '10px 60px 10px 60px', border: '1px solid ', marginTop: '20px', borderRadius: '10px' }}>EXPLORE ALL</Link>
                    </div> */}
                </div>
                <div className='col-12 text-center d-lg-none mt-4 responsive_header' style={{ margin: 'auto' }}>
                    {
                        jobTypeId ?
                            <Link 
                            to={{
                                pathname: '/search/jobs',
                                state: { fromHome: true }
                            }} 
                             onClick={() => setTypeCheck([jobTypeId])} style={{ color: '#2a9df4', padding: '10px 60px 10px 60px', border: '1px solid ', marginTop: '20px', borderRadius: '10px' }}>{t("button:explore-all.title")}</Link>
                            :
                            ""
                    }
                </div>
                <div className='container mb-5'>
                    <hr />
                </div>
            </div>
        </div>
    )
}

export default JobSection