import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { forgetPassword } from '../../fetchAPI'
import { Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import SuccessAlert from '../../v2/components/Alerts/SuccessAlert';
import ErrorAlert from '../../v2/components/Alerts/ErrorAlert';
import { LoadingButton } from '@mui/lab';

function LoginUserRoleIDModal(props) {

    const [userRoleID, setUserRoleID] = useState('')
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        setUserRoleID(event.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        props.registercallbackfunction(userRoleID)
        setLoading(false);
    }

    return (
        <>
            <Dialog
                // {...props}
                open={props.open}
                onClose={props.onClose}
            >
                <DialogTitle>Please Select Type</DialogTitle>
                <Divider />
                <form onSubmit={e => { handleSubmit(e) }}>
                    <DialogContent>
                        <DialogContentText>
                            We were unable to find an account with your email address on our platform, please let us know what would you like to register as:
                        </DialogContentText>

                        <Box sx={{ minWidth: 120, mt: 3 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select Your Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Select Your Type"
                                    value={userRoleID}
                                    onChange={handleChange}
                                >
                                    <MenuItem value={2}>Candidate</MenuItem>
                                    <MenuItem value={3}>Employer</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>

                    </DialogContent>
                    <DialogActions>
                        <Button variant='outlined' onClick={props.onClose}>Cancel</Button>
                        <LoadingButton type='submit' loading={loading} variant='outlined'>Confirm</LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}

export default LoginUserRoleIDModal