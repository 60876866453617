import React, { useContext, useEffect, useState } from 'react'
import CandidateProfileSidebar from '../../components/CandidateComponents/CandidateProfile/CandidateProfileSidebar'
import CandidateProfileDetails from '../../components/CandidateComponents/CandidateProfile/CandidateProfileDetails'
import { deleteEmployerSaveCandidateInfo, getCandidateBySlug, postActivityLogs, postEmployerSaveCandidateInfo } from '../../../fetchAPI'
import { useParams, useHistory } from 'react-router-dom'
import { UserContext } from '../../context/UserContext'

import EmployerContextProvider, { EmployerContext } from '../../context/EmployerContext'
import ErrorAlert from '../../components/Alerts/ErrorAlert'
import CandidateSearchContextProvider from '../../context/CandidateSearchContext'
import { Box, Grid } from '@mui/material'
import CandidateProfileNav from '../../components/CandidateComponents/CandidateProfile/CandidateProfileNav'
import { useAlert } from '../../context/AlertContext'
import GlobalLoader from '../../components/GlobalLoader'
import { fetchIpAddress } from '../../helpers/helperFunctions'

function CandidateProfile() {

    const { slug } = useParams()
    const [candidateInfo, setCandidateInfo] = useState([])
    const [loading, setLoading] = useState(false)
    const { userData, isLoggedin } = useContext(UserContext)
    const [recallData, setRecallData] = useState(1)
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const pageTitle = 'candidate-profile'
    const currentPageURL = window.location.href;
    const userAgent = navigator.userAgent;

    const history = useHistory()

    useEffect(() => {
        if (!isLoggedin) {
            history.push('/login')
        }
    }, [isLoggedin])

    useEffect(() => {
        setLoading(true)
        getCandidateBySlug(slug)
            .then(response => {
                if (response && response.success) {
                    setCandidateInfo(response.response.data)
                    setLoading(false)
                } else {
                    throw Error('400 Bad Request: Unable to find Candidate with ID: ' + slug)
                }
            })
            .catch(err => {
                setResponseMessage(err.message);
                setOpenErrorAlert(true);
            })
    }, [recallData])

    const renderState = () => {
        setRecallData(prev => prev + 1)
    }

    useEffect(() => {
        const logActivity = async () => {
          const ipAddress = await fetchIpAddress();
          const params = { 
            page_title: pageTitle,
            action_type: 'view',
            page_url: currentPageURL,
            ip: ipAddress,
            job_post_id: "",
            platform: "web",
            user_agent: userAgent
          };
          await postActivityLogs(params);
        };

        logActivity();
      }, []); 

    return (
        <CandidateSearchContextProvider>
            {
                loading ?
                    <React.Fragment>
                        <GlobalLoader height="80vh" />
                        <ErrorAlert open={openErrorAlert} message={responseMessage} onHide={() => setOpenErrorAlert(false)} />
                    </React.Fragment>
                    :
                    <div>
                        {
                            userData.id === candidateInfo?.user_id || userData.user_role_id == 3 ?
                                <EmployerContextProvider>

                                    {/* <EmployerContextProvider>
                                        <CandidateProfileCover renderState={renderState} loading={loading} candidateInfo={candidateInfo} />
                                    </EmployerContextProvider> */}
                                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, position: 'fixed', top: '99px', height: '70px', backgroundColor: '#0275D8', width: '100%', zIndex: 10 }}></Box>
                                    <Grid container>
                                        <Grid item xs={0} sm={0} md={4} lg={3} xl={3} sx={{ zIndex: 11, display: { xs: 'none', sm: 'none', md: 'block' } }}>

                                            <CandidateProfileSidebar userData={userData} renderState={renderState} loading={loading} candidateInfo={candidateInfo} masking={candidateInfo.masking}/>

                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8} lg={9} xl={9} sx={{ padding: { xs: '0px', sm: '0px', md: '10px' } }}>
                                            <CandidateProfileNav userData={userData} renderState={renderState} loading={loading} candidateInfo={candidateInfo} masking={candidateInfo.masking}/>
                                            <Box sx={{ padding: { xs: '10px', sm: '10px', md: '0px' } }}>
                                                <CandidateProfileDetails loading={loading} candidateInfo={candidateInfo} masking={candidateInfo.masking}/>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                </EmployerContextProvider>
                                :
                                <center>
                                    <div className='atb-card'>
                                        <h3 style={{ color: 'red' }}>
                                            You dont have the permission to access this page!
                                        </h3>
                                    </div>
                                </center>
                        }
                    </div>
            }
        </CandidateSearchContextProvider>
    )
}

export default CandidateProfile