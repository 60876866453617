import React, { useState } from 'react'
import { Alert, Box, CircularProgress } from '@mui/material'
import { postCandidateReference, deleteCandidateReference, updateCandidateReference } from '../../../../../fetchAPI'
import UpdateDeleteConfirmationModal from './UpdateDeleteConfirmationModal'
import CandidateUpdateModal from './component/CandidateUpdateModal'
import customStyles from './CandidateUpdateProfile.module.css'
import EditSVG from './component/EditSVG'
import DeleteSVG from './component/DeleteSVG'
import AddButton from './component/AddButton'
import { useAlert } from '../../../../context/AlertContext'
import RequiredAsterisk from './component/RequiredAsterisk'
import { defaultMobileNumber, handleKeyDown, validatePhoneNumber } from '../../../../helpers/helperFunctions'

function UpdateReference(props) {


    const [referenceAddData, setReferenceAddData] = useState({})
    const [updateReferenceData, setUpdateReferenceData] = useState({})

    const [loader, setLoader] = useState(false)

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);

    const alertContext = useAlert();



    const enableUpdate = (item) => {
        setUpdateReferenceData({
            id: item.id,
            name: item.name,
            designation: item.designation,
            relation: item.relation,
            mobile_number: item.mobile_number,
            email: item.email,
            organization: item.organization
        })
    }

    const handleUpdateChange = (name) => (event) => {
        setUpdateReferenceData({
            ...updateReferenceData,
            [name]: event.target.value
        })
    }

    const handleChange = (name) => (event) => {
        setReferenceAddData({ ...referenceAddData, [name]: event.target.value })
    }

    let errorsObj = {};

    const [errors, setErrors] = useState({})

    const insertCandidateReference = (body) => {

        let error = false;
        const errorObj = { ...errorsObj };
        if (!body.name) {
            errorObj.name = 'Name is Required';
            error = true;
        }

        if (!body.designation) {
            errorObj.designation = 'Designation is Required';
            error = true;
        }

        if (!body.email) {
            errorObj.email = 'Email is Required';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            setLoader(false);
        }

        else {
            const phoneNumber = body.mobile_number
            if (phoneNumber && !validatePhoneNumber(phoneNumber)) {
              alertContext.setOpenErrorAlert(true);
              alertContext.setResponseMessage("Please enter a valid phone number!");
              setLoader(false);
              return;
            }
            setAddModalOpen(false);
            setReferenceAddData({});
            postCandidateReference(body).then((response) => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    props.renderState()
                    setLoader(false);
                }
                else {
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                    setLoader(false)
                }
            })
        }
    }

    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

    const handleOpen = (itemId) => {
        setOpenDeleteModal(true);
        setDeleteItemId(itemId);
    };

    const handleClose = () => {
        setOpenDeleteModal(false);
    };

    const [deleteItemId, setDeleteItemId] = useState('')
    const deleteReference = (id) => {
        setOpenDeleteModal(false)
        setLoader(true)
        deleteCandidateReference(id).then((response) => {
            if (response.success) {
                alertContext.setResponseMessage(response.response.message)
                alertContext.setOpenAlert(true)
                props.renderState()
                setLoader(false)
            }
            else {
                console.log('errors: ', response);
                const message = response.errors?.map(msg => {
                    alertContext.setResponseMessage(msg.message)
                })
                alertContext.setOpenErrorAlert(true)
                setLoader(false);
            }
        })
    }

    const [updateErrors, setUpdateErrors] = useState({})
    const updateReference = (body) => {

        let error = false;

        const errorObj = { ...errorsObj };

        if (!body.name) {
            errorObj.name = 'Name is Required';
            error = true;
        }

        if (!body.designation) {
            errorObj.designation = 'Designation is Required';
            error = true;
        }

        if (!body.email) {
            errorObj.email = 'Email is Required';
            error = true;
        }

        setUpdateErrors(errorObj);
        if (error) {
            setLoader(false);
        }
        else {
            const phoneNumber = body.mobile_number
            if (phoneNumber && !validatePhoneNumber(phoneNumber)) {
              alertContext.setOpenErrorAlert(true);
              alertContext.setResponseMessage("Please enter a valid phone number!");
              setLoader(false);
              return;
            }
            setUpdateModalOpen(false);
            updateCandidateReference(body.id, body).then((response) => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    props.renderState()
                    setLoader(false);
                }
                else {
                    console.log('errors: ', response);
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                    setLoader(false);
                }
            })
        }
    }

    return (
        <div id='candidate_update_reference_box'>
            <div className='card shadow-sm'>
                <div className={customStyles.cardHeader}>
                    <div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
                        <div className={customStyles.sectionHeader}>References</div>
                        <AddButton title="References" onClickHandler={() => setAddModalOpen(true)} />
                    </div>
                </div>
                <div className='card-body'>
                    {
                        loader ?
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                                <CircularProgress />
                            </Box>
                            :
                            props.candidateReference?.map((item, index) => {
                                return (
                                    <div key={item.id}>
                                        {index > 0 ? <hr /> : ''}
                                        <div className={customStyles.singleRef} >
                                            <div className={customStyles.refTitle}>
                                                {item.name}
                                            </div>

                                            <div className={customStyles.actionContainer} style={{ marginLeft: '10px' }}>
                                                <span style={{ height: '32px' }} onClick={() => { enableUpdate(item); setUpdateModalOpen(true) }}>
                                                    <EditSVG />
                                                </span>
                                                <span style={{ height: '32px' }} onClick={() => handleOpen(item.id)}>
                                                    <DeleteSVG />
                                                </span>
                                            </div>
                                        </div>
                                        <div className={customStyles.refDesignation}>
                                            {item.designation} | {item.organization}
                                        </div>
                                        <div className={customStyles.refMobileNo}>
                                            {item.mobile_number ? `+880${defaultMobileNumber(item.mobile_number)} |` : ``} {item.email}
                                        </div>
                                    </div>
                                )
                            })
                    }
                </div>
            </div>
            {/* reference add fields */}
            <CandidateUpdateModal form="candidateReferenceAddForm" open={addModalOpen} onClose={() => [setErrors({}), setAddModalOpen(false), setReferenceAddData({})]} title='Add References'>
                <div>
                    <div className='card-body'>
                        <form id='candidateReferenceAddForm' onSubmit={(e) => { e.preventDefault(); setLoader(true); insertCandidateReference(referenceAddData); }}>
                            <div className={`row text-left`}>

                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className='form-group'>
                                        <label>Name <RequiredAsterisk/></label>
                                        <input className='form-control atb-input-box' onChange={handleChange('name')} placeholder='Enter name' type='text' />
                                        {errors.name && <Alert severity="error">{errors.name}</Alert>}
                                    </div>
                                </div>

                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className='form-group'>
                                        <label>Designation <RequiredAsterisk/></label>
                                        <input className='form-control atb-input-box' onChange={handleChange('designation')} placeholder='Enter designation' type='text' />
                                        {errors.designation && <Alert severity="error">{errors.designation}</Alert>}
                                    </div>
                                </div>

                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className='form-group'>
                                        <label>Email Address <RequiredAsterisk/></label>
                                        <input className='form-control atb-input-box' onChange={handleChange('email')} placeholder='Enter email' type='email' />
                                        {errors.email && <Alert severity="error">{errors.email}</Alert>}
                                    </div>
                                </div>

                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className='form-group'>
                                        <label>Relation</label>
                                        <input className='form-control atb-input-box' onChange={handleChange('relation')} placeholder='Enter relation' type='text' />
                                        {errors.relation && <Alert severity="error">{errors.relation}</Alert>}
                                    </div>
                                </div>

                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className="form-group">
                                        <label>Mobile Number</label>									
									    <div className="mb-3" style={{position: "relative"}}>
                                            <span style={{position: "absolute", zIndex: 20, padding: "15px", fontSize: "14px", borderRight: "1px solid #E1E6EB"}}>+880</span>
                                            <input 
                                               style={{ paddingLeft: "76px", }} 
                                               className='form-control atb-input-box' 
                                               onChange={handleChange('mobile_number')} 
                                               placeholder='Enter mobile number' 
                                               type='number' 
                                               onKeyDown={(e) => handleKeyDown(e)}
                                            />
                                        </div>
                                        {errors.mobile_number && <Alert severity="error">{errors.mobile_number}</Alert>}
								    </div>
                                </div>

                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className='form-group'>
                                        <label>Organization</label>
                                        <input className='form-control atb-input-box' onChange={handleChange('organization')} placeholder='Enter organization' type='text' />
                                        {errors.organization && <Alert severity="error">{errors.organization}</Alert>}
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </CandidateUpdateModal>

            {/* reference update fields */}
            <CandidateUpdateModal form="candidateReferenceUpdateForm" open={updateModalOpen} onClose={() => [setUpdateErrors({}), setUpdateModalOpen(false), setUpdateReferenceData({})]} title='Update References'>
                <div>
                    <div className='card-body'>
                        <form id='candidateReferenceUpdateForm' onSubmit={(e) => { e.preventDefault(); setLoader(true); updateReference(updateReferenceData); }}>
                            <div className='row'>
                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className='form-group'>
                                        <label>Name <RequiredAsterisk/></label>
                                        <input value={updateReferenceData.name} className='form-control atb-input-box' onChange={handleUpdateChange('name')} placeholder='Enter name' type='text' />
                                        {updateErrors.name && <Alert severity="error">{updateErrors.name}</Alert>}
                                    </div>
                                </div>

                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className='form-group'>
                                        <label>Designation <RequiredAsterisk/></label>
                                        <input value={updateReferenceData.designation} className='form-control atb-input-box' onChange={handleUpdateChange('designation')} placeholder='Enter designation' type='text' />
                                        {updateErrors.designation && <Alert severity="error">{updateErrors.designation}</Alert>}
                                    </div>
                                </div>

                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className='form-group'>
                                        <label>Email Address <RequiredAsterisk/></label>
                                        <input value={updateReferenceData.email} className='form-control atb-input-box' onChange={handleUpdateChange('email')} placeholder='Enter email' type='email' />
                                        {updateErrors.email && <Alert severity="error">{updateErrors.email}</Alert>}
                                    </div>
                                </div>

                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className='form-group'>
                                        <label>Relation</label>
                                        <input value={updateReferenceData.relation} className='form-control atb-input-box' onChange={handleUpdateChange('relation')} placeholder='Enter relation' type='text' />
                                        {updateErrors.relation && <Alert severity="error">{updateErrors.relation}</Alert>}
                                    </div>
                                </div>

                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className="form-group">
                                        <label>Mobile Number</label>									
									    <div className="mb-3" style={{position: "relative"}}>
                                            <span style={{position: "absolute", zIndex: 20, padding: "15px", fontSize: "14px", borderRight: "1px solid #E1E6EB"}}>+880</span>
                                            <input 
                                               style={{ paddingLeft: "76px", }} 
                                               className='form-control atb-input-box' 
                                               value={defaultMobileNumber(updateReferenceData.mobile_number)}
                                               onChange={handleUpdateChange('mobile_number')} 
                                               placeholder='Enter mobile number' 
                                               type='number' 
                                               onKeyDown={(e) => handleKeyDown(e)}
                                            />
                                        </div>
                                        {updateErrors.mobile_number && <Alert severity="error">{updateErrors.mobile_number}</Alert>}
								    </div>
                                </div>

                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className='form-group'>
                                        <label>Organization</label>
                                        <input value={updateReferenceData.organization} className='form-control atb-input-box' onChange={handleUpdateChange('organization')} placeholder='Enter organization' type='text' />
                                        {updateErrors.organization && <Alert severity="error">{updateErrors.organization}</Alert>}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </CandidateUpdateModal>
            <UpdateDeleteConfirmationModal open={openDeleteModal} onClose={handleClose} deleteItem={() => deleteReference(deleteItemId)} />
        </div>
    )
}

export default UpdateReference