// スタイル
import "swiper/swiper-bundle.min.css";
import "./Slider.css";

// ライブラリ
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Virtual, Autoplay, Pagination, Navigation } from "swiper";
import SwiperPropTypes from "./SwiperPropTypes";
import { Box, Typography } from "@mui/material";

// コンポーネント
const SliderComponent = ({ slides: propsSlides, params: propsParams }) => {
  /**
   * 変数定義
   */
  // スライダーのID
  const id = useMemo(() => {
    return (
      "SliderComponent--" +
      Math.random()
        .toString(36)
        .substring(2)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsSlides]);

  // スライダーの内容とパラメーター
  const [swiperProps, setSwiperProps] = useState({
    slides: undefined,
    params: {},
  });

  // Swiperのインスタンス
  const swiperRef = useRef(null);
  const [swiper, setSwiper] = useState(null);

  // autoplay
  const [autoplay, setAutoplay] = useState(false);
  const autoplayIntervalRef = useRef(null);
  const startAutoplayRef = useRef(null);

  /**
   * 関数定義
   */
  // 現在のslidesPerViewを取得
  const getSlidesPerView = useCallback(() => {
    if (!swiper || !swiperProps.params) return 1;

    // 現在のviewport widthを取得
    const viewportWidth = window.innerWidth;
    //
    let slidesPerView = swiperProps.params.slidesPerView;
    // breakpointの設定がある場合、現在のviewport widthに合わせてslidesPerViewを変更
    if (swiperProps.params.breakpoints) {
      const breakpoints = Object.keys(swiperProps.params.breakpoints);
      breakpoints.forEach((breakpoint) => {
        if (viewportWidth >= breakpoint) {
          slidesPerView =
            swiperProps.params.breakpoints[breakpoint].slidesPerView;
        }
      });
    }
    return slidesPerView || 1;
  }, [swiper, swiperProps.params]);

  // 前のスライドを表示
  const onClickPrev = useCallback(() => {
    if (!swiper || !swiperProps.params) return;

    const slidesPerView = getSlidesPerView();
    if (slidesPerView > 1) {
      for (let i = 0; i < slidesPerView; i++) {
        swiper.slidePrev();
      }
    } else {
      swiper.slidePrev();
    }
  }, [getSlidesPerView, swiper, swiperProps.params]);

  // 次のスライドを表示
  const onClickNext = useCallback(() => {
    if (!swiper || !swiperProps.params) return;

    const slidesPerView = getSlidesPerView();
    if (slidesPerView > 1) {
      for (let i = 0; i < slidesPerView; i++) {
        swiper.slideNext();
      }
    } else {
      swiper.slideNext();
    }
  }, [getSlidesPerView, swiper, swiperProps.params]);

  /**
   * ライフサイクル
   */
  // スライダーの内容とパラメーターを更新
  useEffect(() => {
    if (!!propsSlides) {
      // スライドの内容が変更された場合、スライドの内容を更新
      let newSlides = [...(propsSlides || [])]; // propsSlidesの内容をコピー

      // Swiperのパラメーターが変更された場合、Swiperのインスタンスを更新
      let newParams = { ...(propsParams || {}) };
      if (newParams.navigation) {
        newParams = {
          ...newParams,
          navigation: false,
        };
      }
      if (newParams.virtual) {
        newParams = {
          ...newParams,
          virtual: {
            ...newParams.virtual,
          },
        };
      }
      if (newParams.slidesPerView && newParams.slidesPerView !== "auto") {
        let slidesPerView = getSlidesPerView();
        if (newSlides && newSlides.length <= slidesPerView) {
          newParams = {
            ...newParams,
            virtual: false,
            loop: false,
            centeredSlides: false,
            navigation: false,
            autoplay: false,
          };
        }
      }

      if (newParams.autoplay && !autoplay) {
        newParams = {
          ...newParams,
          autoplay: false,
        };
        setAutoplay(true);
      } else if (!newParams.autoplay && autoplay) {
        setAutoplay(false);
      }

      setSwiperProps({
        slides: newSlides,
        params: newParams,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiper, id, propsParams, propsSlides]);

  // Swiperのインスタンスを更新
  useEffect(() => {
    if (swiper) {
      swiper.update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiper, swiperProps]);

  // autoplayを更新
  useEffect(() => {
    if (!swiper || !swiperProps.params) {
      clearInterval(autoplayIntervalRef.current);
      return;
    }

    const startAutoplay = () => {
      autoplayIntervalRef.current = setInterval(() => {
        if (!swiper || !swiper.params) {
          clearInterval(autoplayIntervalRef.current);
          return;
        }
        const slidesPerView = getSlidesPerView();
        if (slidesPerView > 1) {
          for (let i = 0; i < slidesPerView; i++) {
            swiper.slideNext();
          }
        } else {
          swiper.slideNext();
        }
      }, swiperProps.params?.autoplay?.delay || 5000);
    };

    startAutoplayRef.current = startAutoplay;

    const resetAutoplay = () => {
      clearInterval(autoplayIntervalRef.current);
      startAutoplayRef.current();
    };

    if (!autoplay) {
      clearInterval(autoplayIntervalRef.current);
      swiper.off("touchStart", resetAutoplay);
      swiper.off("touchEnd", startAutoplayRef.current);
      swiper.off("slideChange", resetAutoplay);
    }

    startAutoplayRef.current();

    if (swiper) {
      swiper.on("touchStart", resetAutoplay);
      swiper.on("touchEnd", resetAutoplay);
      swiper.on("slideChange", resetAutoplay);
    }

    return () => {
      clearInterval(autoplayIntervalRef.current);
      if (swiper) {
        swiper.off("touchStart", resetAutoplay);
        swiper.off("touchEnd", resetAutoplay);
        swiper.off("slideChange", resetAutoplay);
      }
    };
  }, [autoplay, getSlidesPerView, swiper, swiperProps.params]);

  /**
   * レンダリング
   */
  // スライドの内容がない場合、スライダーを表示しない
  if (
    !swiperProps.params ||
    !swiperProps.slides ||
    swiperProps.slides.length === 0
  ) {
    if (!!propsSlides) {
      return (
        <Box
          component="div"
          sx={{
            maxWidth: "calc(100% - 24px - 24px)",
            width: 1500,
            height: 70,
            p: 3,
            border: "1px dashed grey",
            margin: 3,
          }}
        >
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            No Jobs Available
          </Typography>
        </Box>
      );
    } else {
      return null;
    }
  }

  // スライダーを表示
  return (
    <div key={id} id={id} className="SliderComponent">
      <Swiper
        key={`${id}--swiper`}
        ref={swiperRef}
        modules={[
          Virtual,
          Autoplay,
          // Pagination,
          Navigation
        ]}
        onSwiper={setSwiper}
        {...swiperProps.params}
      >
        {swiperProps.slides.map((slideContent, index) => (
          <SwiperSlide key={`${id}--slide--${index}`} virtualIndex={index}>
            {slideContent}
          </SwiperSlide>
        ))}
      </Swiper>

      <>
        <div
          key={`${id}--prev`}
          onClick={onClickPrev}
          className="SliderComponent__button SliderComponent__button--prev"
        />
        <div
          key={`${id}--next`}
          onClick={onClickNext}
          className="SliderComponent__button SliderComponent__button--next"
        />
      </>
    </div>
  );
};

SliderComponent.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.node),
  params: SwiperPropTypes,
};

export default SliderComponent;
