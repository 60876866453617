import React from 'react'
import HeroSection from '../components/HeroSection/HeroSection'
import { Card } from 'react-bootstrap'
import { textAlign } from '@mui/system'
import PublicPageHeroSections from '../components/HeroSection/PublicPageHeroSections'

function OurStoryv2() {
    return (
        <div>
            <PublicPageHeroSections path='/v2/images/our-story.jpg' text='We strive for high quality job satisfaction levels' />

            <div className='container m-t30'>

                {/* Mission and Vision */}
                <div className='row'>
                    <div className='col-lg-6 col-md-6'>
                        <Card className='atb-card mb-5 text-white' style={{ backgroundColor: '#03254c' }}>
                            <h1 className='mt-3'>Mission</h1>
                            <h5 style={{ padding: '0px 30px 30px 30px' }}>Through us, we make both companies and individuals smile.
                                This will be a job-matching website where you can test more of your potential and more of your company's potential.</h5>
                        </Card>
                    </div>
                    <div className='col-lg-6 col-md-6'>
                        <Card className='atb-card mb-5 text-white' style={{ backgroundColor: '#008bcc' }}>
                            <h1 className='mt-3'>Vision</h1>
                            <h5 style={{ padding: '0px 30px 30px 30px' }}>We will create an HR portal that you will want to check every day. It will be a product that both businesses and individuals will say atBJobs is the No.1 job matching site.</h5>
                        </Card>
                    </div>
                </div>

                {/* Who we are */}
                <div className='row m-t50'>
                    <div className='col-lg-12 col-md-12'>
                        <Card className='atb-card mb-5 text-white' style={{ backgroundColor: '#2b3940' }}>
                            <div className='row' style={{ alignItems: 'center' }}>
                                <div className='col-lg-5 col-md-5 text-left'>
                                    <div className='container mb-2'>
                                        <h1 className='mt-3'>WHO WE ARE?</h1>
                                        <h5 style={{ lineHeight: '2', textAlign: 'justify' }}> We have been working with Japanese companies in Bangladesh since 2019 on development projects with Japanese companies.As we enter 2022, we have started the product development of atBJobs because we want to create a global standard job matching website with DX-orientation.</h5>
                                    </div>
                                </div>
                                <div className='col-lg-7 col-md-7'>
                                    <img src='/v2/images/aboutus.png' />
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>

                {/* Core Strengths */}

                <div className='row m-t100'>
                    <div className='col-lg-12 col-md-12'>
                        <h1 class="display-4 text-center p-3 mb-5 d-none d-lg-block" style={{ color: '#03254c', fontWeight: 'bold', letterSpacing: '5px' }}>CORE STRENGTHS</h1>
                        <h1 class="display-5 text-center p-3 mb-5 d-lg-none" style={{ color: '#03254c', fontWeight: 'bold', letterSpacing: '5px' }}>CORE STRENGTHS</h1>
                    </div>
                </div>

                <div className='row  m-t50'>
                    <div className='col-lg-6 col-md-6 m-b50'>
                        <div class="card border-light bg-transparent mb-5">
                            <center>
                                <img src='/v2/icons/japan.png' className="p-1" alt="..." height={'300px'} width={'300px'} />
                            </center>
                            <div class="card-body  m-t30">
                                <h5 style={{ fontSize: '30px', color: "#2b3940" }} class="lead text-center">
                                    Development experience with several Japanese companies and global standards.
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6m-b50'>
                        <div class="card border-light bg-transparent mb-5">
                            <center>
                                <img src='/v2/icons/box.png' className="p-1" alt="..." height={'300px'} width={'300px'} />
                            </center>
                            <div class="card-body  m-t30">
                                <h5 style={{ fontSize: '30px', color: "#2b3940" }} class="lead text-center">
                                    Interviews conducted with over 200 recruiters. The product was developed with their input.
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row m-t50'>
                    <div className='col-lg-6 col-md-6 m-b50'>
                        <div class="card border-light bg-transparent mb-5">
                            <center>
                                <img src='/v2/icons/megaphone.png' className="p-1" alt="..." height={'300px'} width={'300px'} />
                            </center>
                            <div class="card-body  m-t30">
                                <h5 style={{ fontSize: '30px', color: "#2b3940" }} class="lead text-center">
                                    Job matching as well as headhunting and detailed screening.
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 m-b50'>
                        <div class="card border-light bg-transparent mb-5">
                            <center>
                                <img src='/v2/icons/chat.png' className="p-1" alt="..." height={'300px'} width={'300px'} />
                            </center>
                            <div class="card-body m-t30">
                                <h5 style={{ fontSize: '30px', color: "#2b3940" }} class="lead text-center">
                                    Native Japanese, English and Bengali speaking workers
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default OurStoryv2