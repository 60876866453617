
/** Imports */

import React, { createContext, useState, useEffect, useContext } from "react"
import { getEmployerSelf, getNotifications } from "../../fetchAPI";
import { UserContext } from "./UserContext";
import Cookies from 'js-cookie'

/** Context Imports */
export const EmployerContext = createContext();


const EmployerContextProvider = (props) => {

  // UseState Declarations
  const [employerData, setEmployerData] = useState('');
  const [employerDataLoading, setEmployerDataLoading] = useState(false)
  const [defaultSpeacialization, setDefaultSpeacialization] = useState('')

  const [employePreviousBenefits, setEmployePreviousBenefits] =useState([])

  //Job status on Manage job section
  const [status, setStatus] = useState('')

  const [savedCandidateIds, setSavedCandidateIds] = useState([])

  // UseContext Declarations
  const { isLoggedin } = useContext(UserContext)

// console.log('Employer Context rendered!')
  /**
   * Receiving Employer Data from fetch.js and setting context state
   */
  const settingEmployerData = () => {
    setEmployerDataLoading(true)
    /** if user is logged in set User ID to {user_id} */ 

    if (isLoggedin) {
      const user = JSON.parse(Cookies.get('user'))
      const user_id = user.id

      /**
       * Receiving Employer Data
       * 
       * @param {number} user_id - User ID of the Employer 
       */

      getEmployerSelf().then((response) => {
        if (response && response.success) {
          setEmployerData(response.response.data);
          setDefaultSpeacialization(response.response.data.specialization?.map(val => ({ label: val.name, value: val.id })))
          setSavedCandidateIds(response.response.data.saved_candidates?.map(val=>val.id))
          setEmployePreviousBenefits(response.response.data?.employer_benefits?.map(val=>val.employer_add_info_option_id))
          setEmployerDataLoading(false)
        }
        else {
          // console.log('errors:', response);
          setEmployerDataLoading(false)

        }
      })
    }
  }

  useEffect(() => {
    settingEmployerData()
  }, [isLoggedin])
  
  return (
    <EmployerContext.Provider value={{
      employerData,
      setEmployerData,
      settingEmployerData,
      defaultSpeacialization,
      setDefaultSpeacialization,
      employerDataLoading,
      setEmployerDataLoading,
	  savedCandidateIds,
    status, setStatus,
    employePreviousBenefits,
    setEmployePreviousBenefits
    }}>
      {props.children}
    </EmployerContext.Provider>
  );

};

export default EmployerContextProvider;