import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link } from 'react-scroll';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height:'4px',
    
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: '80%',
    width: '100%',
    borderRadius: '5px 5px 0px 0px',
    backgroundColor: '#635ee7',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: 600,
    fontFamily: 'Epilogue',
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.spacing(1),
    color: '#1D1E1B',
    '&.Mui-selected': {
      color: '#0275D8',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
    '&.MuiButtonBase-root':{
      outline:'none'
    }
  }),
);

export default function ScrollableTabAndTabScreen() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{width:'300px',margin:'50px'}}>
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="styled tabs example"
        variant="scrollable"
        scrollButtons="auto"
        sx={{ fontFamily: 'Epilogue' }}
      >
        <StyledTab label={<Link smooth={true} offset={-70} duration={500} to='about_box'>
							About Me
						</Link>} />
        <StyledTab label="Datasets" />
        <StyledTab label="Connections" />
      </StyledTabs>
    </div>
  );
}