import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { Box, Grid, Typography } from '@mui/material';

export default function FaqAccordion() {

    const dataLeft = [
        {
            'title': "Q: How to create a profile and apply to a job?",
            'description': "Ans: To create a profile click on the “Candidate register” on the top-right of the screen and follow the steps to create you account. After creating your account click on the “Find job” section on the top of the page and use the search to find the right job for you."
        },
        {
            'title': "Q: How do I contact the customer support team?",
            'description': "Ans: To connect with our team please visit https://atb-jobs.com/contact-us. You can also call us at +880 1400-492811 (10am – 5pm, Sunday to Thursday)"
        },
    ]

    const dataRight = [
        {
            'title': "Q: How do I use “search” to find the right job",
            'description': "Ans: You can search for jobs on our home page : https://atb-jobs.com/ To search more by specific details you can use the advanced search on : https://atb-jobs.com/search/jobs Here you will be able to filter by job title, location, industry, salary range and many more.You can search for jobs on our home page : https://atb-jobs.com/ To search more by specific details you can use the advanced search on : https://atb-jobs.com/search/jobs Here you will be able to filter by job title, location, industry, salary range and many more."
        },
        {
            'title': "Q: Is atB jobs a Japanese company?",
            'description': "Ans: atB Jobs is part of atB Lab Bangladesh. While our headquarters are in Japan, and our top management team is Japanese, we operate specifically within Bangladesh."
        },

    ]


    return (
        <div>
            <Grid container spacing={{ md: 5, xs: 0 }}>
                <Grid item md={6} xs={12}>
                    {dataLeft.map((item, index) => {
                        return (
                            <Box m={2} key={index}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography
                                            variant='h5'
                                            textAlign={'left'}
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                color: '#424447',
                                                '@media (max-width: 600px)': {
                                                    fontSize: '14px'
                                                },
                                            }}
                                        >
                                            {item.title}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant='body'
                                            textAlign={'left'}
                                            sx={{
                                                fontSize: '14px',
                                                fontWeight: 400,
                                                color: '#424447',
                                                '@media (max-width: 600px)': {
                                                    fontSize: '13px'
                                                },
                                            }}
                                        >
                                            {item.description}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        )
                    })}
                </Grid>
                <Grid item md={6} xs={12}>
                    {dataRight.map((item, index) => {
                        return (
                            <Box m={2} key={index}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography
                                            variant='h5'
                                            textAlign={'left'}
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                color: '#424447',
                                                '@media (max-width: 600px)': {
                                                    fontSize: '14px'
                                                },
                                            }}
                                        >
                                            {item.title}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography
                                            variant='body'
                                            textAlign={'left'}
                                            sx={{
                                                fontSize: '14px',
                                                fontWeight: 400,
                                                color: '#424447',
                                                '@media (max-width: 600px)': {
                                                    fontSize: '13px'
                                                },
                                            }}
                                        >
                                            {item.description}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        )
                    })}
                </Grid>
            </Grid>
        </div>
    );
}
