import React from 'react'
import CandidateDashboardSidebar from '../../components/CandidateComponents/CandidateDashboardSideBar/CandidateDashboardSidebar'
import GenerateResume from '../../components/CandidateComponents/CandidateCVManager/GenerateResume'
import { Box, Grid } from "@mui/material";

function ResumeGenerate() {
  return (
  <div className='' >
  <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, position: 'fixed', height: '70px', backgroundColor: '#0275D8', width: '100%', zIndex: 10 }}></Box>

  <Grid container>
    <Grid item xs={0} sm={0} md={3} lg={2.5} xl={2} sx={{ zIndex: 11, display: { xs: 'none', sm: 'none', md: 'block' } }}>
      <CandidateDashboardSidebar />
    </Grid>
    <Grid item xs={12} sm={12} md={9} lg={9.5} xl={10} sx={{zIndex: 11, padding: { xs: '0px', sm: '0px', md: '10px' }, marginTop: {xs:"0px", sm:"0px", md: "20px"} }}>
    <GenerateResume />
    </Grid>
  </Grid>
  </div>
  )
}

export default ResumeGenerate