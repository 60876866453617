import { Avatar, Box, Button, Card, Divider, Grid, Pagination, Skeleton, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { getMessageThread } from "../../../fetchAPI";
import { Link } from 'react-router-dom'
import { UserContext } from "../../context/UserContext";
import {Card as BCard} from "react-bootstrap";

export default function CandidateMessage() {

    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [paginateInfo, setPaginateInfo] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const { userData } = useContext(UserContext);


    const settingCandidateMessage = (page) => {
        setLoading(true);
        getMessageThread(page)
            .then(response => {
                if (response && response.success) {
                    setMessages(response?.response?.data)
                    setPaginateInfo(response.response.pagination)
                } else {
                    // console.log('Error: ', response)
                }
            }).finally(() => setLoading(false))
    }

    useEffect(() => {
        settingCandidateMessage(1)
    }, [])

    useEffect(() => {
        settingCandidateMessage(currentPage)
    },[currentPage]);

    return (
        <BCard className="shadow-sm" style={{margin:'20px'}}>
            <div className='container'>
                <Stack spacing={1} m={2}>
                    <Typography
                        variant="h5"
                        align="left"
                        sx={{fontWeight: '700',fontFamily: 'Epilogue'}}
                    >
                        Messages
                    </Typography>
                    <Typography
                        variant="body2"
                        align="left"
                        sx={{
                            color: '#72737C'
                        }}
                    >
                        {paginateInfo.total > 0 ? 'Employers have contacted you with messages' : ''}
                    </Typography>
                </Stack>
                <Divider />

                <Box mt={2}>
                        {
                            loading ?
                                <Stack spacing={2}>
                                    <Card>
                                        <Grid container spacing={2} p={3} justifyContent={{ xs: 'center', md: 'space-around' }} alignItems={'center'}>
                                            <Grid item md={9} xs={12}>
                                                <Stack direction={'row'} spacing={2}>
                                                    <Skeleton variant="square" height={100} width={100} />
                                                    <Stack spacing={2}>
                                                        <Skeleton variant="text" height={40} width={400} />
                                                        <Skeleton variant="text" height={20} width={200} />
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                            <Grid item md={3} xs={12}>

                                            </Grid>
                                        </Grid>
                                    </Card>
                                    <Card>
                                        <Grid container spacing={2} p={3} justifyContent={{ xs: 'center', md: 'space-around' }} alignItems={'center'}>
                                            <Grid item md={9} xs={12}>
                                                <Stack direction={'row'} spacing={2}>
                                                    <Skeleton variant="square" height={100} width={100} />
                                                    <Stack spacing={2}>
                                                        <Skeleton variant="text" height={40} width={400} />
                                                        <Skeleton variant="text" height={20} width={200} />
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                            <Grid item md={3} xs={12}>

                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Stack>
                                :
                                <Stack spacing={2}>
                                    {
                                        messages.length !==0 ? messages.map(((message, index) => {

                                            let attachmentNameArray;
                                            let attachmentName;
                                            if (message.attachment != null) {
                                                attachmentNameArray = message.attachment.split('/');
                                                attachmentName = attachmentNameArray[attachmentNameArray.length - 1];
                                            }
                                            let chatHeadText = '';

                                            if(message.from_id == userData.id)
                                            {
                                                chatHeadText = `You sent a message to ${message?.name} ${message.attachment ? `with an attachment!` : '!'}`
                                            }else{
                                                chatHeadText = `Exclusive Update! ${message?.name} has sent you a message ${message.attachment ? `with an attachment!` : '!'}`;
                                            }

                                            return (
                                                <Card key={index}>
                                                    <Grid container spacing={2} p={3} justifyContent={{ xs: 'center', md: 'space-around' }} alignItems={'center'}>
                                                        <Grid item md={9} xs={12}>
                                                            <Stack direction={'row'} spacing={2}>
                                                                <img
                                                                    src={message.partner_profile_pic ? message.partner_profile_pic : "/v2/images/employerPlaceholder.png"}
                                                                    style={{ height: '100px', objectFit: 'cover' }}
                                                                    alt="Employer Placeholder"
                                                                />
                                                                <Stack spacing={1} justifyContent={'space-around'}>
                                                                    <Typography
                                                                        variant="h5"
                                                                        align="left"
                                                                        sx={{
                                                                            fontSize: { xs: '12px', md: '16px' },
                                                                            fontWeight: 600,
                                                                            color: '#0275D8'
                                                                        }}>
                                                                        {chatHeadText}
                                                                    </Typography>
                                                                    {
                                                                        message?.attachment ?
                                                                            <Box
                                                                                sx={{
                                                                                    backgroundColor: '#E4F2FF',
                                                                                    padding: 1,
                                                                                    maxWidth: { xs: '100%', md: 'fit-content' }
                                                                                }}
                                                                            >
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    align="left"
                                                                                    sx={{
                                                                                        fontSize: '12px',
                                                                                        fontWeight: 600
                                                                                    }}>
                                                                                    <a href={message?.attachment} target="_blank">{attachmentName}</a>
                                                                                </Typography>
                                                                            </Box>
                                                                            :
                                                                            null
                                                                    }
                                                                </Stack>
                                                            </Stack>
                                                        </Grid>
                                                        <Grid item md={3} xs={12}>
                                                            <Link to={`/messages/${message.partnerid}`}>
                                                                <Button
                                                                    variant="contained"
                                                                    fullWidth
                                                                    sx={{
                                                                        textTransform: 'capitalize',
                                                                    }}
                                                                >
                                                                    Reply Message
                                                                </Button>
                                                            </Link>

                                                        </Grid>
                                                    </Grid>
                                                    {message.total_unread_message > 0 ? <small style={{color:'red',fontWeight:'bold'}}>You have {message.total_unread_message} unread messages.</small> : ''}
                                                </Card>
                                            )
                                        })) : 'There is No message'
                                    }
                                    {
                                        messages.length !== 0 ?
                                            <div style={{ padding: '30px 20px', display: "flex", justifyContent: "end" }}>
                                                <Pagination page={currentPage} count={paginateInfo.last_page} color="primary" shape="rounded" boundaryCount={1} onChange={(e, v) => setCurrentPage(v)} />
                                            </div>
                                            :
                                            ''
                                    }
                                </Stack>
                        }
                </Box>
            </div>
        </BCard>
    )
}