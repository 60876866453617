import React from "react";
import { Link } from 'react-router-dom'

export default function ProfileStrengthView({candidateData,profileStrength}) {
    return (
        <div className='card shadow-sm steps' style={{ padding: '10px', position: 'sticky', top: '147px', marginRight: '10px' }}>
            <div className='text-center'>
                <div style={{ fontFamily: 'Epilogue', fontSize: '16px', fontWeight: '600', color: '#424447', marginBottom: "10px" }}>Your Profile is {profileStrength}% Complete. </div>
                {
                    profileStrength < 100 ?
                    <div style={{ fontFamily: 'Epilogue', fontSize: '16px', fontWeight: '600', color: '#424447', marginBottom: "10px" }}>Complete the following steps to finish setting up your profile</div>
                    :
                    ''
                }
            </div>
            <hr />
            <div className='main-step'> <img src={candidateData.first_name && candidateData.dob && candidateData.phone_no != '' ? '/v2/icons/check.png' : '/v2/icons/unfinished.png'} /> <span> Basic Details (+30%)</span>
                <div className='sub-steps' style={{ marginLeft: '25px' }}>
                    <div><img src={candidateData.first_name ? '/v2/icons/check.png' : '/v2/icons/unfinished.png'} /><span> Full Name </span></div>
                    <div><img src={candidateData.dob ? '/v2/icons/check.png' : '/v2/icons/unfinished.png'} />
                        <Link to='/candidate-update-profile#about_box'>
                            <span>
                                Date of Birth (+10%)
                            </span>
                        </Link>
                    </div>
                    <div><img src={candidateData.phone_no ? '/v2/icons/check.png' : '/v2/icons/unfinished.png'} />
                        <Link to='/candidate-update-profile#about_box'>
                            <span>
                                Phone Number (+10%)
                            </span>
                        </Link>
                    </div>
                </div>
            </div>

            <div className='main-step'> <img src={candidateData.intro ? '/v2/icons/check.png' : '/v2/icons/unfinished.png'} />
                <Link to='/candidate-update-profile#about_box'>
                    <span>
                        About Section (+10%)
                    </span>
                </Link>
            </div>

            <div className='main-step'> <img src={candidateData.skills?.length != 0 ? '/v2/icons/check.png' : '/v2/icons/unfinished.png'} />
                <Link to='/candidate-update-profile#skill_box'>
                    <span>
                        Skill (+10%)
                    </span>
                </Link>
            </div>

            <div className='main-step'> <img src={candidateData.candidate_experience?.length != 0 ? '/v2/icons/check.png' : '/v2/icons/unfinished.png'} />
                <Link to='/candidate-update-profile#employment_box'>
                    <span>
                        Employment History (+10%)
                    </span>
                </Link>
            </div>

            <div className='main-step'> <img src={candidateData.candidate_education?.length != 0 ? '/v2/icons/check.png' : '/v2/icons/unfinished.png'} />
                <Link to='/candidate-update-profile#education_box'>
                    <span>
                        Education History (+10%)
                    </span>
                </Link>
            </div>

            <div className='main-step'> <img src={candidateData.certification?.length != 0 ? '/v2/icons/check.png' : '/v2/icons/unfinished.png'} />
                <Link to='/candidate-update-profile#certificate_box'>
                    <span>
                        Certificate History (+10%)
                    </span>
                </Link>
            </div>

            <div className='main-step'> <img src={candidateData.projects?.length != 0 ? '/v2/icons/check.png' : '/v2/icons/unfinished.png'} />
                <Link to='/candidate-update-profile#project_box'>
                    <span>
                        Project History (+10%)
                    </span>
                </Link>
            </div>

            <div className='main-step'> <img src={candidateData.candidate_resume?.length != 0 ? '/v2/icons/check.png' : '/v2/icons/unfinished.png'} />
                <Link to='/cv-manager'>
                    <span>
                    Resume (+10%)
                    </span>
                </Link>
            </div>
        </div>
    )
}