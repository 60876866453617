import React, { useContext, useEffect } from 'react'
import CandidateUpdateProfileCover from '../../components/CandidateComponents/CandidateProfile/UpdateProfile/CandidateUpdateProfileCover'
import CandidateUpdateDetails from '../../components/CandidateComponents/CandidateProfile/UpdateProfile/CandidateUpdateDetails'
import { DropdownContext } from '../../context/DropdownContext'
import CandidateDashboardSidebar from '../../components/CandidateComponents/CandidateDashboardSideBar/CandidateDashboardSidebar'
import { Box, Grid } from '@mui/material'
import CandidateUpdateNav from '../../components/CandidateComponents/CandidateProfile/UpdateProfile/CandidateUpdateNav'
import ProfileStrengthView from '../../components/CandidateComponents/CandidateProfile/UpdateProfile/ProfileStrengthView'
import { CandidateContext } from '../../context/CandidateContext'


function CandidateUpdateProfile() {

  const { apiCallingConditionSet, setApiCallingConditionSet } = useContext(DropdownContext)
  const { candidateData, profileStrength } = useContext(CandidateContext)

  useEffect(() => {
    setApiCallingConditionSet({
      ...apiCallingConditionSet,
      qualification: true,
      type: true,
      level: true,
      department: true,
      industry: true,
      position: true,
      skill: true,
      shift: true,
      salaryType: true,
      district: true,
      social: true,
      currency: true
    })
  }, [])
  return (
    <div className='' >
      <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, position: 'fixed', height: '70px', backgroundColor: '#0275D8', width: '100%', zIndex: 10 }}></Box>

      <Grid container>
        <Grid item xs={0} sm={0} md={3} lg={2.5} xl={2} sx={{ zIndex: 11, display: { xs: 'none', sm: 'none', md: 'block' } }}>
          <CandidateDashboardSidebar />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={7} xl={8} sx={{ padding: { xs: '0px', sm: '0px', md: '10px' } }}>
          <CandidateUpdateNav />
          <CandidateUpdateDetails />
        </Grid>
        <Grid item xs={0} sm={0} md={3} lg={2.5} xl={2} sx={{ zIndex: 11, display: { xs: 'none', sm: 'none', md: 'block' } }}>
          <ProfileStrengthView candidateData={candidateData} profileStrength={profileStrength}/>
        </Grid>
      </Grid>
    </div>
  )
}

export default CandidateUpdateProfile