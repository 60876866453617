import React, { useState, useEffect, useRef } from 'react'
import { Alert, Box, CircularProgress } from '@mui/material'
import { postCandidateEducationByID, deleteCandidateEducationByID, updateCandidateEducationByID } from '../../../../../fetchAPI'
import UpdateDeleteConfirmationModal from './UpdateDeleteConfirmationModal'
import JoditEditor from 'jodit-react';
import CandidateUpdateModal from './component/CandidateUpdateModal'
import customStyles from './CandidateUpdateProfile.module.css'
import SingleEducationBlock from './component/SingleEducationBlock'
import EditSVG from './component/EditSVG'
import DeleteSVG from './component/DeleteSVG'
import AddButton from './component/AddButton'
import { useAlert } from '../../../../context/AlertContext'
import RequiredAsterisk from './component/RequiredAsterisk';

function UpdateEducation(props) {


    const [educationeData, setEducationData] = useState({})
    const [updateEducationData, setUpdateEducationData] = useState({})

    const [loader, setLoader] = useState(false)

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addFormRef = useRef();

    const alertContext = useAlert();



    const enableUpdate = (item) => {
        setUpdateEducationData({
            id: item.id,
            degree_name: item.degree_name,
            degree_institute: item.degree_institute,
            degree_start: item.degree_start,
            degree_end: item.degree_end,
            degree_grade: item.degree_grade,
            degree_detail: item.degree_detail,
            major: item.major
        })
    }

    const handleUpdateChange = (name) => (event) => {
        setUpdateEducationData({
            ...updateEducationData,
            [name]: event.target.value
        })
    }

    const handleChange = (name) => (event) => {
        setEducationData({ ...educationeData, [name]: event.target.value })
    }

    let errorsObj = { degree_name: '', degree_institute: '', degree_start: '', degree_end: '', degree_grade: '' };
    const [errors, setErrors] = useState('')
    const insertCandidateEducation = (candId, body) => {

        let error = false;

        const errorObj = { ...errorsObj };

        if (!body.degree_name) {
            errorObj.degree_name = 'Degree Name is Required';
            error = true;
        }
        if (!body.degree_institute) {
            errorObj.degree_institute = 'Institute Name is Required';
            error = true;
        }
        if (!body.degree_start) {
            errorObj.degree_start = 'Start Date is Required';
            error = true;
        }
        if (!body.degree_end) {
            errorObj.degree_end = 'End Date is Required';
            error = true;
        }
        if (!body.degree_grade) {
            errorObj.degree_grade = 'Grade is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            // Do not call the Post API
            setLoader(false);
        }
        else {
            setAddModalOpen(false);
            setEducationData({});
            postCandidateEducationByID(candId, body).then((response) => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    props.renderState()
                    setLoader(false);
                }
                else {
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                    setLoader(false)
                }
            })
        }
    }

    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

    const handleOpen = (itemId) => {
        setOpenDeleteModal(true);
        setDeleteItemId(itemId);
    };

    const handleClose = () => {
        setOpenDeleteModal(false);
    };

    const [deleteItemId, setDeleteItemId] = useState('')
    const deleteCandidateEducation = (candId, educationId) => {
        setOpenDeleteModal(false)
        setLoader(true)
        deleteCandidateEducationByID(candId, educationId).then((response) => {
            if (response.success) {
                alertContext.setResponseMessage(response.response.message)
                alertContext.setOpenAlert(true)
                // props.education.splice(index, 1);
                props.renderState()
                setLoader(false)
            }
            else {
                console.log('errors: ', response);
                const message = response.errors?.map(msg => {
                    alertContext.setResponseMessage(msg.message)
                })
                alertContext.setOpenErrorAlert(true)
                props.renderState()
                setLoader(false);
            }
        })
    }

    const [updateErrors, setUpdateErrors] = useState({})
    const updateCandidateEducation = (candId, body) => {

        let error = false;

        const errorObj = { ...errorsObj };

        if (!body.degree_name) {
            errorObj.degree_name = 'Degree Name is Required';
            error = true;
        }
        if (!body.degree_institute) {
            errorObj.degree_institute = 'Institute Name is Required';
            error = true;
        }
        if (!body.degree_start) {
            errorObj.degree_start = 'Start Date is Required';
            error = true;
        }
        if (!body.degree_end) {
            errorObj.degree_end = 'End Date is Required';
            error = true;
        }
        if (!body.degree_grade) {
            errorObj.degree_grade = 'Grade is Required';
            error = true;
        }
        setUpdateErrors(errorObj);
        if (error) {
            // Do not call the Post API
            setLoader(false);
        }
        else {
            setUpdateModalOpen(false);
            updateCandidateEducationByID(candId, body.id, body).then((response) => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    props.renderState()
                    setLoader(false);
                }
                else {
                    console.log('errors: ', response);
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                    setLoader(false);
                }
            })
        }
    }

    return (
        <div id='education_box'>
            <div className='card shadow-sm'>
                <div className={customStyles.cardHeader}>
                    <div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
                        <div className={customStyles.sectionHeader}>Education</div>
                        <AddButton title="Education" onClickHandler={() => setAddModalOpen(true)} />
                    </div>
                </div>
                <div className='card-body'>
                    {
                        loader ?
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                                <CircularProgress />
                            </Box>
                            :
                            props.education?.map((item, index) => {
                                return (
                                    <div key={item.id}>
                                        {index > 0 ? <hr /> : ''}
                                        <div className={customStyles.singleEducation} >
                                            <div className={customStyles.eduDegree}>
                                                {item.degree_name}
                                            </div>
                                            <div className={customStyles.actionContainer} style={{ marginLeft: '10px' }}>
                                                <span style={{ height: '32px' }} onClick={() => { enableUpdate(item); setUpdateModalOpen(true) }}>
                                                    <EditSVG />
                                                </span>
                                                <span style={{ height: '32px' }} onClick={() => handleOpen(item.id)}>
                                                    <DeleteSVG />
                                                </span>
                                            </div>
                                        </div>
                                        <SingleEducationBlock item={item} />
                                    </div>
                                )
                            })
                    }
                </div>
            </div>
            <CandidateUpdateModal form="candidateEducationAddForm" open={addModalOpen} onClose={() => [setErrors({}), setAddModalOpen(false), setEducationData({})]} title='New Education'>
                <div>
                    <div className='card-body'>
                        <form id='candidateEducationAddForm' ref={addFormRef} onSubmit={(e) => { e.preventDefault(); setLoader(true); insertCandidateEducation(props.candId, educationeData); }}>
                            <div className={`row text-left`}>

                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label>Degree <RequiredAsterisk/> </label>
                                        <input className='form-control atb-input-box' onChange={handleChange('degree_name')} placeholder='Enter your Degree' type='text' />
                                        {errors.degree_name && <Alert severity="error">{errors.degree_name}</Alert>}
                                    </div>
                                </div>
                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label>University or Institute Name <RequiredAsterisk/></label>
                                        <input className='form-control atb-input-box' onChange={handleChange('degree_institute')} placeholder='Enter Institute Name' type='text' />
                                        {errors.degree_institute && <Alert severity="error">{errors.degree_institute}</Alert>}
                                    </div>
                                </div>

                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label>Start Date <RequiredAsterisk/></label>
                                        <input className='form-control atb-input-box' onChange={handleChange('degree_start')} placeholder='Start Date' type='date' />
                                        {errors.degree_start && <Alert severity="error">{errors.degree_start}</Alert>}
                                    </div>
                                </div>
                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label>End Date <RequiredAsterisk/></label>
                                        <input className='form-control atb-input-box' onChange={handleChange('degree_end')} placeholder='End Date' type='date' />
                                        {errors.degree_end && <Alert severity="error">{errors.degree_end}</Alert>}
                                        {errors.dateValid && <Alert severity="error">{errors.dateValid}</Alert>}
                                    </div>
                                </div>
                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label>Grade <RequiredAsterisk/></label>
                                        <input className='form-control atb-input-box' onChange={handleChange('degree_grade')} placeholder='A , A+ ...' type='text' />
                                        {errors.degree_grade && <Alert severity="error">{errors.degree_grade}</Alert>}
                                    </div>
                                </div>
                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label>Field of Study</label>
                                        <input className='form-control atb-input-box' onChange={handleChange('major')} placeholder='e.g., Mathematics' type='text' />
                                        {errors.major && <Alert severity="error">{errors.major}</Alert>}
                                    </div>
                                </div>
                                <div className='col-md-12 col-lg-12'>
                                    <div className='form-group'>
                                        <label>Description</label>
                                        <JoditEditor
                                            value={educationeData?.degree_detail || ''}
                                            buttons={[
                                                'bold', '|',
                                                'italic', '|',
                                                'ul', '|', 'underline', '|']}
                                            className='m-t10'
                                            onChange={(content) => setEducationData({ ...educationeData, degree_detail: content })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </CandidateUpdateModal>

            <CandidateUpdateModal form="candidateEducationUpdateForm" open={updateModalOpen} onClose={() => [setUpdateErrors({}), setUpdateModalOpen(false), setUpdateEducationData({})]} title='Update Education'>
                <div>
                    <div className='card-body'>
                        <form id='candidateEducationUpdateForm' onSubmit={(e) => { e.preventDefault(); setLoader(true); updateCandidateEducation(props.candId, updateEducationData); }}>
                            <div className='row'>
                                <div className='col-md-6 col-lg-6'>
                                    <label>Degree <RequiredAsterisk/></label>
                                    <input
                                        value={updateEducationData.degree_name}
                                        onChange={handleUpdateChange('degree_name')}
                                        className='form-control atb-input-box m-b10' type='text' />
                                    {updateErrors.degree_name && <Alert severity="error">{updateErrors.degree_name}</Alert>}
                                </div>

                                <div className='col-md-6 col-lg-6'>
                                    <label>University or Institute Name <RequiredAsterisk/></label>
                                    <input
                                        value={updateEducationData.degree_institute}
                                        onChange={handleUpdateChange('degree_institute')}
                                        className='form-control atb-input-box m-b10' type='text' />
                                    {updateErrors.degree_institute && <Alert severity="error">{updateErrors.degree_institute}</Alert>}
                                </div>
                                <div className='col-md-6 col-lg-6'>
                                    <label>Start Date <RequiredAsterisk/></label>
                                    <input
                                        value={updateEducationData.degree_start}
                                        onChange={handleUpdateChange('degree_start')}
                                        className='form-control atb-input-box m-b10' type='date' />
                                    {updateErrors.degree_start && <Alert severity="error">{updateErrors.degree_start}</Alert>}
                                </div>
                                <div className='col-md-6 col-lg-6'>
                                    <label>End Date <RequiredAsterisk/></label>
                                    <input
                                        value={updateEducationData.degree_end}
                                        onChange={handleUpdateChange('degree_end')}
                                        className='form-control atb-input-box m-b10' id='endDate' type='date' />
                                    {updateErrors.degree_end && <Alert severity="error">{updateErrors.degree_end}</Alert>}
                                </div>
                                <div className='col-md-6 col-lg-6'>
                                    <label>Grade <RequiredAsterisk/></label>
                                    <input
                                        value={updateEducationData.degree_grade}
                                        onChange={handleUpdateChange('degree_grade')}
                                        className='form-control atb-input-box m-b10' type='text' />
                                    {updateErrors.degree_grade && <Alert severity="error">{updateErrors.degree_grade}</Alert>}
                                </div>

                                <div className='col-md-6 col-lg-6'>
                                    <label>Field of Study</label>
                                    <input
                                        value={updateEducationData.major}
                                        onChange={handleUpdateChange('major')}
                                        className='form-control atb-input-box m-b10' type='text' />
                                    {updateErrors.major && <Alert severity="error">{updateErrors.major}</Alert>}
                                </div>

                                <div className='col-md-12 col-lg-12'>
                                    <label>Description</label>
                                    <JoditEditor
                                        value={updateEducationData?.degree_detail || ''}
                                        buttons={[
                                            'bold', '|',
                                            'italic', '|',
                                            'ul', '|', 'underline', '|']}
                                        className='m-t10'
                                        onChange={(content) => setUpdateEducationData({ ...updateEducationData, degree_detail: content })}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </CandidateUpdateModal>
            <UpdateDeleteConfirmationModal open={openDeleteModal} onClose={handleClose} deleteItem={() => deleteCandidateEducation(props.candId, deleteItemId)} />
        </div>
    )
}

export default UpdateEducation