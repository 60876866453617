import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { recommendedJobData } from '../../../../fetchAPI'
import { Box, Button, Divider, Grid, Stack, Card, Tooltip, Typography, CardContent, CardActions, Skeleton, IconButton, Pagination } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import EmployerProfilePicture from '../../EmployerComponents/EmployerProfilePicture'
import WorkHistoryTwoToneIcon from '@mui/icons-material/WorkHistoryTwoTone';
import SettingsIcon from '@mui/icons-material/Settings';
import RecommendedJobsModal from '../CandidateDashboard/RecommendedJobsModal'


function CandidateRecommendedJobs() {

    const [recommendedJobs, setrecommendedJobs] = useState([])
    const [loader, setLoader] = useState(false)
    const [message, setMessage] = useState('')
    const [paginationInfo, setPaginationInfo] = useState({});
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        settingRecommendedJobData(1)
    }, [])

    useEffect(() => {
        settingRecommendedJobData(currentPage)

    }, [currentPage])

    const settingRecommendedJobData = (page = 1) => {
        setLoader(true)
        recommendedJobData(page).then((response) => {
            if (response && response.success) {
                setrecommendedJobs(response?.response?.data)
                setPaginationInfo(response?.response?.pagination)
                setMessage(response?.response?.message)
                setLoader(false)
            }
            else {
                // console.log('error: ', response)

            }
        })
    }

    function JobCardComponent({ item }) {

        const displaySalary = () => {

            return `${item.minimum_salary ?
                item.minimum_salary && !item.maximum_salary ?
                    `${item.minimum_salary} ${item.salary_currency ? item.salary_currency.name : 'BDT'} / ${item.salary_type?.type ? item.salary_type?.type : 'Monthly'}`
                    :
                    `${item.minimum_salary} - ${item.maximum_salary} ${item.salary_currency ? item.salary_currency.name : 'BDT'} / ${item.salary_type?.type ? item.salary_type?.type : 'Monthly'} `
                :
                "Negotiable"
                }`;
        }

        const dayLeft = (date) => {
            const today = new Date()
            let days = moment(date).diff(moment(today), 'days')

            if (days > 0) {
                return days
            } else {
                return 0
            }
        }

        return (
            <Card
                style={{ display: "flex", flexDirection: 'column', margin: "20px 10px", fontFamily: 'Epilogue,sans-serif', }}
            >
                <CardContent>
                    <Stack spacing={2}>
                        <Box sx={{ flexGrow: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Grid item>
                                {/* Job Information */}
                                <Tooltip title="Go to Job">
                                    <Link
                                        to={`/jobs/${item?.post_id}`}
                                    >
                                        <Typography variant="h6" align="left" sx={{ fontSize: '16px', color: '#0275D8', fontWeight: '700', wordBreak: 'break-word' }}>
                                            {item?.job_title}
                                        </Typography>
                                    </Link>
                                </Tooltip>
                            </Grid>
                        </Box>
                        <Box sx={{ flexGrow: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Grid item>
                                <Stack spacing={1}>

                                    {/* Profile Information */}
                                    <Typography
                                        className="text-left text-truncate"
                                        sx={{ maxWidth: "10rem", fontSize: '14px', color: '#72737C', fontWeight: '600' }}
                                    >
                                        {item?.employer?.employer_name}
                                    </Typography>

                                    {/* Salary Information */}
                                    <Typography variant="subheading" align="left" sx={{ fontSize: '12px', color: '#1D1E1B', fontWeight: '600' }}>
                                        Salary: {displaySalary(item)}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Go to Profile">
                                    <Avatar alt="Employer Picture" sx={{ width: 60, borderRadius: '5px', height: 'unset' }}>
                                        <Link to={`/employer/profile/${item?.employer?.slug}`}>
                                            <EmployerProfilePicture
                                                profile_picture={item?.employer?.profile_pic_base64}
                                            />
                                        </Link>
                                    </Avatar>
                                </Tooltip>
                            </Grid>
                        </Box>
                    </Stack>

                </CardContent>
                <Divider sx={{ marginTop: 2 }} />
                <CardActions>
                    <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Grid item xs={12} md={6}>
                            <Stack direction={'row'} spacing={1}>
                                <WorkHistoryTwoToneIcon sx={{ color: 'black' }} />
                                <Box className='text-body' sx={{ fontWeight: 'bold', fontSize: { md: '10px', xs: '12px' }, color: '#1D1E1B', fontWeight: '600' }}>
                                    <span>Application Deadline: </span>
                                    <br />
                                    <span >
                                        {moment(item?.job_deadline).format("MMM Do YY")}
                                    </span>
                                    &nbsp;
                                    <span style={{ color: '#DB1616' }}>
                                        ({dayLeft(item?.job_deadline) + ' days left'})
                                    </span>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' } }}>
                            <Box>
                                <Link to={`/jobs/${item?.post_id}`}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            fontSize: { md: '12px', lg: '14px' },
                                            margin: 1,
                                            fontFamily: 'Epilogue,sans-serif',
                                            fontWeight: '700',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        View Details
                                    </Button>
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        )
    }

    return (
        <div>
            <Stack
                direction={'row'}
                spacing={2}
                alignItems={'center'}
                sx={{ marginTop: '20px' }}
            >
                <Typography
                    variant='h4'
                    sx={{
                        fontSize: { xs: '15px', md: '20px' },
                        marginTop: '4px'
                    }}
                >
                    Recommended Jobs
                </Typography>
                <RecommendedJobsModal APIcall={settingRecommendedJobData} />
            </Stack>
            {
                loader ?
                    <React.Fragment>
                        <Grid item md={6} xs={12}>
                            <Box m={2}>
                                <Skeleton animation='wave' variant="rounded" width={'100%'} height={100} />
                            </Box>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Box m={2}>
                                <Skeleton animation='wave' variant="rounded" width={'100%'} height={100} />
                            </Box>
                        </Grid>
                    </React.Fragment>
                    :
                    <div>
                        <Grid container>
                            {
                                recommendedJobs.length > 0 ?
                                    recommendedJobs.map((item, index) => {
                                        return (
                                            <Grid item md={6} xs={12} key={index}>
                                                <JobCardComponent item={item} />
                                            </Grid>
                                        )
                                    })
                                    :
                                    message == 'No Recommended Jobs found' ?
                                        <Grid item md={12} xs={12}>
                                            <Typography variant='body2' align='center' m={5}>Please click on  <span> <SettingsIcon fontSize='small' /> </span>above to set your recommendations for jobs</Typography>
                                        </Grid>
                                        :
                                        <Grid item md={12} xs={12}>
                                            <Typography variant='body2' align='center' m={5}>No Jobs Found</Typography>
                                        </Grid>
                            }
                        </Grid>
                        {
                            paginationInfo ?
                                <div style={{ padding: '30px 20px' }} className="col-md-10 col-lg-10">
                                    <Pagination page={currentPage} count={paginationInfo.last_page} color="primary" shape="rounded" boundaryCount={1} onChange={(e, v) => setCurrentPage(v)} />
                                </div>
                                :
                                ''
                        }

                    </div>

            }
        </div>

    )
}

export default CandidateRecommendedJobs