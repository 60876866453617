import React, { useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import { Link } from "react-router-dom";
import './EmployerNotificationComponent.css'
import moment from "moment";

function EmployerNotificationsComponent() {

    const { notifications, changeNotificationReadStatus, deleteNotificationByUserId, notificationCounter } = useContext(UserContext);

    const dateDistinguisher = (relativeDate) => {
        const then = new Date(relativeDate);
        const now = new Date();

        const msBetweenDates = Math.abs(then.getTime() - now.getTime());

        // 👇️ convert ms to hours                  min  sec   ms
        const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

        return hoursBetweenDates;
    }

    const recent = notifications?.filter(notification => dateDistinguisher(notification.created_at) < 24)

    const earlier = notifications?.filter(notification => dateDistinguisher(notification.created_at) > 24)


    return (
        <div className="atb-card text-left">
            <h4 className="text-left"> Notifications</h4>
            <hr />
            <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/5.3.45/css/materialdesignicons.css" integrity="sha256-NAxhqDvtY0l4xn+YVa6WjAcmd94NNfttjNsDmNatFVc=" crossorigin="anonymous" />

            {/* <div className="container"> */}

            {
                notifications.length != 0 ?
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="box shadow-sm rounded bg-white mb-3">
                                <div className="box-title shadow-sm border-bottom p-3" style={{ backgroundColor: '#dbecf8' }}>
                                    <h6 className="m-0">Recent</h6>
                                </div>
                                <div className="box-body p-0">

                                    {
                                        recent?.map((notification, index) => {
                                            return (
                                                <div key={index} className={`p-3 d-flex align-items-center ${notification.read_status ? "" : 'unread-notification'} border-bottom osahan-post-header hover-class`}>
                                                    {/* <div className="dropdown-list-image mr-3">
                                                            <img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="" />
                                                        </div> */}
                                                    <Link onClick={() => changeNotificationReadStatus({ read_status: +true, notification_id: notification.id })} to={notification.body?.link} className="font-weight-bold mr-3 text-dark">
                                                        <div className="text-truncate">{notification?.title}</div>
                                                        <div className="small mb-2">{notification?.body?.message}</div>
                                                        {/* <div className="small mb-2">{notification?.decoded?.link}</div> */}
                                                        {/* <button type="button" className="btn btn-outline-success btn-sm">View Jobs</button> */}
                                                    </Link>
                                                    <span className="ml-auto mb-auto">
                                                        <i className="fa fa-times dropdown-item d-flex justify-content-end" onClick={() => deleteNotificationByUserId({ notification_id: notification.id })}></i>
                                                        <br />
                                                        <div class="small text-right text-muted pt-1">{moment(notification?.created_at).fromNow()}</div>
                                                    </span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="box shadow-sm rounded bg-white mb-3">
                                <div className="box-title shadow-sm border-bottom p-3" style={{ backgroundColor: '#dbecf8' }}>
                                    <h6 className="m-0">Earlier</h6>
                                </div>
                                <div className="box-body p-0">

                                    {
                                        earlier?.map((notification, index) => {
                                            return (
                                                <div key={index} className={`p-3 d-flex align-items-center ${notification.read_status ? "" : 'unread-notification'} text-black border-bottom osahan-post-header hover-class`}>
                                                    {/* <div className="dropdown-list-image mr-3">
                                                            <img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="" />
                                                        </div> */}
                                                    <Link onClick={() => changeNotificationReadStatus({ read_status: +true, notification_id: notification.id })} to={notification.body?.link} className="text-dark">
                                                        <div className="font-weight-bold mr-3 ">
                                                            <div className="text-truncate">{notification?.title}</div>
                                                            <div className="small mb-2">{notification?.body?.message}</div>
                                                            {/* <div className="small mb-2">{notification?.decoded?.link}</div> */}
                                                            {/* <button type="button" className="btn btn-outline-success btn-sm">View Jobs</button> */}
                                                        </div>
                                                    </Link>
                                                    <span className="ml-auto mb-auto">
                                                        <i className="fa fa-times dropdown-item d-flex justify-content-end" onClick={() => deleteNotificationByUserId({ notification_id: notification.id })}></i>
                                                        <br />
                                                        <div class="small text-right text-muted pt-1">{moment(notification?.created_at).fromNow()}</div>
                                                    </span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <>
                                {/* <div className="box shadow-sm rounded bg-white mb-3">
                            <div className="box-title border-bottom p-3">
                                <h6 className="m-0">Earlier</h6>
                            </div>
                            <div className="box-body p-0">
                                <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                                    <div className="dropdown-list-image mr-3 d-flex align-items-center bg-danger justify-content-center rounded-circle text-white">DRM</div>
                                    <div className="font-weight-bold mr-3">
                                        <div className="text-truncate">DAILY RUNDOWN: MONDAY</div>
                                        <div className="small">Nunc purus metus, aliquam vitae venenatis sit amet, porta non est.</div>
                                    </div>
                                    <span className="ml-auto mb-auto">
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="mdi mdi-dots-vertical"></i>
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                                <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="text-right text-muted pt-1">3d</div>
                                    </span>
                                </div>
                                <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                                    <div className="dropdown-list-image mr-3">
                                        <img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="" />
                                    </div>
                                    <div className="font-weight-bold mr-3">
                                        <div className="text-truncate">DAILY RUNDOWN: SATURDAY</div>
                                        <div className="small">Pellentesque semper ex diam, at tristique ipsum varius sed. Pellentesque non metus ullamcorper</div>
                                    </div>
                                    <span className="ml-auto mb-auto">
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="mdi mdi-dots-vertical"></i>
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                                <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="text-right text-muted pt-1">3d</div>
                                    </span>
                                </div>
                                <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                                    <div className="dropdown-list-image mr-3">
                                        <img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar2.png" alt="" />
                                    </div>
                                    <div className="font-weight-bold mr-3">
                                        <div className="mb-2"><span className="font-weight-normal">Congratulate Gurdeep Singh Osahan (iamgurdeeposahan)</span> for 5 years at Askbootsrap Pvt.</div>
                                        <button type="button" className="btn btn-outline-success btn-sm">Say congrats</button>
                                    </div>
                                    <span className="ml-auto mb-auto">
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="mdi mdi-dots-vertical"></i>
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                                <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="text-right text-muted pt-1">4d</div>
                                    </span>
                                </div>
                                <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                                    <div className="dropdown-list-image mr-3">
                                        <img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar4.png" alt="" />
                                    </div>
                                    <div className="font-weight-bold mr-3">
                                        <div>
                                            <span className="font-weight-normal">Congratulate Mnadeep singh (iamgurdeeposahan)</span> for 4 years at Askbootsrap Pvt.
                                            <div className="small text-success"><i className="fa fa-check-circle"></i> You sent Mandeep a message</div>
                                        </div>
                                    </div>
                                    <span className="ml-auto mb-auto">
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="mdi mdi-dots-vertical"></i>
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                                <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="text-right text-muted pt-1">4d</div>
                                    </span>
                                </div>
                                <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                                    <div className="dropdown-list-image mr-3 d-flex align-items-center bg-success justify-content-center rounded-circle text-white">M</div>
                                    <div className="font-weight-bold mr-3">
                                        <div className="text-truncate">DAILY RUNDOWN: MONDAY</div>
                                        <div className="small">Nunc purus metus, aliquam vitae venenatis sit amet, porta non est.</div>
                                    </div>
                                    <span className="ml-auto mb-auto">
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="mdi mdi-dots-vertical"></i>
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                                <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="text-right text-muted pt-1">3d</div>
                                    </span>
                                </div>
                                <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                                    <div className="dropdown-list-image mr-3"><img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar3.png" alt="" /></div>
                                    <div className="font-weight-bold mr-3">
                                        <div className="text-truncate">DAILY RUNDOWN: SATURDAY</div>
                                        <div className="small">Pellentesque semper ex diam, at tristique ipsum varius sed. Pellentesque non metus ullamcorper</div>
                                    </div>
                                    <span className="ml-auto mb-auto">
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="mdi mdi-dots-vertical"></i>
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                                <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="text-right text-muted pt-1">3d</div>
                                    </span>
                                </div>
                                <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                                    <div className="dropdown-list-image mr-3">
                                        <img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="" />
                                    </div>
                                    <div className="font-weight-bold mr-3">
                                        <div className="mb-2"><span className="font-weight-normal">Congratulate Gurdeep Singh Osahan (iamgurdeeposahan)</span> for 5 years at Askbootsrap Pvt.</div>
                                        <button type="button" className="btn btn-outline-success btn-sm">Say congrats</button>
                                    </div>
                                    <span className="ml-auto mb-auto">
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="mdi mdi-dots-vertical"></i>
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                                <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="text-right text-muted pt-1">4d</div>
                                    </span>
                                </div>
                                <div className="p-3 d-flex align-items-center osahan-post-header">
                                    <div className="dropdown-list-image mr-3">
                                        <img className="rounded-circle" src="https://bootdey.com/img/Content/avatar/avatar2.png" alt="" />
                                    </div>
                                    <div className="font-weight-bold mr-3">
                                        <div>
                                            <span className="font-weight-normal">Congratulate Mnadeep singh (iamgurdeeposahan)</span> for 4 years at Askbootsrap Pvt.
                                            <div className="small text-success"><i className="fa fa-check-circle"></i> You sent Mandeep a message</div>
                                        </div>
                                    </div>
                                    <span className="ml-auto mb-auto">
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-light btn-sm rounded" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="mdi mdi-dots-vertical"></i>
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <button className="dropdown-item" type="button"><i className="mdi mdi-delete"></i> Delete</button>
                                                <button className="dropdown-item" type="button"><i className="mdi mdi-close"></i> Turn Off</button>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="text-right text-muted pt-1">4d</div>
                                    </span>
                                </div>
                            </div>
                        </div> */}
                            </>

                        </div>
                    </div>
                    :
                    <div style={{ marginLeft: '20px' }}>
                        <p>Currently You don't have any notifications.</p>
                    </div>
            }
        </div>
    )
}

export default EmployerNotificationsComponent