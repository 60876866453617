import React, { useState, useContext } from 'react'
import './JobDetailsEmployeSidebar.css'
import { CandidateContext } from '../../../context/CandidateContext'
import { UserContext } from '../../../context/UserContext'
import { followCompany, unFollowCompany } from '../../../../fetchAPI'
import SuccessAlert from '../../Alerts/SuccessAlert'
import { Link } from 'react-router-dom'
import { Typography, Skeleton, Stack, Box, Divider, Tooltip } from '@mui/material'
import EmployerProfilePicture from '../../EmployerComponents/EmployerProfilePicture'
import ShowMoreOrShowLessComponent from './ShowMoreOrShowLessComponent.js'
import { LoadingButton } from '@mui/lab'
import { JobSearchContext } from '../../../context/JobSearchContext.js'
import { useHistory } from "react-router-dom";

function CXOJobDetailsEmployeSidebar(props) {
    const { followEmployerIds, settingCandidateData, candidateData } = useContext(CandidateContext)
    const { setEmployeName } = useContext(JobSearchContext)
    const { userData } = useContext(UserContext)

    const [openAlert, setOpenAlert] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')

    const history = useHistory()

    const unSaveEmployer = (candId, empId) => {
        setSaveLoading(false);
        setLoading(true);

        unFollowCompany(candId, empId)
            .then(response => {
                if (response && response.success) {
                    settingCandidateData()
                    setResponseMessage(response.response.message)
                    setOpenAlert(true)
                } else {
                    // Handle failure, show error message or take appropriate action
                    console.log(response)
                }
            })
            .catch(error => {
                // Handle errors, show error message or take appropriate action
                console.log(error)
            })
            .finally(() => {
                // setLoading(false);
            });
    }

    const saveEmployer = (candId, empId) => {

        if (userData) {
            setLoading(false);
            setSaveLoading(true);

            followCompany(candId, empId)
                .then(response => {
                    if (response && response.success) {
                        settingCandidateData()
                        setResponseMessage(response.response.message)
                        setOpenAlert(true)
                    } else {
                        // Handle failure, show error message or take appropriate action
                        console.log(response)
                    }
                })
                .catch(error => {
                    // Handle errors, show error message or take appropriate action
                    console.log(error)
                })
                .finally(() => {
                    // setLoading(false);
                });
        }
        else {
            history.push('/login')
        }

    }


    // Parsing HTML from Rich Editor data
    const introHtml = props.jobData?.employer?.intro;
    const parser = new DOMParser();
    const doc = parser.parseFromString(introHtml, 'text/html');
    const introPlainText = doc.body.textContent || "";

    return (
        <Box sx={{ overflow: 'hidden' }}>
            <Stack
                spacing={props.jobDataLoading ? 2 : 3}
                sx={{
                    padding: 2,
                }}
            >
                {
                    props.jobDataLoading ?

                        // Skeleton loader when data is still being fetched
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <Skeleton variant="circular" width={80} height={80} />
                            <Skeleton variant="text" width={200} sx={{ marginTop: 2 }} />
                            <Skeleton variant="text" width={200} />
                            <Skeleton variant="text" width={200} />
                            <Skeleton variant="text" height={50} width={200} />
                            <Skeleton variant="rounded" height={120} width={200} />
                        </Box>
                        :


                        candidateData?.is_cxo_eligible || userData?.id === props.jobData?.employer?.user_id ?
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Tooltip title='Go to Profile'>
                                        <Link
                                            to={`/employer/profile/${props.jobData?.employer?.slug}`}
                                        >
                                            {
                                                candidateData?.is_cxo_eligible || userData?.id === props.jobData?.employer?.user_id ?
                                                    <EmployerProfilePicture
                                                        profile_picture={props.jobData.employer?.profile_pic_base64}
                                                        height={200}
                                                        width={200}
                                                    />
                                                    :
                                                    <img src='/images/cxo/blurred-image.png' />
                                            }


                                        </Link>
                                    </Tooltip>
                                </Box>
                                <Link
                                    to={`/employer/profile/${props.jobData?.employer?.slug}`}
                                >
                                    <Typography
                                        variant='h5'
                                        align='center'
                                        sx={{
                                            marginTop: 4,
                                            color: '#0275D8',
                                            fontWeight: 700,
                                            '&:hover': {
                                                textDecoration: 'underline'
                                            },
                                        }}
                                    >
                                        {
                                            candidateData?.is_cxo_eligible || userData?.id === props.jobData?.employer?.user_id ?
                                                props.jobData.employer?.employer_name
                                                :
                                                <img src='/images/cxo/blurred-text.png' />
                                        }
                                    </Typography>
                                </Link>
                                {
                                    userData.user_role_id !== 3 ?
                                        followEmployerIds.includes(props.jobData.employer?.id) ?
                                            <React.Fragment>
                                                <Box mt={6}>
                                                    <LoadingButton
                                                        loading={loading}
                                                        variant='contained'
                                                        fullWidth
                                                        onClick={() => unSaveEmployer(candidateData.id, props.jobData.employer?.id)}
                                                        sx={{
                                                            backgroundColor: '#339757',
                                                            borderRadius: '60px',
                                                            color: 'white',
                                                            padding: 2,
                                                            boxShadow: 0,
                                                            '&:hover': {
                                                                backgroundColor: '#267141',
                                                            },
                                                        }}
                                                    >
                                                        Unfollow
                                                    </LoadingButton>
                                                </Box>
                                            </React.Fragment>
                                            :
                                            <Box mt={6}>
                                                <LoadingButton
                                                    loading={saveLoading}
                                                    variant='contained'
                                                    fullWidth
                                                    onClick={() => saveEmployer(candidateData.id, props.jobData.employer.id)}
                                                    sx={{
                                                        backgroundColor: '#00B19A',
                                                        borderRadius: '60px',
                                                        color: 'white',
                                                        padding: 2,
                                                        boxShadow: 0,
                                                        '&:hover': {
                                                            backgroundColor: '#008775',
                                                        },
                                                    }}
                                                >
                                                    + Follow
                                                </LoadingButton>
                                            </Box>

                                        :
                                        ""
                                }
                                <Divider />

                                <Box>
                                    <Typography
                                        variant='h6'
                                        align='left'
                                        sx={{
                                            marginTop: 2,
                                            color: '#0275D8',
                                            fontWeight: 700,
                                            fontSize: '20px'
                                        }}

                                    >
                                        Company Overview
                                    </Typography>
                                    {
                                        props.jobData?.employer?.web ?
                                            <Typography
                                                variant='body1'
                                                align='left'
                                                sx={{
                                                    marginTop: 2,
                                                    fontWeight: 700
                                                }}

                                            >
                                                Website: {props.jobData?.employer?.web}
                                            </Typography>
                                            :
                                            ""
                                    }
                                    <Typography
                                        variant='body1'
                                        align='left'
                                        sx={{
                                            marginTop: 2,
                                            fontWeight: 700
                                        }}

                                    >
                                        Address: {props.jobData?.employer?.map_location}
                                    </Typography>
                                    {
                                        props.jobData?.employer?.intro ?
                                            <ShowMoreOrShowLessComponent
                                                text={introPlainText}
                                                maxLines={5}
                                            />
                                            :
                                            ""
                                    }
                                    <Box onClick={() => setEmployeName(props.jobData?.employer?.employer_name)}>
                                        <Typography
                                            variant='h6'
                                            align='left'
                                            sx={{
                                                marginTop: 2,
                                                fontSize: '15px',
                                                fontWeight: 700,
                                                color: '#0275D8',
                                                textDecorationLine: 'underline'
                                            }}
                                        >
                                            <Link 
                                            to={{
                                                pathname: '/search/jobs',
                                                state: { fromHome: true }
                                            }} 
                                            >
                                                More Jobs From This Company
                                            </Link>
                                        </Typography>
                                    </Box>
                                </Box>

                            </>
                            :
                            <img src='/images/cxo/blurred-sidebar.png' width={'auto'} />

                    // Job Details Employer Sidebar after data is fetched



                }
            </Stack>
            <SuccessAlert open={openAlert} message={responseMessage} onHide={() => setOpenAlert(false)} />
        </Box >
    )
}

export default CXOJobDetailsEmployeSidebar