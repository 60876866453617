import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../context/UserContext';
import { useHistory } from 'react-router-dom';
import { Box, Card, Container, Divider, Grid, Stack, TextField, Typography } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { loginUser } from '../../fetchAPI';
import { LoadingButton } from '@mui/lab';
import ErrorAlert from '../components/Alerts/ErrorAlert';
import LoginUserRoleIDModal from '../components/LoginUserRoleIDModal';
import ForgetPasswordModal from '../components/ForgetPasswordModal';
import { fetchIpAddress } from '../helpers/helperFunctions';


export default function Loginv2() {

    const history = useHistory();
    const [loader, setLoader] = useState(false)
    const [loginInfo, setLoginInfo] = useState({ email_or_phone: "", password: "" });
    const [errorMessage, setErrorMessage] = useState('')
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const [googleOAuthResponse, setGoogleOAuthResponse] = useState(null)
    const [openRoleIDModal, setOpenRoleIDModal] = useState(false)
    const [forgetPasswordModal, setForgetPasswordModal] = useState(false)
    const [showPassword, setShowPassword] = useState(false);

    // UseContext Declarations
    const { loginData, redirectionLink } = useContext(UserContext);

    const urlParams = new URLSearchParams(window.location.search);
    const job_id = urlParams.get('job_id');

    /**
    * Create a function that handle the input event and
    * save the value of that event on an state every time the component change
    * 
    * Setting login information in loginInfo state
    * 
    * @param {string} name
    * @param {event} event 
    */

    const handleChange = (name) => (event) => {
        setLoginInfo({ ...loginInfo, [name]: event.target.value });
    };

    async function onLogin(e) {
        e.preventDefault();
        setLoader(true);

        const ipAddress = await fetchIpAddress();
        const updatedLoginInfo = { ...loginInfo, ip: ipAddress };

        /**
         * Calling login API 
         * 
         * @param {object} loginInfo - Sending Login Information
         * @return {object} Success or false json with User Data
         */

        loginUser(updatedLoginInfo).then((response) => {
            if (response && response.success) {
                if (response.response.data?.user?.user_role_id !== 1) {
                    loginData(response)
                    if (job_id && response.response.data?.user?.user_role_id == 2) {
                        history.push(`/candidate-register/update?job_id=${job_id}`)
                    } else {
                        history.push(redirectionLink);
                    }
                    setLoader(false)
                }
                else {
                    setLoader(false)
                    setErrorMessage('Invalid User')
                    throw Error('Error: ' + 'Invalid User')
                }
            }
            else {
                setLoader(false)
                setErrorMessage(response?.errors[0].message[0])
                throw Error('Error: ' + response?.errors[0].message[0])
            }
        })
            .catch(err => {
                setLoader(false)
                setErrorMessage(err.message);
                setOpenErrorAlert(true);
            })
    }

    // Google Oauth 

    const handleCallBackForRegistration = async (userRoleID) => {

        const ipAddress = await fetchIpAddress();

        axios.post(`${process.env.REACT_APP_API_SERVER_BASE_URL}/googleOauthLogin`, {
            credential: googleOAuthResponse,
            user_role_id: userRoleID,
            ip: ipAddress
        }).then((response) => {
            if (response && response.status === 200) {

                loginData(response.data)
                if (job_id && response.data.response.data.user.user_role_id == 2) {
                    //gtag code - don't remove
                    window.dataLayer.push({
                        'event': 'candidateNewRegistration',
                        'regMethod': 'google'
                    });
                    //gtag code ended
                    //esk
                    window.esk('track', 'candidateNewRegistration');
                    //end esk
                    history.push(`/candidate-register/update?job_id=${job_id}&reg=success`)
                } else if (response.data.response.data.user.user_role_id == 2) {
                    //gtag code - don't remove
                    window.dataLayer.push({
                        'event': 'candidateNewRegistration',
                        'regMethod': 'google'
                    });
                    //gtag code ended
                    //esk
                    window.esk('track', 'candidateNewRegistration');
                    //end esk
                    if (redirectionLink.includes('dashboard') && !redirectionLink.includes('?')) {
                        const urlSearchParamsString = `?reg=success`;
                        history.push(redirectionLink + urlSearchParamsString);
                    } else {
                        history.push(redirectionLink);
                    }
                } else {
                    history.push(redirectionLink);
                }

            }
            else {
                throw Error('Error: ' + response)
            }
        }).catch(function (error) {
            throw Error('Error: ' + error)
        });
    }

    async function handleCallback(response) {

        setGoogleOAuthResponse(response.credential);

        const ipAddress = await fetchIpAddress();

        axios.post(`${process.env.REACT_APP_API_SERVER_BASE_URL}/googleOauthLogin`, {
            credential: response.credential,
            ip: ipAddress
        }).then((response) => {

            if (response && response.status === 200) {
                loginData(response.data)
                if (job_id && response?.data?.response?.data?.user?.user_role_id == 2) {
                    history.push(`/candidate-register/update?job_id=${job_id}`)
                } else {
                    history.push(redirectionLink);
                }
                setLoader(false)
            }
            else {
                setLoader(false)
                console.log("api err: ", response)
            }
        }).catch(function (error) {
            if (error.response.status === 400) {
                setLoader(true);
                setErrorMessage("Please let us know if you are signing in as a Candidate or an Employer");
                setOpenErrorAlert(true);
                setTimeout(() => {
                    setLoader(false);
                    setOpenRoleIDModal(true)
                }, 1000);
            }
        });
    }

    useEffect(() => {
        /*global google*/
        google.accounts.id.initialize({
            client_id: '723908483827-aquc3ubglvopidh8dant7rt81pv183te.apps.googleusercontent.com',
            callback: handleCallback,
        });

        google.accounts.id.renderButton(
            document.getElementById('gsign'),
            {
                theme: 'outline',
                size: 'large',
                width: `${window.innerWidth >= 900 ? '900px' : '100px'}`,
                text: 'signin_with',
            }
        )

    }, [loader])

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '350px',
                    backgroundImage: 'url(/v2/images/candidateBackground.png)'
                }}
            />

            <Box
                sx={{
                    position: 'relative',
                    top: '-300px',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '-250px'
                }}
            >
                <Card
                    sx={{
                        display: 'flex',
                        height: '100%',
                        width: '800px',
                        boxShadow: '0px 4px 100px 0px rgba(0, 0, 0, 0.25)',
                        borderRadius: '22px',
                        justifyContent: 'center',
                        margin: '10px'
                    }}
                >
                    <Container maxWidth='sm'>
                        <Grid item md={12} xs={12}>
                            <Stack spacing={1} sx={{ display: 'flex', alignItems: 'center', marginY: 7 }}>
                                <Typography align='center' sx={{ fontWeight: 600 }}>Welcome Back!</Typography>
                                <Typography variant='h6' sx={{ color: '#0275D8', textAlign: 'center', fontWeight: 700, fontSize: '18px' }}>Unlock your career potential with just one click.</Typography>
                                <Typography variant='h6' sx={{ fontWeight: 600, textAlign: "center" }}>Sign in to your account</Typography>
                            </Stack>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <div className='row justify-content-center'>
                                <div className="App">
                                    <div id='gsign'></div>
                                </div>
                            </div>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <Box m={3}>
                                <Divider>OR</Divider>
                            </Box>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <Box m={3}>
                                <form
                                    className='ga_sigin_form' // do not remove ga_ class
                                    onSubmit={onLogin}
                                >
                                    <Stack spacing={2}>
                                        <TextField
                                            required
                                            variant="outlined"
                                            type='text'
                                            label="Enter Your Email Address Or Phone Number"
                                            fullWidth
                                            onChange={handleChange("email_or_phone")}
                                        />
                                        <Box sx={{ position: "relative" }}>
                                            <TextField
                                                required
                                                variant="outlined"
                                                type={showPassword ? "text" : "password"}
                                                label="Enter Your Password"
                                                fullWidth
                                                onChange={handleChange("password")}
                                            />
                                            {showPassword ?
                                                <VisibilityOutlinedIcon sx={{ position: "absolute", top: 17, right: 10, cursor: "pointer", color: "#666666" }} onClick={() => setShowPassword(!showPassword)} />
                                                :
                                                <VisibilityOffOutlinedIcon sx={{ position: "absolute", top: 17, right: 10, cursor: "pointer", color: "#666666" }} onClick={() => setShowPassword(!showPassword)} />
                                            }
                                        </Box>
                                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Typography align='right' sx={{ color: '#898989', fontSize: '12px', cursor: "pointer" }} onClick={() => setForgetPasswordModal(true)}> Forgot Password?</Typography>
                                        </Box>
                                        <LoadingButton
                                            loading={loader}
                                            variant='contained'
                                            type='submit'
                                        >
                                            Sign In
                                        </LoadingButton>
                                    </Stack>
                                </form>
                            </Box>

                            <Grid item md={12} xs={12}>
                                <Box m={5} mt={10}>
                                    <Typography variant='h6' align='center' sx={{ fontSize: '16px', fontWeight: 700 }}>Don't have an account yet? <Link to={'/candidate-register'}> <Typography variant='span' sx={{ color: '#0275D8', fontWeight: 700 }}> Register here</Typography> </Link></Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Card>

                {/* Forget Password Modal */}
                <ForgetPasswordModal
                    open={forgetPasswordModal}
                    onClose={() => setForgetPasswordModal(false)}
                />

                <ErrorAlert open={openErrorAlert} message={errorMessage} onHide={() => setOpenErrorAlert(false)} />
                <LoginUserRoleIDModal open={openRoleIDModal} onClose={() => setOpenRoleIDModal(false)} registercallbackfunction={handleCallBackForRegistration} />
            </Box>
        </>
    )
}