import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import { useHistory } from "react-router-dom";
import {
  Box,
  Card,
  Checkbox,
  Container,
  Divider,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { newRegisterUser, registerUser } from "../../fetchAPI";
import ErrorAlert from "../components/Alerts/ErrorAlert";
import InputAdornment from "@mui/material/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
import { validatePassword, validatePhoneNumber, handleKeyDown } from "../helpers/helperFunctions";
import { useAlert } from "../context/AlertContext";

const useStyles = makeStyles({
  input: {
    borderLeft: '1px solid #C4C4C4',
    paddingLeft: "14px",
    marginLeft: "7px"
  },
});

export default function CandidateRegister() {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [candidateRegisterInfo, setCandidateRegisterInfo] = useState({
    user_role_id: 2,
    password: ""
  });
  const [userRoleID, setUserRoleID] = useState(2);
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [validationMessages, setValidationMessages] = useState("");
  
  const [validationStatus, setValidationStatus] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    numbers: false,
    specialChar: false
  });

  const urlParams = new URLSearchParams(window.location.search);
  const job_id = urlParams.get("job_id");
  const classes = useStyles();
  const alertContext = useAlert();

  const {
    loginData,
    redirectionLink,
    userData,
    setRedirectionLink,
  } = useContext(UserContext);
  // useEffect(()=>{
  //     if(job_id){
  //         setRedirectionLink(`/candidate-register/update?job_id=${job_id}`)
  //     }
  // },[job_id])

  const redirectRouteForOAuth = job_id
    ? `/candidate-register/update?job_id=${job_id}`
    : "/dashboard";

  const handleChangeCandidate = (name) => (event) => {
    const value = event.target.value;
    setCandidateRegisterInfo((prevState) => ({
      ...prevState,
      [name]: value
    }));

    if (name === "password") {
      const { status, messages } = validatePassword(value);
      setValidationStatus(status);
      setValidationMessages(messages);
    }
  };

  const submitCandidateForm = (e) => {
    e.preventDefault();

    const phoneNumber = candidateRegisterInfo?.mobile_number
    if (phoneNumber) {
      if(!validatePhoneNumber(phoneNumber)){
        alertContext.setOpenErrorAlert(true);
        alertContext.setResponseMessage("Please enter a valid phone number!");
        return;
      }
    }

    const allValid = Object.values(validationStatus).every(Boolean);
    if (!allValid) {
      alertContext.setOpenErrorAlert(true);
      alertContext.setResponseMessage(validationMessages);
      return;
    }
    
    const formData = new FormData();

    formData.append("name", candidateRegisterInfo?.name);
    formData.append("mobile_number", phoneNumber);
    if (candidateRegisterInfo?.email && candidateRegisterInfo?.email !== "") {
      formData.append("email", candidateRegisterInfo?.email);
    }
    formData.append("password", candidateRegisterInfo?.password);
    formData.append("user_role_id", candidateRegisterInfo?.user_role_id);

    setLoader(true);

    newRegisterUser(formData)
      .then((response) => {
        if (response && response.success) {
          //gtag code - don't remove
          window.dataLayer.push({
            'event': 'candidateNewRegistration',
            'regMethod': 'credential'
          });
          //gtag code ended
          //esk
          window.esk('track', 'candidateNewRegistration');
          //end esk
          loginData(response);
          if (job_id && response?.response?.data?.user?.user_role_id == 2) {
            history.push(
              `/candidate-register/update?job_id=${job_id}&reg=success`
            );
          } else if (
            !job_id &&
            response?.response?.data?.user?.user_role_id == 2
          ) {
            history.push(`/candidate-register/update?reg=success`);
          } else {
            if (
              redirectionLink.includes("dashboard") &&
              !redirectionLink.includes("?")
            ) {
              const urlSearchParamsString = `?reg=success`;
              history.push(redirectionLink + urlSearchParamsString);
            } else {
              history.push(redirectionLink);
            }
          }

          // history.push(redirectRoute)
        } else {
          alertContext.setOpenErrorAlert(true);
          alertContext.setResponseMessage(response?.errors[0]?.message[0]);
        }
      })
      .catch((err) => {
        setLoader(false);
        throw Error("Error: " + err);
      })
      .finally(() => setLoader(false));
  };

  // Google Auth

  function handleCallback(response) {
    const userOb = jwtDecode(response.credential);

    axios
      .post(`${process.env.REACT_APP_API_SERVER_BASE_URL}/googleOauthLogin`, {
        credential: response.credential,
        user_role_id: userRoleID,
      })
      .then((response) => {
        if (response && response.status == 200) {
          loginData(response.data);
          if (
            job_id &&
            response?.data?.response?.data?.user?.user_role_id == 2
          ) {
            if (response?.data?.response?.data?.isNewUser == true) {
              //gtag code - don't remove
              window.dataLayer.push({
                'event': 'candidateNewRegistration',
                'regMethod': 'google'
              });
              //gtag code ended
              //esk
              window.esk('track', 'candidateNewRegistration');
              //end esk
              history.push(
                `/candidate-register/update?job_id=${job_id}&reg=success`
              );
            }else{
              history.push(
                `/candidate-register/update?job_id=${job_id}`
              );
            }
            
          } else {
            if (
              redirectionLink.includes("dashboard") &&
              !redirectionLink.includes("?")
            ) {
              let urlSearchParamsString = ``;
              if (response?.data?.response?.data?.isNewUser == true) {
                //gtag code - don't remove
                window.dataLayer.push({
                  'event': 'candidateNewRegistration',
                  'regMethod': 'google'
                });
                //gtag code ended
                //esk
                window.esk('track', 'candidateNewRegistration');
                //end esk
                urlSearchParamsString += `?reg=success`;
              }
              history.push(redirectionLink + urlSearchParamsString);
            } else {
              history.push(redirectionLink);
            }
          }
          setLoader(false);
        } else {
          setLoader(false);
          // throw Error('Error: ' + response)
          console.log("Res API: ", response);
        }
      })
      .catch(function(error) {
        console.log("API err: ", error);
      });
  }

  useEffect(() => {
    /*global google*/
    google.accounts.id.initialize({
      client_id:
        "723908483827-aquc3ubglvopidh8dant7rt81pv183te.apps.googleusercontent.com",
      callback: handleCallback,
    });

    google.accounts.id.renderButton(document.getElementById("gsign"), {
      theme: "outline",
      type: "standard",
      size: "large",
      width: `${window.innerWidth >= 900 ? "200px" : "90px"}`,
      text: "signup_with",
    });
  }, [userRoleID]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "350px",
          backgroundImage: "url(/v2/images/candidateBackground.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      />
      <Box
        sx={{
          position: "relative",
          top: "-300px",
          display: "flex",
          justifyContent: "center",
          marginBottom: "-250px",
        }}
      >
        <Card
          sx={{
            display: "flex",
            height: "100%",
            width: "800px",
            boxShadow: "0, 0, 0, 0.25",
            borderRadius: "22px",
            justifyContent: "center",
            margin: "10px",
            boxShadow: "0px 4px 100px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Container maxWidth="sm">
            <Grid item md={12} xs={12}>
              <Stack
                spacing={2}
                sx={{ display: "flex", alignItems: "center", margin: 7 }}
              >
                <Typography
                  align="center"
                  sx={{ fontWeight: 600, fontSize: "14px" }}
                >
                  {" "}
                  Create your account & find your dream job today!
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#0275D8",
                    textAlign: "center",
                    fontWeight: 700,
                    fontSize: "18px",
                  }}
                >
                  Access 1000's of new jobs - apply in minutes.
                </Typography>
                <Typography
                  align="center"
                  variant="h6"
                  sx={{ fontWeight: 600 }}
                >
                  {" "}
                  New member registration
                </Typography>
              </Stack>
            </Grid>

            <Grid item md={12} xs={12}>
              <div className="row justify-content-center">
                <div className="App">
                  <div id="gsign"></div>
                </div>
              </div>
            </Grid>

            <Grid item md={12} xs={12}>
              <Box m={3}>
                <Divider>OR</Divider>
              </Box>
            </Grid>

            <Grid item md={12} xs={12}>
              <Box m={3}>
                <form
                  id="candidate-form"
                  onSubmit={(e) => submitCandidateForm(e)}
                  className="ga_candidate_register_form" // for google analytics and google tag manager. Don't remove this class
                >
                  <Stack spacing={2}>
                    <TextField
                      variant="outlined"
                      label="Enter Your Full Name"
                      fullWidth
                      required
                      inputProps={{ minLength: 4 }}
                      onChange={handleChangeCandidate("name")}
                    />
                    <TextField
                      variant="outlined"
                      type="number"
                      placeholder="Enter Mobile Number *"
                      fullWidth
                      required
                      onChange={handleChangeCandidate("mobile_number")}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+880</InputAdornment>
                        ),
                        onKeyDown: (e) => handleKeyDown(e),
                        classes: {
                          input: classes.input 
                        },
                        sx: {
                          '& input::placeholder': {
                            color: 'rgba(0, 0, 0, 0.6)',
                            opacity: 1,
                            fontWeight: 400,
                            fontSize: "16px"
                          },
                        }
                      }}
                    />
                    <TextField
                      variant="outlined"
                      type="email"
                      label="Enter Your Email Address"
                      fullWidth
                      required
                      onChange={handleChangeCandidate("email")}
                    />
                    <Box sx={{position: "relative"}}>
                    <TextField
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      label="Create Your Password"
                      fullWidth
                      required
                      // error={passError}
                      // inputProps={{ minLength: 8 }}
                      onChange={handleChangeCandidate("password")}
                    />
                    {showPassword ? 
                      <VisibilityOutlinedIcon sx={{position: "absolute", top: 17, right: 10, cursor: "pointer", color: "#666666"}} onClick={() => setShowPassword(!showPassword)} /> 
                    : 
                      <VisibilityOffOutlinedIcon sx={{position: "absolute", top: 17, right: 10, cursor: "pointer", color: "#666666"}} onClick={() => setShowPassword(!showPassword)}/>
                    }
                    </Box>
                    <Typography
                      variant="caption"
                      sx={{ color: "#1D1E1B", paddingLeft: 3 }}
                    >
                      <li style={{ color: validationStatus.uppercase ? '#0079C0' : '#1D1E1B' }}>
                        Uppercase letters (A - Z)
                      </li>
                      <li style={{ color: validationStatus.lowercase ? '#0079C0' : '#1D1E1B' }}>
                        Lowercase letters (a - z)
                      </li>
                      <li style={{ color: validationStatus.numbers ? '#0079C0' : '#1D1E1B' }}>
                        Numbers (0 - 9)
                      </li>
                      <li style={{ color: validationStatus.specialChar ? '#0079C0' : '#1D1E1B' }}>
                        Special character (!#$%&@^'?)
                      </li>
                      <li style={{ color: validationStatus.minLength ? '#0079C0' : '#1D1E1B' }}>
                        At least 8 characters in length
                      </li>
                    </Typography>
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "nowrap",
                        alignItems: "baseline",
                      }}
                    >
                      <Grid item md={1}>
                        <Checkbox required />
                      </Grid>
                      <Grid item md={11}>
                        <Typography variant="caption">
                          By clicking "Create an account", you confirm that you
                          agree to ATB Jobs{" "}
                          <Link to={"/terms-conditions"} target={"_blank"}>
                            Terms and Conditions{" "}
                          </Link>{" "}
                          and{" "}
                          <Link to={"/privacy-policy"} target={"_blank"}> Privacy Policy. </Link>
                        </Typography>
                      </Grid>
                    </Grid>
                    <LoadingButton
                      loading={loader}
                      variant="contained"
                      type="submit"
                      sx={{textTransform: "none"}}
                    >
                      Create an account
                    </LoadingButton>
                  </Stack>
                </form>
              </Box>

              <Grid item md={12} xs={12}>
                <Box m={3} mt={2}>
                  <Typography
                    variant="h6"
                    align="center"
                    sx={{ fontSize: "16px", fontWeight: 700 }}
                  >
                    Already have an account?{" "}
                    <Link to={"/login"}>
                      <Typography
                        variant="span"
                        sx={{ color: "#0275D8", fontWeight: 700 }}
                      >
                        Log In{" "}
                      </Typography>{" "}
                    </Link>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Card>
      </Box>
    </>
  );
}
