import React, { useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import EmployerDashboard from "./Employer/EmployerDashboard";
import CandidateDashboard from "./Candidate/CandidateDashboard";
import { useHistory } from "react-router-dom";
import CandidateContextProvider from "../context/CandidateContext";
import EmployerContextProvider from "../context/EmployerContext";
import ErrorAlert from "../components/Alerts/ErrorAlert";
import Loginv2 from "./Loginv2";

function DashboardRouting() {

    const { userData, settingUserData, isLoggedin } = useContext(UserContext)

    const history = useHistory()

    // Redirect user to home page If already loggedin
    // if(!isLoggedin){
    // 	history.push('/login')
    // }

    useEffect(() => {
        if (!userData && isLoggedin) {
            settingUserData()
        }
    }, []);

    const role_id = userData.user_role_id

    // Rendering Dashboard based on User Role
    function renderDashboard(id) {
        if (id == 2) {
            history.push('/candidate-dashboard')
            // return (<CandidateContextProvider><CandidateDashboard /></CandidateContextProvider>)
        } else if (id == 3) {
            history.push('/employer-dashboard')
            // return (<EmployerContextProvider><EmployerDashboard /></EmployerContextProvider>)
        }
        else {
            return (
                <>
                    <Loginv2 />
                    <ErrorAlert open={true} message={"Your account type was not selected properly, please contact us immediately to complete your registration."} />
                </>
            )
        }
    }


    return (
        <>
            {/* Calling Render function */}
            {renderDashboard(role_id)}
        </>
    )
}

export default DashboardRouting