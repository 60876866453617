import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { forgetPassword } from '../../fetchAPI'
import { Button, Divider } from '@mui/material';
import SuccessAlert from '../../v2/components/Alerts/SuccessAlert';
import ErrorAlert from '../../v2/components/Alerts/ErrorAlert';
import { LoadingButton } from '@mui/lab';

function ForgetPasswordModal(props) {
    const [resetEmail, setResetEmail] = useState('')
    const [forgetPasswordModal, setForgetPasswordModal] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const [loading, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState(null);

    const notificationMsg = (
        <>
            If you have not set any email, please contact our support team at phone: <span style={{color: '#000000a8', fontSize: '11px', fontWeight:'600'}}>+8801400492822</span>
        </>
    );

    let resetPass = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await forgetPassword(resetEmail);
            if (response.success) {
                setResponseMessage(response.response.data);
                setLoading(false);
                setOpenSuccessAlert(true);
                setErrMsg('')
            } else {
                throw new Error('Error: ' + response.errors[0].message);
            }
        } catch (err) {
            setErrMsg(notificationMsg);
            setLoading(false);
            setResponseMessage(err.message);
            setOpenErrorAlert(true);
        }
    }
    return (
        <>
            {/* <Modal {...props} className="modal fade modal-bx-info editor">
                <div className="modal-dialog my-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="ResumeheadlineModalLongTitle">Forget Password?</h5>
                            <button type="button" className="close" onClick={props.onHide}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className='col-lg-1 col-md-1'></div>
                                <div className='col-lg-10 col-md-10'>
                                    {responseMessage ?
                                        <div>
                                            <center>{responseMessage}</center>
                                        </div> :
                                        <>
                                            <p>Please enter your email account to reset you Password.</p>
                                            <form onSubmit={resetPass}>
                                                <div className='input-group'>
                                                    <input type='email' onChange={(e) => setResetEmail(e.target.value)} className="form-control" />
                                                    <button type='submit' className='site-button' placeholder='example@exampple.com'>Submit</button>
                                                </div>
                                            </form>
                                        </>
                                    }
                                </div>
                                <div className='col-lg-1 col-md-1'></div>
                            </div>

                        </div>
                    </div>
                </div>
            </Modal> */}

            <Dialog {...props}>
                <DialogTitle>Forgot Password</DialogTitle>
                <Divider />
                <form onSubmit={resetPass}>
                    <DialogContent>
                        <DialogContentText>
                            Kindly provide your registered email address to reset your password and follow the instructions provided in the email.
                            {errMsg && (
                                <div style={{color: '#F44336', fontSize: '11px', marginTop:'12px', width:'70%'}}>
                                    * {errMsg}
                                </div>
                            )}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            id="name"
                            label="Email Address"
                            type="email"
                            fullWidth
                            variant="standard"
                            onChange={(e) => setResetEmail(e.target.value)}
                            sx={{ marginTop: 3 }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant='outlined' onClick={props.onClose}>Cancel</Button>
                        <LoadingButton type='submit' loading={loading} variant='outlined'>Confirm</LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>

            <SuccessAlert open={openSuccessAlert} message={responseMessage} onHide={() => setOpenSuccessAlert(false)} />
            <ErrorAlert open={openErrorAlert} message={responseMessage} onHide={() => setOpenErrorAlert(false)} />
        </>
    )
}

export default ForgetPasswordModal