import React, { useEffect, useState, useContext } from "react";
import { followList } from "../../../../fetchAPI";
import { CandidateContext } from "../../../context/CandidateContext";
import EmployerCard from "../../EmployerComponents/EmployerCard/EmployerCard";
import "./CandidateFollowedCompanies.css";
import { Card } from "react-bootstrap";
import { Box } from "@mui/material";
import GlobalLoader from "../../GlobalLoader";

function CandidateFollowedCompanies() {
  const { candidateData } = useContext(CandidateContext);
  const [followedCompanies, setFollowedCompanies] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    followList(candidateData.id)
      .then((response) => {
        setFollowedCompanies(response.response.data.follow_employers);
      })
      .catch((error) => {
        console.log("Data fetching error : ", error);
      })
      .finally(() => {
        setLoader(false);
      });
  }, [candidateData]);

  return (
    <Box sx={{margin: {xs:'5px', sm:'10px', md:'20px', lg: '30px'}}}>
      <Card className="shadow-sm bg-white">
        <Card.Header className="bg-white pt-4 px-3">
          <h4 className="font-epilogue" style={{ fontWeight: 700, fontFamily: "Epilogue" }}>
            Followed Company
          </h4>
          <p
            className="mb-3"
            style={{ fontSize: "14px", fontWeight: 700, fontFamily: "Poppins" , color: '#0079C1'}}
          >
            You are currently following {followedCompanies?.length} Company
            Profile
          </p>
        </Card.Header>
        <Card.Body className="bg-white">
          {loader ? (
            <GlobalLoader height="60vh" />
          ) : (
            <div className="row g-4">
              {followedCompanies?.map((employer) => {
                return (
                  <div className="col-lg-6">
                    <EmployerCard employerInfo={employer} />
                  </div>
                );
              })}
            </div>
          )}
        </Card.Body>
      </Card>
    </Box>

  );
}

export default CandidateFollowedCompanies;
