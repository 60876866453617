import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from "./reportWebVitals";
import translationResources from './translationResources';

import i18next from "i18next"
import { I18nextProvider } from 'react-i18next';

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng : "en",
  resources : translationResources
});

//ReactDOM.render(<App />, document.getElementById('root'));

ReactDOM.render(
	<React.StrictMode>
        <BrowserRouter>
          <I18nextProvider i18n={i18next}>
            <App />
          </I18nextProvider>
        </BrowserRouter>
	</React.StrictMode>
    ,
  document.getElementById("root")
);
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
