import React, { createContext, useContext, useEffect, useRef, useState } from "react"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material'

const DeleteConfirmationModalContext = createContext();

export function useDeleteAlert() {
	const context = useContext(DeleteConfirmationModalContext);
	return context;
}

const DeleteConfirmationModalContextProvider = (props) => {

	const [open, setOpen] = useState(false)
	const [deleteFunction,setDeleteFunction] = useState(() => () => {});

	return (
		<DeleteConfirmationModalContext.Provider value={{
			setOpen,
			setDeleteFunction
		}}>
			{props.children}
			<div>
				<Dialog
					open={open}
					onClose={() => setOpen(false)}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						{"Are you sure?"}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							<center>
								<img src='/images/stop.png' style={{ height: '80px', width: '80px', marginBottom: '50px' }} />
							</center>
							Are you sure that you want to delete this item? It will be lost permanently!
						</DialogContentText>
					</DialogContent>
					<DialogActions>

						<button
							className="btn"
							onClick={() => [setOpen(false)]}
						>
							Cancel
						</button>

						<button
							className="btn btn-danger"
							onClick={() => [setOpen(false),deleteFunction()]}
						>
							Delete
						</button>
					</DialogActions>
				</Dialog>
			</div>
		</DeleteConfirmationModalContext.Provider>
	);

};

export default DeleteConfirmationModalContextProvider;