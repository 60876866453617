import React, { useContext, useState } from 'react'
import RegisterLoginOverlay from '../RegisterLoginOverlay';
import { UserContext } from '../../context/UserContext';
import { Link } from 'react-router-dom';
import { Box, Button, Grid, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

function CallToAction() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const { userData } = useContext(UserContext)

    // translations
    const { t } = useTranslation(["homepage", "button"])
    return (
        <div style={{ backgroundColor: '#00457C', }}>
            <div className='container' style={{ paddingTop: '60px', paddingBottom: '60px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} sx={{ order: { xs: 2, md: 1 } }}>
                        <Stack spacing={4}>
                            <div style={{ color: 'white', fontSize: '18px', fontFamily: 'Poppins,sans-serif', fontWeight: '600', wordWrap: 'break-word' }}>{t("call-to-action.text-1")}</div>
                            <div style={{ color: 'white', fontSize: '45px', fontFamily: 'Epilogue,sans-serif', fontWeight: '700', textTransform: 'capitalize', wordWrap: 'break-word' }}>{t("call-to-action.text-2")}</div>
                            <div style={{ color: 'white', fontSize: '18px', fontFamily: 'Poppins,sans-serif', fontWeight: '600', textTransform: 'capitalize', wordWrap: 'break-word' }}>{t("call-to-action.text-3")}</div>
                            {
                                userData && userData.user_role_id == 3 ?
                                    <Link to={'/employer-post-job'}>
                                        <Button variant="contained" sx={{ width: { xs: '100%', md: '360px' }, padding: '15px', fontSize: { md: '12px', lg: '16px' }, fontFamily: 'Epilogue,sans-serif', fontWeight: '600', textTransform: 'capitalize' }}>{t("button:post-job-for-free.title")}</Button>
                                    </Link>
                                    :
                                    <Link to={'/employer-register'}>
                                        <Button variant="contained" sx={{ backgroundColor: '#0079C1', width: '330px', padding: '15px', fontSize: { md: '12px', lg: '16px' }, fontFamily: 'Epilogue,sans-serif', fontWeight: '600', textTransform: 'capitalize' }}>{t("button:post-job-for-free.title")}</Button>
                                    </Link>
                            }

                        </Stack>

                    </Grid>
                    <Grid item xs={12} md={6} sx={{ textAlign: { md: 'center' }, order: { xs: 1, md: 2 } }}>
                        <img className='d-none d-sm-inline' src='/v2/images/homepage_postJob_section_image2.png' style={{ height: '350px' }} />
                        <img className='d-inline d-sm-none' src='/v2/images/homepage_postJob_section_image2.png' style={{ width: '100%' }} />
                    </Grid>
                </Grid>
                {/* <RegisterLoginOverlay open={open} onClose={handleClose} /> */}
            </div>
            {/* <Box sx={{ padding: { xs: '10%', sm: '8%', md: '5%' } }}>
                <div className='row'>
                        <div className='col-lg-6 col-md-6'>
                            <div className=''>
                                <img src='/v2/images/callToAction.jpg' className="p-1 img-fluid" style={{ borderRadius: '20px', height:'500px' }} />
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <div className='m-5'>
                                <h5 className='mt-3' style={{ color: '#2a9df4' }}>Looking for an expert for your company?</h5>
                                <h1 style={{ color: '##03254c' }}>Asia's easiest-to-use platform!</h1>
                                <span style={{ fontSize: '23px', color: '#2b3940', lineHeight: '2' }}>Despite the various challenges in Bangladeshi recruitment, our product is user-friendly and we prioritise the addition of popular features to create the No 1 customer satisfaction platform.</span>

                                <div className='text-left' style={{ marginTop: '72px' }}>
                                    <div className='d-none d-lg-block'>
                                        {
                                            userData && userData.user_role_id == 3 ?
                                                <Link to={'/employer-post-job'}><button className='btn' style={{ padding: '20px 120px 20px 120px', borderRadious: '30px', backgroundColor: '#2a9df4', color: 'white', fontWeight: 'bold' }} >Post A Job Today!</button></Link>
                                                :
                                                <button onClick={handleOpen} className='btn' style={{ padding: '20px 120px 20px 120px', borderRadious: '30px', backgroundColor: '#2a9df4', color: 'white', fontWeight: 'bold' }} >Post A Job Today!</button>
                                        }
                                    </div>
                                    <div className='d-lg-none'>
                                        {
                                            userData && userData.user_role_id == 3 ?
                                                <Link to={'/employer-post-job'}><button className="btn btn-primary btn-lg btn-block">Post A Job Today!</button></Link>
                                                :
                                                <button onClick={handleOpen} className="btn btn-primary btn-lg btn-block">Post A Job Today!</button>
                                        }
                                    </div>
                                </div>
                                <RegisterLoginOverlay open={open} onClose={handleClose} />
                            </div>
                        </div>
                    </div>

            </Box> */}
        </div>
    )
}

export default CallToAction