import React from 'react'
import parse from 'react-html-parser'
import './CandidateProfile.css'
import styles from './UpdateProfile/CandidateUpdateProfile.module.css';
import SingleExpBlock from './UpdateProfile/component/SingleExpBlock'
import SingleEducationBlock from './UpdateProfile/component/SingleEducationBlock'
import SingleProjectBlock from './UpdateProfile/component/SingleProjectBlock'
import SingleCertificateBlock from './UpdateProfile/component/SingleCertificateBlock'
import SinglePersonalInfoBlock from './UpdateProfile/component/SinglePersonalInfoBlock'
import { Box, Button } from '@mui/material'
import moment from 'moment';
import SingleCareerInfoBlock from './UpdateProfile/component/SingleCareerInfoBlock';
import CommonMasking from '../../maskingcomponents/CommonMasking';
import { downloadResume } from '../../../../fetchAPI';

function CandidateProfileDetails(props) {

	function getFileNameFromMediaLink(link) {
		let a = link.split('/');
		return a[a.length - 1];
	}

	const appliedResumePath = props.applyInfo?.applied_resume;
	const appliedResumeFilename = appliedResumePath ? appliedResumePath.split('/').pop() : '';

	const resumeDownload = (resumeId, resumeLink) => {

        let filename = 'resume'

        if (resumeLink) {
            let resumeReverse = resumeLink?.split('').reverse().join('');
            let reverseArray = resumeReverse.split("/")
            filename = reverseArray[0].split('').reverse().join('')
        }

        downloadResume(resumeId).then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `${filename}`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

	return (
		<div className=''>

			<Box className='card shadow-sm ' id='basic_info_block' sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, marginTop: "10px" }}>
				<div className={styles.cardHeader}>
					<div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
						<div className={styles.sectionHeader}>Basic Info</div>
					</div>
				</div>
				<div className='card-body'>
					<div className={styles.infoBlock} style={{ marginBottom: '10px' }}>
						<div>

							<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
								<rect width="32" height="32" rx="16" fill="#DCEFFF" />
								<path d="M16.3495 11.6574C14.2668 11.6574 12.4637 13.1038 12.0119 15.1368L10.5323 21.7947C10.5215 21.8436 10.516 21.8934 10.516 21.9435C10.516 22.3222 10.823 22.6292 11.2018 22.6292H21.4972C21.5472 22.6292 21.5971 22.6237 21.6459 22.6129C22.0156 22.5307 22.2487 22.1644 22.1666 21.7947L20.687 15.1368C20.2353 13.1038 18.4321 11.6574 16.3495 11.6574ZM16.3495 10.2859C19.0749 10.2859 21.4346 12.1788 22.0259 14.8393L23.5054 21.4972C23.7519 22.6063 23.0526 23.7052 21.9434 23.9517C21.7969 23.9843 21.6473 24.0007 21.4972 24.0007H11.2018C10.0656 24.0007 9.14453 23.0797 9.14453 21.9435C9.14453 21.7934 9.16096 21.6437 9.19352 21.4972L10.673 14.8393C11.2643 12.1788 13.624 10.2859 16.3495 10.2859Z" fill="#0275D8" />
								<path d="M15.6147 10.717C15.7554 11.0686 15.5843 11.4677 15.2327 11.6083C14.881 11.749 14.482 11.578 14.3413 11.2263L12.9698 7.79761C12.747 7.24044 13.2967 6.68599 13.8557 6.90407C14.3692 7.10438 14.7519 7.20006 14.978 7.20006C15.0343 7.20006 15.0809 7.18803 15.1827 7.14476C15.2098 7.13327 15.2415 7.11925 15.316 7.08629C15.6707 6.93086 15.9509 6.85719 16.3495 6.85719C16.7472 6.85719 17.0318 6.93105 17.3876 7.08499C17.4779 7.12452 17.514 7.14025 17.5459 7.1534C17.6338 7.1896 17.6761 7.20006 17.721 7.20006C17.9338 7.20006 18.32 7.10352 18.8482 6.90216C19.4066 6.68932 19.9511 7.24279 19.7291 7.79761L18.3577 11.2263C18.217 11.578 17.8179 11.749 17.4663 11.6083C17.1147 11.4677 16.9436 11.0686 17.0843 10.717L17.9472 8.55974C17.8683 8.56759 17.793 8.57154 17.721 8.57154C17.4707 8.57154 17.2633 8.52023 17.0237 8.42157C16.9764 8.40209 16.9245 8.37947 16.8431 8.34374C16.6446 8.25786 16.5321 8.22867 16.3495 8.22867C16.1686 8.22867 16.0624 8.2566 15.8665 8.34244C15.7992 8.37224 15.7566 8.39107 15.7191 8.407C15.4623 8.51614 15.2474 8.57154 14.978 8.57154C14.9056 8.57154 14.8303 8.56778 14.752 8.56031L15.6147 10.717Z" fill="#0275D8" />
								<path d="M19.3936 16.6368V17.5797H16.5821V19.534C16.5821 19.694 16.6049 19.814 16.6506 19.894C16.6964 19.9683 16.7992 20.0055 16.9592 20.0055C17.0907 20.0055 17.2192 19.9855 17.3449 19.9455C17.4707 19.8997 17.585 19.8426 17.6878 19.774C17.7907 19.6997 17.8735 19.614 17.9364 19.5169C17.9993 19.4197 18.0307 19.3197 18.0307 19.2169C18.0307 19.1654 18.0078 19.1169 17.9621 19.0711C17.9164 19.0254 17.8278 19.0026 17.6964 19.0026C17.5935 19.0026 17.5021 19.0111 17.4221 19.0283L17.2849 18.154C17.3878 18.1254 17.5021 18.1054 17.6278 18.094C17.7592 18.0825 17.8935 18.0768 18.0307 18.0768C18.3678 18.0768 18.6393 18.154 18.845 18.3083C19.0507 18.4625 19.1536 18.7026 19.1536 19.0283C19.1536 19.3369 19.0822 19.6112 18.9393 19.8512C18.7964 20.0912 18.6079 20.2912 18.3736 20.4512C18.1393 20.6112 17.8821 20.7341 17.6021 20.8198C17.3278 20.8998 17.0592 20.9398 16.7964 20.9398C16.5563 20.9398 16.3535 20.9112 16.1878 20.8541C16.0278 20.7969 15.8992 20.7226 15.802 20.6312C15.7392 20.5683 15.6792 20.4969 15.622 20.4169C15.5706 20.3369 15.5277 20.2255 15.4934 20.0826C15.4649 19.9397 15.4506 19.7483 15.4506 19.5083V17.5797H14.7306V16.6368H19.3936ZM15.3649 14.5195C15.5534 14.5195 15.7192 14.5424 15.862 14.5881C16.0049 14.6338 16.1249 14.7053 16.222 14.8024C16.3363 14.911 16.4249 15.0624 16.4878 15.2567C16.5506 15.451 16.5821 15.7253 16.5821 16.0796V16.671H15.4506V16.1225C15.4506 15.9796 15.4392 15.8596 15.4163 15.7624C15.3934 15.6653 15.3477 15.5939 15.2792 15.5481C15.2106 15.4967 15.1077 15.471 14.9706 15.471C14.8963 15.471 14.8163 15.4796 14.7306 15.4967C14.6506 15.5139 14.5677 15.5396 14.482 15.5739L14.1562 14.751C14.3791 14.671 14.5791 14.6138 14.7563 14.5795C14.9334 14.5395 15.1363 14.5195 15.3649 14.5195Z" fill="#0275D8" />
							</svg>

						</div>
						<div style={{ marginLeft: '20px' }}>
							<div className={styles.heading_black}>
								Qualification
							</div>
							<div className={styles.heading_blue}>
								{props.candidateInfo.qualification?.name}
							</div>
						</div>
					</div>
					<div className={styles.infoBlock} style={{ marginBottom: '10px' }}>
						<div>

							<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
								<rect width="32" height="32" rx="16" fill="#DCEFFF" />
								<path d="M16.3495 11.6574C14.2668 11.6574 12.4637 13.1038 12.0119 15.1368L10.5323 21.7947C10.5215 21.8436 10.516 21.8934 10.516 21.9435C10.516 22.3222 10.823 22.6292 11.2018 22.6292H21.4972C21.5472 22.6292 21.5971 22.6237 21.6459 22.6129C22.0156 22.5307 22.2487 22.1644 22.1666 21.7947L20.687 15.1368C20.2353 13.1038 18.4321 11.6574 16.3495 11.6574ZM16.3495 10.2859C19.0749 10.2859 21.4346 12.1788 22.0259 14.8393L23.5054 21.4972C23.7519 22.6063 23.0526 23.7052 21.9434 23.9517C21.7969 23.9843 21.6473 24.0007 21.4972 24.0007H11.2018C10.0656 24.0007 9.14453 23.0797 9.14453 21.9435C9.14453 21.7934 9.16096 21.6437 9.19352 21.4972L10.673 14.8393C11.2643 12.1788 13.624 10.2859 16.3495 10.2859Z" fill="#0275D8" />
								<path d="M15.6147 10.717C15.7554 11.0686 15.5843 11.4677 15.2327 11.6083C14.881 11.749 14.482 11.578 14.3413 11.2263L12.9698 7.79761C12.747 7.24044 13.2967 6.68599 13.8557 6.90407C14.3692 7.10438 14.7519 7.20006 14.978 7.20006C15.0343 7.20006 15.0809 7.18803 15.1827 7.14476C15.2098 7.13327 15.2415 7.11925 15.316 7.08629C15.6707 6.93086 15.9509 6.85719 16.3495 6.85719C16.7472 6.85719 17.0318 6.93105 17.3876 7.08499C17.4779 7.12452 17.514 7.14025 17.5459 7.1534C17.6338 7.1896 17.6761 7.20006 17.721 7.20006C17.9338 7.20006 18.32 7.10352 18.8482 6.90216C19.4066 6.68932 19.9511 7.24279 19.7291 7.79761L18.3577 11.2263C18.217 11.578 17.8179 11.749 17.4663 11.6083C17.1147 11.4677 16.9436 11.0686 17.0843 10.717L17.9472 8.55974C17.8683 8.56759 17.793 8.57154 17.721 8.57154C17.4707 8.57154 17.2633 8.52023 17.0237 8.42157C16.9764 8.40209 16.9245 8.37947 16.8431 8.34374C16.6446 8.25786 16.5321 8.22867 16.3495 8.22867C16.1686 8.22867 16.0624 8.2566 15.8665 8.34244C15.7992 8.37224 15.7566 8.39107 15.7191 8.407C15.4623 8.51614 15.2474 8.57154 14.978 8.57154C14.9056 8.57154 14.8303 8.56778 14.752 8.56031L15.6147 10.717Z" fill="#0275D8" />
								<path d="M19.3936 16.6368V17.5797H16.5821V19.534C16.5821 19.694 16.6049 19.814 16.6506 19.894C16.6964 19.9683 16.7992 20.0055 16.9592 20.0055C17.0907 20.0055 17.2192 19.9855 17.3449 19.9455C17.4707 19.8997 17.585 19.8426 17.6878 19.774C17.7907 19.6997 17.8735 19.614 17.9364 19.5169C17.9993 19.4197 18.0307 19.3197 18.0307 19.2169C18.0307 19.1654 18.0078 19.1169 17.9621 19.0711C17.9164 19.0254 17.8278 19.0026 17.6964 19.0026C17.5935 19.0026 17.5021 19.0111 17.4221 19.0283L17.2849 18.154C17.3878 18.1254 17.5021 18.1054 17.6278 18.094C17.7592 18.0825 17.8935 18.0768 18.0307 18.0768C18.3678 18.0768 18.6393 18.154 18.845 18.3083C19.0507 18.4625 19.1536 18.7026 19.1536 19.0283C19.1536 19.3369 19.0822 19.6112 18.9393 19.8512C18.7964 20.0912 18.6079 20.2912 18.3736 20.4512C18.1393 20.6112 17.8821 20.7341 17.6021 20.8198C17.3278 20.8998 17.0592 20.9398 16.7964 20.9398C16.5563 20.9398 16.3535 20.9112 16.1878 20.8541C16.0278 20.7969 15.8992 20.7226 15.802 20.6312C15.7392 20.5683 15.6792 20.4969 15.622 20.4169C15.5706 20.3369 15.5277 20.2255 15.4934 20.0826C15.4649 19.9397 15.4506 19.7483 15.4506 19.5083V17.5797H14.7306V16.6368H19.3936ZM15.3649 14.5195C15.5534 14.5195 15.7192 14.5424 15.862 14.5881C16.0049 14.6338 16.1249 14.7053 16.222 14.8024C16.3363 14.911 16.4249 15.0624 16.4878 15.2567C16.5506 15.451 16.5821 15.7253 16.5821 16.0796V16.671H15.4506V16.1225C15.4506 15.9796 15.4392 15.8596 15.4163 15.7624C15.3934 15.6653 15.3477 15.5939 15.2792 15.5481C15.2106 15.4967 15.1077 15.471 14.9706 15.471C14.8963 15.471 14.8163 15.4796 14.7306 15.4967C14.6506 15.5139 14.5677 15.5396 14.482 15.5739L14.1562 14.751C14.3791 14.671 14.5791 14.6138 14.7563 14.5795C14.9334 14.5395 15.1363 14.5195 15.3649 14.5195Z" fill="#0275D8" />
							</svg>

						</div>
						<div style={{ marginLeft: '20px' }}>
							<div className={styles.heading_black}>
								Looking for (Job Level)
							</div>
							<div className={styles.heading_blue}>
								{props.candidateInfo.level?.candidate_level_name}
							</div>
						</div>
					</div>
					<div className={styles.infoBlock} style={{ marginBottom: '10px' }}>
						<div>

							<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
								<rect width="32" height="32" rx="16" fill="#DCEFFF" />
								<path d="M16.3495 11.6574C14.2668 11.6574 12.4637 13.1038 12.0119 15.1368L10.5323 21.7947C10.5215 21.8436 10.516 21.8934 10.516 21.9435C10.516 22.3222 10.823 22.6292 11.2018 22.6292H21.4972C21.5472 22.6292 21.5971 22.6237 21.6459 22.6129C22.0156 22.5307 22.2487 22.1644 22.1666 21.7947L20.687 15.1368C20.2353 13.1038 18.4321 11.6574 16.3495 11.6574ZM16.3495 10.2859C19.0749 10.2859 21.4346 12.1788 22.0259 14.8393L23.5054 21.4972C23.7519 22.6063 23.0526 23.7052 21.9434 23.9517C21.7969 23.9843 21.6473 24.0007 21.4972 24.0007H11.2018C10.0656 24.0007 9.14453 23.0797 9.14453 21.9435C9.14453 21.7934 9.16096 21.6437 9.19352 21.4972L10.673 14.8393C11.2643 12.1788 13.624 10.2859 16.3495 10.2859Z" fill="#0275D8" />
								<path d="M15.6147 10.717C15.7554 11.0686 15.5843 11.4677 15.2327 11.6083C14.881 11.749 14.482 11.578 14.3413 11.2263L12.9698 7.79761C12.747 7.24044 13.2967 6.68599 13.8557 6.90407C14.3692 7.10438 14.7519 7.20006 14.978 7.20006C15.0343 7.20006 15.0809 7.18803 15.1827 7.14476C15.2098 7.13327 15.2415 7.11925 15.316 7.08629C15.6707 6.93086 15.9509 6.85719 16.3495 6.85719C16.7472 6.85719 17.0318 6.93105 17.3876 7.08499C17.4779 7.12452 17.514 7.14025 17.5459 7.1534C17.6338 7.1896 17.6761 7.20006 17.721 7.20006C17.9338 7.20006 18.32 7.10352 18.8482 6.90216C19.4066 6.68932 19.9511 7.24279 19.7291 7.79761L18.3577 11.2263C18.217 11.578 17.8179 11.749 17.4663 11.6083C17.1147 11.4677 16.9436 11.0686 17.0843 10.717L17.9472 8.55974C17.8683 8.56759 17.793 8.57154 17.721 8.57154C17.4707 8.57154 17.2633 8.52023 17.0237 8.42157C16.9764 8.40209 16.9245 8.37947 16.8431 8.34374C16.6446 8.25786 16.5321 8.22867 16.3495 8.22867C16.1686 8.22867 16.0624 8.2566 15.8665 8.34244C15.7992 8.37224 15.7566 8.39107 15.7191 8.407C15.4623 8.51614 15.2474 8.57154 14.978 8.57154C14.9056 8.57154 14.8303 8.56778 14.752 8.56031L15.6147 10.717Z" fill="#0275D8" />
								<path d="M19.3936 16.6368V17.5797H16.5821V19.534C16.5821 19.694 16.6049 19.814 16.6506 19.894C16.6964 19.9683 16.7992 20.0055 16.9592 20.0055C17.0907 20.0055 17.2192 19.9855 17.3449 19.9455C17.4707 19.8997 17.585 19.8426 17.6878 19.774C17.7907 19.6997 17.8735 19.614 17.9364 19.5169C17.9993 19.4197 18.0307 19.3197 18.0307 19.2169C18.0307 19.1654 18.0078 19.1169 17.9621 19.0711C17.9164 19.0254 17.8278 19.0026 17.6964 19.0026C17.5935 19.0026 17.5021 19.0111 17.4221 19.0283L17.2849 18.154C17.3878 18.1254 17.5021 18.1054 17.6278 18.094C17.7592 18.0825 17.8935 18.0768 18.0307 18.0768C18.3678 18.0768 18.6393 18.154 18.845 18.3083C19.0507 18.4625 19.1536 18.7026 19.1536 19.0283C19.1536 19.3369 19.0822 19.6112 18.9393 19.8512C18.7964 20.0912 18.6079 20.2912 18.3736 20.4512C18.1393 20.6112 17.8821 20.7341 17.6021 20.8198C17.3278 20.8998 17.0592 20.9398 16.7964 20.9398C16.5563 20.9398 16.3535 20.9112 16.1878 20.8541C16.0278 20.7969 15.8992 20.7226 15.802 20.6312C15.7392 20.5683 15.6792 20.4969 15.622 20.4169C15.5706 20.3369 15.5277 20.2255 15.4934 20.0826C15.4649 19.9397 15.4506 19.7483 15.4506 19.5083V17.5797H14.7306V16.6368H19.3936ZM15.3649 14.5195C15.5534 14.5195 15.7192 14.5424 15.862 14.5881C16.0049 14.6338 16.1249 14.7053 16.222 14.8024C16.3363 14.911 16.4249 15.0624 16.4878 15.2567C16.5506 15.451 16.5821 15.7253 16.5821 16.0796V16.671H15.4506V16.1225C15.4506 15.9796 15.4392 15.8596 15.4163 15.7624C15.3934 15.6653 15.3477 15.5939 15.2792 15.5481C15.2106 15.4967 15.1077 15.471 14.9706 15.471C14.8963 15.471 14.8163 15.4796 14.7306 15.4967C14.6506 15.5139 14.5677 15.5396 14.482 15.5739L14.1562 14.751C14.3791 14.671 14.5791 14.6138 14.7563 14.5795C14.9334 14.5395 15.1363 14.5195 15.3649 14.5195Z" fill="#0275D8" />
							</svg>

						</div>
						<div style={{ marginLeft: '20px' }}>
							<div className={styles.heading_black}>
								Total Year of Experience
							</div>
							<div className={styles.heading_blue}>
								{props.candidateInfo.total_experience} Years
							</div>
						</div>
					</div>
					<div className={styles.infoBlock} style={{ marginBottom: '10px' }}>
						<div>

							<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
								<rect width="32" height="32" rx="16" fill="#DCEFFF" />
								<path d="M16.3495 11.6574C14.2668 11.6574 12.4637 13.1038 12.0119 15.1368L10.5323 21.7947C10.5215 21.8436 10.516 21.8934 10.516 21.9435C10.516 22.3222 10.823 22.6292 11.2018 22.6292H21.4972C21.5472 22.6292 21.5971 22.6237 21.6459 22.6129C22.0156 22.5307 22.2487 22.1644 22.1666 21.7947L20.687 15.1368C20.2353 13.1038 18.4321 11.6574 16.3495 11.6574ZM16.3495 10.2859C19.0749 10.2859 21.4346 12.1788 22.0259 14.8393L23.5054 21.4972C23.7519 22.6063 23.0526 23.7052 21.9434 23.9517C21.7969 23.9843 21.6473 24.0007 21.4972 24.0007H11.2018C10.0656 24.0007 9.14453 23.0797 9.14453 21.9435C9.14453 21.7934 9.16096 21.6437 9.19352 21.4972L10.673 14.8393C11.2643 12.1788 13.624 10.2859 16.3495 10.2859Z" fill="#0275D8" />
								<path d="M15.6147 10.717C15.7554 11.0686 15.5843 11.4677 15.2327 11.6083C14.881 11.749 14.482 11.578 14.3413 11.2263L12.9698 7.79761C12.747 7.24044 13.2967 6.68599 13.8557 6.90407C14.3692 7.10438 14.7519 7.20006 14.978 7.20006C15.0343 7.20006 15.0809 7.18803 15.1827 7.14476C15.2098 7.13327 15.2415 7.11925 15.316 7.08629C15.6707 6.93086 15.9509 6.85719 16.3495 6.85719C16.7472 6.85719 17.0318 6.93105 17.3876 7.08499C17.4779 7.12452 17.514 7.14025 17.5459 7.1534C17.6338 7.1896 17.6761 7.20006 17.721 7.20006C17.9338 7.20006 18.32 7.10352 18.8482 6.90216C19.4066 6.68932 19.9511 7.24279 19.7291 7.79761L18.3577 11.2263C18.217 11.578 17.8179 11.749 17.4663 11.6083C17.1147 11.4677 16.9436 11.0686 17.0843 10.717L17.9472 8.55974C17.8683 8.56759 17.793 8.57154 17.721 8.57154C17.4707 8.57154 17.2633 8.52023 17.0237 8.42157C16.9764 8.40209 16.9245 8.37947 16.8431 8.34374C16.6446 8.25786 16.5321 8.22867 16.3495 8.22867C16.1686 8.22867 16.0624 8.2566 15.8665 8.34244C15.7992 8.37224 15.7566 8.39107 15.7191 8.407C15.4623 8.51614 15.2474 8.57154 14.978 8.57154C14.9056 8.57154 14.8303 8.56778 14.752 8.56031L15.6147 10.717Z" fill="#0275D8" />
								<path d="M19.3936 16.6368V17.5797H16.5821V19.534C16.5821 19.694 16.6049 19.814 16.6506 19.894C16.6964 19.9683 16.7992 20.0055 16.9592 20.0055C17.0907 20.0055 17.2192 19.9855 17.3449 19.9455C17.4707 19.8997 17.585 19.8426 17.6878 19.774C17.7907 19.6997 17.8735 19.614 17.9364 19.5169C17.9993 19.4197 18.0307 19.3197 18.0307 19.2169C18.0307 19.1654 18.0078 19.1169 17.9621 19.0711C17.9164 19.0254 17.8278 19.0026 17.6964 19.0026C17.5935 19.0026 17.5021 19.0111 17.4221 19.0283L17.2849 18.154C17.3878 18.1254 17.5021 18.1054 17.6278 18.094C17.7592 18.0825 17.8935 18.0768 18.0307 18.0768C18.3678 18.0768 18.6393 18.154 18.845 18.3083C19.0507 18.4625 19.1536 18.7026 19.1536 19.0283C19.1536 19.3369 19.0822 19.6112 18.9393 19.8512C18.7964 20.0912 18.6079 20.2912 18.3736 20.4512C18.1393 20.6112 17.8821 20.7341 17.6021 20.8198C17.3278 20.8998 17.0592 20.9398 16.7964 20.9398C16.5563 20.9398 16.3535 20.9112 16.1878 20.8541C16.0278 20.7969 15.8992 20.7226 15.802 20.6312C15.7392 20.5683 15.6792 20.4969 15.622 20.4169C15.5706 20.3369 15.5277 20.2255 15.4934 20.0826C15.4649 19.9397 15.4506 19.7483 15.4506 19.5083V17.5797H14.7306V16.6368H19.3936ZM15.3649 14.5195C15.5534 14.5195 15.7192 14.5424 15.862 14.5881C16.0049 14.6338 16.1249 14.7053 16.222 14.8024C16.3363 14.911 16.4249 15.0624 16.4878 15.2567C16.5506 15.451 16.5821 15.7253 16.5821 16.0796V16.671H15.4506V16.1225C15.4506 15.9796 15.4392 15.8596 15.4163 15.7624C15.3934 15.6653 15.3477 15.5939 15.2792 15.5481C15.2106 15.4967 15.1077 15.471 14.9706 15.471C14.8963 15.471 14.8163 15.4796 14.7306 15.4967C14.6506 15.5139 14.5677 15.5396 14.482 15.5739L14.1562 14.751C14.3791 14.671 14.5791 14.6138 14.7563 14.5795C14.9334 14.5395 15.1363 14.5195 15.3649 14.5195Z" fill="#0275D8" />
							</svg>

						</div>
						<div style={{ marginLeft: '20px' }}>
							<div className={styles.heading_black}>
								Expected Salary
							</div>
							<div className={styles.heading_blue}>
								{props.masking ? <CommonMasking>xxxxxx</CommonMasking> : props.candidateInfo.expected_salary}
							</div>
						</div>
					</div>
					<div className={styles.infoBlock} style={{ marginBottom: '10px' }}>
						<div>

							<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
								<rect width="32" height="32" rx="16" fill="#DCEFFF" />
								<path fill-rule="evenodd" clip-rule="evenodd" d="M10.1293 8.70724C11.1683 7.66826 12.8866 7.79038 13.7682 8.96585L15.2316 10.917C15.9525 11.8783 15.857 13.2234 15.0073 14.073L13.9284 15.152C14.0329 15.4242 14.3772 16.0214 15.4253 17.0696C16.4736 18.1178 17.0708 18.462 17.343 18.5665L18.4219 17.4877C19.2716 16.638 20.6167 16.5424 21.5779 17.2634L23.5291 18.7268C24.7046 19.6084 24.8267 21.3267 23.7877 22.3657C23.4499 22.7035 23.3928 22.7606 22.8291 23.3243C22.2546 23.8988 21.0348 24.4358 19.7997 24.4895C17.867 24.5735 15.2419 23.7154 12.0108 20.4842C8.77963 17.2531 7.92147 14.6279 8.0055 12.6952C8.05224 11.6201 8.39405 10.4394 9.17516 9.67037C9.73436 9.10219 9.80708 9.02946 10.1293 8.70724ZM9.61364 12.7652C9.55539 14.1048 10.12 16.317 13.149 19.346C16.1779 22.375 18.3901 22.9396 19.7298 22.8813C20.9804 22.8269 21.6513 22.2226 21.6909 22.1861L22.6495 21.2275C22.9958 20.8812 22.9552 20.3084 22.5633 20.0145L20.6121 18.5511C20.2917 18.3108 19.8433 18.3426 19.5601 18.6259C19.1334 19.0526 18.8421 19.3481 18.272 19.9162C17.0876 21.0961 15.0601 18.9807 14.2872 18.2078C13.5768 17.4975 11.4121 15.4057 12.5776 14.2263C12.5799 14.224 12.8939 13.9101 13.8691 12.9348C14.1523 12.6516 14.1842 12.2033 13.9439 11.8828L12.4805 9.93165C12.1866 9.53982 11.6138 9.49911 11.2675 9.84544C10.9487 10.1642 10.588 10.5249 10.3098 10.805C9.7373 11.3815 9.64428 12.0605 9.61364 12.7652Z" fill="#0275D8" />
							</svg>

						</div>
						<div style={{ marginLeft: '20px' }}>
							<div className={styles.heading_black}>
								Contact Number
							</div>
							{props.masking ?
								<CommonMasking>
									<div className={styles.heading_blue}>
										01XXXXXXXXX
									</div>
								</CommonMasking> :
								<div className={styles.heading_blue}>
									{props.candidateInfo.phone_no}
								</div>
							}
						</div>
					</div>
					<div className={styles.infoBlock} style={{ marginBottom: '10px' }}>
						<div>

							<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
								<rect width="32" height="32" rx="16" fill="#DCEFFF" />
								<path fill-rule="evenodd" clip-rule="evenodd" d="M21.3346 11.3333H10.668C10.2998 11.3333 10.0013 11.6318 10.0013 12V20C10.0013 20.3682 10.2998 20.6667 10.668 20.6667H21.3346C21.7028 20.6667 22.0013 20.3682 22.0013 20V12C22.0013 11.6318 21.7028 11.3333 21.3346 11.3333ZM10.668 10C9.5634 10 8.66797 10.8954 8.66797 12V20C8.66797 21.1046 9.5634 22 10.668 22H21.3346C22.4392 22 23.3346 21.1046 23.3346 20V12C23.3346 10.8954 22.4392 10 21.3346 10H10.668Z" fill="#0275D8" />
								<path fill-rule="evenodd" clip-rule="evenodd" d="M11.4905 12.9079C11.7262 12.625 12.1466 12.5868 12.4294 12.8225L15.5758 15.4445C15.8231 15.6505 16.1822 15.6505 16.4294 15.4445L19.5758 12.8225C19.8587 12.5868 20.2791 12.625 20.5148 12.9079C20.7505 13.1907 20.7123 13.6111 20.4294 13.8468L17.283 16.4688C16.5413 17.0869 15.464 17.0869 14.7222 16.4688L11.5758 13.8468C11.293 13.6111 11.2548 13.1907 11.4905 12.9079Z" fill="#0275D8" />
							</svg>

						</div>
						<div style={{ marginLeft: '20px' }}>
							<div className={styles.heading_black}>
								Email Address
							</div>
							{props.masking ?
								<CommonMasking>
									<div className={styles.heading_blue}>
										common@xyz.com
									</div>
								</CommonMasking> :
								<div className={styles.heading_blue} style={{ wordBreak: 'break-word' }}>
									{props.candidateInfo.user?.email}
								</div>
							}

						</div>
					</div>
					<div className={styles.infoBlock} style={{ marginBottom: '10px' }}>
						<div>

							<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
								<rect width="32" height="32" rx="16" fill="#DCEFFF" />
								<g clip-path="url(#clip0_1451_499)">
									<path d="M15.9991 8.88867C12.997 8.88867 10.5547 11.331 10.5547 14.3331C10.5547 15.2941 10.8086 16.2388 11.2889 17.0652C11.4009 17.2579 11.526 17.4456 11.661 17.6233L15.7626 23.1109H16.2357L20.3373 17.6233C20.4722 17.4456 20.5974 17.2579 20.7094 17.0652C21.1897 16.2388 21.4436 15.2941 21.4436 14.3331C21.4436 11.331 19.0012 8.88867 15.9991 8.88867ZM15.9991 16.1387C15.0035 16.1387 14.1936 15.3287 14.1936 14.3331C14.1936 13.3375 15.0035 12.5276 15.9991 12.5276C16.9947 12.5276 17.8047 13.3375 17.8047 14.3331C17.8047 15.3287 16.9947 16.1387 15.9991 16.1387Z" fill="#0275D8" />
								</g>
								<defs>
									<clipPath id="clip0_1451_499">
										<rect width="14.2222" height="14.2222" fill="white" transform="translate(8.89062 8.88867)" />
									</clipPath>
								</defs>
							</svg>

						</div>
						<div style={{ marginLeft: '20px' }}>
							<div className={styles.heading_black}>
								Location
							</div>
							{props.masking ?
								<CommonMasking>
									<div className={styles.heading_blue}>
										Address Here, Other Part
									</div>
								</CommonMasking> :
								<div className={styles.heading_blue}>
									{props.candidateInfo.address}
								</div>
							}

						</div>
					</div>
				</div>
			</Box>
			<Box className='card shadow-sm' sx={{ marginTop: { xs: '10px', sm: '10px', md: '50px' } }} id='about_box'>
				<div className={styles.cardHeader}>
					<div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
						<div className={styles.sectionHeader}>Profile Summary</div>
					</div>
				</div>
				<div className='card-body'>
					{
						props.masking ?
							<CommonMasking>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nulla turpis, scelerisque sit amet scelerisque vitae, fermentum non odio. Nullam sit amet felis sit amet diam aliquam hendrerit in at lectus. Vivamus eu tortor et odio commodo porta non eget libero. Pellentesque dignissim nisl a bibendum iaculis. Fusce tincidunt non elit non imperdiet. Fusce luctus augue id libero cursus, id euismod velit placerat. Aliquam eget vestibulum enim. Proin venenatis commodo massa, a gravida quam tempus vitae.
							</CommonMasking>
							:
							<>
								{parse(props.candidateInfo.intro)}
							</>
					}

				</div>
			</Box>

			<div className='card shadow-sm' style={{ marginTop: '10px' }} id='skill_box'>
				<div className={styles.cardHeader}>
					<div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
						<div className={styles.sectionHeader}>Key Skills</div>
					</div>
				</div>
				<div className='card-body'>
					<div className={styles.skillContainer}>
						{props.candidateInfo.skills?.map((item, index) => {
							return (

								<div className={styles.singleSkill} key={item.id}>
									<div>
										<span>{item.skill_name}</span>
										<span> - {item.pivot?.year_of_experience} years</span>
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>

			<div className='card shadow-sm' style={{ marginTop: '10px' }} id='employment_box'>
				<div className={styles.cardHeader}>
					<div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
						<div className={styles.sectionHeader}>Work Experience</div>
					</div>
				</div>
				<div className='card-body'>
					{
						props.candidateInfo.candidate_experience?.map((item, index) => {
							return (
								<div key={item.id}>
									{index > 0 ? <hr /> : ''}
									<div className={styles.expTitle}>{item.role}</div>
									<div className={styles.singleExp} >
										<SingleExpBlock item={item} masking={props.masking} />
									</div>
								</div>
							)
						})
					}
				</div>
			</div>

			<div className='card shadow-sm' style={{ marginTop: '10px' }} id='education_box'>
				<div className={styles.cardHeader}>
					<div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
						<div className={styles.sectionHeader}>Education</div>
					</div>
				</div>
				<div className='card-body'>
					{
						props.candidateInfo.candidate_education?.map((item, index) => {
							return (
								<div key={item.id}>
									{index > 0 ? <hr /> : ''}
									<div className={styles.eduDegree}>
										{item.degree_name}
									</div>
									<div className={styles.singleEducation} >
										<SingleEducationBlock item={item} masking={props.masking} />
									</div>
								</div>
							)
						})
					}
				</div>
			</div>

			<div id='resume_box' style={{ marginTop: '10px' }}>
				<div className='card shadow-sm'>
					<div className={styles.cardHeader}>
						<div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
							<div className={styles.sectionHeader}>Resume</div>
						</div>
					</div>
					<div className='card-body'>
						{
							props.masking ?
								<CommonMasking>
									<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px' }}>
										<div>
											<div className={styles.resumeTitle}>
												Resume_Title.pdf
											</div>
											<div className={styles.resumeUploadedOn}>Uploaded on Jan XX , XXXX</div>
										</div>
									</div>
								</CommonMasking>
							:
							appliedResumePath ? (
								<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px' }}>
									<div className={styles.resumeTitle} style={{ marginTop: '10px' }}>
										{appliedResumeFilename}
									</div>
									<Button onClick={(e) => resumeDownload(props.applyInfo?.candidate_resume_id, appliedResumePath)} >
										<svg width="32" height="32" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
											<circle cx="21" cy="21" r="21" fill="#E5F5FF" />
											<path d="M20.1394 23.3634V11.9175C20.1394 10.5462 20.4277 10.0241 21.2068 10.0008C21.986 9.97738 22.3133 10.4916 22.3133 11.8707V23.3011C22.695 23.4647 22.8041 23.1686 22.96 23.0128C24.1131 21.8752 25.2429 20.7298 26.3961 19.5922C27.0895 18.8988 27.7986 18.7663 28.2349 19.2962C28.7803 19.9662 28.5232 20.5272 27.9622 21.0805C26.0221 23.005 24.1131 24.9763 22.1808 26.893C21.4795 27.5942 20.9809 27.5942 20.2719 26.893C18.3291 24.9607 16.3968 23.0258 14.4749 21.0883C13.8204 20.4338 13.7425 19.7637 14.2645 19.3195C14.7865 18.8754 15.4488 19.0079 15.9709 19.5533C17.2565 20.8857 18.5655 22.2025 19.8667 23.527L20.1394 23.3634Z" fill="#0079C1" />
											<path d="M21.1019 31.1699H14.0271C12.796 31.1699 12.4688 30.8505 12.4688 29.6116C12.4688 28.8324 12.4688 28.0533 12.4688 27.2741C12.4688 26.4949 12.7804 26.0274 13.5752 26.0508C14.3699 26.0742 14.6114 26.5884 14.6426 27.3053C14.7049 29.0428 14.7127 29.0428 16.4347 29.0428C19.8864 29.0428 23.3459 29.0428 26.7976 29.0428C27.5767 29.0428 27.8027 28.8168 27.7247 28.0922C27.6741 27.6912 27.6741 27.2855 27.7247 26.8845C27.7483 26.6469 27.8612 26.4271 28.0405 26.2695C28.2198 26.1119 28.4523 26.0282 28.6909 26.0352C28.8132 26.0107 28.9393 26.0117 29.0611 26.0383C29.183 26.0648 29.298 26.1164 29.399 26.1895C29.5 26.2627 29.5848 26.356 29.648 26.4636C29.7112 26.5711 29.7514 26.6906 29.7662 26.8144C29.9142 27.9889 29.9142 29.1773 29.7662 30.3518C29.6727 31.0375 29.0649 31.131 28.465 31.131L21.1019 31.1699Z" fill="#0079C1" />
										</svg>
									</Button>
								</div>
							) : (
								''
							)
						}
					</div>
				</div>

			</div>

			<div className='card shadow-sm' style={{ marginTop: '10px' }} id='project_box'>
				<div className={styles.cardHeader}>
					<div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
						<div className={styles.sectionHeader}>Projects</div>
					</div>
				</div>
				<div className='card-body'>
					{
						props.candidateInfo.projects?.map((item, index) => {
							return (
								<div key={item.id}>
									{index > 0 ? <hr /> : ''}
									<div className={styles.projectTitle}>{item.title}</div>
									<div className={styles.singleProject} >
										<SingleProjectBlock item={item} masking={props.masking} />
									</div>
								</div>
							)
						})
					}
				</div>
			</div>

			<div className='card shadow-sm' style={{ marginTop: '10px' }} id='certificate_box'>
				<div className={styles.cardHeader}>
					<div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
						<div className={styles.sectionHeader}>Training & Certifications</div>
					</div>
				</div>
				<div className='card-body'>
					{
						props.candidateInfo.certification?.map((item, index) => {
							return (
								<div key={item.id}>
									{index > 0 ? <hr /> : ''}
									<div className={styles.certificateTitle}>{item.name}</div>
									<div className={styles.singleCertificate} >
										<SingleCertificateBlock item={item} masking={props.masking} />
									</div>
								</div>
							)
						})
					}
				</div>
			</div>

			<div className='card shadow-sm' style={{ marginTop: '10px' }} id='personal_box'>
				<div className={styles.cardHeader}>
					<div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
						<div className={styles.sectionHeader}>Personal Info</div>
					</div>
				</div>
				<div className='card-body'>
					<SinglePersonalInfoBlock title="Father's Name" value={props.candidateInfo.father_name} masking={props.masking} />
					<SinglePersonalInfoBlock title="Mother's Name" value={props.candidateInfo.mother_name} masking={props.masking} />
					<SinglePersonalInfoBlock title="Gender" value={props.candidateInfo.gender} masking={props.masking} />
					<SinglePersonalInfoBlock title="Date of Birth" value={props.candidateInfo.dob} masking={props.masking} />
					<SinglePersonalInfoBlock title="Home Town" value={props.candidateInfo.birth_city} masking={props.masking} />
					<SinglePersonalInfoBlock title="Availablity" value={props.candidateInfo.available_date} masking={props.masking} />
					<SinglePersonalInfoBlock title="Martial Status" value={props.candidateInfo.martial_status} masking={props.masking} />
					<SinglePersonalInfoBlock title="Looking for Job" value={props.candidateInfo.searching_for_job_status} masking={props.masking} />
					<SinglePersonalInfoBlock title="Nationality" value={props.candidateInfo.nationality} masking={props.masking} />
					<SinglePersonalInfoBlock title="Religion" value={props.candidateInfo.religion} masking={props.masking} />
					<SinglePersonalInfoBlock title="Current Location  " value={props.candidateInfo.current_location} masking={props.masking} />
				</div>
			</div>

			<div className='card shadow-sm' style={{ marginTop: '10px' }} id='candidate_profile_view_career_box'>
				<div className={styles.cardHeader}>
					<div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
						<div className={styles.sectionHeader}>Career & Application Info</div>
					</div>
				</div>
				<div className='card-body'>
					<SingleCareerInfoBlock masking={props.masking} title="Looking For" value={`${props.candidateInfo.level?.candidate_level_name || ''} Level Job`} />
					<SingleCareerInfoBlock masking={props.masking} title="Qualification" value={props.candidateInfo.qualification?.name} />
					<SingleCareerInfoBlock masking={props.masking} title="Preferred Job Category" value={props.candidateInfo.preferred_jobcategories?.map((item) => item.name).join(' , ')} />
					<SingleCareerInfoBlock masking={props.masking} title="Preferred District" value={props.candidateInfo.preferred_districts?.map((item) => item.name).join(' , ')} />
					<SingleCareerInfoBlock masking={props.masking} title="Preferred Organization Types" value={props.candidateInfo.preferred_organization_types?.map((item) => item.name).join(' , ')} />
				</div>
			</div>

			<div className='card shadow-sm' id='candidate_profile_view_language_box' style={{ marginTop: '10px' }}>
				<div className={styles.cardHeader}>
					<div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
						<div className={styles.sectionHeader}>Languages</div>
					</div>
				</div>
				<div className='card-body'>
					<table className='table '>
						<thead>
							<th>Languages</th>
							<th>Proficiency</th>
						</thead>
						<tbody>
							{
								props.candidateInfo.languages?.map((item, index) => {
									return (
										<tr key={item.id}>
											<td>{item.language?.name}</td>
											<td>{item.proficiency}</td>
										</tr>
									)
								})
							}
						</tbody>
					</table>
				</div>
			</div>

			<div className='card shadow-sm' id='candidate_profile_view_hobby_box' style={{ marginTop: '10px' }}>
				<div className={styles.cardHeader}>
					<div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
						<div className={styles.sectionHeader}>Hobbies and Interests</div>
					</div>
				</div>
				<div className='card-body'>
					{
						props.candidateInfo.candidate_hobbies?.map((item, index) => {
							return (
								<div key={item.id}>
									{index > 0 ? <hr /> : ''}
									<div className={styles.singleHobby} >
										<div className={styles.hobbyTitle}>
											{props.masking ? <CommonMasking>Hobby Title</CommonMasking> : item.hobby}
										</div>
									</div>
									<div className={styles.hobbyDetails}>
										{props.masking ?
											<CommonMasking>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nulla turpis,
												scelerisque sit amet scelerisque vitae, fermentum non odio. Nullam sit amet felis sit amet
												diam aliquam hendrerit in at lectus.</CommonMasking>
											:
											<>{parse(item.description)}</>}

									</div>
								</div>
							)
						})
					}
				</div>
			</div>

			<div className='card shadow-sm' id='candidate_profile_view_reference_box' style={{ marginTop: '10px' }}>
				<div className={styles.cardHeader}>
					<div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
						<div className={styles.sectionHeader}>References</div>
					</div>
				</div>
				<div className='card-body'>
					{
						props.candidateInfo.references?.map((item, index) => {
							return (
								<React.Fragment key={item.id}>
									{
										props.masking ?
											<CommonMasking key={item.id}>
												<div key={item.id}>
													{index > 0 ? <hr /> : ''}
													<div className={styles.singleRef} >
														<div className={styles.refTitle}>
															Reference Name
														</div>
													</div>
													<div className={styles.refDesignation}>
														Designation | Organization
													</div>
													<div className={styles.refMobileNo}>
														Mobile No | Email Address
													</div>
												</div>
											</CommonMasking>
											:
											<div key={item.id}>
												{index > 0 ? <hr /> : ''}
												<div className={styles.singleRef} >
													<div className={styles.refTitle}>
														{item.name}
													</div>
												</div>
												<div className={styles.refDesignation}>
													{item.designation} | {item.organization}
												</div>
												<div className={styles.refMobileNo}>
													{item.mobile_number} | {item.email}
												</div>
											</div>
									}
								</React.Fragment>
							)
						})
					}
				</div>
			</div>

			<div className='card shadow-sm' style={{ marginTop: '10px' }} id='social_box'>
				<div className={styles.cardHeader}>
					<div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
						<div className={styles.sectionHeader}>Social Profiles</div>
					</div>
				</div>
				<div className='card-body'>
					{
						props.candidateInfo.social_networks?.map((item, index) => {
							return (
								<React.Fragment key={item.pivot.id}>
									{
										props.masking ?
											<CommonMasking>
												<div key={item.pivot.id} className={styles.singleSocialProfile}>
													<div className={styles.singleSocialProfileContent}>
														<div className={styles.singleSocialProfilePlatformContainer}>
															<img width={25} height={25} src={`/v2/icons/${item.name.toLowerCase()}.png`} />
															<span className={styles.singleSocialProfilePlatform} style={{ marginLeft: '10px' }}>{item.name}</span>
														</div>
														<div className={styles.singleSocialProfileLink} style={{ maxWidth: 'calc(100% - 50px)' }}>
															Social Item Link
														</div>
													</div>
												</div>
											</CommonMasking>
											:
											<div key={item.pivot.id} className={styles.singleSocialProfile}>
												<div className={styles.singleSocialProfileContent}>
													<div className={styles.singleSocialProfilePlatformContainer}>
														<img width={25} height={25} src={`/v2/icons/${item.name.toLowerCase()}.png`} />
														<span className={styles.singleSocialProfilePlatform} style={{ marginLeft: '10px' }}>{item.name}</span>
													</div>
													<div className={styles.singleSocialProfileLink} style={{ maxWidth: 'calc(100% - 50px)' }}>
														<a target='_blank' href={item.pivot?.link}>{item.pivot?.link}</a>
													</div>
												</div>
											</div>
									}
								</React.Fragment>
							)
						})
					}
				</div>
			</div>

		</div>
	)
}

export default CandidateProfileDetails