import React, { useEffect, useState } from 'react';
import { Button, Grid, Skeleton, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import Carousel from 'react-bootstrap/Carousel';
import { Card } from 'react-bootstrap';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { getJobByGender } from '../../../fetchAPI';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import EmployerProfilePicture from '../EmployerComponents/EmployerProfilePicture';

function GenderSpecificJobs() {

    const [femaleJobs, setFemaleJobs] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true);
        gettingFemaleJobs()

    }, []);

    const gettingFemaleJobs = () => {

        getJobByGender(2).then((response) => {
            if (response.success) {
                setFemaleJobs(response?.response?.data)
                setIsLoading(false);

            }
            else {
                // console.log('errors: ', response);
            }
        })
    }


    return (
        <div className='m-b100 mt-5'>
            <div className='container'>
                <Grid container spacing={2} sx={{ justifyContent: 'space-between', alignItems: 'top' }}>
                    <Grid item>
                        {
                            isLoading ?
                                <>
                                    <Grid container spacing={1} sx={{ display: { xs: 'none', md: 'flex' } }}>
                                        <Grid item>
                                            <Skeleton animation='wave' variant="rounded" width={400} height={40} />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton animation='wave' variant="rounded" width={40} height={40} />
                                        </Grid>
                                    </Grid>
                                </>
                                :
                                <h1 className='d-none d-lg-block' style={{ color: '#03254c' }}>Female Jobs <span className='' style={{ color: 'red' }}> &nbsp;({femaleJobs?.length})</span></h1>
                        }
                        {
                            isLoading ?
                                <>
                                    <Grid container spacing={1} sx={{ display: { xs: 'flex', md: 'none' }, m: 3 }}>
                                        <Grid item>
                                            <Skeleton animation='wave' variant="rounded" width={200} height={40} />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton animation='wave' variant="rounded" width={40} height={40} />
                                        </Grid>
                                    </Grid>
                                </>
                                :
                                <>
                                    <h1 className='d-lg-none text-center' style={{ color: '#03254c' }}>Female Jobs <span className='' style={{ color: 'red' }}> &nbsp;({femaleJobs?.length})</span></h1> {/** Mobile View */}
                                </>
                        }
                    </Grid>
                    <Grid item>
                        {
                            isLoading ?
                                <Skeleton animation='wave' variant="rounded" width={300} height={50} sx={{ display: { xs: 'none', md: 'flex' } }} />
                                :
                                // <Link to='/search/jobs' className='d-none d-lg-block' style={{ fontSize: '28px', color: '#2a9df4', padding: '10px 60px 10px 60px', border: '1px solid ', borderRadius: '10px' }}>EXPLORE ALL</Link>
                                <Link 
                                to={{
                                    pathname: '/search/jobs',
                                    state: { fromHome: true }
                                }}
                                ><Button className='d-none d-lg-block' size='large' variant='outlined'>Explore All</Button></Link>
                        }
                    </Grid>
                </Grid>
            </div>
            <Grid>
                {/* Desktop View */}
                <Carousel fade prevIcon={<ArrowCircleLeftIcon fontSize='large' />} nextIcon={<ArrowCircleRightIcon fontSize='large' />} className='d-none d-lg-block'>
                    <Carousel.Item>
                        <Stack direction={'row'} spacing={3} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
                            {
                                isLoading ?
                                    <>
                                        <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                                            <Grid item>
                                                <Skeleton variant="rectangular" width={300} height={150} />
                                            </Grid>
                                            <Grid item>
                                                <Skeleton variant="rectangular" width={300} height={150} />
                                            </Grid>
                                            <Grid item>
                                                <Skeleton variant="rectangular" width={300} height={150} />
                                            </Grid>
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        {
                                            femaleJobs?.slice(0, 3).map((item, index) => {
                                                return (
                                                    <Card key={index} className='atb-card' style={{ display: 'flex', height: '100%', margin: '1rem' }}>
                                                        <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <Grid item>
                                                                <Tooltip title="Go to Profile">
                                                                    <Link to={`/employer/profile/${item?.author_id}`} className='text-dark'><Typography noWrap='true' variant='h6' align='left'> {item?.employer?.employer_name}</Typography></Link>
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid item>
                                                                <Tooltip title="Go to Profile">
                                                                    <Avatar alt="Employer Picture" sx={{ width: 60, height: 60 }}>
                                                                        <Link to={`/employer/profile/${item?.author_id}`}><EmployerProfilePicture profile_picture={item?.employer?.profile_pic_base64} /></Link>
                                                                    </Avatar>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2}>
                                                            <Grid item>
                                                                <Typography className='text-left text-truncate' style={{ maxWidth: '15rem' }}>{item?.job_title}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2}>
                                                            <Grid item>
                                                                <Typography variant='subheading' align='left'>{item?.minimum_salary} - {item?.maximum_salary} {item?.salary_currency?.name} / {item?.salary_type?.type}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                        {/* <Grid container spacing={2}>
                                                        <Grid item>
                                                            <Typography className='text-left text-truncate' style={{ maxWidth: '15rem', fontSize: 12 }}>{item?.job_address}</Typography>
                                                        </Grid>
                                                        </Grid> */}
                                                        {/* <Grid container spacing={2}>
                                                            <Grid item>
                                                                <span className='candidate-tags' style={{ marginLeft: 0 }}> {item?.job_type?.name}</span>
                                                                <span className='candidate-tags'> {item?.job_level?.candidate_level_name}</span>
                                                                <span className='candidate-tags'> {item?.department?.name}</span>
                                                            </Grid>
                                                        </Grid> */}
                                                        <Grid container spacing={2} mt={1} alignItems={'center'}>
                                                            <Grid item>
                                                                <Link to={`/jobs/${item?.post_id}`}><Button variant='contained' >Job Details</Button></Link>
                                                            </Grid>
                                                            <Grid item>
                                                                <Link 
                                                                to={{
                                                                    pathname: '/search/jobs',
                                                                    state: { fromHome: true }
                                                                }} 
                                                                ><Button variant='outlined'>View More</Button></Link>
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography variant='caption' align='right'>{moment(item?.post_date).startOf('day').fromNow()}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Card>

                                                )
                                            })
                                        }
                                    </>
                            }
                        </Stack>
                    </Carousel.Item>
                    {
                        femaleJobs?.length > 3 ?
                            <Carousel.Item>
                                <Stack direction={'row'} spacing={3} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
                                    {
                                        isLoading ?
                                            <>
                                                <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                                                    <Grid item>
                                                        <Skeleton variant="rectangular" width={300} height={150} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Skeleton variant="rectangular" width={300} height={150} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Skeleton variant="rectangular" width={300} height={150} />
                                                    </Grid>
                                                </Grid>
                                            </>
                                            :
                                            <>
                                                {
                                                    femaleJobs?.slice(3, 6).map((item, index) => {

                                                        return (
                                                            <Card key={index} className='atb-card' style={{ display: 'flex', height: '100%', margin: '1rem' }}>
                                                                <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                                                    <Grid item>
                                                                        <Tooltip title="Go to Profile">
                                                                            <Link to={`/employer/profile/${item?.author_id}`} className='text-dark'><Typography noWrap='true' variant='h6' align='left'> {item?.employer?.employer_name}</Typography></Link>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Tooltip title="Go to Profile">
                                                                            <Avatar alt="Employer Picture" sx={{ width: 60, height: 60 }}>
                                                                                <Link to={`/employer/profile/${item?.author_id}`}><EmployerProfilePicture profile_picture={item?.employer?.profile_pic_base64} /></Link>
                                                                            </Avatar>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={2}>
                                                                    <Grid item>
                                                                        <Typography className='text-left text-truncate' style={{ maxWidth: '15rem' }}>{item?.job_title}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={2}>
                                                                    <Grid item>
                                                                        <Typography variant='subheading' align='left'>{item?.minimum_salary} - {item?.maximum_salary} {item?.salary_currency?.name} / {item?.salary_type?.type}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                {/* <Grid container spacing={2}>
                                                        <Grid item>
                                                            <Typography className='text-left text-truncate' style={{ maxWidth: '15rem', fontSize: 12 }}>{item?.job_address}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <span className='candidate-tags' style={{ marginLeft: 0 }}> {item?.job_type?.name}</span>
                                                            <span className='candidate-tags'> {item?.job_level?.candidate_level_name}</span>
                                                            <span className='candidate-tags'> {item?.department?.name}</span>
                                                        </Grid>
                                                    </Grid> */}
                                                                <Grid container spacing={2} mt={1} alignItems={'center'}>
                                                                    <Grid item>
                                                                        <Link to={`/jobs/${item?.post_id}`}><Button variant='contained' >Job Details</Button></Link>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Link 
                                                                        to={{
                                                                            pathname: '/search/jobs',
                                                                            state: { fromHome: true }
                                                                        }} 
                                                                        ><Button variant='outlined'>View More</Button></Link>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant='caption' align='right'>{moment(item?.post_date).startOf('day').fromNow()}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Card>

                                                        )
                                                    })
                                                }
                                            </>
                                    }
                                </Stack>
                            </Carousel.Item>
                            :
                            ""

                    }
                    {
                        femaleJobs?.length > 6 ?
                            <Carousel.Item>
                                <Stack direction={'row'} spacing={3} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
                                    {
                                        isLoading ?
                                            <>
                                                <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                                                    <Grid item>
                                                        <Skeleton variant="rectangular" width={300} height={150} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Skeleton variant="rectangular" width={300} height={150} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Skeleton variant="rectangular" width={300} height={150} />
                                                    </Grid>
                                                </Grid>
                                            </>
                                            :
                                            <>
                                                {
                                                    femaleJobs?.slice(6, 9).map((item, index) => {
                                                        // console.log(item)

                                                        return (
                                                            <Card key={index} className='atb-card' style={{ display: 'flex', height: '100%', margin: '1rem' }}>
                                                                <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                                                    <Grid item>
                                                                        <Tooltip title="Go to Profile">
                                                                            <Link to={`/employer/profile/${item?.author_id}`} className='text-dark'><Typography noWrap='true' variant='h6' align='left'> {item?.employer?.employer_name}</Typography></Link>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Tooltip title="Go to Profile">
                                                                            <Avatar alt="Employer Picture" sx={{ width: 60, height: 60 }}>
                                                                                <Link to={`/employer/profile/${item?.author_id}`}><EmployerProfilePicture profile_picture={item?.employer?.profile_pic_base64} /></Link>
                                                                            </Avatar>
                                                                        </Tooltip>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={2}>
                                                                    <Grid item>
                                                                        <Typography className='text-left text-truncate' style={{ maxWidth: '15rem' }}>{item?.job_title}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={2}>
                                                                    <Grid item>
                                                                        <Typography variant='subheading' align='left'>{item?.minimum_salary} - {item?.maximum_salary} {item?.salary_currency?.name} / {item?.salary_type?.type}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                {/* <Grid container spacing={2}>
                                                        <Grid item>
                                                            <Typography className='text-left text-truncate' style={{ maxWidth: '15rem', fontSize: 12 }}>{item?.job_address}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <span className='candidate-tags' style={{ marginLeft: 0 }}> {item?.job_type?.name}</span>
                                                            <span className='candidate-tags'> {item?.job_level?.candidate_level_name}</span>
                                                            <span className='candidate-tags'> {item?.department?.name}</span>
                                                        </Grid>
                                                    </Grid> */}
                                                                <Grid container spacing={2} mt={1} alignItems={'center'}>
                                                                    <Grid item>
                                                                        <Link to={`/jobs/${item?.post_id}`}><Button variant='contained' >Job Details</Button></Link>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Link 
                                                                        to={{
                                                                            pathname: '/search/jobs',
                                                                            state: { fromHome: true }
                                                                        }} 
                                                                        ><Button variant='outlined'>View More</Button></Link>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant='caption' align='right'>{moment(item?.post_date).startOf('day').fromNow()}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Card>

                                                        )
                                                    })
                                                }
                                            </>
                                    }
                                </Stack>
                            </Carousel.Item>
                            :
                            ""

                    }
                </Carousel>

                {/* Mobile View */}
                <Carousel fade prevIcon={<ArrowCircleLeftIcon />} nextIcon={<ArrowCircleRightIcon />} indicators={true} className='d-lg-none'>
                    {
                        femaleJobs?.map((item, index) => {

                            return (
                                <Carousel.Item>
                                    <Stack direction={'row'} spacing={3} sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center' }}>


                                        <Card key={index} className='atb-card' style={{ display: 'flex', height: '100%', margin: '1rem', padding: '1.5rem' }}>
                                            <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                                <Grid item>
                                                    <Tooltip title="Go to Profile">
                                                        <Link to={`/employer/profile/${item?.author_id}`} className='text-dark'><Typography noWrap='true' variant='h6' align='left'> {item?.employer?.employer_name}</Typography></Link>
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item>
                                                    <Tooltip title="Go to Profile">
                                                        <Avatar alt="Employer Picture" sx={{ width: 60, height: 60 }}>
                                                            <Link to={`/employer/profile/${item?.author_id}`}><EmployerProfilePicture profile_picture={item?.employer?.profile_pic_base64} /></Link>
                                                        </Avatar>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <Typography className='text-left text-truncate' style={{ maxWidth: '15rem' }}>{item?.job_title}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <Typography variant='subheading' align='left'>{item?.minimum_salary} - {item?.maximum_salary} {item?.salary_currency?.name} / {item?.salary_type?.type}</Typography>
                                                </Grid>
                                            </Grid>
                                            {/* <Grid container spacing={2}>
                                                <Grid item>
                                                    <Typography className='text-left text-truncate' style={{ maxWidth: '15rem', fontSize: 12 }}>{item?.job_address}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <span className='candidate-tags' style={{ marginLeft: 0 }}> {item?.job_type?.name}</span>
                                                    <span className='candidate-tags'> {item?.job_level?.candidate_level_name}</span>
                                                    <span className='candidate-tags'> {item?.department?.name}</span>
                                                </Grid>
                                            </Grid> */}
                                            <Grid container spacing={2} mt={1} alignItems={'center'}>
                                                <Grid item>
                                                    <Link to={`/jobs/${item?.post_id}`}><Button variant='contained' >Job Details</Button></Link>
                                                </Grid>
                                                <Grid item>
                                                    <Link 
                                                    to={{
                                                        pathname: '/search/jobs',
                                                        state: { fromHome: true }
                                                    }} 
                                                    ><Button variant='outlined'>View More</Button></Link>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant='caption' align='right'>{moment(item?.post_date).startOf('day').fromNow()}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Stack>
                                </Carousel.Item>
                            )
                        })
                    }

                </Carousel>
            </Grid>
        </div >
    )
}

export default GenderSpecificJobs