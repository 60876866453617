import { Box, Card, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, Link, Stack, TextField, Typography, Button, FormControl, MenuItem, Select } from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { postPageConfiguration } from "../../../fetchAPI";
import { DropdownContext } from '../../../v2/context/DropdownContext'
import { LoadingButton } from "@mui/lab";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import ErrorAlert from "../../components/Alerts/ErrorAlert";
// import { set } from "react-ga";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import PageNotFound from "../PageNotFound";

function UploadIcon() {
    return (
        <img
            alt="upload icon"
            src="/v2/icons/uploadIcon.png"
        />
    )
}

function CrossIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <g clipPath="url(#clip0_336_218)">
                <path d="M10.0819 0H11.8846C12.4882 0.0602184 13.087 0.160977 13.6771 0.301597C15.9367 0.871798 17.9572 2.14414 19.4479 3.93559C20.9387 5.72705 21.8228 7.94526 21.9732 10.2711C22.0879 11.8964 21.8337 13.5267 21.2294 15.0398C20.3171 17.3474 18.6524 19.2794 16.5051 20.5226C14.3577 21.7659 11.8536 22.2475 9.39842 21.8894C7.81876 21.6716 6.30896 21.0988 4.98233 20.2139C2.74353 18.7655 1.10922 16.5507 0.385316 13.9842C0.195071 13.2954 0.0661308 12.5912 0 11.8797V10.0768C0.0268047 9.84555 0.0502589 9.61096 0.0837648 9.37974C0.311118 7.80958 0.883242 6.30925 1.75906 4.98644C3.20696 2.74071 5.42584 1.10191 7.99787 0.378676C8.68051 0.193329 9.37773 0.0666424 10.0819 0ZM10.9765 20.683C12.8995 20.6883 14.7807 20.1229 16.3821 19.0583C17.9836 17.9936 19.2332 16.4777 19.9727 14.7024C20.7123 12.9271 20.9085 10.9723 20.5366 9.08537C20.1647 7.19846 19.2414 5.46434 17.8836 4.10255C16.5258 2.74075 14.7945 1.81251 12.9089 1.43536C11.0233 1.0582 9.0682 1.24907 7.29114 1.98383C5.51407 2.71858 3.99495 3.96417 2.92607 5.5629C1.85719 7.16162 1.28663 9.04159 1.28663 10.9648C1.28398 12.2395 1.53262 13.5022 2.01832 14.6807C2.50402 15.8592 3.21725 16.9304 4.11722 17.833C5.01719 18.7356 6.08623 19.4519 7.26322 19.941C8.4402 20.43 9.70204 20.6821 10.9765 20.683Z" fill="#0275D8" />
                <path d="M16.0228 6.6049C16.0202 6.71556 15.9923 6.82417 15.9412 6.92235C15.8901 7.02053 15.8171 7.10567 15.7279 7.17124L12.2232 10.6765C11.8882 11.0116 11.8882 10.9446 12.2232 11.2897C13.3892 12.4626 14.5564 13.6322 15.7246 14.7983C15.8108 14.8679 15.882 14.9543 15.9339 15.0522C15.9857 15.1501 16.0171 15.2576 16.0262 15.368C16.0301 15.4868 16.001 15.6043 15.9423 15.7075C15.8835 15.8108 15.7973 15.8958 15.6932 15.953C15.5891 16.0103 15.4712 16.0376 15.3526 16.0319C15.2339 16.0263 15.1191 15.9879 15.021 15.921C14.9288 15.8523 14.8425 15.7761 14.763 15.6931L11.1845 12.1141C10.9835 11.9131 10.9835 11.9131 10.7791 12.1141L7.15043 15.7501C7.08247 15.8378 6.99574 15.9092 6.8966 15.9591C6.79746 16.009 6.68842 16.036 6.57747 16.0383C6.45865 16.0411 6.34155 16.0094 6.24042 15.947C6.13929 15.8845 6.0585 15.794 6.00787 15.6864C5.9538 15.5842 5.92923 15.4689 5.93693 15.3535C5.94462 15.2381 5.98427 15.1271 6.05143 15.0329C6.12454 14.9362 6.20521 14.8454 6.29268 14.7615L9.85771 11.1959C10.0554 10.9982 10.0554 10.9948 9.85771 10.7938L6.24242 7.17124C6.15278 7.10056 6.07966 7.01117 6.02815 6.9093C5.97665 6.80743 5.948 6.69554 5.94421 6.58145C5.939 6.46426 5.96787 6.34806 6.02734 6.24694C6.08681 6.14583 6.17432 6.06414 6.27927 6.01176C6.38089 5.95671 6.4958 5.931 6.61118 5.93751C6.72656 5.94402 6.83786 5.98249 6.93264 6.04862C7.02854 6.12271 7.11922 6.20333 7.20404 6.2899L10.7691 9.85548C10.9701 10.0565 10.9735 10.0565 11.1711 9.85548L14.7831 6.24634C14.8536 6.15625 14.9428 6.08259 15.0447 6.0305C15.1465 5.97842 15.2585 5.94915 15.3728 5.94473C15.4599 5.94134 15.5468 5.95618 15.6279 5.98829C15.7089 6.0204 15.7824 6.06908 15.8436 6.13122C15.9048 6.19336 15.9523 6.26758 15.9832 6.34915C16.014 6.43072 16.0275 6.51782 16.0228 6.6049Z" fill="#0275D8" />
            </g>
            <defs>
                <clipPath id="clip0_336_218">
                    <rect width="22" height="22" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}


export default function PageBuilder() {

    const history = useHistory()

    //get dropdown values
    const { industries, positions, genders, types, levels, skills, qualifications, departments } = useContext(DropdownContext)

    // generating states
    const [loading, setLoading] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const [headerSelectedImage, setHeaderSelectedImage] = useState('')
    const [bodySelectedImage, setBodySelectedImage] = useState('')
    const [footerSelectedImage, setFooterSelectedImage] = useState('')
    const [routeFields, setRouteFields] = useState('')
    const { userData } = useContext(UserContext);

    // const handleChanges = (name) = (event) =>{
    //     setRouteFields({...routeFields, [name] : event.target.value})
    // }

    const handleChanges = (name) => (event) => {
        setRouteFields({ ...routeFields, [name]: event.target.value })
    }

    const handleChangeImages = (name) => (event) => {
        setRouteFields({ ...routeFields, [name]: event.target.files[0] })
    }

    const handleChangeCheckboxs = (name) => (event) => {
        setRouteFields({ ...routeFields, [name]: event.target.checked })
    }

    const submitByState = () => {
        setLoading(true)
        const formData = new FormData()
        for (const [key, value] of Object.entries(routeFields)) {
            // console.log(`${key}: ${value}`);
            formData.append(key, value)
        }

        postPageConfiguration(formData)
            .then(response => {
                if (response && response.success) {
                    setResponseMessage(response.response.message)
                    setOpenSuccessAlert(true)
                    setHeaderSelectedImage('')
                    setBodySelectedImage('')
                    setFooterSelectedImage('')
                    history.push(`/special-page/${response.response.data.route_name}`)
                } else {
                    setResponseMessage(response.errors[0]?.message)
                    setOpenErrorAlert(true)
                }
            }).finally(() => setLoading(false))
    }

    // Form fields 
    const routeRef = useRef('')
    const headerTextRef = useRef('')
    const footerTextRef = useRef('')
    const headerBgRef = useRef('')
    const bodyBgRef = useRef('')
    const footerBgRef = useRef('')

    const [isManualActive, setIsManualActive] = useState(true)

    // Filter fields 
    const manualSearchRef = useRef('')
    const keywordRef = useRef('')
    const titleRef = useRef('')
    const locationRef = useRef('')
    const experienceFromRef = useRef('')
    const experienceToRef = useRef('')
    const departmentRef = useRef('')
    const industryRef = useRef('')
    const salaryFromRef = useRef('')
    const salaryToRef = useRef('')
    const negotiableRef = useRef('')
    const expireSoonRef = useRef('')
    const companyNameRef = useRef('')
    const positionRef = useRef('')
    const skillRef = useRef('')
    const qualificationRef = useRef('')
    const jobTypeRef = useRef('')
    const jobLevelRef = useRef('')
    const genderRef = useRef('')
    const minimumAgeRef = useRef('')
    const maximumAgeRef = useRef('')

    // For benefit 
    const benefitRef = useRef('')

    const resetForm = () => {
        var specialForm = document.getElementById('special-page-form')
        specialForm.reset();
        setRouteFields({})
    }

    const closeSuccessAlert = () => {
        setOpenSuccessAlert(false)
        setResponseMessage('')
    }
    const closeErrorAlert = () => {
        setOpenErrorAlert(false)
        setResponseMessage('')
    }

    if(+userData?.user_role_id !== 1) return <PageNotFound/>

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Card sx={{
                display: 'flex',
                height: '100%',
                width: '1300px',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.2), 0px 16px 32px rgba(0, 0, 0, 0.4)',
                borderRadius: '22px',
                justifyContent: 'center',
                margin: '10px',
                flexDirection: 'column',
                margin: 3,
                padding: 6
            }}
            >
                <form onSubmit={(e) => [e.preventDefault(), submitByState()]} id="special-page-form">
                    <Stack spacing={2}>
                        <InputLabel sx={{ fontWeight: 'bold' }}>Route</InputLabel>
                        <TextField
                            required
                            type='text'
                            name='route'
                            variant='outlined'
                            placeholder='Route'
                            fullWidth
                            inputRef={routeRef}
                            onChange={handleChanges('route_name')}
                        />

                        <Grid container spacing={2} sx={{ width: '100%' }}>
                            <Grid item md={6} xs={12} sx={{ paddingLeft: '0px !important' }}>
                                <InputLabel sx={{ fontWeight: 'bold' }}>Header Text</InputLabel>
                                <TextField
                                    type='text'
                                    name='header'
                                    variant='outlined'
                                    placeholder='Header Text'
                                    fullWidth
                                    inputRef={headerTextRef}
                                    onChange={handleChanges('header_text')}

                                />
                            </Grid>
                            <Grid item md={6} xs={12}
                                sx={{
                                    '@media (max-width: 600px)': {
                                        paddingLeft: '0px !important'
                                    },
                                }}>
                                <InputLabel sx={{ fontWeight: 'bold' }}>Footer Text</InputLabel>
                                <TextField
                                    type='text'
                                    name='footer'
                                    variant='outlined'
                                    placeholder='Footer Text'
                                    fullWidth
                                    inputRef={footerTextRef}
                                    onChange={handleChanges('footer_text')}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ width: '100%' }}>
                            <Grid item md={4} xs={12}>
                                <InputLabel sx={{ fontWeight: 'bold' }}>Header Background</InputLabel>
                                <Stack spacing={0}>
                                    <input type="file" id='bg-image-input' name={'header_background_image'} hidden ref={headerBgRef} onChange={handleChangeImages('header_background_image')} />
                                    <Box sx={{ border: '2px dashed #0275D8', borderRadius: '6px', padding: 2, backgroundColor: '#EAF5FF' }} >
                                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Grid item md={8}>
                                                <Stack spacing={1}>
                                                    <Typography variant="h5" sx={{ fontSize: '16px' }}>Upload Background image</Typography>
                                                    {
                                                        routeFields?.header_background_image !== '' ? routeFields?.header_background_image?.name :
                                                            <Typography variant="h5" sx={{ fontSize: '14px', color: '#72737C' }}>Drop your files here. or <Link>Browse</Link></Typography>
                                                    }
                                                </Stack>
                                            </Grid>
                                            <Grid item md={4} sx={{ textAlign: 'right' }} onClick={() => headerBgRef.current.click()} >
                                                <IconButton aria-label="upload Button">
                                                    <UploadIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Typography variant="caption" sx={{ color: '#72737C', marginTop: 2 }}>Uploaded Banner Size Should Be 1920x750 PX, JPG, PNG</Typography>
                                </Stack>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <InputLabel sx={{ fontWeight: 'bold' }}>Body Background</InputLabel>
                                <Stack spacing={0}>
                                    <input type="file" name="body_background_image" id='bg-image-input' hidden ref={bodyBgRef} onChange={handleChangeImages('body_background_image')} />
                                    <Box sx={{ border: '2px dashed #0275D8', borderRadius: '6px', padding: 2, backgroundColor: '#EAF5FF' }}>
                                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Grid item md={8}>
                                                <Stack spacing={1}>
                                                    <Typography variant="h5" sx={{ fontSize: '16px' }}>Upload Background image</Typography>
                                                    {
                                                        routeFields?.body_background_image !== '' ? routeFields?.body_background_image?.name :
                                                            <Typography variant="h5" sx={{ fontSize: '14px', color: '#72737C' }}>Drop your files here. or <Link>Browse</Link></Typography>
                                                    }
                                                </Stack>
                                            </Grid>
                                            <Grid item md={4} sx={{ textAlign: 'right' }} onClick={() => bodyBgRef.current.click()}>
                                                <IconButton aria-label="upload Button">
                                                    <UploadIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Typography variant="caption" sx={{ color: '#72737C', marginTop: 2 }}>Uploaded Banner Size Should Be 1920x750 PX, JPG, PNG</Typography>
                                </Stack>
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <InputLabel sx={{ fontWeight: 'bold' }}>Footer Background</InputLabel>
                                <Stack spacing={0}>
                                    <input type="file" name="footer_background_image" id='bg-image-input' hidden ref={footerBgRef} onChange={handleChangeImages('footer_background_image')} />
                                    <Box sx={{ border: '2px dashed #0275D8', borderRadius: '6px', padding: 2, backgroundColor: '#EAF5FF' }}>
                                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Grid item md={8}>
                                                <Stack spacing={1}>
                                                    <Typography variant="h5" sx={{ fontSize: '16px' }}>Upload Background image</Typography>
                                                    {
                                                        routeFields?.footer_background_image !== '' ? routeFields?.footer_background_image?.name :
                                                            <Typography variant="h5" sx={{ fontSize: '14px', color: '#72737C' }}>Drop your files here. or <Link>Browse</Link></Typography>
                                                    }
                                                </Stack>
                                            </Grid>
                                            <Grid item md={4} sx={{ textAlign: 'right' }} onClick={() => footerBgRef.current.click()}>
                                                <IconButton aria-label="upload Button">
                                                    <UploadIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Typography variant="caption" sx={{ color: '#72737C', marginTop: 2 }}>Uploaded Banner Size Should Be 1920x750 PX, JPG, PNG</Typography>
                                </Stack>
                            </Grid>
                        </Grid>

                        <Box mt={4}>
                            <Stack spacing={2}>
                                <InputLabel sx={{ fontWeight: 'bold' }}>Configure Search Criteria</InputLabel>
                                <Grid container spacing={2} sx={{ width: '100%' }}>
                                    <Grid item>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={isManualActive} onChange={() => setIsManualActive(value => !value)} />} label="Manual Search URL" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox checked={!isManualActive} onChange={() => setIsManualActive(value => !value)} />} label="From Search Panel" />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Box>

                        <Box mt={4}>
                            <Stack spacing={2}>
                                {
                                    isManualActive ?
                                        <Grid container spacing={2}>
                                            <Grid item md={12}>
                                                <TextField
                                                    type='text'
                                                    name='footer'
                                                    variant='outlined'
                                                    placeholder='Enter pre defined manual search url'
                                                    fullWidth
                                                    inputRef={manualSearchRef}
                                                    onChange={handleChanges('manual_route_param')}
                                                />
                                            </Grid>
                                            <Grid item md={2}>
                                                <LoadingButton
                                                    loading={loading}
                                                    size="medium"
                                                    variant="contained"
                                                    fullWidth
                                                    sx={{
                                                        paddingTop: 2,
                                                        paddingBottom: 2,
                                                        backgroundColor: '#DB1616',
                                                        '&:hover': {
                                                            backgroundColor: '#e3163d',
                                                        },
                                                    }}
                                                    type="submit"
                                                >
                                                    Save
                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                        :
                                        <Card sx={{
                                            border: '1px solid #C9C9C9',
                                            backgroundColor: '#F2F2F2',
                                            borderRadius: '10px',
                                            padding: 5
                                        }}
                                        >
                                            <Stack spacing={2}>
                                                <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <Grid item>
                                                        <Typography variant="h4" sx={{ fontSize: '18px', fontWeight: 700 }}>Quick Search</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            variant="outlined"
                                                            endIcon={<CrossIcon />}
                                                            sx={{
                                                                backgroundColor: 'rgba(73, 133, 255, 0.20)',
                                                                borderRadius: '20px'
                                                            }}
                                                            onClick={() => resetForm()}
                                                        >
                                                            Clear Filter
                                                        </Button>
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Grid item md={3}>
                                                        <TextField
                                                            type='text'
                                                            name='keywords'
                                                            variant='outlined'
                                                            placeholder='Keywords'
                                                            fullWidth
                                                            sx={{
                                                                backgroundColor: 'white'
                                                            }}
                                                            inputRef={keywordRef}
                                                            onChange={handleChanges('keyword')}
                                                        />
                                                    </Grid>
                                                    <Grid item md={3}>
                                                        <TextField
                                                            type='text'
                                                            name='job_title'
                                                            variant='outlined'
                                                            placeholder='Job Title'
                                                            fullWidth
                                                            sx={{
                                                                backgroundColor: 'white'
                                                            }}
                                                            inputRef={titleRef}
                                                            onChange={handleChanges('job_title')}
                                                        />
                                                    </Grid>
                                                    <Grid item md={3}>
                                                        <TextField
                                                            type='text'
                                                            name='location'
                                                            variant='outlined'
                                                            placeholder='Location'
                                                            fullWidth
                                                            sx={{
                                                                backgroundColor: 'white'
                                                            }}
                                                            inputRef={locationRef}
                                                            onChange={handleChanges('work_location')}
                                                        />
                                                    </Grid>
                                                    <Grid item md={3}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                name='experience_from'
                                                                variant='outlined'
                                                                placeholder='Experience From'
                                                                type="number"
                                                                sx={{
                                                                    backgroundColor: 'white'
                                                                }}
                                                                inputRef={experienceFromRef}
                                                                onChange={handleChanges('minimum_experience')}
                                                            />
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item md={3}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                name='experience_to'
                                                                variant='outlined'
                                                                placeholder='Experience To'
                                                                type="number"
                                                                sx={{
                                                                    backgroundColor: 'white'
                                                                }}
                                                                inputRef={experienceToRef}
                                                                onChange={handleChanges('maximum_experience')}
                                                            />
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item md={3}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                name='salary_from'
                                                                variant='outlined'
                                                                placeholder='Salary from'
                                                                type="number"
                                                                sx={{
                                                                    backgroundColor: 'white'
                                                                }}
                                                                inputRef={salaryFromRef}
                                                                onChange={handleChanges('minimum_salary')}
                                                            />
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item md={3}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                name='salary_to'
                                                                variant='outlined'
                                                                placeholder='Salary to'
                                                                type="number"
                                                                sx={{
                                                                    backgroundColor: 'white'
                                                                }}
                                                                inputRef={salaryToRef}
                                                                onChange={handleChanges('maximum_salary')}
                                                            />
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item md={3}>
                                                        <Stack direction={'row'} sx={{ width: '100%' }}>
                                                            <Grid item>
                                                                <FormGroup>
                                                                    <FormControlLabel control={<Checkbox inputRef={negotiableRef} onChange={handleChangeCheckboxs('negotiable')} />} label="Negotiable" />
                                                                </FormGroup>
                                                            </Grid>
                                                            <Grid item>
                                                                <FormGroup>
                                                                    <FormControlLabel control={<Checkbox inputRef={expireSoonRef} onChange={handleChangeCheckboxs('expire_soon')} />} label="Expire Soon" />
                                                                </FormGroup>
                                                            </Grid>
                                                        </Stack>
                                                    </Grid>

                                                    <Grid item md={3}>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                name='company_name'
                                                                variant='outlined'
                                                                placeholder='Company Name'
                                                                type="text"
                                                                sx={{
                                                                    backgroundColor: 'white'
                                                                }}
                                                                inputRef={companyNameRef}
                                                                onChange={handleChanges('company_name')}
                                                            />
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item md={3}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                defaultValue={0}
                                                                sx={{
                                                                    backgroundColor: 'white'
                                                                }}
                                                                inputRef={industryRef}
                                                                onChange={handleChanges('industry')}
                                                            >
                                                                <MenuItem value={0} disabled>
                                                                    <Typography sx={{ color: '#949494' }}>Category</Typography>
                                                                </MenuItem>
                                                                {
                                                                    industries?.map((item, index) => {
                                                                        return (
                                                                            <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item md={3}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                defaultValue={0}
                                                                sx={{
                                                                    backgroundColor: 'white'
                                                                }}
                                                                inputRef={departmentRef}
                                                                onChange={handleChanges('department')}
                                                            >
                                                                <MenuItem value={0} disabled>
                                                                    <Typography sx={{ color: '#949494' }}>Department</Typography>
                                                                </MenuItem>
                                                                {
                                                                    departments?.map((item, index) => {
                                                                        return (
                                                                            <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item md={3}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                defaultValue={0}
                                                                sx={{
                                                                    backgroundColor: 'white'
                                                                }}
                                                                inputRef={positionRef}
                                                                onChange={handleChanges('position')}
                                                            >
                                                                <MenuItem value={0} disabled>
                                                                    <Typography sx={{ color: '#949494' }}>Position</Typography>
                                                                </MenuItem>
                                                                {
                                                                    positions?.map((item, index) => {
                                                                        return (
                                                                            <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item md={3}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                defaultValue={0}
                                                                sx={{
                                                                    backgroundColor: 'white'
                                                                }}
                                                                inputRef={skillRef}
                                                                onChange={handleChanges('skills')}
                                                            >
                                                                <MenuItem value={0} disabled>
                                                                    <Typography sx={{ color: '#949494' }}>Skill</Typography>
                                                                </MenuItem>
                                                                {
                                                                    skills?.map((item, index) => {
                                                                        return (
                                                                            <MenuItem key={index} value={item.id}>{item.skill_name}</MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item md={3}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                defaultValue={0}
                                                                sx={{
                                                                    backgroundColor: 'white'
                                                                }}
                                                                inputRef={qualificationRef}
                                                                onChange={handleChanges('qualification')}
                                                            >
                                                                <MenuItem value={0} disabled>
                                                                    <Typography sx={{ color: '#949494' }}>Qualification</Typography>
                                                                </MenuItem>
                                                                {
                                                                    qualifications?.map((item, index) => {
                                                                        return (
                                                                            <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item md={3}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                defaultValue={0}
                                                                sx={{
                                                                    backgroundColor: 'white'
                                                                }}
                                                                inputRef={jobTypeRef}
                                                                onChange={handleChanges('job_type')}
                                                            >
                                                                <MenuItem value={0} disabled>
                                                                    <Typography sx={{ color: '#949494' }}>Job Type</Typography>
                                                                </MenuItem>
                                                                {
                                                                    types?.map((item, index) => {
                                                                        return (
                                                                            <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item md={3}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                defaultValue={0}
                                                                sx={{
                                                                    backgroundColor: 'white'
                                                                }}
                                                                inputRef={jobLevelRef}
                                                                onChange={handleChanges('job_level')}
                                                            >
                                                                <MenuItem value={0} disabled>
                                                                    <Typography sx={{ color: '#949494' }}>Job Level</Typography>
                                                                </MenuItem>
                                                                {
                                                                    levels?.map((item, index) => {
                                                                        return (
                                                                            <MenuItem key={index} value={item.id}>{item.candidate_level_name}</MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>

                                                    <Grid item md={3}>
                                                        <FormControl fullWidth>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                defaultValue={0}
                                                                sx={{
                                                                    backgroundColor: 'white'
                                                                }}
                                                                inputRef={genderRef}
                                                                onChange={handleChanges('gender')}
                                                            >
                                                                <MenuItem value={0} disabled>
                                                                    <Typography sx={{ color: '#949494' }}>Gender</Typography>
                                                                </MenuItem>
                                                                {
                                                                    genders?.map((item, index) => {
                                                                        return (
                                                                            <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Grid item md={2}>
                                                        <Button
                                                            size="medium"
                                                            variant="contained"
                                                            fullWidth
                                                            disabled
                                                            sx={{
                                                                paddingTop: 2,
                                                                paddingBottom: 2,
                                                                backgroundColor: '#1D1E1B',
                                                                '&:hover': {
                                                                    backgroundColor: '#363636',
                                                                },
                                                            }}
                                                        >
                                                            Search By Benefit
                                                        </Button>
                                                    </Grid>
                                                    <Grid item md={2}>
                                                        <LoadingButton
                                                            loading={loading}
                                                            size="medium"
                                                            variant="contained"
                                                            fullWidth
                                                            sx={{
                                                                paddingTop: 2,
                                                                paddingBottom: 2,
                                                                backgroundColor: '#DB1616',
                                                                '&:hover': {
                                                                    backgroundColor: '#e3163d',
                                                                },
                                                            }}
                                                            type="submit"
                                                        >
                                                            Save
                                                        </LoadingButton>
                                                    </Grid>
                                                    <Grid item md={2}>
                                                        <Button
                                                            size="medium"
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled
                                                            sx={{
                                                                paddingTop: 2,
                                                                paddingBottom: 2,
                                                                backgroundColor: 'white',
                                                                '&:hover': {
                                                                    backgroundColor: '#bedfef',
                                                                },
                                                            }}
                                                        >
                                                            Advanced Search
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Stack>
                                        </Card>
                                }
                            </Stack>
                        </Box>
                    </Stack>
                </form>
            </Card>
            <SuccessAlert open={openSuccessAlert} message={responseMessage} onHide={closeSuccessAlert} />
            <ErrorAlert open={openErrorAlert} message={responseMessage} onHide={closeErrorAlert} />
        </Box >
    )
}