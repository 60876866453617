import React from "react";
import { Link } from 'react-router-dom';
import HeroSection from '../components/HeroSection/HeroSection'

function PrivacyPolicyPage() {

    return (
        <div>
            <HeroSection text={"atB Jobs Bangladesh Privacy Policy"} />
            <div className="page-wraper">
                <div className="page-content bg-white">
                    <div className="content-block">
                        <div className="section-full content-inner overlay-white-middle">
                            <div className="container">
                                <div className="row align-items-center m-b50">
                                    <div className="col-md-12 col-lg-12 m-b20">
                                        <label className="m-b15">
                                            The authority of atB-Jobs Bangladesh has the right to make changes to this
                                            privacy policy at any time by notifying its Users on this page and possibly
                                            within this Application and/or - as far as technically and legally feasible
                                            - sending a notice to Users via any contact information available to us. It
                                            is strongly recommended to check this page often, referring to the date of
                                            the last modification listed at the bottom.
                                        </label>


                                        <div className='m-b15 mt-4'>
                                            <p><label className="m-b15" style={{fontStyle: 'italic'}}>
                                                Version 1.0.2 (Last Updated May 2nd, 2024)
                                            </label></p>

                                            <h5>Children</h5>
                                            <p>atBJobs.com is not directed to children under the age of 18 and does not
                                                knowingly collect information from children under the age of 18.</p>
                                            <p>We also refuse registration to children under the age of 18. (For the
                                                protection of under-age children).</p>

                                            <h5>Information we collect:</h5>
                                            <p>atBJobs.com offers several mobile applications. atBJobs.com collects and
                                                uses information about you as described herein.</p>
                                            <p>The categories of personal information we collect</p>
                                            <p>We are atB-Jobs Bangladesh our goal is to collected the personal and
                                                professional information about you: identifiers, commercial information,
                                                biometric information, internet information, geolocation data, sensorial
                                                information, employment related information and inferred
                                                information.</p>
                                            <p>We will not collect additional personal information without notifying
                                                you.</p>
                                            <p>How we collect information: what are the sources of the personal
                                                information we collect?</p>
                                            <p>We will collect your personal information, either directly or indirectly,
                                                from you when you use this Website.</p>
                                            <p>For example, you directly provide your personal information when you
                                                submit requests via any forms on this Website. You also provide personal
                                                information indirectly when you navigate this website, as personal
                                                information about you is automatically observed and collected. Finally,
                                                we may collect your personal information from third parties that work
                                                with us in connection with the Service or with the functioning of this
                                                Website and features thereof.</p>
                                            <p>How we use the information we collect: sharing and exposing of your
                                                personal information with third parties for a business purpose,</p>
                                            <p>We may share the personal information we collect about you to a third
                                                party for business purposes. In that case, we may make a written
                                                agreement with such third party that requires the recipient to both keep
                                                the personal information confidential and not use it for any other
                                                purpose and those are necessary for the performance of the
                                                agreement.</p>
                                            <p>We may also share your personal information to third parties when you the
                                                permission, or authorize us to do so, in order to provide you with our
                                                service.</p>
                                            <p>To find out more about the purposes of the processing, please refer to
                                                the relevant section of this document.</p>
                                            <p>Definitions and legal references</p>

                                            <h5>Personal Data</h5>
                                            <p>Any information that directly, indirectly, or in connection with other
                                                information — including a personal identification number — allows for
                                                the identification or identifiability of a natural person.</p>

                                            <h5>Usage Data</h5>
                                            <p>Information is collected automatically through this Website, which can include: the IP addresses or domain names of the computers utilized by the Users who use this Website, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer, the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Webpage) and the details about the path followed within the Website with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.</p>

                                            <h5>Third-Party Tracking Technologies</h5>
                                            <p>atB-Jobs Bangladesh uses the Analytics tracking technology to track visits to our website. It allows us to analyze the usage of our website and is merely for our internal use.</p>

                                            <h5>Cookies and Other Tracking Technologies</h5>
                                            <p>Some of our Web pages utilize "Cookies" and other tracking technologies. A "Cookie" is a small text file that may be used, for example, to collect information about Website activity. Some ''Cookies'' and other technologies may serve to recall Personal Information previously indicated by a user. Most browsers allow you to control ''Cookies'', including whether or not to accept them and how to remove them. You may set most browsers to notify you if you receive a cookie, or you may choose to block cookies with your browser, but please note that if you choose to wipe or block your cookies, you will need to re-enter through your original user ID and password to accomplish access to certain parts of the Website. Our use of cookies and other tracking technologies allows us to improve our website and your web experience. Your IP address might be used to track your location to provide you with various location-based services and for our data analyzing purposes. We may also analyze information that does not carry personal information for aptitude and statistics.</p>

                                            <h5>Security</h5>
                                            <p>We use lots of tools (encryption, passwords, and physical security and many more) to protect your personal information against unauthorized access and exposure.</p>

                                            <h5>Opting out of E-Mail Notification Services</h5>
                                            <p>We allow users to opt-out of having Email Notification Alerts sent to them at any time.</p>

                                            <h5>Revision and updates</h5>
                                            <p>atB-Jobs Bangladesh have authority to revises and updates its privacy policy in regular bases and publishes changes to the policy at our website.</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicyPage