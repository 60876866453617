import React, { useState, useContext, useEffect } from 'react'
import SuccessAlert from '../../Alerts/SuccessAlert'
import { EmployerContext } from '../../../context/EmployerContext'
import { DropdownContext } from '../../../context/DropdownContext'
import { deleteEmployerSaveCandidateInfo, postEmployerSaveCandidateInfo, downloadResume, changeApplyStatus } from '../../../../fetchAPI'
import { Link } from 'react-router-dom'
import { UserContext } from '../../../context/UserContext'
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { Box, Button, Grid, List, ListItem, ListItemIcon, ListItemText, MenuItem, Stack, Tooltip, Typography, Select, IconButton, CircularProgress } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { EmailOutlined, Phone } from '@mui/icons-material'
import { makeStyles } from '@material-ui/core/styles';
import PersonAddAlt1TwoToneIcon from '@mui/icons-material/PersonAddAlt1TwoTone';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import SimCardDownloadTwoToneIcon from '@mui/icons-material/SimCardDownloadTwoTone';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ReactHtmlParser from 'react-html-parser'

const useStyles = makeStyles((theme) => ({
	textField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'white',
			},
			'&:hover fieldset': {
				borderColor: 'white',
			},
			'&.Mui-focused fieldset': {
				borderColor: 'white',
			},
		},
		'& .MuiFormLabel-root': {
			color: 'white',
		},
		'& .MuiInputBase-input': {
			color: 'white',
		},
	},
}));


function AppliedCandidateCover(props) {
	const { employerData, savedCandidateIds, settingEmployerData } = useContext(EmployerContext)
	const { statusList } = useContext(DropdownContext)
	const { userData } = useContext(UserContext)

	const [openAlert, setOpenAlert] = useState(false)
	const [responseMessage, setResponseMessage] = useState('')

	const [currentStatus, setCurrentStatus] = useState({ id: 1, name: 'Applied' })
	const classes = useStyles();

	useEffect(() => {
		setCurrentStatus(props?.applyInfo?.apply_status)
	}, [props.applyInfo])

	/**
	 * This function will remove candidate from Employe Saved list
	 * @param {int} empId 
	 * @param {int} candId 
	 */
	const unSave = (empId, candId) => {
		deleteEmployerSaveCandidateInfo(empId, candId)
			.then(response => {
				if (response && response.success) {
					setResponseMessage(response.response.message)
					setOpenAlert(true)
					settingEmployerData()
					props.renderState()
				}
			})
	}


	/**
	 * This function will add candidate from Employe Saved list
	 * @param {int} empId 
	 * @param {int} candId 
	 */
	const saveCandidate = (empId, candId) => {
		postEmployerSaveCandidateInfo(empId, candId)
			.then(response => {
				if (response && response.success) {
					setResponseMessage(response.response.message)
					setOpenAlert(true)
					settingEmployerData()
					props.renderState()
				}
			})
	}

	// download resume
	const resumeDownload = (resumeId, resumeLink) => {

		let filename = 'resume'

		if (resumeLink) {
			let resumeReverse = resumeLink?.split('').reverse().join('');
			let reverseArray = resumeReverse.split("/")
			filename = reverseArray[0].split('').reverse().join('')
		}

		downloadResume(resumeId).then((response) => response.blob())
			.then((blob) => {
				// Create blob link to download
				const url = window.URL.createObjectURL(
					new Blob([blob]),
				);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(
					'download',
					`${filename}`,
				);

				// Append to html link element page
				document.body.appendChild(link);

				// Start download
				link.click();

				// Clean up and remove the link
				link.parentNode.removeChild(link);
			});
	}

	const onOverlay = () => (event) => {
		var element = document.getElementById('cover-letter-overlay-id')
		element.classList.add('d-block')
	}

	const offOverlay = () => (event) => {
		var element = document.getElementById('cover-letter-overlay-id')
		element.classList.remove('d-block')
	}

	const [statusChangeData, setStatusChangeData] = useState({ post_id: props.jobId, user_id: userData.id })
	const [loading, setLoading] = useState(false);


	const handleApplyChange = (name) => (event) => {
		setLoading(true);
		setStatusChangeData({ ...statusChangeData, candidate_id: props.candidateInfo.id, [name]: event.target.value })
	}

	useEffect(() => {
		setLoading(true);
		changeApplyStatus(props.applyInfo.job_post_id, statusChangeData)
			.then(response => {
				if (response && response.success) {
					setResponseMessage(response.response.message)
					setOpenAlert(true)
					props.renderState()
				} else {
					// console.log('errors', response)
				}
				setLoading(false);
			})

	}, [statusChangeData])

	return (
		// <div className='atb-card'>
		//     <div className='row mt-3'>
		//         <div className='col-md-3 col-lg-3 col-sm-12'>
		// 			{
		// 				props.loading ? 
		// 				<center>
		// 					<img src='/v2/spinners/Spinner-1.gif' />
		// 				</center>
		// 					:
		// 					<>
		// 					<img className='employer-profile-image' src={props.candidateInfo?.profile_pic_base64?props.candidateInfo.profile_pic_base64 : '/images/profile.png'}/>
		// 						<div className='row m-t10'>
		// 							<div className='col-3'></div>
		// 							<div className='col-6'>
		// 							<Select
		// 							options=
		// 							{statusList.map(item => {
		// 								return (
		// 									{label: item.name, value: item.id}
		// 								)
		// 							})
		// 							}
		// 							defaultValue={{label: currentStatus?.name, value: currentStatus?.id}}
		// 							onChange = {handleApplyChange('apply_status')}
		// 							className='profile-apply-status-container'
		// 							classNamePrefix='profile-apply-status'
		// 							placeholder='tfyghujk'
		// 							/>
		// 							</div>
		// 							<div className='col-3'></div>
		// 						</div>
		// 					</>
		// 			}                
		//         </div>
		//         <div className='col-md-9 col-lg-9 col-sm-12'>
		//             <div className='row section-container'>
		// 				<div className='col'>
		// 					<div className='row'>
		// 						<div className='col-lg-9 col-md-12'>
		//                     		<h1 className='employer-profile-title'>{props.candidateInfo.first_name}</h1> 
		// 						</div>
		// 						<div className='col-lg-3 d-none d-lg-block'>

		// 							{ 
		// 							savedCandidateIds?.includes(props.candidateInfo?.id)?
		// 							<Link to='#' onClick={()=> unSave(employerData.id, props.candidateInfo?.id)} className="apply-candidate-buttons">
		// 								<Tooltip title="Unsave Candidate" placement="bottom">
		// 								<img src='/v2/icons/add-friend (1).png' />
		// 								</Tooltip>
		// 							</Link>
		// 							:
		// 							<Link to='#'  onClick={()=> saveCandidate(employerData.id, props.candidateInfo.id)}  className="apply-candidate-buttons">
		// 								<Tooltip title="Save Candidate" placement="bottom">
		// 								<img src='/v2/icons/add-friend (1).png' />
		// 								</Tooltip>
		// 							</Link>
		// 							}
		// 							<Link to='#' onClick={onOverlay()} className="apply-candidate-buttons">
		// 							<Tooltip title="See Cover Letter" placement="bottom">
		// 								<img src='/v2/icons/cv.png' className='applied-card-icons' />
		// 							</Tooltip>
		// 							</Link>

		// 							<Link to='#' onClick={(e)=> resumeDownload(props.applyInfo?.candidate_resume_id, props.applyInfo?.applied_resume)} className="apply-candidate-buttons">
		// 							<Tooltip title="Download Resume" placement="bottom">
		// 								<img src='/v2/icons/download (1).png' className='applied-card-icons' />
		// 							</Tooltip>
		// 							</Link>

		// 						</div>
		// 					</div>
		// 					<div className='row'>
		// 						<div className='col'><h2>{props.candidateInfo.profession}</h2></div>
		// 					</div>
		// 					<div className='row'>
		// 						<div className='col'>
		// 							<table>
		// 								<tbody>
		// 									<tr>
		// 										<td className='icon-table-cover'>
		// 											<img className='employer-profile-sidebar-icons' src='/v2/icons/placeholder.png' />
		// 										</td>
		// 										<td className='text-table-cover'>
		// 											<p>{props.candidateInfo.address}</p>
		// 										</td>
		// 									</tr>
		// 								</tbody>
		// 							</table>
		// 						</div>
		// 						<div className='col'>
		// 							<table>
		// 								<tbody>
		// 									<tr>
		// 										<td className='icon-table-cover'>
		// 											<img className='employer-profile-sidebar-icons' src='/v2/icons/email.png' />
		// 										</td>
		// 										<td className='text-table-cover'>
		// 											<p>{props.candidateInfo.user?.email}</p>
		// 										</td>
		// 									</tr>
		// 								</tbody>
		// 							</table>
		// 						</div>
		// 						<div className='col'>
		// 							<table>
		// 								<tbody>
		// 									<tr>
		// 										<td className='icon-table-cover'>
		// 											<img className='employer-profile-sidebar-icons' src='/v2/icons/mobile.png' />
		// 										</td>
		// 										<td className='text-table-cover'>
		// 											<p>{props.candidateInfo.phone_no}</p>
		// 										</td>
		// 									</tr>
		// 								</tbody>
		// 							</table>
		// 						</div>
		// 					</div>
		// 				</div>
		//             </div>
		//         </div>
		//         <SuccessAlert open={openAlert} message={responseMessage} onHide = {() => setOpenAlert(false)} />

		// 		<div id='cover-letter-overlay-id' className='cover-letter-overlay d-none' onClick={offOverlay()} >
		//             <div className="cover-letter-text">
		//                 {ReactHtmlParser(props.applyInfo.cover_letter? props.applyInfo.cover_letter : "Not Given")}
		//             </div>
		//         </div>

		//     </div>
		// </div>

		<>
			{/* Desktop View */}
			<div className='atb-card d-none d-lg-block' style={{ height: 315, backgroundImage: 'url(/v2/images/cover-photo2.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: '100% 250px' }}>
				<Grid container spacing={0} sx={{ display: { xs: 'none', md: 'flex' }, marginTop: 6 }}>
					<Grid md={2} sx={{ display: 'flex', justifyContent: "flex-end", marginRight: 3, marginLeft: 3 }}>
						<Badge
							className={classes.badge}
							overlap="circular"
							anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
						>
							<Avatar alt="Candidate Avatar" src={props.candidateInfo?.profile_pic_base64 ? props.candidateInfo?.profile_pic_base64 : "/images/profile.png"} sx={{ height: "180px", width: "180px", padding: 0.05, backgroundColor: 'white' }} />
						</Badge>
					</Grid>
					<Grid md={8} sx={{ display: 'flex', justifyContent: "flex-start", marginTop: props.candidateInfo.profession ? 0 : 5 }}>
						<Stack spacing={0} mt={2} mb={0} direction="column" justifyContent="center" alignItems="flex-start">
							<Typography className={classes.typography} variant='h4' color='white'>{props.candidateInfo.first_name}</Typography>
							<Typography className={classes.typography} variant='h5' color='white'>{props.candidateInfo.profession}</Typography>
							<Select
								value={currentStatus?.id}
								onChange={handleApplyChange('apply_status')}
								startAdornment={loading && (
									<Box sx={{ position: 'absolute', top: '55%', left: 10, transform: 'translateY(-50%)' }}>
										<CircularProgress color="inherit" size={20} />
									</Box>
								)}
								style={{ backgroundColor: 'white' }}
								fullWidth
								disabled={loading ? true : false}
							>
								{
									statusList.map(item => {
										return (
											<MenuItem value={item.id}>{item.name}</MenuItem>
										)
									})
								}

							</Select>
						</Stack>
					</Grid>
					<Grid md="auto" sx={{ flexGrow: 1, maxWidth: '100% !important', width: '100% !important' }}>
						<Grid container spacing={0} sx={{ justifyContent: 'space-between', alignItems: 'center', paddingRight: '1.2rem' }}>
							<Grid item md={3} sx={{ display: 'flex', justifyContent: 'space-evenly', alignContent: 'center' }}>
								{
									savedCandidateIds?.includes(props.candidateInfo?.id) ?
										<Link to='#' onClick={() => unSave(employerData.id, props.candidateInfo?.id)} className="">
											<Tooltip title="Unsave Candidate" placement="bottom">
												{/* <img src='/v2/icons/add-friend (1).png' /> */}
												<IconButton color='secondary'>
													<PersonAddAlt1Icon style={{ fontSize: 28 }} />
												</IconButton>
											</Tooltip>
										</Link>
										:
										<Link to='#' onClick={() => saveCandidate(employerData.id, props.candidateInfo.id)} className="">
											<Tooltip title="Save Candidate" placement="bottom">
												{/* <img src='/v2/icons/add-friend (1).png' /> */}
												<IconButton>
													<PersonAddAlt1TwoToneIcon style={{ fontSize: 28 }} />
												</IconButton>
											</Tooltip>
										</Link>
								}
								<Link to='#' onClick={onOverlay()} className="">
									<Tooltip title="See Cover Letter" placement="bottom">
										{/* <img src='/v2/icons/cv.png' className='applied-card-icons' /> */}
										<IconButton>
											<DescriptionTwoToneIcon style={{ fontSize: 28 }} />
										</IconButton>
									</Tooltip>
								</Link>

								<Link to='#' onClick={(e) => resumeDownload(props.applyInfo?.candidate_resume_id, props.applyInfo?.applied_resume)} className="">
									<Tooltip title="Download Resume" placement="bottom">
										{/* <img src='/v2/icons/download (1).png' className='applied-card-icons' /> */}
										<IconButton>
											<SimCardDownloadTwoToneIcon style={{ fontSize: 28 }} />
										</IconButton>
									</Tooltip>
								</Link>
							</Grid>
							<Grid item md={'auto'}>
								<Stack direction={'row'}>
									<LocationOnIcon sx={{ width: '2em', mt: 0 }} />
									<Typography className={classes.typography} variant='body2' mr={3}>{props.candidateInfo.address}</Typography>

									<EmailOutlined sx={{ width: '2em', mt: 0 }} />
									<Typography variant='body2' mr={3}>{props.candidateInfo.user?.email}</Typography>

									<Phone sx={{ width: '2em', mt: 0 }} />
									<Typography className={classes.typography} variant='body2' mr={3}>{props.candidateInfo.phone_no}</Typography>
								</Stack>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>

			{/* Mobile View */}
			<div className='atb-card d-lg-none' style={{ height: props?.candidateInfo?.profession ? 680 : 650, backgroundImage: 'url(/v2/images/cover-photo2.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: '100% 250px' }}>
				<Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center' }}>
					<Grid container spacing={2}>
						<Grid item xs={12} sx={{ marginTop: 4 }}>
							<Badge
								className={classes.badge}
								overlap="circular"
								anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
							>
								<Avatar alt="Travis Howard" src={props.candidateInfo?.profile_pic_base64 ? props.candidateInfo?.profile_pic_base64 : "/images/profile.png"} sx={{ height: "180px", width: "180px", padding: 0.05, backgroundColor: 'white' }} />
							</Badge>
						</Grid>

						<Grid item xs={12} sx={{ marginTop: 4 }}>
							<Stack spacing={1}>
								<Typography variant='h5'>{props.candidateInfo.first_name}</Typography>
								<Typography variant='h6'>{props.candidateInfo.profession}</Typography>
								<Grid sx={{ display: 'flex', justifyContent: 'space-evenly', alignContent: 'center' }}>
									{
										savedCandidateIds?.includes(props.candidateInfo?.id) ?
											<Link to='#' onClick={() => unSave(employerData.id, props.candidateInfo?.id)} className="">
												<Tooltip title="Unsave Candidate" placement="bottom">
													{/* <img src='/v2/icons/add-friend (1).png' /> */}
													<IconButton color='secondary' sx={{ border: 1, borderColor: '#6f6f6f' }}>
														<PersonAddAlt1Icon style={{ fontSize: 28 }} />
													</IconButton>
												</Tooltip>
											</Link>
											:
											<Link to='#' onClick={() => saveCandidate(employerData.id, props.candidateInfo.id)} className="">
												<Tooltip title="Save Candidate" placement="bottom">
													{/* <img src='/v2/icons/add-friend (1).png' /> */}
													<IconButton sx={{ border: 1, borderColor: '#6f6f6f' }}>
														<PersonAddAlt1TwoToneIcon style={{ fontSize: 28 }} />
													</IconButton>
												</Tooltip>
											</Link>
									}
									<Link to='#' onClick={onOverlay()} className="">
										<Tooltip title="See Cover Letter" placement="bottom">
											{/* <img src='/v2/icons/cv.png' className='applied-card-icons' /> */}
											<IconButton sx={{ border: 1, borderColor: '#6f6f6f' }}>
												<DescriptionTwoToneIcon style={{ fontSize: 28 }} />
											</IconButton>
										</Tooltip>
									</Link>

									<Link to='#' onClick={(e) => resumeDownload(props.applyInfo?.candidate_resume_id, props.applyInfo?.applied_resume)} className="">
										<Tooltip title="Download Resume" placement="bottom">
											{/* <img src='/v2/icons/download (1).png' className='applied-card-icons' /> */}
											<IconButton sx={{ border: 1, borderColor: '#6f6f6f' }}>
												<SimCardDownloadTwoToneIcon style={{ fontSize: 28 }} />
											</IconButton>
										</Tooltip>
									</Link>
								</Grid>
							</Stack>
						</Grid>

						<Grid item xs={12} sx={{ display: 'flex', marginTop: 0, justifyContent: 'center' }}>
							<Stack spacing={1}>
								<List >
									<ListItem>
										<ListItemIcon>
											<LocationOnIcon />
										</ListItemIcon>
										<ListItemText primary={props.candidateInfo.address} />
									</ListItem>
									<ListItem>
										<ListItemIcon>
											<EmailOutlined />
										</ListItemIcon>
										<ListItemText primary={props.candidateInfo.user?.email} />
									</ListItem>
									<ListItem>
										<ListItemIcon>
											<Phone />
										</ListItemIcon>
										<ListItemText primary={props.candidateInfo.phone_no} />
									</ListItem>
								</List>
							</Stack>
						</Grid>

						<Grid item xs={12}>
							<Select
								value={currentStatus?.id}
								onChange={handleApplyChange('apply_status')}
								startAdornment={loading && (
									<Box sx={{ position: 'absolute', top: '55%', left: 10, transform: 'translateY(-50%)' }}>
										<CircularProgress color="inherit" size={20} />
									</Box>
								)}
								style={{ backgroundColor: 'white' }}
								fullWidth
								disabled={loading ? true : false}
							>
								{
									statusList.map(item => {
										return (
											<MenuItem value={item.id}>{item.name}</MenuItem>
										)
									})
								}

							</Select>
						</Grid>
					</Grid>
				</Box>
			</div>
			<SuccessAlert open={openAlert} message={responseMessage} onHide={() => setOpenAlert(false)} />

			<div id='cover-letter-overlay-id' className='cover-letter-overlay d-none' onClick={offOverlay()} >
				<div className="cover-letter-text">
					{ReactHtmlParser(props.applyInfo.cover_letter ? props.applyInfo.cover_letter : "Not Given")}
				</div>
			</div>
		</>
	)
}

export default AppliedCandidateCover