/** Imports */

import React, { useContext, useEffect } from 'react'
import { UserContext } from '../context/UserContext';
import { useHistory } from 'react-router-dom';
import RegisterTab from '../components/RegisterTab';

/**
 * Component for users to be able to Sign up
 * 
 * TODO: Server Error notification to be integrated in the component
 * 
 * @component
 */

function Signup() {
    const { isLoggedin } = useContext(UserContext);
    const history = useHistory();

    // Redirect user to home page If already loggedin
    useEffect(() => {
        if (isLoggedin) {
            history.push('/')
        }
    }, [isLoggedin])

    return (
        <section className="">
            <div className="px-4 py-5 px-md-5 text-center text-lg-start" style={{ backgroundColor: 'hsl(0, 0%, 96%)' }}>
                <div className="container">
                    <div className="row gx-lg-5">
                        <div className="col-lg-6 mb-5 mb-lg-0">
                            <div className='row mt-5'>
                                <img src='/images/atB-jobs-logo-blue.png' height='200' width='200' align="left" />
                            </div>
                            <div className='row m-1 text-left'>
                                <h1 className="my-3 display-3 fw-bold ls-tight">
                                    Easy registration <br />
                                    <span className="text-primary">Takes less than 1 minute</span>
                                </h1>
                                <p className='text-left' style={{ color: 'hsl(217, 10%, 50.8%)' }}>
                                    Already registered as a member?
                                    Please enter your member ID and password and click the "Login" button.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-6 mb-5 mb-lg-0">
                            <div className="atb-card">
                                <div className="card-body py-5 px-md-5">
                                    <RegisterTab />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Signup