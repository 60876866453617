import React, { useEffect, useContext, forwardRef } from 'react';
import Slide from '@mui/material/Slide';
import { UserContext } from '../../context/UserContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import Snackbar from '@mui/material/Snackbar';
import { Alert, Typography } from '@mui/material';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function PromotionalAlertToaster() {

    const { promotionalAlertOpen, setPromotionalAlertOpen } = useContext(UserContext);

    const matches = useMediaQuery('(max-width:600px)');

    const handleClose = () => {
        setPromotionalAlertOpen(false);
    };

    const handleResize = () => {
        if (matches) {
            setTimeout(() => {
                setPromotionalAlertOpen(true)
            }, 500)
        } else {
            setPromotionalAlertOpen(false)
        }
    };

    useEffect(() => {
        handleResize()
    }, [matches])

    return (
        <Snackbar
            TransitionComponent={Transition}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={promotionalAlertOpen}
            onClose={handleClose}

        >
            <Alert icon={false} onClose={handleClose} severity="error" sx={{ width: '100%', "& .MuiAlert-action": { position:'absolute',top:'0px',right:'15px' } }}>
                <Typography variant='h4' sx={{ color: '#DB1616', fontSize: 16, fontWeight: '700', }}>
                    Attention!
                </Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: '400', marginTop: '10px' }}>
                    We are currently on developing our mobile version. To get a better experience, please browse our site on a <b>Desktop</b> browser.
                </Typography>
            </Alert>
        </Snackbar>
    )
}

export default PromotionalAlertToaster;