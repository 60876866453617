import React, { useContext, useEffect, useRef, useState } from 'react'
import { Alert, AlertTitle, Box, Grid, Avatar } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { EmployerContext } from '../../../../context/EmployerContext'
import { updateTeamMembers, deleteTeamMembers, insertEmployerTeam } from '../../../../../fetchAPI'

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import RequiredAsterisk from '../../../CandidateComponents/CandidateProfile/UpdateProfile/component/RequiredAsterisk'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
        width: '90%', // Adjust as needed
        maxWidth: '950px', // Adjust as needed
        height: '90%', // Adjust as needed
        maxHeight: '550px', // Adjust as needed
    },
}));

function TeamMemberEdit(props) {

    const [loading, setLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const { settingEmployerData } = useContext(EmployerContext)
    const [errors, setErrors] = useState([]);

    const memberNameRef = useRef('')
    const memberDesignationRef = useRef('')
    const memberPhotoRef = useRef('')
    const memberDescriptionRef = useRef('')

    useEffect(() => {
        // Clearing the errors when modal is opened or closed
        if (!props.open) {
        setErrors([]);
        }
    }, [props.open]);

    const handleTeamInsertion = () => {
        setLoading(true)
        const formData = new FormData()

        formData.append('name', memberNameRef.current.value)
        formData.append('designation', memberDesignationRef.current.value)
        formData.append('description', memberDescriptionRef.current.value)
        formData.append('leader_photo', memberPhotoRef.current.files[0])

        insertEmployerTeam(formData)
            .then(response => {
                if (response && response.success) {
                    props.setResponseMessage(response.response.message)
                    props.setOpenAlert(true)
                    props.closeMemberEditModal()
                    settingEmployerData()
                } else{
                    setErrors(response.errors[0]?.message);
                }
            }).finally(() => setLoading(false))
    }

    const handleTeamUpdate = () => {
        setLoading(true)

        const formData = new FormData()
        formData.append('name', memberNameRef.current.value)
        formData.append('designation', memberDesignationRef.current.value)
        formData.append('description', memberDescriptionRef.current.value)
        formData.append('leader_photo', memberPhotoRef.current.files[0])

        updateTeamMembers(props.item.id, formData)
            .then(response => {
                if (response && response.success) {
                    props.setResponseMessage(response.response.message)
                    props.setOpenAlert(true)
                    props.closeMemberEditModal()
                    settingEmployerData()
                }else{
                    setErrors(response.errors[0]?.message)
                }
            }).finally(() => setLoading(false))
    }

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={props.closeMemberEditModal}
                aria-labelledby="customized-dialog-title"
                open={props.open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {
                        props.item ?
                            'Edit Team Members'
                            :
                            'Add Team Members'
                    }
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={props.closeMemberEditModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <form onSubmit={(e) => {e.preventDefault(); if(props.item) handleTeamUpdate(); else handleTeamInsertion(); }}>
                <DialogContent dividers>
                    {errors.length > 0 && (
                        <Box sx={{ marginTop: 2, marginBottom: 2 , paddingBottom: 1 }}>
                            <Alert
                                severity="error"
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => setErrors([])}
                                    >
                                    <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                <AlertTitle>Error</AlertTitle>
                                <ul style={{ listStyleType: 'circle', paddingLeft: '20px', marginBottom: '8px' }}>
                                    {errors.map((error, index) => (
                                        <li key={index}>{error}</li>
                                    ))}
                                </ul>
                            </Alert>
                        </Box>
                    )}
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <div className="form-group">
                                <label>Full Name <RequiredAsterisk/></label>
                                <input type="text" required ref={memberNameRef} defaultValue={props.item && props.item?.name} name='name' className="form-control atb-input-box" placeholder="Enter Full Name" />
                            </div>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <div className="form-group">
                                <label>Designation <RequiredAsterisk/></label>
                                <input type="text" required ref={memberDesignationRef} defaultValue={props.item && props.item?.designation} name='designation' className="form-control atb-input-box" placeholder="Enter Designation" />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                            <div className="form-group">
                                <label>Description</label>
                                <input type="text" ref={memberDescriptionRef} defaultValue={props.item && props.item?.description} name='description' className="form-control atb-input-box" placeholder="Enter Description" />
                            </div>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <div className="form-group">
                                <label>Photo <RequiredAsterisk/></label> 
                                <Box
                                    sx={{
                                    border: '2px solid #e0e0e0',
                                    maxWidth:'100px',
                                    padding: 1,
                                    flexShrink: 0,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom:'8px'
                                    }}
                                >
                                    <Avatar
                                    alt="User Profile Picture"
                                    src={props.item && props.item?.s3_url}
                                    sx={{
                                        height: "auto",
                                        width: "auto",
                                    }}
                                    />
                                </Box>
                                <input type="file"  ref={memberPhotoRef} name='leader_photo' className="form-control atb-input-box" placeholder="Change photo" />
                                <small style={{fontSize : '10px', color: '#060606de'}}> Please upload a maximum size of 220 X 220 px </small>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={loading} variant="contained" type="submit" className="employer-update-button m-b30 d-none d-lg-block"> {props.item ? 'Save Changes' : 'Add New Member'}</LoadingButton>
                </DialogActions>
                </form>
            </BootstrapDialog>
        </React.Fragment>
    )
}

export default TeamMemberEdit