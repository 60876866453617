import React, { createContext, useContext, useEffect, useState } from "react"
import { searchCandidates } from "../../fetchAPI";
import { useQuery } from "react-query";

export const CandidateSearchContext = createContext();

const CandidateSearchContextProvider = (props) => {
  const [result, setResult] = useState('')
  const [searchResultPagination, setSearchResultPagination] = useState(1)
  const [lastPage, setLastPage] = useState('')
  const [loading, setLoading] = useState(true)

  // Default react select box selection
  const [defaultInstitute, setDefaultInstitute] = useState('')
  const [defaultExpFrom, setDefaultExpFrom] = useState('')
  const [defaultExpTo, setDefaultExpTo] = useState('')
  const [defaultSearchSkills, setDefaultSearchSkills] = useState('')

  // Search Parameters

  //Input Fields
  const [searchName, setSearchName] = useState('')
  const [profession, setProfession] = useState('')
  const [gender, setGender] = useState('')

  // Select Fields
  const [searchUni, setSearchUni] = useState('')
  const [searchExperienceFrom, setSearchExperienceFrom] = useState('')
  const [searchExperienceTo, setSearchExperienceTo] = useState('')
  const [searchSkill, setSearchSkill] = useState('')

  const [expectedSalaryFrom, setExpectedSalaryFrom] = useState('')
  const [expectedSalaryTo, setExpectedSalaryTo] = useState('')
  const [expectedSalary, setExpectedSalary] = useState([0, 0])

  const [currencySearch, setCurrencySearch] = useState('')

  // Checkbox Fields
  const [qualiCheck, setQualiCheck] = useState('')
  const [typeCheck, setTypeCheck] = useState('')
  const [levelCheck, setLevelCheck] = useState('')

  // Final Parameter
  const [searchData, setSearchData] = useState({})

  const { data, isLoading, status } = useQuery(['CandidateSearch', searchData, searchResultPagination], searchCandidates, {staleTime : Infinity})

  useEffect(() => {
    setSearchData({
      name: searchName,
      profession: profession,
      gender: gender,
      candidate_qualification: qualiCheck.toString(),
      candidate_level: levelCheck.toString(),
      type: typeCheck.toString(),
      experience_from: searchExperienceFrom,
      experience_to: searchExperienceTo,
      degree_institute: searchUni.toString(),
      salary_range_from: expectedSalaryFrom,
      salary_range_to: expectedSalaryTo,
      skill: searchSkill.toString(),
      currency: currencySearch,

    })
  }, [searchName, profession, gender, qualiCheck, levelCheck, typeCheck, searchExperienceFrom, searchExperienceTo, searchUni, expectedSalaryFrom, expectedSalaryTo, searchSkill, currencySearch])

  return (
    <CandidateSearchContext.Provider value={{
      result, setResult, searchResultPagination, setSearchResultPagination, lastPage, setLastPage, searchName, setSearchName, profession, setProfession, gender, setGender, searchUni, setSearchUni, searchExperienceFrom, setSearchExperienceFrom, searchExperienceTo, setSearchExperienceTo, qualiCheck, setQualiCheck, typeCheck, setTypeCheck, levelCheck, setLevelCheck, searchData, setSearchData, loading, setLoading, defaultInstitute, setDefaultInstitute, defaultExpFrom, setDefaultExpFrom, defaultExpTo, setDefaultExpTo, defaultSearchSkills, setDefaultSearchSkills,
      setExpectedSalaryFrom, setExpectedSalaryTo, expectedSalaryFrom, expectedSalaryTo, expectedSalary, setExpectedSalary, searchSkill, setSearchSkill, currencySearch, setCurrencySearch,
      data, isLoading, status
    }}>
      {props.children}
    </CandidateSearchContext.Provider>
  );

};

export default CandidateSearchContextProvider;