import React from 'react';

function CCMailActionIcon(props) {

    return (
        <div style={{ cursor: 'pointer', width: '35px', height: '35px', borderRadius: '50%', backgroundColor: props.bg, display:'flex',justifyContent:'center',alignItems:'center' }} onClick={props.clickHandler}>
            {props.icon}
        </div>
    )
}

export default CCMailActionIcon;