import React, { useContext, useEffect, useState } from 'react'
import AppliedCandidateCover from '../../components/CandidateComponents/CandidateProfile/AppliedCandidateCover'
import CandidateProfileSidebar from '../../components/CandidateComponents/CandidateProfile/CandidateProfileSidebar'
import CandidateProfileDetails from '../../components/CandidateComponents/CandidateProfile/CandidateProfileDetails'
import { useParams } from 'react-router-dom'
import { getsingleAppliedCandidate, postActivityLogs } from '../../../fetchAPI'
import AppliedCandidateCustomQuestion from '../../components/CandidateComponents/CandidateProfile/AppliedCandidateCustomQuestion'
import { Link } from 'react-router-dom'
import { UserContext } from '../../context/UserContext'
import { Box, Grid } from '@mui/material'
import CandidateProfileNav from '../../components/CandidateComponents/CandidateProfile/CandidateProfileNav'
import AppliedCandidateStatusBar from '../../components/EmployerComponents/AppliedCandidateStatusBar'
import { fetchIpAddress } from '../../helpers/helperFunctions'

function AppliedCandidateProfile() {
    const { job_id, candidate_id } = useParams()

    const [candidateInfo, setCandidateInfo] = useState({})
    const [customQuestionInfo, setCustomQuestionInfo] = useState('')
    const [applyInfo, setApplyInfo] = useState('')
    const [loading, setLoading] = useState(false)
    const pageTitle = 'applied-candidate-profile'
    const currentPageURL = window.location.href;
    const userAgent = navigator.userAgent;

    const { userData } = useContext(UserContext)

    const renderState = () => {
        setLoading(true)
        getsingleAppliedCandidate(job_id, candidate_id, userData.id)
            .then(response => {
                if (response && response.success) {
                    setCandidateInfo(response.response.data?.candidates[0])
                    setCustomQuestionInfo(response.response.data?.jobCustomQuestionsAndAnswers)
                    setApplyInfo(response.response.data)
                    setLoading(false)
                } else {
                    console.log('errors', response)
                    setLoading(false)
                }
            })
    }

    useEffect(() => {
        renderState()
    }, [])

    useEffect(() => {
        const logActivity = async () => {
          const ipAddress = await fetchIpAddress();
          const params = { 
            page_title: pageTitle,
            action_type: 'view',
            page_url: currentPageURL,
            ip: ipAddress,
            job_post_id: "",
            platform: "web",
            user_agent: userAgent
          };
          await postActivityLogs(params);
        };

        logActivity();
      }, []); 

    return (
        <>
            {
                candidateInfo ?
                    <>

                        <div className='' >
                            <Box sx={{ display: { md: 'block' }, position:{xs:'relative',sm:'relative',md:'fixed'}, top: {md:'97px'}, height:{xs:'unset',sm:'unset',md:'120px'} , backgroundColor: '#0275D8', width: '100%', zIndex: 100 }}>
                                <AppliedCandidateStatusBar
                                    renderState={renderState}
                                    loading={loading}
                                    applyInfo={applyInfo}
                                    candidateInfo={candidateInfo}
                                    jobId={job_id}
                                    userData={userData}
                                />
                            </Box>
                            <Box sx={{ display: { md: 'block' }, position: 'fixed', top: '217px', height: '30px', backgroundColor: '#0275D8', width: '100%', zIndex: 10 }}></Box>
                            <Grid container>
                                <Grid item xs={0} sm={0} md={4} lg={3} xl={3} sx={{ zIndex: 11, display: { xs: 'none', sm: 'none', md: 'block' } }}>

                                    <CandidateProfileSidebar
                                        stickyTop={'225px'}
                                        userData={userData}
                                        renderState={renderState}
                                        loading={loading}
                                        candidateInfo={candidateInfo}
                                        masking={candidateInfo.masking}
                                    />

                                </Grid>
                                <Grid item xs={12} sm={12} md={8} lg={9} xl={9} sx={{ padding: { xs: '0px', sm: '0px', md: '10px' } }}>
                                    <CandidateProfileNav
                                        tabScrollOffsetLgDevice={-280}
                                        tabScrollOffsetSmDevice={-310}
                                        stickyTop={{ xs: '95px', sm: '95px', md: '225px' }}
                                        userData={userData}
                                        renderState={renderState}
                                        loading={loading}
                                        candidateInfo={candidateInfo}
                                        masking={candidateInfo.masking}
                                    />

                                    <Box sx={{ padding: { xs: '10px', sm: '10px', md: '0px' }, marginTop: { xs: '0px', sm: '0px', md: '130px' } }}>
                                        <AppliedCandidateCustomQuestion loading={loading} customQuestionInfo={customQuestionInfo} />
                                        <CandidateProfileDetails loading={loading} candidateInfo={candidateInfo} applyInfo={applyInfo} masking={candidateInfo.masking} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>

                        {/* <div className='container' style={{ padding: '30px' }}>
                            <div className='text-left resume-breadcrumb m-b20'>
                                <span className='prev-url'><Link to={`/applicants/job/${job_id}`}> Applied Candidates </Link></span>
                                <span className='middle-icon'> <i class="fa fa-angle-right" aria-hidden="true"></i> </span>
                                <span className='crnt-url'> {candidateInfo.first_name} </span>
                            </div>
                            <AppliedCandidateCover renderState={renderState} loading={loading} applyInfo={applyInfo} candidateInfo={candidateInfo} jobId={job_id} />
                            <div className='row'>
                                <div className='col-md-4 col-md-4'>
                                    <CandidateProfileSidebar loading={loading} candidateInfo={candidateInfo} />
                                </div>
                                <div className='col-md-8 col-md-8'>
                                    <AppliedCandidateCustomQuestion loading={loading} customQuestionInfo={customQuestionInfo} />
                                    <CandidateProfileDetails loading={loading} candidateInfo={candidateInfo} />
                                </div>
                            </div>
                        </div> */}
                    </>

                    :
                    <center>
                        <div className='atb-card'>
                            <h3 style={{ color: 'red' }}>
                                You dont have the permission to access this page!
                            </h3>
                        </div>
                    </center>
            }
        </>
    )
}

export default AppliedCandidateProfile