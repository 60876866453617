import { Avatar, Box, Button, Card, Chip, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import ChipComponent from "../ChipComponent";
import moment from "moment";
import { Link } from 'react-router-dom'
import { CandidateContext } from "../../context/CandidateContext";
import SuccessAlert from "../Alerts/SuccessAlert";
import ErrorAlert from "../Alerts/ErrorAlert";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { postCandidateSaveJobInfo, deleteCandidateSaveJobInfo } from "../../../fetchAPI";

function FavoriteIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M14.6875 1.7124C12.3186 1.7124 10.7965 3.26252 10 4.65525C9.20348 3.26252 7.68145 1.7124 5.3125 1.7124C2.30613 1.7124 0 4.01494 0 7.06881C0 10.3802 2.88922 12.6352 7.20363 16.0496C9.31621 17.7199 9.45195 17.8469 10 18.2874C10.4473 17.9279 10.6948 17.7112 12.7964 16.0496C17.1108 12.6352 20 10.3802 20 7.06881C20 4.01498 17.6939 1.7124 14.6875 1.7124Z" fill="#FF5353" />
        </svg>
    )
}

function SalaryIcon() {
    return (
        <Avatar
            alt="Salary Image"
            src="/v2/images/moneyIcon.png"
            sx={{
                width: '30px',
                height: '30px',
            }}
        />
    )
}

export default function JobCardComponentv2({ item }) {
    const [responseMessage, setResponseMessage] = useState('')
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const [openAErrorlert, setOpenErrorAlert] = useState(false)
    // const {savedJobs} = useContext(CandidateContext)

    // console.log(savedJobs)


    const dayLeft = (date) => {
        const today = new Date()
        let days = moment(date).diff(moment(today), 'days')

        if (days > 0) {
            return days
        } else {
            return 0
        }
    }

    const displaySalary = () => {
        if (item?.negotiable) {
            return 'Negotiable'
        } else {
            if (item.maximum_salary) {
                return item?.minimum_salary + ' - ' + item?.maximum_salary + ' BDT /' + item.salary_type?.type
            }
        }
    }

    const unSaveCandidate = (candId, jobId) => {
        deleteCandidateSaveJobInfo(candId, jobId)
            .then(response => {
                if (response && response.success) {
                    // settingCandidateData()
                    setResponseMessage(response.response.message)
                    setOpenSuccessAlert(true)
                } else {
                    setResponseMessage(response.errors[0].message)
                    setOpenErrorAlert(true)
                }
            })
    }

    const saveCandidate = (candId, jobId) => {
        postCandidateSaveJobInfo(candId, jobId)
            .then(response => {
                if (response && response.success) {
                    // settingCandidateData()
                    setResponseMessage(response.response.message)
                    setOpenSuccessAlert(true)
                } else {
                    setResponseMessage(response.errors[0].message)
                    setOpenErrorAlert(true)
                }
            })
    }

    const hideSuccessAlert = () => {
        setOpenSuccessAlert(false)
    }

    const hideErrorAlert = () => {
        setOpenErrorAlert(false)
    }

    return (
        <Box m={4}>
            <Card
                sx={{
                    border: '1px solid #C4C4C4',
                    padding: 3,
                    '@media (max-width: 600px)': {
                        padding: 2,
                    },
                }}
            >
                <Stack direction="row" spacing={1} justifyContent="space-between">
                    <Box sx={{ display: 'flex' }}>
                        <Avatar
                            alt="Company Image"
                            src={item.employer?.profile_pic_base64 ? item.employer?.profile_pic_base64 : "/images/employer/employer-building.png"}
                            sx={{
                                width: '56px',
                                height: '56px',
                            }}
                        />
                        <Stack spacing={1} sx={{ marginLeft: 1 }}>
                            <Link to={`/employer/profile/${item?.employer?.slug}`}>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontSize: '24px',
                                        fontWeight: 700,
                                        '@media (max-width: 600px)': {
                                            fontSize: '16px',
                                        },
                                    }}
                                >
                                    {item.employer?.employer_name}
                                </Typography>
                            </Link>
                            <Typography
                                variant="caption"
                                sx={{
                                    color: '#0275D8',
                                    fontSize: '18px',
                                    fontWeight: 600,
                                    '@media (max-width: 600px)': {
                                        fontSize: '14px',
                                    },
                                }}
                            >
                                {item.job_title}
                            </Typography>
                        </Stack>
                    </Box>
                    <Box m={1}>
                        {/* {
                            savedJobIds?.includes(item.post_id) ?
                                <IconButton onClick={() => { unSaveCandidate(candidateData?.id, item.post_id) }} aria-label="Unsave Button">
                                    <FavoriteIcon />
                                </IconButton>
                                :
                                candidateData ?
                                    <IconButton onClick={() => saveCandidate(candidateData?.id, { 'job_post_id': item.post_id })} aria-label="Save Button">
                                        <FavoriteBorderIcon />
                                    </IconButton>
                                    :
                                    // <Link to='#'><button onClick={handleOpen} class="save-button">Save</button></Link>
                                    ''
                        } */}
                    </Box>
                </Stack>
                <Grid container spacing={2} sx={{ flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'center', md: 'flex-start' }, marginTop: '3px' }}>
                    <Grid item xs={12} md={7}>
                        <Stack direction="row" spacing={1} sx={{ marginLeft: { xs: 3, md: 7 }, alignItems: 'center' }}>
                            <SalaryIcon />
                            <Typography
                                variant="caption"
                                sx={{
                                    color: '#515B6F',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    '@media (max-width: 600px)': {
                                        fontSize: '13px',
                                    },
                                }}
                            >
                                Salary: {displaySalary()}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={5} sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' } }}>
                        <ChipComponent title={item?.job_type?.name} />
                        <Chip label="Dhaka" variant="outlined" sx={{ border: '2px solid #FFB836', marginRight: 1, marginLeft: 1, color: '#FFB836' }} />
                        <Chip label={item?.qualification?.name} variant="outlined" sx={{ border: '2px solid #4640DE', marginRight: 1, color: '#4640DE' }} />
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ justifyContent: { xs: 'center', md: 'space-between' }, marginTop: '3px', alignItems: 'center' }}>
                    <Grid item md={7}>
                        <Stack direction="row" spacing={1}
                            sx={{
                                marginLeft: 7,
                                '@media (max-width: 600px)': {
                                    marginLeft: 0
                                },
                            }}
                        >
                            <Typography
                                variant="caption"
                                align="center"
                                sx={{
                                    color: '#515B6F',
                                    fontSize: '13px',
                                    fontWeight: 500,
                                    '@media (max-width: 600px)': {
                                        fontSize: '12px',
                                    },
                                }}
                            >
                                Application Deadline: {moment(item?.job_deadline).format("MMM Do YY")} ({dayLeft(item.job_deadline) + ' days left'})
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item md={5}>
                        <Stack direction="row" spacing={1}>
                            <Button variant="outlined" size="large" sx={{ '@media (max-width: 600px)': { fontSize: '12px' } }}><Link to={`/jobs/${item?.post_id}`}>View Details</Link></Button>
                            <Button variant="contained" size="large" sx={{ '@media (max-width: 600px)': { fontSize: '12px' } }}><Link className='text-light' to={`/login?job_id=${item?.post_id}`}>Apply Now</Link></Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Card>
            <SuccessAlert open={openSuccessAlert} message={responseMessage} onHide={hideSuccessAlert} />
            <ErrorAlert open={openAErrorlert} message={responseMessage} onHide={hideErrorAlert} />
        </Box>
    )
}