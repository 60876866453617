import { Box, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { CandidateContext } from "../../../context/CandidateContext";
import CandidateUpdateModal from '../CandidateProfile/UpdateProfile/component/CandidateUpdateModal.js'
import { useAlert } from "../../../context/AlertContext.js";
import { updateCandidateBasicInfo } from "../../../../fetchAPI.js";

function EditIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#E5F5FF" />
      <g clip-path="url(#clip0_2572_12941)">
        <path
          d="M23.2599 10.538C23.2656 11.0872 23.0546 11.6176 22.6703 12.0201C22.6192 12.075 22.5645 12.1271 22.5105 12.1796C19.2059 15.3928 15.9018 18.6062 12.5981 21.8199C12.4637 21.9547 12.2922 22.0491 12.1041 22.0919C11.1063 22.328 10.1109 22.5722 9.11442 22.8158C8.59044 22.9431 8.2824 22.6443 8.4126 22.1372C8.66595 21.1519 8.91847 20.1664 9.17017 19.1806C9.20408 19.0357 9.27957 18.903 9.38788 18.7981C12.7261 15.554 16.0636 12.3091 19.4004 9.06315C20.5017 7.99346 22.2529 8.21234 22.9868 9.504C23.1658 9.82086 23.2598 10.1765 23.2599 10.538ZM9.59113 21.673C10.3491 21.4881 11.0713 21.3111 11.7943 21.1365C11.8505 21.1202 11.9008 21.0888 11.9394 21.0459C15.2479 17.8275 18.5569 14.6085 21.8662 11.3892C22.3535 10.9147 22.3552 10.1864 21.8852 9.72562C21.4103 9.25973 20.6538 9.26007 20.1615 9.73831C16.8548 12.9506 13.5488 16.1642 10.2436 19.3793C10.2026 19.4194 10.161 19.4565 10.1455 19.5165C9.96409 20.2249 9.78096 20.9324 9.59113 21.673Z"
          fill="#0079C1"
        />
        <path
          d="M19.8078 22.857C18.6208 22.857 17.434 22.857 16.2475 22.857C15.9105 22.857 15.6889 22.6855 15.6632 22.4083C15.6492 22.2848 15.6841 22.1607 15.7605 22.0612C15.837 21.9617 15.9495 21.8941 16.0753 21.8721C16.1446 21.8601 16.2149 21.8546 16.2852 21.8556C18.6411 21.8556 20.9971 21.8556 23.3532 21.8556C23.7082 21.8556 23.9153 21.999 23.9654 22.2745C23.978 22.3393 23.9768 22.4058 23.9621 22.4702C23.9474 22.5345 23.9194 22.5953 23.8798 22.6488C23.8402 22.7023 23.7899 22.7474 23.7318 22.7815C23.6737 22.8155 23.6092 22.8377 23.542 22.8467C23.4663 22.8554 23.39 22.8587 23.3137 22.8567C22.1458 22.8571 20.9771 22.8572 19.8078 22.857Z"
          fill="#0079C1"
        />
      </g>
      <defs>
        <clipPath id="clip0_2572_12941">
          <rect
            width="15.5897"
            height="14.4762"
            fill="white"
            transform="translate(8.38281 8.38086)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default function CandidateDashboardDesignation() {
  const { candidateData, settingCandidateData } = useContext(CandidateContext);

  const [addData, setAddData] = useState({})
  const [addModalOpen, setAddModalOpen] = useState(false);
  const alertContext = useAlert();

  const handleChange = (name) => (event) => {
    setAddData({ ...addData, [name]: event.target.value })
  }

  const updateCandidate = (updateParameters) => {
    setAddModalOpen(false);
    setAddData({});
    updateCandidateBasicInfo(candidateData.id, updateParameters)
      .then(response => {
        if (response && response.success) {
          alertContext.setResponseMessage(response.response.message)
          alertContext.setOpenAlert(true)
          settingCandidateData()
        } else {
          const message = response.errors?.map(msg => {
            alertContext.setResponseMessage(msg.message)
          })
          alertContext.setOpenErrorAlert(true)
        }
      })
  }

  return (
    <>
      <Box
        sx={{
          py: "20px",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "10px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Epilogue,sans-serif",
                fontWeight: 700,
                fontSize: "20px",
                color: "#424447",
                textAlign: { xs: 'left', sm: 'left', md: "center" },
                mr: 2,
              }}
            >
              {candidateData.first_name}
            </Typography>
            <Box sx={{ cursor: "pointer" }} onClick={() => [setAddData({
              first_name: candidateData.first_name,
              profession: candidateData.profession
            }), setAddModalOpen(true)]}>
              <EditIcon />
            </Box>
          </Box>
          <Typography
            sx={{
              fontFamily: "Poppins,sans-serif",
              fontSize: "16px",
              color: "#72737C",
              textAlign: { xs: 'left', sm: 'left', md: "center" },
            }}
          >
            {candidateData.profession ? candidateData.profession : " "}
          </Typography>
        </Box>
      </Box>
      <CandidateUpdateModal form="candidateNameDesignationAddForm" open={addModalOpen} onClose={() => [setAddModalOpen(false), setAddData({})]} title='Update Name and Designation'>
        <div>
          <div style={{ display: 'flex', alignItems: 'center', minHeight: '250px' }}>
            <div className='card-body' >
              <form id='candidateNameDesignationAddForm' onSubmit={(e) => { e.preventDefault(); updateCandidate(addData); }}>
                <div className={`row text-left`}>

                  <div className='col-md-12 col-lg-6'>
                    <div className='form-group'>
                      <label>Name</label>
                      <input value={addData.first_name} className='form-control atb-input-box' onChange={handleChange('first_name')} placeholder='Enter your name' type='text' />
                    </div>
                  </div>

                  <div className='col-md-12 col-lg-6'>
                    <div className='form-group'>
                      <label>Designation</label>
                      <input value={addData.profession} className='form-control atb-input-box' onChange={handleChange('profession')} placeholder='Enter your designation' type='text' />
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
      </CandidateUpdateModal>
    </>

  );
}
