import React from 'react'
import EmployerDashboardSideBar from '../../components/EmployerComponents/EmployerDashboardSideBar/EmployerDashboardSideBar'
import Dashboard from '../../components/EmployerComponents/EmployerDashboard/Dashboard'

function EmployerDashboard() {
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-lg-4 col-md-6'>
                    <EmployerDashboardSideBar />
                </div>
                <div className='col-lg-8 col-md-6'>
                    <Dashboard/>
                </div>
            </div>
        </div>
    )
}

export default EmployerDashboard