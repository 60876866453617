import React, { useContext, useEffect, useState } from 'react'
import DataChart from '../DataChart'
import CandidateCard from '../../CandidateCard/CandidateCard'
// import EmployerContext from '../../../../context/EmployerContext'
import { EmployerContext } from '../../../context/EmployerContext'
import { Link } from 'react-router-dom'
import { getweeklyapplicantsbyID, getSuggestedCandidate } from '../../../../fetchAPI'
import { UserContext } from '../../../context/UserContext'
import GlobalLoader from '../../GlobalLoader'


function Dashboard() {


  const [weeklyApplicantsData, setWeeklyApplicantsData] = useState()
  const [applicantLoading, setApplicantLoading] = useState(false)
  const [suggestedCandidateData, setSuggestedCandidateData] = useState()

  // Employer Data Initialization
  const { employerData, employerDataLoading, status, setStatus } = useContext(EmployerContext)

  const { notifications, changeNotificationReadStatus, deleteNotificationReadStatus } = useContext(UserContext)

  useEffect(() => {
    settingWeeklyApplicantsData()
    settingSuggestedCandidateData()

  }, [employerData])

  const settingWeeklyApplicantsData = async () => {
    try {
        setApplicantLoading(true);
        const response = await getweeklyapplicantsbyID(employerData.id);
        
        if (response && response.success) {
            setWeeklyApplicantsData(response?.response?.data);
        } else {
            // console.log('error: ', response);
        }
    } catch (error) {
        console.error('Error fetching weekly applicants data: ', error);
    } finally {
        setApplicantLoading(false);
    }
};

  const settingSuggestedCandidateData = () => {

    getSuggestedCandidate(employerData.id).then((response) => {
      if (response && response.success) {
        setSuggestedCandidateData(response?.response?.data)
      }
      else {
        // console.log('error: ', response)

      }
    })
  }

  return (
    <div className='atb-card-dashboard'>
      <div className='container'>
        <hr className='d-lg-none' />
        <div className='text-left'>
          {/* Title */}
          {/* TODO: Implement button on Mobile responsiveness:  <button type="button" class="btn btn-outline-primary btn-sm float-right d-lg-none"><i class="fa fa-bars" aria-hidden="true"></i> Menu</button> */}
          <h2 className='mb-4 m-3'> Welcome, {employerData.employer_name}</h2>
          <hr className='d-lg-none' />
          <p className='m-3'> Welcome to your Dashboard.</p>
          <hr className='d-lg-none' />

          {/* Published and Pending Jobs Card */}
          <div className='row mb-4'>
            <div className='col-lg-6 col-md-6'>
              <Link to='/employer-manage-jobs' onClick={() => setStatus('published')}>
                <div className='atb-card text-center'>
                  <img src="/v2/icons/portfolio.png" width="40" height="40" />
                  <h5> Published Jobs</h5>
                  <small className='text-muted'> {employerData.total_published_job} Jobs Published </small>
                </div>
              </Link>
            </div>
            <div className='col-lg-6 col-md-6'>
              <Link to='/employer-manage-jobs' onClick={() => setStatus('draft')}>
                <div className='atb-card'>
                  <img src="/v2/icons/cv.png" width="40" height="40" />
                  <h5> Draft Jobs</h5>
                  <small className='text-muted'> {employerData.total_draft_job} Draft Jobs </small>
                </div>
              </Link>
            </div>

          </div>

          {/* This week's applicants table */}
          <h4 className='mb-4'> This week's applicants.</h4>
          {applicantLoading ? <GlobalLoader height='20vh' /> : 
          <div className='table-responsive'>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Jobs</th>
                  <th scope="col">New Applicants</th>
                  <th scope="col">Total Applicants</th>
                  <th scope="col">Like</th>
                  <th scope="col">Status</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {
                  weeklyApplicantsData?.map(applicantData => {
                    return (
                      <tr>
                        <th scope="row">{applicantData?.job_title}</th>
                        <td>{applicantData?.currentWeekApplicant}</td>
                        <td>{applicantData?.total_applicant}</td>
                        <td>{applicantData?.like_count || "0"}</td>
                        <td>{applicantData?.job_status}</td>
                        <td><Link to={`applicants/job/${applicantData?.post_id}`}><button type="button" class="btn btn-primary btn-sm">Applicants</button></Link></td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
          }

          {/* DataChart */}
          <hr className='d-lg-none' />
          <h4 className='mb-2'> Data Chart.</h4>
          <DataChart />

          {/* Suggested Candidates to be temporarily off */}

          {/* <hr className='d-lg-none' />
          <div className='row mt-2'>
            <div className='col-lg-6 col-md-6'>
              <h4 className='mt-1 mb-3'> Suggested Candidates.</h4>
            </div>
            <div className='col-lg-6 col-md-6 d-none d-lg-block'>
              <Link to={'/search/candidates'}><button type="button" class="btn btn-outline-primary btn-md float-right">Explore All</button></Link>
            </div>
          </div>

          <div className='row mb-4'>
            {
              suggestedCandidateData?.map(candidateData => {
                return (
                  <div className='col-lg-6 col-md-6'>
                    <CandidateCard candidateInfo={candidateData?.candidate} />
                  </div>
                )
              })
            }

          </div>

          <Link to={'/search/candidates'}><button type="button" class="btn btn-outline-primary btn-md btn-block mb-4 d-lg-none">Explore All</button></Link> */}


        </div>
      </div>
    </div>
  )
}

export default Dashboard