import React from 'react'

function EmployerProfilePicture(props) {
  return (
    <img
      {...props}
      alt='Company Avatar'
      src={props.profile_picture ? props.profile_picture : '/images/employer/employer-building.png'}
      height={props.height}
      width={props.width}
    />
  )
}

export default EmployerProfilePicture