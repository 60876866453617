export const languageProficiencies = [
    {
        title: 'Basic',
        value: 'Basic',
    },
    {
        title: 'Conversational',
        value: 'Conversational',
    },
    {
        title: 'Fluent',
        value: 'Fluent',
    },
    {
        title: 'Native',
        value: 'Native',
    },
];

export const RELIGION_LIST = [
    { label: 'Islam', value: 'Islam', id: 1 },
    { label: 'Hindu', value: 'Hindu', id: 2 },
    { label: 'Christianity', value: 'Christianity', id: 3 },
    { label: 'Buddha', value: 'Buddha', id: 4 },
];

export const WORK_TYPE_LIST = [
    { label: 'On-Site', value: 'On-Site', id: 1 },
    { label: 'Hybrid', value: 'Hybrid', id: 2 },
    { label: 'Remote', value: 'Remote', id: 3 },
];
