import React from "react";
import moment from 'moment'
import ReactHtmlParser from 'react-html-parser'
import styles from "../CandidateUpdateProfile.module.css";
import CommonMasking from "../../../../maskingcomponents/CommonMasking";

export default function SingleExpBlock({ item, masking }) {
    let yearDuration = item.work_experience ? (item.work_experience / 12).toFixed(1) : 0;
    return (
        <div style={{ width: '100%' }}>
            {
                masking ?
                    <CommonMasking>
                        <div className={styles.expOrg}>
                            <div>
                                Organization Name
                            </div>
                            <div>
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                            </div>
                            <div>
                                Type
                            </div>
                        </div>
                    </CommonMasking>
                    :
                    <div className={styles.expOrg}>
                        <div>
                            {item.organization_name}
                        </div>
                        <div>
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                        </div>
                        <div>
                            {item.candidate_type?.name || ''}
                        </div>
                    </div>
            }

            <div className={styles.expDateDuration}>
                {moment(item.start_date).format("MMM Do YYYY")} - {item.currently_working == 1 ? 'Present' : moment(item.end_date).format("MMM Do YYYY")}
                &nbsp;{yearDuration > 0 ? ` ( ${yearDuration} Year) ` : ''} | {masking ? <CommonMasking style={{display:'inline'}}>Address, Other Part</CommonMasking> : <>{item.company_location}</> }
            </div>
            {
                masking ?
                    <CommonMasking>
                        <div>
                            <hr />
                            <div className={styles.expTitle}>Job Responsibilities :</div>
                            <div className={styles.expDesc}>
                                1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />
                                2. Nullam nulla turpis, scelerisque sit amet scelerisque vitae, <br />
                                3. fermentum non odio. Nullam sit amet felis sit amet diam aliquam hendrerit <br />
                            </div>
                        </div>
                    </CommonMasking>
                    :
                    <>
                        {
                            item.organization_desc != '' && item.organization_desc != null ?
                                <div>
                                    <hr />
                                    <div className={styles.expTitle}>Job Responsibilities :</div>
                                    <div className={styles.expDesc}>
                                        {ReactHtmlParser(item.organization_desc)}
                                    </div>
                                </div>
                                :
                                ''
                        }
                    </>
            }
            {
                masking ?
                    <CommonMasking>
                        {
                            item.technology != '' && item.technology != null ?
                                <div>
                                    <hr />
                                    <div className={styles.expTitle}>Area of Expertise :</div>
                                    <div className="row" style={{ margin: 'unset' }}>
                                        {
                                            item.technology.map((tech) => {
                                                return (
                                                    <div key={tech.id} className={styles.singleSkill} style={{ margin: '5px' }}>
                                                        Skill - N years
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                :
                                ''
                        }
                    </CommonMasking>
                    :
                    <>
                        {
                            item.technology != '' && item.technology != null ?
                                <div>
                                    <hr />
                                    <div className={styles.expTitle}>Area of Expertise :</div>
                                    <div className="row" style={{ margin: 'unset' }}>
                                        {
                                            item.technology.map((tech) => {
                                                return (
                                                    <div key={tech.id} className={styles.singleSkill} style={{ margin: '5px' }}>
                                                        {tech.skill_name} - {tech?.year_of_experience} years
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                :
                                ''
                        }
                    </>
            }


        </div>
    )
}