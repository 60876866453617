import React, { useState, useEffect } from 'react';
import Style from '../../../css/lp5/lp5.module.css';
import { Link } from 'react-router-dom';
import ButtonIcon from '../../../images/lp4/icon/arrow.png';
import Milvik from '../../../images/lp4/image/milvik.png';
import Arogga from '../../../images/lp4/image/arogga.png';
import CrownCement from '../../../images/lp4/image/crown_cement.png';
import Expo from '../../../images/lp4/image/expo.png';
import Runner from '../../../images/lp4/image/runner.png';
import Rupayan from '../../../images/lp4/image/rupayan.png';
import Sara from '../../../images/lp4/image/sara.png';
import SatcGroup from '../../../images/lp4/image/satc_group.png';
import Shikho from '../../../images/lp4/image/shikho.png';
import TheWestin from '../../../images/lp4/image/the_westin.png';
import Vivo from '../../../images/lp4/image/vivo.png';
import User1 from '../../../images/lp4/image/user-1.png';
import UserReview from '../../../images/lp4/image/user_review.png';
import Slider from 'react-slick';
import UserReview2 from '../../../images/lp4/image/ur2.png';
import UserReview3 from '../../../images/lp4/image/ur3.png';
import UserReview4 from '../../../images/lp4/image/ur4.png';
import UserReview5 from '../../../images/lp4/image/ur5.png';
import UserReview6 from '../../../images/lp4/image/ur6.png';
import GraahoImg from '../../../images/lp2/client/graaho.png';
import MilvikImg from '../../../images/lp2/client/milvik.png';
import ArrowRightIcon from '../../../images/lp4/icon/arrow-right.png';


const sliderData = [
    { id: '1', img: Vivo, name: 'Vivo' },
    { id: '2', img: Expo, name: 'Expo' },
    { id: '3', img: Rupayan, name: 'Rupayan' },
    { id: '4', img: Shikho, name: 'Shikho' },
    { id: '5', img: SatcGroup, name: 'SatcGroup' },
    { id: '6', img: Arogga, name: 'Arogga' },
    { id: '7', img: Sara, name: 'Sara' },
    { id: '8', img: Runner, name: 'Runner' },
    { id: '9', img: CrownCement, name: 'CrownCement' },
    { id: '10', img: Milvik, name: 'Milvik' },
    { id: '11', img: TheWestin, name: 'TheWestin' },
    { id: '12', img: Rupayan, name: 'Rupayan' },
];

const UserReviewData = [
    { id: '1', img: UserReview },
    { id: '2', img: UserReview2 },
    { id: '3', img: UserReview3 },
    { id: '4', img: UserReview4 },
    { id: '5', img: UserReview5 },
    { id: '6', img: UserReview6 },
];

const TestimonialsData = [
    {
        id: '1',
        title: '“atB Jobs is a game changer!”',
        name: 'Soyed Zonaid Kabir',
        dig: 'Executive Director, Graaho',
        img: GraahoImg,
        des:
            'To all my fellow Top Management and HR person of the I.T Industry: atB Jobs is a game changer! We are being able to recruit efficiently even our high level tech team members.',
    },
    {
        id: '2',
        title: "“We're recruiting through atB Jobs”",
        name: 'Mr. Baharuddin',
        dig: 'HR, MILVIK',
        img:MilvikImg,
        des:
            "Finding the right team member has always been a challenge, but with atB Jobs' powerful filtering, it's easier to find perfect fits for our team. Join us—we're recruiting through atB Jobs"
    },
    {
        id: '1',
        title: '“atB Jobs is a game changer!”',
        name: 'Soyed Zonaid Kabir',
        dig: 'Executive Director, Graaho',
        img: GraahoImg,
        des:
            'To all my fellow Top Management and HR person of the I.T Industry: atB Jobs is a game changer! We are being able to recruit efficiently even our high level tech team members.',
    },
    {
        id: '2',
        title: "“We're recruiting through atB Jobs”",
        name: 'Mr. Baharuddin',
        dig: 'HR, MILVIK',
        img:MilvikImg,
        des:
            "Finding the right team member has always been a challenge, but with atB Jobs' powerful filtering, it's easier to find perfect fits for our team. Join us—we're recruiting through atB Jobs"
    },
];

const BannerSection = () => {
    return (
        <div className={Style.bannerContanier}>
            <div className={Style?.userContainer}>
                <div className={Style.containers}>
                    <h1
                        className={`${Style.titleText} ${Style.textCenters}`}
                        style={{ textAlign: 'center' }}
                    >
                        পারফেক্ট জব ম্যাচমেকিংয়ে <br /> এবার চাকরিই খুঁজবে
                        আপনাকে!
                    </h1>
                    <div className={Style.btnPadding}>
                        <Link
                            to="/candidate-register"
                            className={Style.btnsLink}
                        >
                            <span> স্বপ্নের শুরু এখানে</span>
                            <img src={ButtonIcon} alt="icon" />
                        </Link>
                    </div>
                </div>
            </div>

            <div
                style={{ position: 'relative' }}
                className={`${Style.containers}`}
            >
                <div className={`${Style.videos}`}>
                    <iframe
                        className={Style.vedioLink}
                        src="https://www.youtube.com/embed/fGnIssuVDnw?si=fidI9cu-eliQzyky"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

const SliderSection = () => {
    return (
        <div className={Style.containers}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <div
                    style={{
                        width: '50px',
                        height: '3px',
                        backgroundColor: '#3B5997',
                    }}
                ></div>
                <span className={Style.activeContent}>Our Clients</span>
            </div>
            <h2 className={`${Style.titleText}`} style={{ color: '#000000' }}>
                Trusted By <span style={{ color: '#3B5997' }}>Companies</span>
            </h2>

            <div className={`${Style.sliderGrid}`}>
                {sliderData?.map((data) => (
                    <div key={data?.id} className={Style.sliderCard}>
                        <img src={data?.img} alt={data?.name} />
                    </div>
                ))}
            </div>
        </div>
    );
};

const UserReviewSection = () => {
    return (
        <div className={`${Style.containers} ${Style.reviewContainer}`}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <div
                    style={{
                        width: '50px',
                        height: '3px',
                        backgroundColor: '#3B5997',
                    }}
                ></div>
                <span className={Style.activeContent}>Reviews</span>
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <h2 className={Style.titleText} style={{ color: '#000000' }}>
                    Reviews by <span style={{ color: '#3B5997' }}>Users</span>
                </h2>

                <Link className={Style.viewLink} to="/candidate-register">
                    View All
                </Link>
            </div>

            <div className={Style.jobGird}>
                {UserReviewData.map((data) => (
                    <div key={data?.id} className={Style.userCardReview}>
                        <img src={data?.img} alt="user review" />
                    </div>
                ))}
            </div>
        </div>
    );
};

const TecnologySection = () => {
    return (
        <div className={Style.TechContainer}>
            <div className={`${Style.containers} ${Style.techContainerFlex}`}>
                <h2
                    className={`${Style.titleText} ${Style.textCenters} ${Style.techTitle}`}
                >
                    জাপানের প্রযুক্তিতে ঘুচবে <br />
                    আপনার বেকারত্বের হতাশা
                </h2>
                <Link className={Style.viewDetials} to="/candidate-register">
                    রেজিস্ট্রেশান করুন এখনই
                </Link>
            </div>
        </div>
    );
};

const TestimonialsSection = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        // appendDots: (dots) => (
        //     <div
        //         style={{
        //             display: 'flex',
        //             justifyContent: 'center',
        //             padding: '10px',
        //             marginTop: '3rem',
        //         }}
        //     >
        //         <ul style={{ margin: '0px' }}>{dots}</ul>
        //     </div>
        // ),
        customPaging: (i) => (
            <div
                style={{
                    width: '10px',
                    height: '10px',
                    background: '#112848',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    transition: 'background 0.3s',
                }}
                className={
                    i === 0
                        ? `${Style.paginationDot} ${Style.active}`
                        : `${Style.paginationDot}`
                }
            ></div>
        ),
    };
    return (
        <div className={`${Style.containers} ${Style.testimonialContainer}`}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <div
                    style={{
                        width: '50px',
                        height: '3px',
                        backgroundColor: '#3B5997',
                    }}
                ></div>
                <span className={Style.activeContent}>Testimonials</span>
            </div>
            <h2 className={`${Style.titleText} `} style={{ color: '#000000' }}>
                Don't take our word for it
            </h2>

            <div className={Style.testimonialGrid}>
                <Slider {...settings}>
                    {TestimonialsData.map((data) => (
                        <div key={data.id} className={Style.testimonialCard}>
                            <h3
                                style={{
                                    fontSize: '18px',
                                    fontWeight: '700',
                                    margin: '0',
                                    paddingBottom: '10px',
                                }}
                            >
                                {data.title}
                            </h3>
                            <p>{data.des}</p>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '15px',
                                }}
                            >
                                <div className={Style.clientReviewCard}>
                                    <img src={data.img} alt={data.name} />
                                </div>
                                <div>
                                    <h5
                                        style={{
                                            color: '#000',
                                            fontSize: '16px',
                                            lineHeight: '24px',
                                            padding: '0px',
                                            margin: '0',
                                        }}
                                    >
                                        {data.name}
                                    </h5>
                                    <span style={{ fontSize: '14px' }}>
                                        {data.dig}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

const FloatingSection = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const isScrollingDown = window.scrollY > 200;
            setIsVisible(isScrollingDown);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={isVisible ? `${Style.boxs}` : ''}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'center',
                }}
            >
                <Link to="/candidate-register">
                    <button className={Style.buttons}>Register for Free <img src={ArrowRightIcon} alt="Arrow Right" style={{ marginLeft: '10px', height: '24px' }} /></button>
                </Link>
            </div>
        </div>
    );
};

const Lp5 = () => {
    return (
        <>
            <BannerSection />
            <SliderSection />
            <UserReviewSection />
            <TecnologySection />
            <TestimonialsSection />
            <FloatingSection />
        </>
    );
};

export default Lp5;
