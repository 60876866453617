import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { Container, Stack, Item } from '@mui/system';
import Carousel from 'react-bootstrap/Carousel';
import { Card } from 'react-bootstrap';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getTopJobs } from '../../../fetchAPI';
import moment from 'moment';
import { Link } from 'react-router-dom';

function TopJobs() {

    const [topJobs, setTopJobs] = useState();

    useEffect(() => {
        gettingTopJobs()

    }, []);

    const gettingTopJobs = () => {

        getTopJobs('weekly').then((response) => {
            if (response.success) {
                setTopJobs(response?.response?.data)

            }
            else {
                // console.log('errors: ', response);
            }
        })
    }



    return (
        <>
            <Grid>
                {/* Desktop View */}
                <Carousel fade prevIcon={<ChevronLeftIcon />} nextIcon={<ChevronRightIcon />} indicators={true} className='d-none d-lg-block'>
                    <Carousel.Item>
                        <Stack direction={'row'} spacing={3} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
                            {
                                topJobs?.slice(0, 3).map((topJob, index) => {
                                    // console.log('topJob: ', topJob)
                                    return (
                                        <Card key={index} className='atb-card' style={{ display: 'flex', height: '100%', margin: '1rem' }}>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <Link to={`/employer/profile/${topJob?.author_id}`} className='text-dark'><Typography variant='subheading' align='left'> {topJob?.employer?.employer_name}</Typography></Link>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <Typography className='text-left text-truncate' style={{ maxWidth: '15rem' }}>{topJob?.job_title}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <Typography variant='subheading' align='left'>{topJob?.minimum_salary} - {topJob?.maximum_salary} {topJob?.salary_currency?.name} / {topJob?.salary_type?.type}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <Typography className='text-left text-truncate' style={{ maxWidth: '15rem', fontSize: 12 }}>{topJob?.job_address}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <span className='candidate-tags' style={{ marginLeft: 0 }}> {topJob?.job_type?.name}</span>
                                                    <span className='candidate-tags'> {topJob?.job_level?.candidate_level_name}</span>
                                                    <span className='candidate-tags'> {topJob?.department?.name}</span>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} mt={1} alignItems={'center'}>
                                                <Grid item>
                                                    <Link to={`/jobs/${topJob?.post_id}`}><Button variant='contained' >Apply</Button></Link>
                                                </Grid>
                                                <Grid item>
                                                    <Link to={'/search/jobs'}><Button variant='outlined'>View More</Button></Link>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant='caption' align='right'>{moment(topJob?.post_date).startOf('day').fromNow()}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Card>

                                    )
                                })
                            }
                        </Stack>
                    </Carousel.Item>
                    {
                        topJobs?.length > 3 ?
                            <Carousel.Item>
                                <Stack direction={'row'} spacing={3} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
                                    {
                                        topJobs?.slice(3, 5).map((topJob, index) => {

                                            return (
                                                <Card key={index} className='atb-card' style={{ display: 'flex', height: '100%', margin: '1rem' }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <Link to={`/employer/profile/${topJob?.author_id}`} className='text-dark'> <Typography variant='subheading' align='left'> {topJob?.employer?.employer_name}</Typography></Link>
                                                        </Grid>
                                                        <Grid item>

                                                        </Grid>

                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <Typography className='text-left text-truncate' style={{ maxWidth: '15rem' }}>{topJob?.job_title}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <Typography variant='subheading' align='left'>{topJob?.minimum_salary} - {topJob?.maximum_salary} {topJob?.salary_currency?.name} / {topJob?.salary_type?.type}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <Typography className='text-left text-truncate' style={{ maxWidth: '15rem', fontSize: 12 }}>{topJob?.job_address}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <span className='candidate-tags' style={{ marginLeft: 0 }}> {topJob?.job_type?.name}</span>
                                                            <span className='candidate-tags'> {topJob?.job_level?.candidate_level_name}</span>
                                                            <span className='candidate-tags'> {topJob?.department?.name}</span>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2} mt={1} alignItems={'center'}>
                                                        <Grid item>
                                                            <Link to={`/jobs/${topJob?.post_id}`}><Button variant='contained' >Apply</Button></Link>
                                                        </Grid>
                                                        <Grid item>
                                                            <Link to={'/search/jobs'}><Button variant='outlined'>View More</Button></Link>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant='caption' align='right'>{moment(topJob?.post_date).startOf('day').fromNow()}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Card>

                                            )
                                        })
                                    }
                                </Stack>
                            </Carousel.Item>
                            :
                            ""

                    }
                    {
                        topJobs?.length > 6 ?
                            <Carousel.Item>
                                <Stack direction={'row'} spacing={3} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
                                    {
                                        topJobs?.slice(5, 7).map((topJob, index) => {
                                            // console.log(topJob)

                                            return (
                                                <Card key={index} className='atb-card' style={{ display: 'flex', height: '100%', margin: '1rem' }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <Link to={`/employer/profile/${topJob?.author_id}`} className='text-dark'><Typography variant='subheading' align='left'> {topJob?.employer?.employer_name}</Typography></Link>
                                                        </Grid>
                                                        <Grid item>

                                                        </Grid>

                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <Typography className='text-left text-truncate' style={{ maxWidth: '15rem' }}>{topJob?.job_title}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <Typography variant='subheading' align='left'>{topJob?.minimum_salary} - {topJob?.maximum_salary} {topJob?.salary_currency?.name} / {topJob?.salary_type?.type}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <Typography className='text-left text-truncate' style={{ maxWidth: '15rem', fontSize: 12 }}>{topJob?.job_address}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <span className='candidate-tags' style={{ marginLeft: 0 }}> {topJob?.job_type?.name}</span>
                                                            <span className='candidate-tags'> {topJob?.job_level?.candidate_level_name}</span>
                                                            <span className='candidate-tags'> {topJob?.department?.name}</span>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={2} mt={1} alignItems={'center'}>
                                                        <Grid item>
                                                            <Link to={`/jobs/${topJob?.post_id}`}><Button variant='contained' >Apply</Button></Link>
                                                        </Grid>
                                                        <Grid item>
                                                            <Link to={'/search/jobs'}><Button variant='outlined'>View More</Button></Link>
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant='caption' align='right'>{moment(topJob?.post_date).startOf('day').fromNow()}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Card>

                                            )
                                        })
                                    }
                                </Stack>
                            </Carousel.Item>
                            :
                            ""

                    }
                </Carousel>

                {/* Mobile View */}
                <Carousel fade prevIcon={<ChevronLeftIcon />} nextIcon={<ChevronRightIcon />} indicators={true} className='d-lg-none'>
                    {
                        topJobs?.map((topJob, index) => {

                            return (
                                <Carousel.Item>
                                    <Stack direction={'row'} spacing={3} sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center' }}>


                                        <Card key={index} className='atb-card' style={{ display: 'flex', height: '100%', margin: '1rem', padding: '1.5rem' }}>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <Link to={`/employer/profile/${topJob?.author_id}`} className='text-dark'><Typography variant='subheading' align='left'> {topJob?.employer?.employer_name}</Typography></Link>
                                                </Grid>
                                                <Grid item>

                                                </Grid>

                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <Typography className='text-left text-truncate' style={{ maxWidth: '15rem' }}>{topJob?.job_title}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <Typography variant='subheading' align='left'>{topJob?.minimum_salary} - {topJob?.maximum_salary} {topJob?.salary_currency?.name} / {topJob?.salary_type?.type}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <Typography className='text-left text-truncate' style={{ maxWidth: '15rem', fontSize: 12 }}>{topJob?.job_address}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <span className='candidate-tags' style={{ marginLeft: 0 }}> {topJob?.job_type?.name}</span>
                                                    <span className='candidate-tags'> {topJob?.job_level?.candidate_level_name}</span>
                                                    <span className='candidate-tags'> {topJob?.department?.name}</span>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} mt={1} alignItems={'center'}>
                                                <Grid item>
                                                    <Link to={`/jobs/${topJob?.post_id}`}><Button variant='contained' >Apply</Button></Link>
                                                </Grid>
                                                <Grid item>
                                                    <Link to={'/search/jobs'}><Button variant='outlined'>View More</Button></Link>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant='caption' align='right'>{moment(topJob?.post_date).startOf('day').fromNow()}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Stack>
                                </Carousel.Item>
                            )
                        })
                    }

                </Carousel>
            </Grid>
        </>
    )
}

export default TopJobs;