import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Style from '../../../css/lp4/lp4.module.css';
import BannerImg from '../../../images/lp4/image/bannerImg.png';
import ButtonIcon from '../../../images/lp4/icon/arrow.png';
import Milvik from '../../../images/lp4/image/milvik.png';
import Arogga from '../../../images/lp4/image/arogga.png';
import CrownCement from '../../../images/lp4/image/crown_cement.png';
import Expo from '../../../images/lp4/image/expo.png';
import Runner from '../../../images/lp4/image/runner.png';
import Rupayan from '../../../images/lp4/image/rupayan.png';
import Sara from '../../../images/lp4/image/sara.png';
import SatcGroup from '../../../images/lp4/image/satc_group.png';
import Shikho from '../../../images/lp4/image/shikho.png';
import TheWestin from '../../../images/lp4/image/the_westin.png';
import Vivo from '../../../images/lp4/image/vivo.png';
import Date from '../../../images/lp4/image/date.png';
import User1 from '../../../images/lp4/image/user-1.png';
import Slider from 'react-slick';
import ArrowRightIcon from '../../../images/lp4/icon/arrow-right.png';
import Masrafi from '../../../images/lp3/client/mashrafi.png';
import Tarequr from '../../../images/lp3/client/tarequr.png';
import Nowshin from '../../../images/lp3/client/noshin.png';
import jbb1 from '../../../images/lp4/image/jbb1.png';
import jbb2 from '../../../images/lp4/image/jbb2.png';
import jbb3 from '../../../images/lp4/image/jbb3.png';
import jbb4 from '../../../images/lp4/image/jbb4.png';
import jbb5 from '../../../images/lp4/image/jbb5.png';
import jbb6 from '../../../images/lp4/image/jbb6.png';

const sliderData = [
    { id: '1', img: Vivo, name: 'Vivo' },
    { id: '2', img: Expo, name: 'Expo' },
    { id: '3', img: Rupayan, name: 'Rupayan' },
    { id: '4', img: Shikho, name: 'Shikho' },
    { id: '5', img: SatcGroup, name: 'SatcGroup' },
    { id: '6', img: Arogga, name: 'Arogga' },
    { id: '7', img: Sara, name: 'Sara' },
    { id: '8', img: Runner, name: 'Runner' },
    { id: '9', img: CrownCement, name: 'CrownCement' },
    { id: '10', img: Milvik, name: 'Milvik' },
    { id: '11', img: TheWestin, name: 'TheWestin' },
    { id: '12', img: Rupayan, name: 'Rupayan' },
];

const FindJobData = [
    { id: '1', img: jbb1 },
    { id: '2', img: jbb2 },
    { id: '3', img: jbb3 },
    { id: '4', img: jbb4 },
    { id: '5', img: jbb5 },
    { id: '6', img: jbb6 },
   
];


const testimonials = [
    {
        id: '1',
        name: 'Daliya Nowshin Nisha',
        position: 'Executive - Corporate Sales',
        image: Nowshin,
        text:
            'atB Jobs Portal transformed my Job hunt with its user-friendly Interface and vast job listings. I quickly found and secured my dream job within weeks of using the platform! Thank you, atB Jobs Portal, for simplifying my job hunt.',
    },
    {
        id: '2',
        name: 'Masrafi Anam',
        position: 'Mobile App Developer',
        image: Masrafi,
        text:
            'Thanks to atB Jobs, I secured my dream job effortlessly. Their efficient platform and regular updates kept me Informed about relevant opportunities, making the entire process stress-free. I could not be happler with the outcome.',
    },
    {
        id: '3',
        name: 'Md Tarequr Rahman ',
        position: 'Executive - Corporate Sales',
        image: Tarequr,
        text:
            'Grateful to atB Jobs for guiding me to my dream Job effortlessly! Their streamlined platform and proactive approach simplified everything. Highly recommended for anyone seeking career advancement!',
    },
];
const BannerSection = () => {
    return (
        <div className={Style.bannerContainer}>
            <div className={`${Style.containers} ${Style.bannerFlex}`}>
                <div className={Style.bannerFlexLeft}>
                    <p className={Style.activeContent}>
                        স্বপ্ন ছুতে ১ ক্লিক দুরে…
                    </p>
                    <h1 className={`${Style.titleText} ${Style.textCenters}`}>
                        ১৬ হাজার
                        <sup style={{ fontSize: '40px' }}>+</sup> লাইভ জব <br />
                        ১৬ হাজার
                        <sup style={{ fontSize: '40px' }}>+</sup> স্বপ্নের সুযোগ
                    </h1>
                    <p className={`${Style.contentText} ${Style.textCenters}`}>
                        শুধু চাকরি নয়, আবিষ্কার করুন আপনার স্বপ্নের ক্যারিয়ার।
                        আমাদের ১৬ হাজার+ লাইভ জবের মধ্যে আপনার পরবর্তী পদক্ষেপ
                        খুঁজে নিন।
                    </p>

                    <div className={Style.btnPadding}>
                        <Link
                            to="/candidate-register"
                            className={Style.btnsLink}
                        >
                            <span> স্বপ্নের শুরু এখানে</span>
                            <img src={ButtonIcon} alt="icon" />
                        </Link>
                    </div>
                </div>
                <div className={Style.bannerFlexRight}>
                    <img
                        className={Style.bannerImage}
                        src={BannerImg}
                        alt="image"
                    />
                </div>
            </div>
        </div>
    );
};

const SliderSection = () => {
    return (
        <div className={`${Style.containers} ${Style.sliderGrid}`}>
            {sliderData?.map((data) => (
                <div key={data?.id} className={Style.sliderCard}>
                    <img src={data?.img} alt={data?.name} />
                </div>
            ))}
        </div>
    );
};

const FindJobSection = () => {
    return (
        <div className={`${Style.containers} ${Style.findJobContianer}`}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <h2 className={Style.titleColor}>
                    Jobs by <span className={Style.titleText}>Benefits</span>
                </h2>

                <Link className={Style.viewLink} to="/search/jobs">
                    View All
                </Link>
            </div>

            <div className={Style.jobGird}>
                {FindJobData.map((data) => (
                    <Link key={data.id} to="/search/jobs">
                        <div className={Style.jobCard}>
                            <div className={Style.jobImg}>
                                <img src={data.img} alt={`Company ${data.id}`} />
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

const UserSection = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
        // appendDots: (dots) => (
        //     <div
        //         style={{
        //             display: 'flex',
        //             justifyContent: 'center',
        //             padding: '10px',
        //         }}
        //     >
        //         <ul style={{ margin: '0px' }}>{dots}</ul>
        //     </div>
        // ),
        customPaging: (i) => (
            <div
                style={{
                    width: '10px',
                    height: '10px',
                    background: '#ffff',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    transition: 'background 0.3s',
                }}
                className={
                    i === 0
                        ? `${Style.paginationDot} ${Style.active}`
                        : `${Style.paginationDot}`
                }
            ></div>
        ),
    };
    return (
        <div className={Style?.userContainer}>
            <div className={`${Style.containers} ${Style.userWidth}`}>
                <h2
                    className={Style.titleText}
                    style={{ color: '#ffffff', textAlign: 'center' }}
                >
                    Our Happy Users
                </h2>
                <p
                    className={Style.contentText}
                    style={{ color: '#ffffff', textAlign: 'center' }}
                >
                    The following is the assessment of our user of our
                    platforms, what they experience, feel and the impact after
                    using atB.
                </p>

                <div
                    style={{ marginTop: '3rem' }}
                    className={Style.testimonialsGrid}
                >
                    <Slider {...settings}>
                        {testimonials?.map((data) => (
                            <div className={Style.clientReviewCard}>
                                <div>
                                    <img src={data?.image} alt="" />
                                </div>
                                <h4>{data?.name}</h4>
                                <h6>{data?.position}</h6>
                                <p>{data?.text}</p>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
};
const FloatingSection = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const isScrollingDown = window.scrollY > 200;
            setIsVisible(isScrollingDown);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={isVisible ? `${Style.boxs}` : ''}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'center',
                }}
            >
                <Link to="/candidate-register">
                    <button className={Style.buttons}>Register for Free <img src={ArrowRightIcon} alt="Arrow Right" style={{ marginLeft: '10px', height: '24px' }} /></button>
                </Link>
            </div>
        </div>
    );
};
const LP4 = () => {
    return (
        <>
            <BannerSection />
            <SliderSection />
            <FindJobSection />
            <UserSection />
            <FloatingSection />
        </>
    );
};

export default LP4;
