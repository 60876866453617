import React from "react";
import { Link } from 'react-router-dom';
import HeroSection from '../components/HeroSection/HeroSection'

function TermsAndConditionsPage() {

    return (
        <div>
            <HeroSection text={"Terms And Conditions"} />
            <div className="page-wraper">
                <div className="page-content bg-white">
                    <div className="content-block">
                        <div className="section-full content-inner overlay-white-middle">
                            <div className="container">
                                <div className="row align-items-center m-b50">
                                    <div className="col-md-12 col-lg-12 m-b20">
                                        <label className="m-b15">Please read these terms and conditions ("terms and
                                            conditions", "terms") carefully before using {<Link
                                                to={'/'}> www.atb-jobs.com</Link>} website (“website”, "service")
                                            operated by atBJobs ("us", 'we", "our").</label>


                                        <div className='m-b15 mt-4'>
                                            <p><label className="m-b15" style={{fontStyle: 'italic'}}>Terms and
                                                Condition V1.0.2 (Last Updated May 2nd, 2024) </label></p>

                                            <h5>Disclaimer</h5>

                                            <p>Regarding the acquisition of personal information by a third party, our
                                                company assumes no responsibility in the following cases:</p>
                                            <p>(1) When a user discloses personal information to a specific company
                                                using the functions of this service or other means.</p>
                                            <p>(2) If the user is unexpectedly identified based on the user's activity
                                                information or information entered into this service.</p>
                                            <p>(3) When a user provides personal information and that information is
                                                used in an external service linked from this service.</p>
                                            <p>(4) If a third party other than the user obtains information that can
                                                identify an individual (ID, password, etc.).</p>


                                            <h5>Handling of personal information</h5>
                                            <p>(1) Acquisition, use, and provision of personal information</p>
                                            <p>Regarding the acquisition, use, and provision of personal information, we
                                                will handle it appropriately within the scope of the purpose of use
                                                described in "Handling of Personal Information", taking into account the
                                                content and scale of the information service, etc. Our company will not
                                                use acquired or entrusted personal information for any purpose other
                                                than the purpose of use as defined by the terms and conditions agreed by
                                                users. If personal information is to be used for purposes beyond the
                                                intended use, the consent of the individual shall be obtained in
                                                advance.</p>
                                            <p>(2) Proper management of personal information</p>
                                            <p>
                                                <ul>
                                                    <li>We will maintain a safety management system at an appropriate
                                                        and reasonable level to prevent unauthorized access to personal
                                                        information, loss, destruction, falsification, leakage, etc. of
                                                        personal information.
                                                    </li>
                                                    <li>Access to personal information will be limited to the minimum
                                                        necessary number of officers and employees.
                                                    </li>
                                                    <li>When outsourcing the handling of personal information, we will
                                                        conclude a confidentiality agreement and conduct necessary
                                                        audits to ensure its effectiveness.
                                                    </li>
                                                    <li>Our company respects the rights of individuals regarding
                                                        personal information, and will respond to requests for
                                                        disclosure, correction, or deletion of personal information
                                                        through reasonable procedures.
                                                    </li>
                                                </ul>
                                            </p>
                                            <p>(3) Our company may disclose confidential information to our company's
                                                related parties, such as its own officers, employees, lawyers or tax
                                                accountants, consultants, and advisors, only to the extent necessary to
                                                provide this service or as specified in these Terms of Use. The
                                                information shall not be disclosed or leaked to any other third party
                                                without the prior written consent of the user company, unless disclosure
                                                is required by law or by order of a court or government agency and will
                                                not be used for purposes other than providing this service or other
                                                purposes outside the scope of these Terms of Use.</p>

                                            <h5>Intellectual property rights such as copyright and other property
                                                rights</h5>
                                            <p>You agree that all materials, products, and services provided on this
                                                website are the property of atBJobs, its affiliates, directors,
                                                officers, employees, agents, suppliers, or licensors including all
                                                copyrights, trade secrets, trademarks, patents, and other intellectual
                                                property. You also agree that you will not reproduce or redistribute the
                                                atBJobs's intellectual property in any way, including electronic,
                                                digital, or new trademark registrations. You grant atBJobs a
                                                royalty-free and non-exclusive license to display, use, copy, transmit,
                                                and broadcast the content you upload and publish. For issues regarding
                                                intellectual property claims, you should contact the company in order to
                                                come to an agreement.</p>
                                            <p>
                                                <ol>
                                                    <li>The copyright of the deliverables resulting from the outsourced
                                                        work delivered by the Company based on this agreement shall
                                                        belong to the Company, and the Company shall provide the User
                                                        with the right to use the work to the extent necessary for the
                                                        User to use the service effectively.
                                                    </li>
                                                    <li>Of the constituent parts included in the deliverables, the
                                                        copyright and all other intellectual property rights of existing
                                                        components to which the Company has previously held rights or
                                                        those that are commonly used in similar types of work belong to
                                                        the Company.
                                                    </li>
                                                    <li>The Company shall be able to publicly announce that the
                                                        Deliverables were created by itself. Users will in advance agree
                                                        that the Company may use such deliverables (including those
                                                        created by the User) to the extent necessary for atBJobs.com
                                                        operated by the Company and for the advertising purpose of the
                                                        Company.
                                                    </li>
                                                    <li>In addition to what is provided for in this article, if an
                                                        invention, device, design, or other result (hereinafter referred
                                                        to as an "invention, etc.") is generated in the process of
                                                        performing commissioned work, a patent or utility model
                                                        registration for the invention, etc. , the right to receive
                                                        design registration, etc., and the industrial property rights
                                                        and other intellectual property rights (including rights related
                                                        to know-how, etc.) acquired based on such rights shall belong to
                                                        the party to which the person who made the invention, etc.
                                                    </li>
                                                    <li>With respect to materials, data, etc. provided by the User to
                                                        the Company necessary for carrying out the commissioned work,
                                                        the User shall not be responsible for any third party's
                                                        copyrights, patent rights, design rights, or any other
                                                        intellectual property rights. You warrant that you do not
                                                        infringe on any proprietary rights.
                                                    </li>
                                                    <li>When using this service and using materials belonging to our
                                                        company, the materials will not be transferred, but the
                                                        materials will be provided to the service used by the user.
                                                        Users shall be prohibited the secondary use of the materials.
                                                    </li>
                                                    <li>If a third party makes a complaint, demand, injunction, or any
                                                        other claim due to infringement of intellectual property rights
                                                        when the user uses the product, the user and the company will
                                                        discuss and resolve the matter.
                                                    </li>
                                                    <li>Intellectual property rights for photographs, logos, documents,
                                                        etc. provided by information posters to our company that the
                                                        information poster has previously held intellectual property
                                                        rights to
                                                    </li>
                                                </ol>
                                            </p>

                                            <p>(hereinafter referred to as ""information poster property rights"")
                                                belongs to the person. However, intellectual property rights related to
                                                job advertisements and each content included in job
                                                advertisements (including information, documents, photographs, images,
                                                videos, etc.) belong to our company, excluding property rights of
                                                information posters. The author shall not exercise any legal rights in
                                                this regard.</p>
                                            <p>The user and the job publishing Company promise to the other party that
                                                they will not engage in any of the following acts either themselves or
                                                by using a third party.</p>
                                            <p>
                                                <ol>
                                                    <li>Violent demands</li>
                                                    <li>Unreasonable demands beyond legal responsibility</li>
                                                    <li>Acts of threatening behavior or using violence regarding
                                                        transactions.
                                                    </li>
                                                    <li>Spreading rumors, using fraudulent means or force to damage the
                                                        credibility of the other party, or disrupting the other party's
                                                        business.
                                                    </li>
                                                    <li>Other acts similar to the preceding items.</li>
                                                </ol>
                                            </p>
                                            <p>The user company or our company may cancel all or part of this agreement
                                                in writing without any notice if the other party falls under any of the
                                                following items or is reasonably deemed to fall under any of the
                                                following: shall be able to do so.</p>
                                            <p>
                                                <ol>
                                                    <li>In the case of violation of paragraph 1 or the preceding
                                                        paragraph
                                                    </li>
                                                    <li>Against anti-social forces, such as when you or your officers,
                                                        etc. engage in any kind of transaction with anti-social forces
                                                        by investing, lending, providing funds or services, etc. to
                                                        anti-social forces. If the person has a relationship where the
                                                        person is recognized to be involved in the provision of funds,
                                                        etc. or benefits, etc.
                                                    </li>
                                                    <li>Relationships in which the company or its officers, etc. are
                                                        deemed to be using anti-social forces unfairly, such as for the
                                                        purpose of seeking fraudulent profits for the company or a third
                                                        party, or for the purpose of causing damage to a third party. If
                                                        you have
                                                    </li>
                                                    <li>If the person or his/her officers, etc. have a socially
                                                        reprehensible relationship with antisocial forces.
                                                    </li>
                                                    <li>In other cases pursuant to each of the preceding items.</li>
                                                </ol>
                                            </p>
                                            <p>If this Agreement is canceled pursuant to the provisions of the preceding
                                                paragraph, neither the User Company nor the Company shall be responsible
                                                for compensating the other party for any damage caused to the other
                                                party due to such cancellation, and the Company shall not be responsible
                                                for compensating the other party for any damages incurred as a result of
                                                such cancellation. It shall be possible to claim compensation.</p>

                                            <h5>App permissions</h5>
                                            <p>The atBJobs app requires specific permissions to run on Android and
                                                requires access to certain systems within your device. The app can store
                                                your personalization data. A common reason for storage permissions is
                                                that this data helps users get a personalized app experience.</p>

                                            <h5>About usage fees</h5>
                                            <p>The platform is free for all companies and job seekers until further
                                                notice issued by the Company. </p>

                                            <h5>Conditions of use</h5>
                                            <p>By using this website, you certify that you have read and reviewed this
                                                agreement and that you agree to comply with its terms. If you do not
                                                want to be bound by the terms of this Agreement, you are advised to
                                                leave the website accordingly. atbJobs only grants use and access of
                                                this website, its products, and its services to those who have accepted
                                                its terms.</p>

                                            <h5>Privacy policy</h5>
                                            <p>Before you continue using our website, we advise you to read our privacy
                                                policy regarding our user data collection. It will help you better
                                                understand our practices.</p>

                                            <h5>Age restriction</h5>
                                            <p>You must be at least 18 (eighteen) years of age before you can use this
                                                website. By using this website, you warrant that you are at least 18
                                                years of age and you may legally adhere to this Agreement. atBJobs
                                                assumes no responsibility for liabilities related to age
                                                misrepresentation.</p>

                                            <h5>User accounts</h5>
                                            <p>As a user of this website, you may be asked to register with us and
                                                provide private information. You are responsible for ensuring the
                                                accuracy of this information, and you are responsible for maintaining
                                                the safety and security of your identifying information. You are also
                                                responsible for all activities that occur under your account or
                                                password.</p>
                                            <p>If you think there are any possible issues regarding the security of your
                                                account on the website, inform us immediately so we may address them
                                                accordingly.</p>
                                            <p>We reserve all rights to terminate accounts or remove content and cancel
                                                orders at our sole discretion.</p>

                                            <h5>Applicable law</h5>
                                            <p>By visiting this website, you agree that the laws of the People's
                                                Republic of Bangladesh (?), without regard to principles of conflict
                                                laws, will govern these terms and conditions, or any dispute of any sort
                                                that might come between atBJobs and you, or its business partners and
                                                associates.</p>

                                            <h5>Indemnification</h5>
                                            <p>You agree to indemnify atBJobs and its affiliates and hold atBJobs
                                                harmless against legal claims and demands that may arise from your use
                                                or misuse of our services. We reserve the right to select our own legal
                                                counsel.</p>

                                            <h5>Limitation on liability</h5>
                                            <p>atBJobs is not liable for any damages that may occur to you as a result
                                                of your misuse of our website.</p>

                                            <label style={{fontStyle: 'italic'}}>atBJobs reserves the right to edit,
                                                modify, and change this Agreement at any time. We shall let our users
                                                know of these changes through electronic mail. This Agreement is an
                                                understanding between atBJobs and the user, and this supersedes and
                                                replaces all prior agreements regarding the use of this website.</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsAndConditionsPage