import React, { useEffect, useState, useContext } from 'react'
import HeroSection from '../components/HeroSection/HeroSection'
import { getAllCategories } from './../../fetchAPI'
import { JobSearchContext } from '../context/JobSearchContext'
import { Link } from 'react-router-dom'
import GlobalLoader from '../components/GlobalLoader'
import NoResultsFound from '../components/NoResultsFound'

function ExploreCategories() {

    const [categoriesList, setCategoriesList] = useState('')
    const [categoryLoading, setCategoryLoading] = useState(false)

    const { setSearchDepartment, setDefaultSelectDepartment } = useContext(JobSearchContext)


    useEffect(() => {
        gettingCategoriesList()
    }, [])

    const gettingCategoriesList = () => {
        setCategoryLoading(true)
        getAllCategories().then(response => {
            if (response && response.success) {
                setCategoriesList(response?.response?.data)
                setCategoryLoading(false)
            }
            else {
                console.log('error: ', response)
            }
        })
    }

    return (
        <div>
            <HeroSection path='/v2/images/categoriesHeroSection.png' text="Categories" />
            {categoryLoading ? <GlobalLoader height='50vh' /> : 
            <div className='container mt-5'>
            <div className='row d-flex flex-wrap'>
                {categoriesList.length > 0 ?
                    categoriesList.map((data) => (
                        <div className='col-lg-3 col-md-4 d-flex'>
                            <Link 
                                to={{
                                    pathname: '/search/jobs',
                                    state: { fromHome: true }
                                }}  
                                onClick={() => {
                                    setSearchDepartment(data.id);
                                    setDefaultSelectDepartment({ label: data.name, value: data.id });
                                }}
                                className='d-flex align-items-stretch w-100'
                            >
                                <div className='category-card text-center mt-5 d-flex flex-column' style={{ padding: '60px 20px', border: '1px solid #1167b1', borderRadius: '20px', flex: 1 }}>
                                    <div className='category-name m-t15'>
                                        <h4 style={{ color: '#03254c', overflow: "hidden" }}>{data.name}</h4>
                                        <p>{data?.job_count} Open Jobs <br />
                                            {data?.total_vacancy ?? 0} Vacancies</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )) : <NoResultsFound />
                }
            </div>
                <div className='mb-5'></div>
            </div>
            }
        </div>
    )
}

export default ExploreCategories