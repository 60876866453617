import React, { createContext, useEffect, useState, useContext, useMemo } from "react";
import { getCandidateSelf, getAllCandidates, getCandidateSaveJobInfo, getProfilePercentage } from "./../../fetchAPI";
import { ProfileStrengthContext } from "./ProfileStrengthContext";
import { UserContext } from "./UserContext";

export const CandidateContext = createContext();

const CandidateContextProvider = (props) => {

  const [data, setData] = useState();
  const [candidateData, setCandidateData] = useState({ 'id': 0 });
  const [candidates, setCandidates] = useState('');
  const [updateCandidateBasicInfo, setUpdateCandidateBasicInfo] = useState('');
  const [candidateType, setCandidateType] = useState('')

  const [candLoader, setCandLoader] = useState(false)

  const [candidateDataLoading, setCandidateDataLoading] = useState(true)


  const [followEmployerIds, setFollowEmployerIds] = useState([])
  const [savedJobs, setSavedJobs] = useState('')
  const [savedJobIds, setSavedJobIds] = useState([])
  const [appliedJobsIds, setAppliedJobIds] = useState([])
  const [profileStrength, setprofileStrength] = useState('')

  const { setStrength } = useContext(ProfileStrengthContext)
  const { userData } = useContext(UserContext)

  useEffect(() => {
    setUpdateCandidateBasicInfo({
      ...updateCandidateBasicInfo,
      'first_name': candidateData.first_name,
      'profession': candidateData.profession,
      'dob': candidateData.dob,
      'present_salary': candidateData.present_salary,
      'expected_salary': candidateData.expected_salary,
      'phone_no': candidateData.phone_no,
      'address': candidateData.address,
      'gender': candidateData.gender,
      'level': candidateData.level?.id,
      'qualification': candidateData.qualification?.id,
      'candidate_types': candidateData.candidate_types?.map(val => val.id).toString(),
      'intro': candidateData.intro,
      'martial_status': candidateData.martial_status,
      'searching_for_job_status': candidateData.searching_for_job_status
    })
  }, [candidateData]);

  useEffect(() => {
    if (candidateData.id !== 0) {
      getProfilePercentage().then((response) => {
        if (response && response.success) {
          setStrength(response.response.data)
          setprofileStrength(response.response.data)
        }
        else {
          // console.log('errors: ', response);
        }
      })

      settingSavedJobs()
    }

  }, [candidateData])




  const settingCandidateData = async () => {
    setCandLoader(true)
    // getting Candidate Data 
    await getCandidateSelf().then((response) => {
      if (response && response.success) {
        setCandidateData(response.response.data);
        setFollowEmployerIds(response.response.data.follow_employers?.map(val => val.id))
        setAppliedJobIds(response.response.data?.appliedJobIds)
        setCandidateDataLoading(false)
        setCandLoader(false)
      }
      else {
        // console.log('Set Candidate By ID In State Error:', response);
        setCandLoader(false)

      }
    })
  }

  useEffect(() => {
    if (userData && +userData.user_role_id === 2) { //added a + to ensure that the value is always a number
      settingCandidateData()
    }
  }, [userData])

  // Get All Candidates 
  const settingCandidatesData = () => {

    // getting Candidates Data 
    getAllCandidates(data).then((response) => {
      if (response && response.success) {
        // console.log("response: ", response.response.data)
        setCandidates(response.response.data);
      }
      else {
        // console.log('Set All Candidates in State Error:', response);
      }
    })
  }

  const settingSavedJobs = () => {
    getCandidateSaveJobInfo(candidateData.id).then((response) => {
      if (response && response.success) {
        setSavedJobs(response.response.data?.saved_jobs);
        setSavedJobIds(response.response.data.saved_jobs?.map(val => val.post_id))
      }
      else {
        // console.log('errors: ', response);
      }
    })
  }


  const contextValues = useMemo(() => {
    return {
      candidateData, setCandidateData,
      settingCandidateData,
      candidates,
      settingCandidatesData,
      updateCandidateBasicInfo, setUpdateCandidateBasicInfo,
      candidateDataLoading, setCandidateDataLoading,
      candidateType, setCandidateType,
      savedJobs, settingSavedJobs,
      profileStrength,
      savedJobIds,
      followEmployerIds,
      appliedJobsIds,
      setAppliedJobIds,
      candLoader
    }
  }, [settingCandidateData])

  return (
    <CandidateContext.Provider value={contextValues}>
      {props.children}
    </CandidateContext.Provider>
  );

};

export default CandidateContextProvider;