import React from "react"

export default function EditSVG() {
    return (

        <svg style={{cursor:'pointer'}} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="16" fill="#E5F5FF" />
            <g clip-path="url(#clip0_1941_7666)">
                <path d="M25.5262 8.83126C25.5337 9.55204 25.2567 10.2482 24.7524 10.7765C24.6852 10.8485 24.6134 10.917 24.5426 10.9858C20.2053 15.2032 15.8686 19.4208 11.5326 23.6387C11.3561 23.8156 11.1311 23.9396 10.8842 23.9958C9.57455 24.3056 8.26809 24.6262 6.96024 24.9459C6.27251 25.1129 5.8682 24.7207 6.03909 24.0552C6.37161 22.762 6.70305 21.4685 7.03341 20.1747C7.07791 19.9844 7.17699 19.8104 7.31916 19.6726C11.7006 15.4148 16.0811 11.1558 20.4606 6.89551C21.906 5.49154 24.2045 5.77881 25.1678 7.47412C25.4027 7.89 25.526 8.35677 25.5262 8.83126ZM7.58591 23.446C8.58069 23.2033 9.5287 22.9709 10.4776 22.7417C10.5513 22.7203 10.6173 22.6791 10.668 22.6229C15.0105 18.3987 19.3535 14.1738 23.6969 9.9484C24.3365 9.32567 24.3388 8.36972 23.7219 7.765C23.0986 7.15352 22.1056 7.15397 21.4596 7.78166C17.1195 11.9978 12.7804 16.2157 8.44222 20.4354C8.3885 20.4881 8.33385 20.5367 8.31347 20.6155C8.07543 21.5454 7.83507 22.4738 7.58591 23.446Z" fill="#0275D8" />
                <path d="M20.9945 25.0001C19.4366 25.0001 17.879 25.0001 16.3216 25.0001C15.8794 25.0001 15.5885 24.7749 15.5547 24.4111C15.5365 24.249 15.5821 24.0862 15.6825 23.9556C15.7829 23.825 15.9306 23.7362 16.0956 23.7073C16.1866 23.6916 16.2788 23.6843 16.3712 23.6857C19.4633 23.6857 22.5555 23.6857 25.6479 23.6857C26.1138 23.6857 26.3857 23.8739 26.4515 24.2355C26.4679 24.3205 26.4664 24.4079 26.4471 24.4924C26.4278 24.5768 26.391 24.6566 26.3391 24.7268C26.2871 24.797 26.221 24.8563 26.1448 24.9009C26.0686 24.9456 25.9838 24.9747 25.8957 24.9866C25.7963 24.9979 25.6961 25.0023 25.5961 24.9996C24.0631 25.0002 22.5293 25.0004 20.9945 25.0001Z" fill="#0275D8" />
            </g>
            <defs>
                <clipPath id="clip0_1941_7666">
                    <rect width="20.4615" height="19" fill="white" transform="translate(6 6)" />
                </clipPath>
            </defs>
        </svg>

    )
}