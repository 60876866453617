import global_en_homepage from './v2/translations/en/homepage/global.json'
import global_bg_homepage from './v2/translations/bg/homepage/global.json'
import button_en from './v2/translations/en/button/global.json'
import button_bg from './v2/translations/bg/button/global.json'
import header_en from './v2/translations/en/header/global.json'
import header_bg from './v2/translations/bg/header/global.json'
import footer_en from "./v2/translations/en/footer/global.json"
import footer_bg from './v2/translations/bg/footer/global.json'

const translationResources = {
    en : {
      homepage : global_en_homepage,
      button : button_en,
      header : header_en,
      footer : footer_en
    },
    bg : {
      homepage : global_bg_homepage,
      button : button_bg,
      header : header_bg,
      footer : footer_bg
    }
}

export default translationResources