import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getAllEmployerImage, getTopFeaturedCompanies } from '../../../fetchAPI'
import { Link } from 'react-router-dom'
import '../HomePageComponents/EmployerImageSlider.css'
import { Box, Button, Card, Container, Grid, Stack, Tooltip, CardHeader, Avatar, Typography, Skeleton } from '@mui/material'
import { DefaultParams } from '../Slider/SliderFunctions'
import SliderComponent from '../Slider/Slider'
import { useTranslation } from 'react-i18next'
import TopCompanyCard from '../EmployerComponents/TopCompanyCard'
import CandidateContextProvider from '../../context/CandidateContext'
import SuccessAlert from '../Alerts/SuccessAlert'
import ErrorAlert from '../Alerts/ErrorAlert'

const JobCardSkeleton = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          border: '1px solid #e0e0e0',
          padding: 0,
           width: {
            xs: '100%',
            sm: '90%',
            md: 220,
            lg: 280,
            xl: 350,
          }
        }}
      >
        <Skeleton variant="rounded" width="100%" height={200} animation="wave" />
        <Box sx={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: "space-between", mt: 2, px: {xs:1, xl: 4} }}>
            <Skeleton variant="rounded" width={60} height={60} animation="wave" />
            <Skeleton variant="text" width={200} height={20} animation="wave" />
        </Box>
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", rowGap: 2, my: 3}}>
            <Box sx={{display: "flex", flexDirection: "row", columnGap: 1}}>
                <Skeleton variant="circular" width={20} height={20} animation="wave" />
                <Skeleton variant="text" width={100} height={20} animation="wave" />
            </Box>
            <Skeleton variant="rounded" width={100} height={25} animation="wave" />
        </Box>
      </Box>
    );
  };

function EmployerImageSlider() {

    const { data, isLoading, status } = useQuery('topFeaturedCompanies', getTopFeaturedCompanies, { keepPreviousData: true, staleTime: Infinity })
    const [sliderData, setSliderData] = useState([]);
    const [slider, setSlider] = useState(null);

    const [responseMessage, setResponseMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        if (data) {
            let itemCountInSingleColumn = 1
            let dataRows = data.response?.data;
            let sliderA = [];
            for (let i = 0, k = 0; i < dataRows?.length; i = i + itemCountInSingleColumn, k++) {
                sliderA[k] = [];
                for (let j = 0; j < itemCountInSingleColumn; j++) {
                    if (dataRows[i + j])
                        sliderA[k][j] = dataRows[i + j];
                }
            }
            setSliderData(sliderA);
        }
    }, [data])


    useEffect(() => {
        if (sliderData.length > 0) {
            let slidesComponent = sliderData.map((sitem, sindex) => {
                return (
                    <div key={sindex} className='text-c'>
                        {
                            sitem.map((item, index) => {
                                return (
                                    <TopCompanyCard item={item} key={index} setOpenAlert={setOpenAlert} setOpenErrorAlert={setOpenErrorAlert} setResponseMessage={setResponseMessage} setErrorMessage={setErrorMessage} />
                                )
                            })
                        }
                    </div>
                )
            });

            setSlider(<SliderComponent slides={slidesComponent} params={{
                ...DefaultParams, breakpoints: {
                    400: {
                        slidesPerView: 1
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    992: {
                        slidesPerView: 2,
                    },
                    1200: {
                        slidesPerView: 3,
                    },
                    1400: {
                        slidesPerView: 4,
                    },
                    3000: {
                        slidesPerView: 5,
                    },
                },
                virtual: false,
            }} />);
        }
    }, [sliderData])

    // translations
    const { t } = useTranslation(["homepage", "button"])

    return (
        <Box className='container' sx={{marginTop: "130px", '@media (max-width: 991px)': {marginTop: "50px"},}}>
            <Grid className='p-3' container spacing={2} sx={{ justifyContent: { xs: 'center', md: 'space-between' }, alignItems: "center" }}>
            {
                isLoading ?
                    <>
                        <Grid item>
                            <Skeleton animation='wave' variant="rounded" width={400} height={40} />
                        </Grid>
                    </>
                    :
                    <Typography variant='h3' className='responsive_header' sx={{ fontSize: {xs: '20px', sm: '20px', lg: '28px'}, paddingLeft: "15px", marginTop: "25px" }}>{t("homepage:top-feature-companies.title")}</Typography>
            }
                <Grid item>
                    {isLoading ? 
                        <Skeleton animation='wave' variant="rounded" width={150} height={50} sx={{ display: { xs: 'none', md: 'flex' } }} />
                        :
                    <div>
                        <Link to='/company/list?top_companies=true'><Button className='d-none d-lg-block' size='large' variant='outlined'>{t("button:explore-all.title")}</Button></Link>
                    </div>
                    }
                </Grid>
            </Grid>
            <CandidateContextProvider>
                <Box sx={{marginTop: "40px", '@media (max-width: 991px)': {marginTop: "40px"}}}>
                    {isLoading ?
                    <Box sx={{display: 'flex', justifyContent: "space-between", alignItems: "center", columnGap: 2}}>
                        <JobCardSkeleton />
                        <Box sx={{display: {xs: "none", md: "block"}}}>
                        <JobCardSkeleton />
                        </Box>
                        <Box sx={{display: {xs: "none", md: "block"}}}>
                        <JobCardSkeleton />
                        </Box>
                        <Box sx={{display: {xs: "none", lg: "block"}}}>
                        <JobCardSkeleton />
                        </Box>
                    </Box> 
                    : 
                        slider}
                </Box>
            </CandidateContextProvider>

            <div className='text-c'>
                {isLoading ? 
                     <Skeleton animation='wave' variant="rounded" width={150} height={50} sx={{ display: { xs: 'none', md: 'flex' }, marginY: "30px" }} />
                    :
                    <Link to='/company/list?top_companies=true'><Button className='d-block d-lg-none' size='large' variant='outlined' sx={{ marginTop: '110px !important' }}>{t("button:explore-all.title")}</Button></Link>
                }
            </div>

            <SuccessAlert open={openAlert} message={responseMessage} onHide={() => setOpenAlert(false)} />
            <ErrorAlert open={openErrorAlert} message={errorMessage} onHide={() => setOpenErrorAlert(false)} />
        </Box>
    )
}

export default EmployerImageSlider