import React, { useState, useEffect, useContext } from "react";
import "./CandidateSavedJobs.css";

import { Card } from "react-bootstrap";
import { CandidateContext } from "../../../context/CandidateContext";
import { getCandidateSaveJobInfo } from "../../../../fetchAPI";
import { Grid } from "@mui/material";
import NoResultsFound from "../../NoResultsFound";
import JobsCard from "../CandidateAppliedJobs/JobsCard";
import GlobalLoader from "../../GlobalLoader";

function CandidateSavedJobs() {
  const { candidateData, settingCandidateData } = useContext(CandidateContext);
  const [gridView, setGridView] = useState(true);

  useEffect(() => {
    setGridView(true);
  }, [window.innerWidth]);
  const [savedJobs, setSavedJobs] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    getCandidateSaveJobInfo(candidateData.id).then((response) => {
      if (response.success) {
        setSavedJobs(response.response.data.saved_jobs);
        setLoader(false);
      } else {
        // console.log('errors: ', response);
        setLoader(false);
      }
    });
  }, [candidateData]);

  return (
    <Card className="shadow-sm bg-white my-4 mb-md-4 my-md-0">
      <Card.Header className="bg-white pt-4 px-3">
        <h4 className="font-epilogue" style={{ fontWeight: 700 }}>
          Saved Jobs
        </h4>
        <p
          className="font-poppins text-primary mb-3"
          style={{ fontSize: "14px", fontWeight: 700 }}
        >
          You have Save {savedJobs?.length} jobs
        </p>
      </Card.Header>
      <Card.Body className="bg-white">
        {loader ? (
          <GlobalLoader height="60vh" />
        ) : (
          <Grid container spacing={2}>
            {savedJobs?.length > 0 ? (
              savedJobs?.map((item, index) => {
                return (
                  <Grid item xs={12} md={gridView ? 6 : 12}>
                    <JobsCard
                      item={item}
                      index={index}
                      grid={gridView}
                      candidateData={candidateData}
                      settingCandidateData={settingCandidateData}
                    />
                  </Grid>
                );
              })
            ) : (
              <NoResultsFound />
            )}
          </Grid>
        )}
      </Card.Body>
    </Card>
  );
}

export default CandidateSavedJobs;
