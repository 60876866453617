import { Box, Container, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import EmployerProfileSidebar from "../../components/EmployerComponents/EmployerProfile/EmployerProfileSidebar";
import { getEmployerBySlug, postActivityLogs } from "../../../fetchAPI";
import { useParams } from 'react-router-dom'
import EmployerInformation from "../../components/EmployerComponents/EmployerProfile/EmployerInformation";
import CandidateContextProvider from "../../context/CandidateContext";
import JobSearchContextProvider from "../../context/JobSearchContext";
import PageNotFound from "../PageNotFound";
import GlobalLoader from "../../components/GlobalLoader";
import { fetchIpAddress } from "../../helpers/helperFunctions";

export default function EmployerProfilev2() {

    const { slug } = useParams()
    const [employerInfo, setEmployerInfo] = useState([])
    const [loading, setLoading] = useState(false)
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const pageTitle = 'employer-profile'
    const currentPageURL = window.location.href;
    const userAgent = navigator.userAgent;

    const getEmployerProfile = () => {
        setLoading(true)
        getEmployerBySlug(slug)
            .then(response => {
                if (response && response.success) {
                    setEmployerInfo(response.response.data)
                    setLoading(false)
                } else {
                    setLoading(false)
                    setResponseMessage(response?.errors[0].message)
                    setOpenErrorAlert(true)

                }
            })
    }
    useEffect(() => {
        getEmployerProfile()
    }, [])

    useEffect(() => {
        const logActivity = async () => {
          const ipAddress = await fetchIpAddress();
          const params = { 
            page_title: pageTitle,
            action_type: 'view',
            page_url: currentPageURL,
            ip: ipAddress,
            job_post_id: "",
            platform: "web",
            user_agent: userAgent
          };
          await postActivityLogs(params);
        };

        logActivity();
      }, []); 

    if(!employerInfo && !loading) return <PageNotFound />

    return (
        <>
        {loading ? <GlobalLoader height="85vh" /> : 
        <Box
            sx={{
                backgroundColor: '#F9F9F9'
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    height: '150px',
                    backgroundImage: 'url(/v2/images/employer_profile_banner.png)'
                }}
            />

            <Box
                sx={{
                    position: 'relative',
                    top: { xs: '-285px', md: '-255px' },
                    display: 'flex',
                    marginTop: '150px',
                    marginLeft: '30px',
                    marginRight: '30px',
                    zIndex: 1000,
                    '@media (max-width: 600px)': {
                        marginLeft: 1,
                        marginRight: 1,
                    },
                }}
            >
                <Grid
                    container
                    spacing={0}
                    justifyContent={{ xs: 'center', md: 'space-between' }}
                >
                    <Grid item md={3}>
                        <CandidateContextProvider >
                            <EmployerProfileSidebar loading={loading} employerInfo={employerInfo} />
                        </CandidateContextProvider>
                    </Grid>
                    <Grid item md={9} sx={{ width: '100%' }}>
                        <EmployerInformation loading={loading} employerInfo={employerInfo} />
                    </Grid>
                </Grid>
            </Box>
        </Box>
        }
        </>
    )
}