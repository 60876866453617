import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { CandidateContext } from '../../../../context/CandidateContext'
import './UpdateCandidateProfile.css'
import { updateCandidateBasicInfo, uploadProfilePic } from '../../../../../fetchAPI'
import SuccessAlert from '../../../Alerts/SuccessAlert'
import ErrorAlert from '../../../Alerts/ErrorAlert'
import { UserContext } from '../../../../context/UserContext'
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import { Box, Button, Grid, List, ListItem, ListItemIcon, ListItemText, Stack, TextField, Tooltip, Typography } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CreateIcon from '@mui/icons-material/Create';
import { EmailOutlined, Phone, ShowerRounded } from '@mui/icons-material'
import { makeStyles } from '@material-ui/core/styles';
// import { white } from '@mui/material/colors'

const useStyles = makeStyles((theme) => ({
	textField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'white',
			},
			'&:hover fieldset': {
				borderColor: 'white',
			},
			'&.Mui-focused fieldset': {
				borderColor: 'white',
			},
		},
		'& .MuiFormLabel-root': {
			color: 'white',
		},
		'& .MuiInputBase-input': {
			color: 'white',
		},
	},
	typography: {
		border: '1px solid transparent',
		borderRadius: 4,
		padding: 1,
		'&:hover': {
			border: '1px solid lightgray',
			cursor: 'pointer'
		}
	},
	badge: {
		'&:hover': {
			cursor: 'pointer'
		}
	},
	whiteIcon: {
		color: 'white !important'
	}
}));


function CandidateUpdateProfileCover() {

	const { candidateData, settingCandidateData } = useContext(CandidateContext)

	const [disableUpdate, setDisableUpdate] = useState(true)
	const [updateData, setUpdateDate] = useState('')
	const [showNameTextField, setShowNameTextField] = useState(false);
	const [showTextField, setShowTextField] = useState(false);
	const [showProfessionField, setShowProfessionField] = useState(false);
	const [showNumberField, setShowNumberField] = useState(false)
	const classes = useStyles();

	// Handling OutFocus Clicks
	// const wrapperRef = useRef(null);

	// const handleClickOutside = (event) => {
	// 	if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
	// 		// Do something outside click is detected
	// 		console.log('You clicked outside of me!');
	// 		setShowTextField(!showTextField);
	// 		console.log(showTextField)
	// 	}
	// };

	// useEffect(() => {
	// 	document.addEventListener('click', handleClickOutside);
	// 	return () => {
	// 		document.removeEventListener('click', handleClickOutside);
	// 	};
	// }, []);


	const handleChange = (name) => (event) => {
		setUpdateDate({ [name]: event.target.value })
	}


	const [openAlert, setOpenAlert] = useState(false)
	const [openErrorAlert, setOpenErrorAlert] = useState(false)
	const [responseMessage, setResponseMessage] = useState('')

	const updateCandidate = (candId, updateParameters) => {
		updateCandidateBasicInfo(candId, updateParameters)
			.then(response => {
				if (response && response.success) {
					setResponseMessage(response.response.message)
					setOpenAlert(true)
					settingCandidateData()
					setDisableUpdate(true)
				} else {
					// console.log('Error', response)
					const message = response.errors?.map(msg => {
						setResponseMessage(msg.message)
					})
					setOpenErrorAlert(true)
					// props.renderState()
				}
			})
	}

	const { profilePic, setSuccessTwister, loading, successTwister, successMessage, setSelectedFile } = useContext(UserContext)

	const SmallAvatar = styled(Avatar)(({ theme }) => ({
		width: 22,
		height: 22,
		border: `2px solid ${theme.palette.background.paper}`,
	}));

	const nameRef = useRef(null)
	const professionRef = useRef(null)
	const addressRef = useRef(null)
	const numberRef = useRef(null)

	useEffect(()=>{
		if(showNameTextField){
			nameRef.current.firstChild.firstChild.focus()
		}
	},[showNameTextField])

	useEffect(()=>{
		if(showProfessionField){
			professionRef.current.firstChild.focus()
		}
	},[showProfessionField])

	useEffect(()=>{
		if(showTextField){
			addressRef.current.firstChild.firstChild.focus()
		}
	},[showTextField])

	useEffect(()=>{
		if(showNumberField){
			numberRef.current.firstChild.firstChild.focus()
		}
	},[showNumberField])

	return (
		<>
			{/* Desktop View */}
			<div className='atb-card d-none d-lg-block' style={{ height: 315, backgroundImage: 'url(/v2/images/cover-photo2.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: '100% 250px' }}>
				<Grid container spacing={0} sx={{ display: { xs: 'none', md: 'flex' }, marginTop: 7 }}>
					<Grid md={2} sx={{ display: 'flex', justifyContent: "flex-end", marginRight: 3, marginLeft: 3 }}>
						<Tooltip title="Change Profile Picture">
							<label htmlFor='upload-pic'>
								<Badge
									className={classes.badge}
									overlap="circular"
									anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
									badgeContent={
										<SmallAvatar alt="Remy Sharp" src="/v2/icons/photo-camera.png" sx={{ height: "25px", width: "25px", padding: 0.05, backgroundColor: 'white' }} />
									}
								>
									<Avatar alt="Travis Howard" src={profilePic ? profilePic : '/v2/images/candidate-default.png'} sx={{ height: "180px", width: "180px", padding: 0.05, backgroundColor: 'white' }} />
								</Badge>
							</label>
						</Tooltip>
						<input hidden type={"file"} id="upload-pic" onChange={(event) => setSelectedFile(event.target.files[0])} />
					</Grid>
					<Grid md={8} sx={{ display: 'flex', justifyContent: "flex-start", marginTop: candidateData?.first_name || candidateData?.profession ? 5 : 0 }}>
						<Stack spacing={showNameTextField || showProfessionField ? 1 : 0} mt={showNameTextField || showProfessionField ? 0 : 2} mb={showNameTextField || showProfessionField ? 5 : 0} direction="column" justifyContent="center" alignItems="flex-start">
							{
								candidateData?.first_name ?
									showNameTextField ?
										<TextField
											className={classes.textField}
											InputProps={{
												style: {
													fontSize: 20,
												}
											}}
											size='medium'
											id="outlined-basic"
											variant="outlined"
											onChange={handleChange('first_name')}
											onBlur={() => [updateCandidate(candidateData.id, updateData)][setShowNameTextField(!showNameTextField)]}
											defaultValue={candidateData.first_name}
											focused
											ref={nameRef}
										/>
										:
										<Tooltip title='Edit Name'>
											<Typography className={classes.typography} onClick={() => setShowNameTextField(true)} variant='h4' color='white'>{candidateData.first_name}</Typography>
										</Tooltip>
									:
									<TextField
										className={classes.textField}
										size='medium'
										id="outlined-basic"
										variant="outlined"
										onChange={handleChange('first_name')}
										onBlur={() => [updateCandidate(candidateData.id, updateData)]}
										label="Enter your Name"
										sx={{ marginBottom: 1 }}

									/>

							}
							{
								candidateData?.profession ?
									showProfessionField ?
										<TextField
											className={classes.textField}
											size='small'
											id="outlined-basic"
											variant="outlined"
											onChange={handleChange('profession')}
											onBlur={() => [updateCandidate(candidateData.id, updateData)][setShowProfessionField(!showProfessionField)]}
											defaultValue={candidateData.profession}
											label="Edit Profession"
										ref={professionRef}
										/>
										:
										<Tooltip title='Edit Designation'>
											<Typography className={classes.typography} onClick={() => setShowProfessionField(true)} variant='h5' color='white'>{candidateData.profession}</Typography>
										</Tooltip>
									:
									<TextField
										className={classes.textField}
										size='small'
										id="outlined-basic"
										variant="outlined"
										onChange={handleChange('profession')}
										label="Add Profession"
										onBlur={() => [updateCandidate(candidateData.id, updateData)]}
									/>

							}
						</Stack>
					</Grid>
					{/* <Grid md="auto" sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-end', alignContent: 'flex-end', mb: 0.5, maxWidth: showTextField ? '100% !important' : '45% !important', width: showTextField ? '100% !important' : '45% !important', }}> */}
					<Grid md="auto" sx={{ flexGrow: 1, maxWidth: '100% !important', width: '100% !important' }}>
						<Grid container spacing={0} sx={{ justifyContent: 'flex-end', alignItems: candidateData?.address || candidateData?.email || candidateData?.phone_no ? '' : 'center' }}>
							<LocationOnIcon sx={{ width: '2em', mt: showTextField ? 1 : 0 }} />
							{
								candidateData?.address ?
									showTextField ?
										<TextField
											id="outlined-basic"
											size='small'
											variant="outlined"
											defaultValue={candidateData.address}
											onChange={handleChange('address')}
											onBlur={() => [updateCandidate(candidateData.id, updateData)][setShowTextField(!showTextField)]}
											ref={addressRef}
											focused
										/>
										:
										<Tooltip title='Edit Address'>
											<Typography className={classes.typography} onClick={() => setShowTextField(true)} variant='body2' mr={3}>{candidateData.address}</Typography>
										</Tooltip>
									:
									<TextField
										id="outlined-basic"
										size='small'
										variant="outlined"
										label="Add Address"
										// defaultValue={candidateData.address}
										onChange={handleChange('address')}
										onBlur={() => [updateCandidate(candidateData.id, updateData)]}
									// ref={wrapperRef}
									/>
							}
							<EmailOutlined sx={{ width: '2em', mt: showTextField ? 1 : 0 }} />
							{/* {
								showTextField ?
									<TextField
										id="outlined-basic"
										size='small'
										variant="outlined"
										defaultValue={candidateData.user?.email}
										disabled
										// ref={wrapperRef}
										focused
									/>
									: */}
									<Typography variant='body2' mr={3}>{candidateData.user?.email}</Typography>
							{/* } */}
							<Phone sx={{ width: '2em', mt: showTextField ? 1 : 0 }} />
							{
								candidateData?.phone_no ?
									showNumberField ?
										<TextField
											id="outlined-basic"
											size='small'
											variant="outlined"
											defaultValue={candidateData.phone_no}
											onChange={handleChange('phone_no')}
											onBlur={() => [updateCandidate(candidateData.id, updateData)][setShowNumberField(!showNumberField)]}
											ref={numberRef}
											focused
											sx={{ mb: 5 }}
										/>
										:
										<Tooltip title='Edit Contact Number'>
											<Typography className={classes.typography} onClick={() => setShowNumberField(true)} variant='body2' mr={3}>{candidateData.phone_no}</Typography>
										</Tooltip>
									:
									<TextField
										id="outlined-basic"
										size='small'
										variant="outlined"
										label="Add Contact Number"
										// defaultValue={candidateData.phone_no}
										onChange={handleChange('phone_no')}
										onBlur={() => [updateCandidate(candidateData.id, updateData)]}
									// ref={wrapperRef}
									/>

							}
						</Grid>
					</Grid>
				</Grid>
			</div>

			{/* Mobile View */}
			<div className='atb-card d-lg-none' style={{ height: 630, backgroundImage: 'url(/v2/images/cover-photo2.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: '100% 250px' }}>
				<Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center' }}>
					<Grid container spacing={2}>
						<Grid item xs={12} sx={{ marginTop: 4 }}>
							<Tooltip title="Change Profile Picture">
								<label htmlFor='upload-pic'>
									<Badge
										className={classes.badge}
										overlap="circular"
										anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
										badgeContent={
											<SmallAvatar alt="Remy Sharp" src="/v2/icons/photo-camera.png" sx={{ height: "25px", width: "25px", padding: 0.05, backgroundColor: 'white' }} />
										}
									>
										<Avatar alt="Travis Howard" src={profilePic ? profilePic : '/v2/images/candidate-default.png'} sx={{ height: "180px", width: "180px", padding: 0.05, backgroundColor: 'white' }} />
									</Badge>
								</label>
							</Tooltip>
							<input hidden type={"file"} id="upload-pic" onChange={(event) => setSelectedFile(event.target.files[0])} />
						</Grid>

						<Grid item xs={12} sx={{ marginTop: 4 }}>
							<Stack spacing={1}>
								{
									candidateData?.first_name ?
										showTextField ?
											<TextField
												// className={classes.textField}
												InputProps={{
													style: {
														fontSize: 20
													}
												}}
												size='small'
												id="outlined-basic"
												variant="outlined"
												onChange={handleChange('first_name')}
												onBlur={() => [updateCandidate(candidateData.id, updateData)][setShowTextField(!showTextField)]}
												defaultValue={candidateData.first_name}
												focused
											/>
											:
											<Typography variant='h5'>{candidateData.first_name}</Typography>
										:
										<TextField
											// className={classes.textField}
											InputProps={{
												style: {
													fontSize: 20
												}
											}}
											size='small'
											id="outlined-basic"
											variant="outlined"
											label="Enter your Name"
											onChange={handleChange('first_name')}
											onBlur={() => [updateCandidate(candidateData.id, updateData)]}
										// defaultValue={candidateData.first_name}
										/>

								}
								{
									candidateData?.profession ?
										showTextField ?
											<TextField
												// className={classes.textField}
												size='small'
												id="outlined-basic"
												variant="outlined"
												onChange={handleChange('profession')}
												onBlur={() => [updateCandidate(candidateData.id, updateData)][setShowTextField(!showTextField)]}
												defaultValue={candidateData.profession}
												placeholder='Add Profession'
												// ref={wrapperRef}
												focused
											/>
											:
											<Typography variant='h6'>{candidateData.profession}</Typography>
										:
										<TextField
											// className={classes.textField}
											size='small'
											id="outlined-basic"
											variant="outlined"
											label="Add Profession"
											onChange={handleChange('profession')}
											onBlur={() => [updateCandidate(candidateData.id, updateData)][setShowTextField(!showTextField)]}
											placeholder='Add Profession'
										// ref={wrapperRef}
										// focused
										/>

								}
							</Stack>
						</Grid>

						<Grid item xs={12} sx={{ display: 'flex', marginTop: 0, justifyContent: 'center' }}>
							<Stack spacing={1}>
								<List >
									<ListItem>
										<ListItemIcon>
											<LocationOnIcon />
										</ListItemIcon>
										{
											candidateData?.address ?
												showTextField ?
													<TextField
														id="outlined-basic"
														size='small'
														variant="outlined"
														defaultValue={candidateData.address}
														onChange={handleChange('address')}
														onBlur={() => [updateCandidate(candidateData.id, updateData)][setShowTextField(!showTextField)]}
														focused
													/>
													:
													<ListItemText primary={candidateData.address} />
												:
												<TextField
													id="outlined-basic"
													size='small'
													variant="outlined"
													label="Add Address"
													onChange={handleChange('address')}
													onBlur={() => [updateCandidate(candidateData.id, updateData)]}

												/>

										}
									</ListItem>
									<ListItem>
										<ListItemIcon>
											<EmailOutlined />
										</ListItemIcon>
										{
											showTextField ?
												<TextField
													id="outlined-basic"
													size='small'
													variant="outlined"
													defaultValue={candidateData.user?.email}
													disabled
													focused
												/>
												:
												<ListItemText primary={candidateData.user?.email} />
										}
									</ListItem>
									<ListItem>
										<ListItemIcon>
											<Phone />
										</ListItemIcon>
										{
											candidateData?.phone_no ?
												showTextField ?
													<TextField
														id="outlined-basic"
														size='small'
														variant="outlined"
														defaultValue={candidateData.phone_no}
														onChange={handleChange('phone_no')}
														onBlur={() => [updateCandidate(candidateData.id, updateData)][setShowTextField(!showTextField)]}
													/>
													:
													<ListItemText primary={candidateData.phone_no} />
												:
												<TextField
													id="outlined-basic"
													size='small'
													variant="outlined"
													label="Enter Contact Number"
													onChange={handleChange('phone_no')}
													onBlur={() => [updateCandidate(candidateData.id, updateData)]}
												/>

										}
									</ListItem>
								</List>
							</Stack>
						</Grid>

						<Grid item xs={12}>
							<Button
								variant='contained'
								startIcon={<CreateIcon className={classes.whiteIcon} />}
								fullWidth
								onClick={() => setShowTextField(!showTextField)}
							>
								{
									showTextField ? "Cancel" : "Edit Profile"
								}
							</Button>
						</Grid>
					</Grid>
				</Box>


				<SuccessAlert open={openAlert} message={responseMessage} onHide={() => setOpenAlert(false)} />
				<SuccessAlert open={successTwister} message={successMessage} onHide={() => setSuccessTwister(false)} />
				<ErrorAlert open={openErrorAlert} message={responseMessage} onHide={() => setOpenErrorAlert(false)} />
			</div>
		</>
	)
}

export default CandidateUpdateProfileCover