import React, {useState, useContext} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '1px solid transparent',
    boxShadow: 24,
    p: 4,
};


function ComingSoonOverlay(props) {

    return (
        <div>
            <div>
                <Modal
                    open={props.open}
                    onClose={props.onClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Fade in={props.open}>
                        <Box sx={style}>
                            <img src='/v2/images/register.jpg'/>
                            <h3 className='mb-5 text-center' style={{ color: 'hsl(217, 10%, 50.8%)' }}>
                               Coming Soon!
                            </h3>
                        </Box>
                    </Fade>
                </Modal>
            </div>
        </div>

    )
}

export default ComingSoonOverlay