import React, { useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import { changePassword } from "../../fetchAPI";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useAlert } from "../context/AlertContext";
import { validatePassword } from "../helpers/helperFunctions";

function ChangePassword() {
  const alertContext = useAlert()
  const user = JSON.parse(Cookies.get("user"));
  const user_email = user.email;
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);
  const [validationMessages, setValidationMessages] = useState("");
  const [passwordInput, setPasswordInput] = useState({
    current_password: "",
    new_password: "",
    new_confirm_password: "",
    email: user_email,
  });
  const [validationStatus, setValidationStatus] = useState({
    minLength: false,
    uppercase: false,
    lowercase: false,
    numbers: false,
    specialChar: false
  });

  const updatingPassword = (body) => (event) => {
    event.preventDefault();
    const allValid = Object.values(validationStatus).every(Boolean);
    if (!allValid) {
        alertContext.setOpenErrorAlert(true);
        alertContext.setResponseMessage(validationMessages);
        return;
    }

    if(passwordInput.new_password === passwordInput.new_confirm_password){
      changePassword(body).then((response) => {
        if (response && response.success) {
          body.new_confirm_password = '';
          alertContext.setResponseMessage(response.response.message);
          alertContext.setOpenAlert(true);
          document.getElementById("password-change-form").reset();
        } else {
          alertContext.setResponseMessage(response.errors[0].message);
          alertContext.setOpenErrorAlert(true);
        }
      });
    }
  };

  const handlePasswordChange = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    const NewPasswordInput = {
      ...passwordInput,
      [passwordInputFieldName]: passwordInputValue,
    };
    setPasswordInput(NewPasswordInput);
  };

  useEffect(() => {
    const { status, messages } = validatePassword(passwordInput.new_password);
    setValidationStatus(status);
    setValidationMessages(messages);
  }, [passwordInput.new_password]);

  return (
    <Card sx={{ p: 2, mx: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "space-between" },
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            // display: { md: "block" },
            fontWeight: 700,
            fontFamily: "Epilogue, sans-serif",
            fontSize: "1.5rem",
            color: "#424447",
            lineHeight: 1.334,
          }}
        >
          Account Settings
        </Typography>
      </Box>
      <Divider sx={{ my: 2 }} />
      <form
        id="password-change-form"
        onSubmit={updatingPassword(passwordInput)}
      >
        <div className="">
          <div className="">
            {/* Current Password  */}
            <div className="form-group m-t20">
              <Grid item lg={6} xs={12} sx={{position: "relative"}}>
                <InputLabel
                  sx={{
                    fontWeight: 600,
                    fontFamily: "Epilogue",
                    fontSize: "14px",
                    color: "#424447",
                  }}
                >
                  Current Password
                </InputLabel>
                <TextField
                  type={showCurrentPassword ? "text" : "password"}
                  name="current_password"
                  onChange={handlePasswordChange}
                  placeholder="Enter Your Current Password"
                  required
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px 14px",
                    },
                  }}
                />
                {showCurrentPassword ? 
                  <VisibilityOutlinedIcon sx={{position: "absolute", top: 29, right: 10, cursor: "pointer", color: "#666666"}} onClick={() => setShowCurrentPassword(!showCurrentPassword)} /> 
                : 
                  <VisibilityOffOutlinedIcon sx={{position: "absolute", top: 29, right: 10, cursor: "pointer", color: "#666666"}} onClick={() => setShowCurrentPassword(!showCurrentPassword)}/>
                }
              </Grid>
            </div>
            {/* New Password  */}
            <div className="form-group">
              <Grid item lg={6} xs={12} sx={{position: "relative"}}>
                <InputLabel
                  sx={{
                    fontWeight: 600,
                    fontFamily: "Epilogue",
                    fontSize: "14px",
                    color: "#424447",
                  }}
                >
                  Create New Password
                </InputLabel>
                <TextField
                  type={showNewPassword ? "text" : "password"}
                  value={passwordInput.password}
                  onChange={handlePasswordChange}
                  name="new_password"
                  placeholder="Enter New Password"
                  fullWidth
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: "10px 14px",
                    },
                  }}
                />
                {showNewPassword ? 
                  <VisibilityOutlinedIcon sx={{position: "absolute", top: 29, right: 10, cursor: "pointer", color: "#666666"}} onClick={() => setShowNewPassword(!showNewPassword)} /> 
                : 
                  <VisibilityOffOutlinedIcon sx={{position: "absolute", top: 29, right: 10, cursor: "pointer", color: "#666666"}} onClick={() => setShowNewPassword(!showNewPassword)}/>
                }
              </Grid>
              <Typography variant='caption' sx={{ color: '#1D1E1B', mt: 2}}>
                <li style={{ color: validationStatus.uppercase ? '#0079C0' : '#1D1E1B' }}>
                  Uppercase letters (A - Z)
                </li>
                <li style={{ color: validationStatus.lowercase ? '#0079C0' : '#1D1E1B' }}>
                  Lowercase letters (a - z)
                </li>
                <li style={{ color: validationStatus.numbers ? '#0079C0' : '#1D1E1B' }}>
                  Numbers (0 - 9)
                </li>
                <li style={{ color: validationStatus.specialChar ? '#0079C0' : '#1D1E1B' }}>
                  Special character (!#$%&@^'?)
                </li>
                <li style={{ color: validationStatus.minLength ? '#0079C0' : '#1D1E1B' }}>
                  At least 8 characters in length
                </li>
              </Typography>
            </div>
          </div>
          <div className="">
            {/* Confirm New Password  */}
            <div className="form-group">
              <Grid item lg={6} xs={12} sx={{position: "relative"}}>
                <InputLabel
                  sx={{
                    fontWeight: 600,
                    fontFamily: "Epilogue",
                    fontSize: "14px",
                    color: "#424447",
                  }}
                >
                  Confirm Password
                </InputLabel>
                <TextField
                  type={showConfirmedPassword ? "text" : "password"}
                  value={passwordInput.new_confirm_password}
                  onChange={handlePasswordChange}
                  name="new_confirm_password"
                  placeholder="Confirm New Password"
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    style: {
                      padding: "10px 14px",
                    },
                  }}
                />
                {showConfirmedPassword ? 
                  <VisibilityOutlinedIcon sx={{position: "absolute", top: 29, right: 10, cursor: "pointer", color: "#666666"}} onClick={() => setShowConfirmedPassword(!showConfirmedPassword)} /> 
                : 
                  <VisibilityOffOutlinedIcon sx={{position: "absolute", top: 29, right: 10, cursor: "pointer", color: "#666666"}} onClick={() => setShowConfirmedPassword(!showConfirmedPassword)}/>
                }
              </Grid>
              <p className="text-danger">{passwordInput.new_confirm_password && (passwordInput.new_password !== passwordInput.new_confirm_password) && "Confirm password is not matched"}</p>
            </div>
          </div>
          <div className="d-flex">
            <Button type="submit" variant="contained">
              {" "}
              <span>Submit</span>{" "}
            </Button>
          </div>
        </div>
      </form>
    </Card>
  );
}

export default ChangePassword;
