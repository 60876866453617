import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Link, useHistory } from 'react-router-dom';
import { registerUser } from '../../fetchAPI';
import { UserContext } from '../context/UserContext';
import Select from 'react-select';
import ErrorAlert from './Alerts/ErrorAlert';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid transparent',
    boxShadow: 24,
    p: 4,
};


function RegisterLoginOverlay(props) {

    let errorsObj = { name: '', email: '', password: '', user_role_id: '' };

    const userRoles = [{ label: 'Candidate', value: 2 }, { label: 'Employer', value: 3 }]

    // UseState Declarations
    const [registerData, setRegisterData] = useState({ name: "", email: "", password: "", user_role_id: "" })
    const [errors, setErrors] = useState(errorsObj);
    const [errorMessage, setErrorMessage] = useState('')
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const [responseMessage, setResponseMessage] = useState(null)
    // const [roles, setRole] = useState();

    // UseContext Declarations
    const { loginData, isLoggedin } = useContext(UserContext);

    // Use History declaration
    const history = useHistory();

    /**
    * Create a function that handle the input event and
    * save the value of that event on an state every time the component change
    * 
    * Setting login information in SignUpInfo state
    * 
    * @param {string} name
    * @param {event} event 
    */

    const handleChange = (name) => (event) => {
        setRegisterData({ ...registerData, [name]: event.target.value })
    }

    const handleChangeSelect = (name) => (event) => {
        setRegisterData({ ...registerData, [name]: event.value })
    }


    /**
    * Function calling the Login API
    * 
    * @param {event} e 
    * @param {object} SignUpInfo 
    */

    function onSignUp(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        // console.log(errorObj);
        if (registerData.name === '') {
            errorObj.name = 'Name is Required';
            error = true;
        }
        if (registerData.email === '') {
            errorObj.email = 'Email is Required';
            error = true;
        }
        if (registerData.password === '') {
            errorObj.password = 'Password is Required';
            error = true;
        }
        if (registerData.user_role_id === '') {
            errorObj.user_role_id = 'User role is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) return;

        registerUser(registerData).then((response) => {
            if (response && response.success) {
                // console.log(response)
                // console.log("Registration Successful")
                history.push("/login")
            }
            else {
                throw Error('Error: ' + response?.errors[0].message)
                // console.log('error:', response.errors)
                // setErrorMessage(response?.errors[0].message)
            }
        })
        .catch(err => {
            // setLoading(false)
            setResponseMessage(err.message);
            setOpenErrorAlert(true);
        })
    }


    return (
        <div>
            <div>
                <Modal
                    open={props.open}
                    onClose={props.onClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Fade in={props.open}>
                        <Box sx={style}>
                            <h3 className='mb-5 text-center' style={{ color: 'hsl(217, 10%, 50.8%)' }}>
                                Register Now!
                            </h3>
                            <form onSubmit={onSignUp}>
                                {/* <!-- Name input --> */}
                                {errorMessage ?
                                    <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
                                        <center>
                                            <small style={{ color: 'red' }}> {errorMessage} </small>
                                        </center>
                                    </div>
                                    : ''}
                                <div className="form-outline mb-4 text-left">
                                    <input type="text" onChange={handleChange("name")} onClick={() => setErrorMessage('')}
                                        className="form-control atb-input-box" placeholder='Name' />
                                    {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
                                    {/* <label className="form-label" for="form3Example3">Email address</label> */}
                                </div>

                                {/* <!-- Email input --> */}
                                <div className="form-outline mb-4 text-left">
                                    <input type="email" onChange={handleChange("email")} onClick={() => setErrorMessage('')}
                                        className="form-control atb-input-box" placeholder='Email' />
                                    {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                                    {/* <label className="form-label" for="form3Example3">Email address</label> */}
                                </div>

                                {/* <!-- Password input --> */}
                                <div className="form-outline mb-4 text-left">
                                    <input type="password" onChange={handleChange("password")} onClick={() => setErrorMessage('')}
                                        className="form-control atb-input-box" placeholder='Password' />
                                    {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                                    {/* <label className="form-label" for="form3Example4">Password</label> */}
                                </div>

                                <div className="form-group text-left">
                                    <Select
                                        options={userRoles}
                                        onChange={handleChangeSelect('user_role_id')}
                                        className='atb-input-box-container'
                                        classNamePrefix='atb-input-box'
                                        placeholder='Register As?'
                                    />
                                    {/* <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Register as?</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={registerData.roles}
                                                    defaultValue=""
                                                    label="Register as?"
                                                    onChange={handleChange('user_role_id')}
                                                >

                                                    {roles?.slice(1, 3).map((role) => {
                                                        return (
                                                            <MenuItem key={role.id} value={role.id}>{role.role}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl> */}
                                </div>

                                {/* <!-- Submit button --> */}
                                <div className='row'>
                                    <div className="col-lg-12 mb-6 mb-lg-0">
                                        <button type="submit" className="btn btn-primary btn-block mb-4">
                                            Register
                                        </button>
                                    </div>
                                    <p className='ml-4 mr-1 d-none d-lg-block'>Already have an account?</p><Link to={'/login'} className='d-none d-lg-block'> Login Here</Link>
                                </div>

                                <div className='row d-lg-none'>
                                    <div className='col-md-12'>
                                        <hr />
                                        <small className='text-left'> Already have an account yet? </small>
                                        <button type="submit" className="btn btn-primary btn-block mt-2 mb-4">
                                            Login
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </Box>
                    </Fade>
                </Modal>
            </div>
            <ErrorAlert open={openErrorAlert} message={responseMessage} onHide={() => setOpenErrorAlert(false)} />
        </div>

    )
}

export default RegisterLoginOverlay