import React from 'react'
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

function SuccessAlert(props) {


    // const { vertical, horizontal, open } = state;

    return (
        <div>
            {/* {buttons} */}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={props.open}
                onClose={props.onHide}
                // key={vertical + horizontal}
                sx={{'& .MuiAlert-root': {boxShadow: '1px 1px 5px #87bdab8f'}}}
                
            ><Alert severity="success">{props.message}</Alert></Snackbar>
        </div>
    )
}

export default SuccessAlert