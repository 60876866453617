import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Grid, Skeleton, Typography} from '@mui/material';
import { getBenefitSlider } from '../../../fetchAPI';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

import { JobSearchContext } from '../../context/JobSearchContext';
import SliderComponent from '../Slider/Slider';
import { GetBenefitSliderProps } from '../Slider/SliderFunctions';

import { useTranslation } from 'react-i18next';

const JobCardSkeleton = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          border: '1px solid #e0e0e0',
          padding: 0,
            width: {
            xs: '100%',
          }
        }}
      > 
        <Grid container spacing={2} columnGap={2}>
            <Grid item xs={3}>
                <Skeleton variant="rounded" width={80} height={80} animation="wave" />
            </Grid>
            <Grid item xs={8}>
                <Skeleton variant="text" width={"70%"} height={30} animation="wave" />
                <Skeleton variant="text" width={"70%"} height={30} animation="wave" />
            </Grid>
        </Grid>
      </Box>
    );
  };

function BenefitSlider() {
    const {checkedJobBenefitList, setCheckedJobBenefitList, jobs} = useContext(JobSearchContext)

    const [benefitList, setBenefitList] = useState([]);
    const [totalBenefit,setTotalBenefit] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [paginateInfo, setPaginateInfo] = useState({})

    useEffect(() => {
        setPaginateInfo(jobs?.response?.pagination)
    }, [jobs])

    useEffect(() => {
        setIsLoading(true);
        gettingBenefitList()
    }, []);

    const gettingBenefitList = () => {

        getBenefitSlider().then((response) => {
            if (response.success) {
                setBenefitList(response?.response?.data?.benefit_job)
                setTotalBenefit(response?.response?.data?.count)
                setIsLoading(false);

            }
            else {
                // console.log('errors: ', response);
            }
        })
    }
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 4
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          // partialVisibilityGutter: -38
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

    /**
     * Slider Component
     * 2023-12-18 adachi
     */
    const [slides, setSlides] = useState();
    const [params, setParams] = useState({});
    useEffect(() => {
      if (isLoading) {
        return;
      }
      const { slides, params } = GetBenefitSliderProps({
        jobs: benefitList,
        setCheckedJobBenefitList,
      });
      setSlides(slides);
      setParams(params);
    }, [benefitList, checkedJobBenefitList, isLoading, setCheckedJobBenefitList]);
    const slider = useMemo(() => {
      if (isLoading) {
        return <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
                <Box sx={{display: 'flex', flexDirection: "column", rowGap: 3}}>
                <JobCardSkeleton />
                <JobCardSkeleton />
                <JobCardSkeleton />
                <JobCardSkeleton />
                </Box>
            </Grid>
            <Grid item sm={6} md={4} sx={{display: {xs: "none", sm: "block"}}}>
                <Box sx={{display: 'flex', flexDirection: "column", rowGap: 3}}>
                <JobCardSkeleton />
                <JobCardSkeleton />
                <JobCardSkeleton />
                <JobCardSkeleton />
                </Box>
            </Grid>
            <Grid item md={4} sx={{display: {xs: "none", md: "block"}}}>
                <Box sx={{display: 'flex', flexDirection: "column", rowGap: 3}}>
                <JobCardSkeleton />
                <JobCardSkeleton />
                <JobCardSkeleton />
                <JobCardSkeleton />
                </Box>
            </Grid>
        </Grid>
      }
      return <SliderComponent slides={slides} params={params} />;
    }, [slides, params, isLoading]);

    // translation
    const {t} = useTranslation(["homepage", "banner"])

  return (
    <Box sx={{marginTop: "40px", '@media (max-width: 991px)': {marginTop: "0px"}}}>
      <div className='m-b100 mt-4'>
                <div className='container'>
                  <Grid container className='p-3' spacing={2} sx={{ justifyContent: {xs:'center',md: 'space-between'}, justifyItems: "center", '@media (max-width: 991px)': {marginTop: "20px"} }}>
                      <Grid item>
                          {
                              isLoading ?
                              <>
                              <Grid container spacing={1} sx={{ display: { xs: 'none', md: 'flex' }, marginTop: "30px" }}>
                                  <Grid item>
                                      <Skeleton animation='wave' variant="rounded" width={300} height={40} />
                                  </Grid>
                                  <Grid item>
                                      <Skeleton animation='wave' variant="rounded" width={40} height={40} />
                                  </Grid>
                              </Grid>
                              </>
                                  :
                                  <Typography variant='h3' className='d-none d-lg-block' sx={{ color: '#424447', fontWeight: 700, fontSize: {xs: '20px', sm: '20px', lg: '28px'}, marginTop: "-5px" }}>{t("homepage:search-by-benefit.title")} <span className='' style={{ color: '#DB1616' }}> &nbsp;({Number(paginateInfo?.total).toLocaleString()})</span></Typography>
                          }
                          {
                              isLoading ?
                                  <>
                                      <Grid container spacing={1} sx={{ display: { xs: 'flex', md: 'none' }, m: 3 }}>
                                          <Grid item>
                                              <Skeleton animation='wave' variant="rounded" width={200} height={40} />
                                          </Grid>
                                          <Grid item>
                                              <Skeleton animation='wave' variant="rounded" width={40} height={40} />
                                          </Grid>
                                      </Grid>
                                  </>
                                  :
                                  <>
                                      <Typography variant='h3' className='d-lg-none text-center responsive_header' sx={{ color: '#424447', fontWeight: 700, fontSize: {xs: '20px', sm: '20px', lg: '28px'} }}>{t("homepage:search-by-benefit.title")} <span className='' style={{ color: '#DB1616' }}> &nbsp;({Number(paginateInfo?.total).toLocaleString()})</span></Typography> {/** Mobile View */}
                                  </>
                          }
                      </Grid>
                      <Grid>
                          {
                              isLoading ?
                                  <Skeleton animation='wave' variant="rounded" width={150} height={50} sx={{ display: { xs: 'none', md: 'flex' }, marginTop: "40px" }} />
                                  :
                                  // <Link to='/search/jobs' className='d-none d-lg-block' style={{ fontSize: '28px', color: '#2a9df4', padding: '10px 60px 10px 60px', border: '1px solid ', borderRadius: '10px' }}>EXPLORE ALL</Link>
                                  <Link 
                                  to={{
                                      pathname: '/search/jobs',
                                      state: { fromHome: true }
                                  }} 
                                  ><Button className='d-none d-lg-block' size='large' variant='outlined'>{t("button:explore-all.title")}</Button></Link>
                          }
                      </Grid>
                  </Grid>
              </div>
              <div className='container'>
                  {/** Slider Component */}
                  <Box sx={{marginTop: "70px", '@media (max-width: 991px)': {marginTop: "60px"}}}>
                    {slider}
                  </Box>
              </div>
              
              <div className='text-c'>
                  {
                      isLoading ?
                          <Skeleton animation='wave' variant="rounded" width={300} height={50} sx={{ display: { xs: 'flex', md: 'none' }, marginTop: "30px" }} />
                          :
                          <Link 
                          to={{
                              pathname: '/search/jobs',
                              state: { fromHome: true }
                          }} 
                          ><Button className='d-block d-lg-none' size='large' variant='outlined'sx={{ marginTop: '132px !important'}}>{t("button:explore-all.title")}</Button></Link>
                  }
              </div>
      </div >
    </Box>
  )
}

export default BenefitSlider