/**Imports */

import React, { useState, useEffect, useContext } from 'react';
import EmployerDashboardSideBar from '../../components/EmployerComponents/EmployerDashboardSideBar/EmployerDashboardSideBar'
import CandidateCard from '../../components/CandidateCard/CandidateCard'
import { EmployerContext } from '../../context/EmployerContext';
import { getEmployerSaveCandidateInfo } from '../../../fetchAPI';
import { Link, useHistory } from 'react-router-dom';



/**
 * Component for Employers to be able to see a list of all Saved Candidates
 *  
 * @component
 */

function EmployerSavedProfiles() {

    // UseState Declarations
    const [profiles, setProfiles] = useState([])
    const [loading, setLoading] = useState(true)


    // UseContext Declarations
    const { employerData } = useContext(EmployerContext)

    // Use History declaration
    const history = useHistory();

    // Use Effect Declarations
    useEffect(() => {

        settingWatchlistData()

    }, [employerData])


    /**
   * Calling Active Jobs API 
   * 
   * @param {object} employer_id - Sending Employer ID 
   * @return {object} Success or false json with Saved Candidates Data
   */

    const settingWatchlistData = () => {

        getEmployerSaveCandidateInfo(employerData.id).then((response) => {
            if (response && response.success) {
                setProfiles(response.response.data?.saved_candidates)
                setLoading(false)
            }
            else {
                console.log('errors: ', response);
            }
        })

    }


    return (
        <div className='container'>
            <div className='row'>
                <div className='col-lg-4 col-md-0'>
                    <EmployerDashboardSideBar />
                </div>
                <div className='col-lg-8 col-md-12'>
                    <div className='atb-card-dashboard'>
                        <div className='container'>
                            <hr className='d-lg-none' />
                            <div className='text-left'>
                                <h2 className='m-2 mt-4'> Saved Profiles</h2><hr />
                            </div>
                            <div className='row'>
                                {
                                    profiles && profiles.length !== 0 ? profiles.map((candidate, index) => (

                                        <div className='col-lg-6 col-md-6' key={index}>
                                            <CandidateCard candidateInfo={candidate} />
                                        </div>

                                    )) : 
                                    <div className='col-12'>
                                        <center className='p-10'>
                                            <h5> You haven't saved any Candidate Profile Yet!</h5>
                                        </center>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmployerSavedProfiles