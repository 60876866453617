import React from "react";
import moment from 'moment'
import ReactHtmlParser from 'react-html-parser'
import styles from "../CandidateUpdateProfile.module.css";
import CommonMasking from "../../../../maskingcomponents/CommonMasking";

export default function SingleCertificateBlock({ item , masking}) {
    return (
        <>
            {
                masking ?
                    <CommonMasking>
                        <div>

                            <div className={styles.certificateInstitute}>
                                Institute : Abcd Xyz
                            </div>
                            <div className={styles.certificateDuration}>Jan 2010 - Feb 2014</div>
                            <div className={styles.certificateInstitute}>
                                Duration: 5 Years
                            </div>
                            <div className={styles.certificateDesc}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nulla turpis, scelerisque sit amet scelerisque vitae, fermentum non odio. Nullam sit amet felis sit amet diam aliquam hendrerit in at lectus.
                            </div>
                        </div>
                    </CommonMasking>
                    :
                    <div>

                        <div className={styles.certificateInstitute}>
                            Institute : {item.institute}
                        </div>
                        <div className={styles.certificateDuration}>{moment(item.start).format("MMM Do YYYY")} - {item.end ? moment(item.end).format("MMM Do YYYY") : 'Now'}</div>
                        <div className={styles.certificateInstitute}>
                            {
                                item.duration ? `Duration: ${item.duration} ` : ``
                            }
                        </div>
                        <div className={styles.certificateDesc}>
                            {ReactHtmlParser(item.description)}
                        </div>
                    </div>
            }
        </>
    )
}