import React, { useEffect, useMemo, useState } from 'react';
import { Button, Grid, Skeleton, Tooltip, Typography } from '@mui/material';
import Carousel from 'react-multi-carousel';
import { Card } from 'react-bootstrap';
import { mostRecentJobs } from '../../../fetchAPI';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import EmployerProfilePicture from '../EmployerComponents/EmployerProfilePicture';
import { GetMostRecentJobsSliderProps } from '../Slider/SliderFunctions';
import SliderComponent from '../Slider/Slider';

function MostRecentJobs() {

    const [jobs, setJobs] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true);
        gettingMostRecentJobs()

    }, []);

    const gettingMostRecentJobs = () => {

        mostRecentJobs().then((response) => {
            if (response?.success) {
                setJobs(response?.response?.data)
                setIsLoading(false);

            }
            else {
                // console.log('errors: ', response);
            }
        })
    }

    // const {setExpireSoon} = useContext(JobSearchContext)

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 4
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          // partialVisibilityGutter: -38
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

    /**
     * Slider Component
     * 2023-12-18 adachi
     */
    const [slides, setSlides] = useState();
    const [params, setParams] = useState({});
    useEffect(() => {
      if (isLoading) {
        return;
      }
      const { slides, params } = GetMostRecentJobsSliderProps({
        jobs: jobs?.recent_job,
      });
      setSlides(slides);
      setParams(params);
    }, [isLoading, jobs]);
    const slider = useMemo(() => {
      if (isLoading) {
        return null;
      }
      return <SliderComponent slides={slides} params={params} />;
    }, [slides, params, isLoading]);

    return (
        <div className='m-b100 mt-5'>
            <div className='container'>
                <Grid container spacing={2} sx={{ justifyContent: 'space-between', alignItems: 'top' }}>
                    <Grid item>
                        {
                            isLoading ?
                                <>
                                    <Grid container spacing={1} sx={{ display: { xs: 'none', md: 'flex' } }}>
                                        <Grid item>
                                            <Skeleton animation='wave' variant="rounded" width={400} height={40} />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton animation='wave' variant="rounded" width={40} height={40} />
                                        </Grid>
                                    </Grid>
                                </>
                                :
                                <h1 className='d-none d-lg-block' style={{ color: '#03254c' }}>This Month's Recent Jobs <span className='' style={{ color: 'red' }}> &nbsp;({jobs?.count})</span></h1>
                        }
                        {
                            isLoading ?
                                <>
                                    <Grid container spacing={1} sx={{ display: { xs: 'flex', md: 'none' }, m: 3 }}>
                                        <Grid item>
                                            <Skeleton animation='wave' variant="rounded" width={200} height={40} />
                                        </Grid>
                                        <Grid item>
                                            <Skeleton animation='wave' variant="rounded" width={40} height={40} />
                                        </Grid>
                                    </Grid>
                                </>
                                :
                                <>
                                    <h1 className='d-lg-none text-center' style={{ color: '#03254c' }}>This Month's Recent Jobs <span className='' style={{ color: 'red' }}> &nbsp;({jobs?.count})</span></h1> {/** Mobile View */}
                                </>
                        }
                    </Grid>
                    <Grid item>
                        {
                            isLoading ?
                                <Skeleton animation='wave' variant="rounded" width={300} height={50} sx={{ display: { xs: 'none', md: 'flex' } }} />
                                :
                                // <Link to='/search/jobs' className='d-none d-lg-block' style={{ fontSize: '28px', color: '#2a9df4', padding: '10px 60px 10px 60px', border: '1px solid ', borderRadius: '10px' }}>EXPLORE ALL</Link>
                                <Link to='/search/jobs'><Button className='d-none d-lg-block' size='large' variant='outlined'>Explore All</Button></Link>
                        }
                    </Grid>
                </Grid>
            </div>
            <div className='container'>
                {/** Slider Component */}
                {slider}
            </div>
        </div >
    )
}

export default MostRecentJobs;