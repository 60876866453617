import React, {useContext, useEffect, useState} from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { UserContext } from '../../context/UserContext';
import { Link } from 'react-router-dom';

function UserJourneyAlert(props) {
  const {applyAlertNotificationOpen, setApplyAlertNotificationOpen} = useContext(UserContext);

  const {applyAttemptJobInfo} = useContext(UserContext)

  const handleClose = () => {
    setApplyAlertNotificationOpen(false);
  };


  const jobMessage = () =>{
    if (applyAttemptJobInfo.job_id){
      return (
        <h5 style={{color : 'white'}}>
          You were trying to apply on <Link to={`/jobs/${applyAttemptJobInfo.job_id}`} style={{color: '#2a9df4'}}> {applyAttemptJobInfo.job_title} </Link> . <Link to={`/jobs/${applyAttemptJobInfo.job_id}`} style={{color: '#2a9df4'}}> <u style={{color: '#2a9df4'}}> Click Here </u> </Link> to go back to Apply Screen.
        </h5>
      )
    }else{
      return ''
    }
  }

  return (
    <div>
      <Snackbar
        open={applyAlertNotificationOpen}
        // autoHideDuration={6000}
        // onClose={handleClose}
        message={jobMessage()}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}

export default UserJourneyAlert;