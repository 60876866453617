import React, { useState, useRef, useContext, useEffect } from 'react'
import { insertEmployerContent, updateEmployerContent } from '../../../../../fetchAPI'
import { Modal, Box, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { EmployerContext } from '../../../../context/EmployerContext'
import { Form } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
	'& .MuiDialog-paper': {
		width: '90%', // Adjust as needed
		maxWidth: '950px', // Adjust as needed
		height: '90%', // Adjust as needed
		maxHeight: '550px', // Adjust as needed
	},
}));


function ContentUpdateModal(props) {
	const [loading, setLoading] = useState(false)
	const { settingEmployerData } = useContext(EmployerContext)

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: { xs: '90%', sm: '60%' },
		height: { xs: '90%', sm: '60%' },
		bgcolor: 'background.paper',
		borderRadius: '20px',
		boxShadow: 24,
		p: { xs: 2, sm: 4 },
		display: { xs: 'flex' },
		// "&::-webkit-scrollbar" : {
		//     width : '10px',
		// },
		justifyContent: 'center'
	};

	const imageAlignmentRef = useRef('')
	const descriptionRef = useRef('')
	const imageRef = useRef('')

	const contentImageRef = useRef('')
	const contentTitleRef = useRef('')
	const contentDescriptionRef = useRef('')
	const contentImageAlignRef = useRef('')

	const handleContentUpdate = () => {
		setLoading(true)

		const formData = new FormData()
		formData.append('employer_content_section_id', props.item.employer_content_section_id)
		formData.append('align', imageAlignmentRef.current.value)
		formData.append('description', descriptionRef.current.value)
		if (imageRef.current.files[0]) {
			formData.append('content_photo', imageRef.current.files[0])
		}

		updateEmployerContent(props.item.id, formData)
			.then(response => {
				if (response && response.success) {
					props.closeContentEditModal()
					props.setResponseMessage(response.response.message)
					props.setOpenAlert(true)
					settingEmployerData()
				}else{
					props.closeContentEditModal()
					props.setErrorMessage(response.errors[0]?.message)
					props.setOpenErrorAlert(true)
				}
			}).finally(() => setLoading(false))
	}

	const handleContentInsertion = () => {
		setLoading(true)

		const formData = new FormData();
		formData.append('content_photo', imageRef.current.files[0]);
		formData.append('employer_content_section_id', contentTitleRef.current.value);
		formData.append('description', descriptionRef.current.value);
		formData.append('align', imageAlignmentRef.current.value);

		insertEmployerContent(formData)
			.then(response => {
				if (response && response.success) {
					props.setResponseMessage(response.response.message);
					props.setOpenAlert(true);
					settingEmployerData();
				}else{
					props.closeContentEditModal()
					props.setErrorMessage(response.errors[0]?.message)
					props.setOpenErrorAlert(true)
				}
			})
			.finally(() => setLoading(false));
	}

	const imageRestrictionList = {
		1 : '1300 X 550',
		2 : '630 X 450',
		3 : '630 X 610',
		4 : '630 X 450',
		5 : '640 X 350'
	}

	const [imageRestrictionText, setImageRestrictionText] = useState('')
	const [imageRestrictionId, setImageRestrictinId] = useState()
	useEffect(()=>{
		if(props.item){
			setImageRestrictinId(props.item.employer_content_section_id)
		}
	},[props])
	useEffect(()=>{
		let isChanged = false;
		if(!isChanged && imageRestrictionId){
			setImageRestrictionText(`Please upload an image with a maximum size of ${imageRestrictionList[imageRestrictionId]} px.`)
		}

		return ()=>{
			isChanged = true;
		}
	},[imageRestrictionId])

	return (
		<React.Fragment>
			<BootstrapDialog
				onClose={()=>[props.closeContentEditModal, setImageRestrictionText('')]}
				aria-labelledby="customized-dialog-title"
				open={props.open}
			>
				<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
					{
						props.item ?
							'Edit Content'
							:
							'Add New Content'
					}
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={props.closeContentEditModal}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent dividers>
					<Grid container spacing={2}>
						{
							props.item ?
								null
								:
								<React.Fragment>
									{/* Content Section */}

									<Grid item md={6} xs={12}>
										<div className="form-group">
											<label>Content Title</label>
											<Form.Control onChange={(e)=> setImageRestrictinId(e.target.value)} as="select" ref={contentTitleRef} custom className="atb-input-box">
												<option value=''>Select</option>
												{
													props.contentTitleList?.map((item, index) => {
														return (
															<option key={index} value={item.id}>{item.title}</option>
														)
													})
												}
											</Form.Control>
										</div>
									</Grid>
								</React.Fragment>
						}

						<Grid item md={props.item ? 12 : 6} xs={12}>
							<div className="form-group">
								<label>Content Image Alignment</label>
								<Form.Control as="select" ref={imageAlignmentRef} custom className="atb-input-box">
									<option value=''>Select</option>
									<option value={'left'}>Left</option>
									<option value={'right'}>Right</option>
								</Form.Control>
							</div>
						</Grid>
					</Grid>

					<Grid container spacing={2}>
						<Grid item md={12} xs={12}>
							<div className="form-group">
								<label>Content Image</label>
								<input type="file" ref={imageRef} className="form-control atb-input-box" placeholder="Upload Photo" />
								<small style={{fontSize : '10px', color : 'green'}}>{imageRestrictionText}</small>
							</div>
						</Grid>
						<Grid item md={12} xs={12}>
							<div className="form-group">
								<label>Description</label>
								<textarea type="textarea" rows={4} defaultValue={props.item?.description} ref={descriptionRef} className="form-control atb-input-box" placeholder="Enter Description" />
							</div>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<LoadingButton onClick={() => props.item ? handleContentUpdate() : handleContentInsertion()} loading={loading} variant="contained" type="submit" className="employer-update-button m-b30 d-none d-lg-block">Save Changes</LoadingButton>
				</DialogActions>
			</BootstrapDialog>
		</React.Fragment>
	)
}

export default ContentUpdateModal