import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container } from '@mui/system';
import TopJobs from './TopJobs';
import TopJobsMonthly from './TopJobsMonthly';
import { Grid } from '@mui/material';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TopJobsPanel() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Grid sx={{ mb: 5 }}>
                <Typography variant='h4' align='center'>Popular Jobs</Typography>
            </Grid>
            <Box sx={{ width: '100%' }}>
                <Container>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                            <Tab label="This Week" {...a11yProps(0)} />
                            <Tab label="This Month" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                </Container>
                <TabPanel value={value} index={0}>
                    <TopJobs />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <TopJobsMonthly />
                </TabPanel>
            </Box>
        </>
    );
}

export default TopJobsPanel;