import React from 'react'
import CallToAction from '../components/HomePageComponents/CallToAction'
import CategoryBox from '../components/HomePageComponents/CategoryBox'
import Testimonial from '../components/HomePageComponents/Testimonial'
import TopCompany from '../components/HomePageComponents/TopCompany'
import HeroSection from '../components/HomePageComponents/HeroSection'
import JobSection from '../components/HomePageComponents/JobSection'
import TopJobsPanel from '../components/HomePageComponents/TopJobsTab'
import EmployerImageSlider from '../components/HomePageComponents/EmployerImageSlider'
import TopJobsMonthly from '../components/HomePageComponents/TopJobsMonthly'
import GenderSpecificJobs from '../components/HomePageComponents/GenderSpecificJobs'
import BenefitSlider from '../components/HomePageComponents/BenefitSlider'
import BenefitListCarousel from '../components/HomePageComponents/BenefitListCarousel'
import HighPaidJobs from '../components/HomePageComponents/HighPaidJobs'
import ExpireSoonJobs from '../components/HomePageComponents/ExpireSoonJobs'
import MostRecentJobs from '../components/HomePageComponents/MostRecentJobs'
import LiveJobs from '../components/HomePageComponents/LiveJobs'

function HomePage() {
  return (
    <div>
      <HeroSection />
      <LiveJobs />
      <EmployerImageSlider />
      <CategoryBox />
      {/* <HighPaidJobs /> */}
      <BenefitSlider />
      <ExpireSoonJobs />
      {/* <JobSection /> */}
      <CallToAction />

      {/* Sections which are unused now but might need them later */}

      {/* <TopJobsMonthly /> */}
      {/* <Testimonial /> */}
      {/* <MostRecentJobs /> */}
      {/* <GenderSpecificJobs /> */}
      {/* <BenefitListCarousel /> */}
      {/* <GenderSpecificJobs /> */}
      {/* <TopJobsPanel /> */}
      {/* <TopCompany /> */}
    </div>
  )
}

export default HomePage