import { Box, ClickAwayListener, Stack, Tooltip, tooltipClasses } from '@mui/material';
import React, { useState } from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkIcon from '@mui/icons-material/Link';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip
        disableFocusListener
        disableHoverListener
        disableTouchListener
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#00A264',
        color: 'white',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));


export default function SocialsComponent(props) {
    const [responseMessage, setResponseMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [open, setOpen] = useState(false);
    const [onHover, setOnHover] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleOnHoverTooltipClose = () => {
        setOnHover(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
        setTimeout(() => {
            setOpen(false);
        }, 1500); // Close the tooltip after 2000 milliseconds (adjust as needed)
    };

    const handleIconClick = (link) => {
        const url = link ? link.toString() : '';

        if (url) {
            const hasHttp = url.startsWith('http://');
            const hasHttps = url.startsWith('https://');

            if (hasHttp || hasHttps) {
                // The string contains either 'http://' or 'https://'
                window.open(url, '_blank'); // Open in a new tab
            } else {
                // The string does not contain 'http://' or 'https://'
                window.open(`http://${url}`, '_blank'); // Open in a new tab
            }
        } else {
            console.error('Invalid URL: web is not defined');
        }
    };

    const socialLinkIconComponent = (socialLink) => {
        const socialIcons = {
            Facebook: (
                <FacebookIcon
                    sx={{
                        backgroundColor: '#3b5998',
                        color: 'white',
                        padding: 1,
                        width: '15%',
                        height: '15%',
                        borderRadius: '5px',
                        cursor: socialLink?.pivot?.link ? 'pointer' : 'default', // Set cursor based on link availability
                    }}
                    onClick={() => handleIconClick(socialLink?.pivot?.link)}
                />
            ),
            LinkedIn: (
                <LinkedInIcon
                    sx={{
                        backgroundColor: '#0a66c2',
                        color: 'white',
                        padding: 1,
                        width: '15%',
                        height: '15%',
                        borderRadius: '5px',
                        cursor: socialLink?.pivot?.link ? 'pointer' : 'default',
                    }}
                    onClick={() => handleIconClick(socialLink?.pivot?.link)}
                />
            ),
            Instagram: (
                <InstagramIcon
                    sx={{
                        background: 'linear-gradient(90deg, #feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5)',
                        color: 'white',
                        padding: 1,
                        width: '15%',
                        height: '15%',
                        borderRadius: '5px',
                        cursor: socialLink?.pivot?.link ? 'pointer' : 'default',
                    }}
                    onClick={() => handleIconClick(socialLink?.pivot?.link)}
                />
            ),
            Twitter: (
                <TwitterIcon
                    sx={{
                        background: '#1da1f2',
                        color: 'white',
                        padding: 1,
                        width: '15%',
                        height: '15%',
                        borderRadius: '5px',
                        cursor: socialLink?.pivot?.link ? 'pointer' : 'default',
                    }}
                    onClick={() => handleIconClick(socialLink?.pivot?.link)}
                />
            ),
            Link: (
                <LinkIcon
                    sx={{
                        background: '#178bf4',
                        color: 'white',
                        padding: 1,
                        width: '15%',
                        height: '15%',
                        borderRadius: '5px',
                        cursor: socialLink?.pivot?.link ? 'pointer' : 'default',
                    }}
                    onClick={() => handleIconClick(socialLink?.pivot?.link)}
                />
            ),
        };

        return socialIcons[socialLink.name] || null;
    };

    // Copy Profile Url
    function copyUrl() {
        const currentUrl = window.location.href;

        try {
            navigator.clipboard.writeText(currentUrl).then(() => {
                setResponseMessage('URL copied')
                setOpenAlert(true)
                // You can setResponseMessage and setOpenAlert here if needed
            }).catch((err) => {
                console.error("Error copying URL to clipboard:", err);
                // Handle the error if necessary
            });
        } catch (err) {
            console.error("Error copying URL to clipboard:", err);
            // Handle the error if necessary
        }
    }

    return (
        <>
            <Box p={2}>

                <Stack direction={'row'} spacing={1} justifyContent={'left'}>
                    {props.socialLinks?.map((socialLink, index) => (
                        <React.Fragment key={index}>{socialLinkIconComponent(socialLink)}</React.Fragment>
                    ))}

                    {/* Tooltip handled for Before and After copy event */}
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                        <LightTooltip
                            onClose={handleTooltipClose}
                            open={open}
                            title={onHover ? "Copy URL" : "Copied"}
                            onMouseEnter={() => [setOnHover(true), handleTooltipOpen()]}
                        >
                            <LinkIcon
                                sx={{
                                    background: '#178bf4',
                                    color: 'white',
                                    padding: 1,
                                    width: '15%',
                                    height: '15%',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => [copyUrl(), handleTooltipOpen(), handleOnHoverTooltipClose()]}
                            />
                        </LightTooltip>
                    </ClickAwayListener>
                </Stack>
            </Box>
        </>
    );
}
