import React from 'react'
import { Link } from 'react-router-dom'

function TopCompany() {
  return (
    <div>
        <div className='top-company m-b100'style={{backgroundColor:'#f9f9f9',padding:'80px 0px 80px 0px' }}>
            <div className='container'>
                <div>
                    <h3 className='d-none d-lg-block' style={{color:'#03254c'}}>Get hired in top companies</h3>
                    <h3 className='d-lg-none text-center' style={{color:'#03254c'}}>Get hired in top companies</h3>
                </div>
                <div className='row m-t50'>
                    {/* <div className='col text-center'>
                        <div className='top-company-logo-img'>
                            <img src='/v2/images/adplay.png' className="p-1" height="150px" width="150px" />
                        </div>
                    </div> */}
                    <div className='col-md-3 col-lg-3 text-center'>
                        <div className='top-company-logo-img'>
                            <img src='/v2/images/asia-group.jpg' className="p-1"style={{height:'200px'}}  />
                        </div>
                    </div>
                    <div className='col-md-3 col-lg-3 text-center'>
                        <div className='top-company-logo-img'>
                            <img src='/v2/images/graaho.png' className="p-1"style={{height:'200px'}}  />
                        </div>
                    </div>
                    <div className='col-md-3 col-lg-3 text-center'>
                        <div className='top-company-logo-img'>
                            <img src='/v2/images/ltid.png' className="p-1"style={{height:'200px'}}  />
                        </div>
                    </div>
                    <div className='col-md-3 col-lg-3 text-center'>
                        <div className='top-company-logo-img'>
                            <img src='/v2/images/takumi.jpg' className="p-1"style={{height:'200px'}}  />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TopCompany