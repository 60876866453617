import React, { useState, useContext } from 'react'
import './JobCard.css'
import { Link } from 'react-router-dom'
import { CandidateContext } from '../../context/CandidateContext'
import { deleteCandidateSaveJobInfo, postCandidateSaveJobInfo } from '../../../fetchAPI'
import SuccessAlert from '../Alerts/SuccessAlert'
import moment from 'moment'
import RegisterLoginOverlay from '../RegisterLoginOverlay'
import { Card } from 'react-bootstrap'
import { UserContext } from '../../context/UserContext'
import EmployerProfilePicture from '../EmployerComponents/EmployerProfilePicture'

function JobCard(props) {
    const { savedJobIds, candidateData, settingCandidateData } = useContext(CandidateContext)
    const [openAlert, setOpenAlert] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [open, setOpen] = React.useState(false);

    const { userData } = useContext(UserContext)

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const unSaveCandidate = (candId, jobId) => {
        deleteCandidateSaveJobInfo(candId, jobId)
            .then(response => {
                if (response && response.success) {
                    settingCandidateData()
                    setResponseMessage(response.response.message)
                    setOpenAlert(true)
                }
            })
    }

    const saveCandidate = (candId, jobId) => {
        postCandidateSaveJobInfo(candId, jobId)
            .then(response => {
                if (response && response.success) {
                    settingCandidateData()
                    setResponseMessage(response.response.message)
                    setOpenAlert(true)
                }
            })
    }

    const dayLeft = (date) => {
        const today = new Date()
        let days = moment(date).diff(moment(today), 'days')

        if (days > 0) {
            return days
        } else {
            return 0
        }
    }

    const displaySalary = () => {
        if (props.item.negotiable) {
            return 'Negotiable'
        } else {
            if (props.item.maximum_salary) {
                return `${props.item.minimum_salary} - ${props.item.maximum_salary} ${props.item.salary_currency ? props.item.salary_currency.name : 'BDT'}/${props.item.salary_type.type}`;
            }
        }
    }

    return (
        <>
            <div className='d-none d-lg-block'>
                <div className='atb-card'>
                    <div className='row flex-column-reverse flex-lg-row m-b10'>
                        <div className='mt-3 col-lg-8 col-md-8'>
                            <Link to={`/jobs/${props.item.post_id}`}>
                                <h3 className='text-left saved-job-title' style={{ color: '#006eff' }}>{props.item.job_title}</h3>
                            </Link>
                            <h4 className='text-left saved-job-title'>Salary: {displaySalary()}</h4>
                            <div className='text-left'>
                                <span className='candidate-tags'> <i class="fa fa-map-marker" aria-hidden="true"></i> &nbsp; {props.item.job_address}</span>
                                <span className='candidate-tags'><i class="fa fa-briefcase" aria-hidden="true"></i> &nbsp; {props.item.job_type?.name ? props.item.job_type?.name : "No Type Mentioned"}</span>
                                <span className='candidate-tags'><i class="fa fa-graduation-cap" aria-hidden="true"></i> &nbsp; {props.item.qualification?.name ? props.item.qualification?.name : "No Qualification Mentioned"}</span>
                            </div>
                            <div className='mt-3 text-left'>
                                {/* <p className='text-body'>{ReactHtmlParser(props.item.job_description)} </p> */}
                                <p className='text-body' style={{ fontWeight: 'bold' }}> Application Deadline: <span style={{ color: '#e54c3c' }}> {moment(props.item?.job_deadline).format("MMM Do YY")} <small> <i> ({dayLeft(props.item.job_deadline) + ' days left'})</i> </small> </span> </p>
                                {/* <p>The company is looking for a <b>{props.item.position?.name}</b> for their <b>{props.item.department?.name}</b> team. Click on "View Details" to learn more about the job and find out if you're fit to apply!</p> */}
                            </div>
                            <div className='text-left'>
                                {
                                    props.item?.job_skill ?
                                        props.item?.job_skill?.map((skill, index) => {
                                            return (
                                                <span key={index} className='skill-tags'> {skill?.skill_name}</span>
                                            )
                                        })
                                        :
                                        ""
                                }
                                {/* <span className='skill-tags'> <i class="fa fa-briefcase" aria-hidden="true"></i> &nbsp; {props.item.job_type}</span>
                        <span className='skill-tags'><i class="fa fa-money" aria-hidden="true"></i> &nbsp; {props.item.job_type?.name ? props.item.job_type?.name : "No Type Mentioned" }</span>
                        <span className='skill-tags'><i class="fa fa-money" aria-hidden="true"></i> &nbsp; {props.item.qualification?.name ? props.item.qualification?.name : "No Qualification Mentioned" }</span> */}
                            </div>

                        </div>
                        <div className='mt-3 col-lg-4 col-md-4'>
                            <div>
                                <Link to={`/employer/profile/${props.item?.employer?.slug}`}>
                                    <EmployerProfilePicture className='profile-image' profile_picture={props.item.employer?.profile_pic_base64} />
                                </Link>
                                <center style={{ padding: '20px' }}>
                                    <Link to={`/employer/profile/${props.item?.employer?.slug}`}><span className='employer-title'>{props.item.employer?.employer_name}</span></Link>
                                </center>
                            </div>
                            <div style={{ padding: '0px 50px' }} className='mt-2'>
                                <Link to={`/jobs/${props.item.post_id}`}><button className='candidate-apply-button' style={{ width: 'unset', padding: '9px', }}>View Details</button></Link>
                                {
                                    savedJobIds.includes(props.item.post_id) ?
                                        <Link to='#' onClick={() => { unSaveCandidate(candidateData.id, props.item.post_id) }} class="save-button">UnSave</Link>
                                        :
                                        candidateData ?
                                            <Link to='#' onClick={() => saveCandidate(candidateData.id, { 'job_post_id': props.item.post_id })}><button class="save-button">Save Job</button></Link>
                                            :
                                            <Link to='#'><button onClick={handleOpen} class="save-button">Save Job</button></Link>
                                }

                            </div>
                            {
                                userData.email === 'info@atb-jobs.com' ?
                                    <div style={{ marginTop: '80px' }}>
                                        <p>Total Applicants : {props.item?.total_applicants}</p>
                                    </div>
                                    : ''
                            }
                        </div>
                        <RegisterLoginOverlay open={open} onClose={handleClose} />
                        <SuccessAlert open={openAlert} message={responseMessage} onHide={() => setOpenAlert(false)} />
                    </div>
                </div>
            </div>


            <div className='d-lg-none'>
                <div className="col-md-1 col-lg-1">
                </div>

                <Card className='atb-card'>
                    <Link to={`/employer/profile/${props.item?.author_id}`}>
                        <EmployerProfilePicture className='profile-image' profile_picture={props.item.employer?.profile_pic_base64} />
                    </Link>
                    <div className='mb-3'>
                        <h4 className='card-title mt-3'>{props?.item?.employer?.employer_name}</h4>
                    </div>
                    <h3 className='display-6 text-truncate'>{props?.item?.job_title}</h3>
                    <div className='mb-3'>
                        <span className='candidate-tags d-inline-block text-truncate' style={{ maxWidth: '100px' }}><i class="fa fa-map-marker" aria-hidden="true"></i> &nbsp; {props?.item?.job_address}</span>
                        <span className='candidate-tags d-inline-block text-truncate' style={{ maxWidth: '100px' }}><i class="fa fa-briefcase" aria-hidden="true"></i> &nbsp; {props?.item?.job_type?.name}</span>
                        <span className='candidate-tags d-inline-block text-truncate' style={{ maxWidth: '100px' }}><i class="fa fa-graduation-cap" aria-hidden="true"></i> &nbsp; {props?.item?.qualification?.name ? props?.item?.qualification?.name : "No Qualification Mentioned"}</span>
                    </div>
                    <h5 className='card-title mt-1'>Salary: {props?.item?.maximum_salary ? props?.item?.minimum_salary + ' BDT' + '-' + props?.item?.maximum_salary + ' BDT' : "No Salary Mentioned"}</h5>
                    <div className='intro-text m-1 mb-1'>
                        {/* <p>The company is looking for a <b>{props?.item?.position?.name}</b> for their <b>{props?.item?.department?.name}</b> team. Click on "View Details" to learn more about the job and find out if you're fit to apply!</p> */}
                        <small className='text-body text-center'> Application Deadline: <span style={{ color: '#e54c3c' }}> {moment(props?.item?.job_deadline).format("MMM Do YY")} <small> <i> ({dayLeft(props.item.job_deadline) + ' days left'})</i> </small></span> </small>
                    </div>
                    <div className='text-left mt-2 mb-2'>
                        {
                            props?.item?.job_skill ?
                                props?.item?.job_skill?.map((skill, index) => {
                                    return (
                                        <span key={index} className='skill-tags'> {skill?.skill_name}</span>
                                    )
                                })
                                :
                                ""
                        }
                    </div>
                    <div className=''>
                        <Link to={`/jobs/${props?.item?.post_id}`}><button className='candidate-apply-button' style={{ float: 'left' }}>View Details</button></Link>
                        {
                            savedJobIds.includes(props.item.post_id) ?
                                <Link to='#' onClick={() => { unSaveCandidate(candidateData.id, props.item.post_id) }}><button class="candidate-apply-button">UnSave</button></Link>
                                :
                                candidateData ?
                                    <Link to='#' onClick={() => saveCandidate(candidateData.id, { 'job_post_id': props.item.post_id })}><button class="employer-save-button">Save Job</button></Link>
                                    :
                                    <Link to='#'><button onClick={handleOpen} class="employer-save-button">Save Job</button></Link>
                        }
                    </div>
                    <SuccessAlert open={openAlert} message={responseMessage} onHide={() => setOpenAlert(false)} />
                </Card>
                <div className="col-md-1 col-lg-1">
                </div>
            </div>
        </>
    )
}

export default JobCard