import React from 'react'
import ContactUs from '../components/ContactUs/ContactUs'
import PublicPageHeroSections from '../components/HeroSection/PublicPageHeroSections'

function ContactPage() {
    return (
        <div>
            <PublicPageHeroSections path="/v2/images/contact-bg.jpg" text='24/7 Customer Care Support for our valued Clients' />
            <div className='container m-t30'>
                <ContactUs />
            </div>
        </div>
    )
}

export default ContactPage