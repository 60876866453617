import React, { useContext, useEffect, useState } from 'react'
import { Chart } from "react-google-charts";
import { getEmployerBarChart } from '../../../fetchAPI';
import { EmployerContext } from '../../context/EmployerContext';
import GlobalLoader from '../GlobalLoader';

/**
 * React Google Charts
 * Documentation link: https://www.react-google-charts.com/
 */

export const options = {
    title: "Posted Job Statistics",
    chartArea: { width: "100%" },
    isStacked: true,
    hAxis: {
        title: "Job Titles",
        minValue: 0,
    },
    vAxis: {
        title: "City",
    },
    backgroundColor: 'transparent'
};

function DataChart() {


    const [barData, setBarData] = useState()
    const [barDataLoading, setBarDataLoading] = useState(false)
    const { employerData } = useContext(EmployerContext)

    useEffect(() => {
        settingBarChartData()

    }, [employerData])

    const settingBarChartData = async () => {
        try {
            setBarDataLoading(true);
            const response = await getEmployerBarChart(employerData.id);
            
            if (response && response.success) {
                setBarData(response?.response?.data?.applied_status_count);
            } else {
                // console.log('error: ', response);
            }
        } catch (error) {
            console.error('Error fetching bar chart data: ', error);
        } finally {
            setBarDataLoading(false);
        }
    };


    const data = [
        ["Job Title", "Total Applied", "Total Short-listed", "Total Hired"],
    ];

    // const data = [
    //     ["City", "2010 Population", "2000 Population"],
    //     ["Job 1", 8175000, 8008000],
    //     ["Los Angeles, CA", 3792000, 3694000],
    //     ["Chicago, IL", 2695000, 2896000],
    //     ["Houston, TX", 2099000, 1953000],
    //     ["Philadelphia, PA", 1526000, 1517000],
    // ];



    return (
        <>
        {barDataLoading ? <GlobalLoader height='20vh' /> : 
        <>
            {
                barData?.map(chartData => {
                    data.push([chartData?.job_title, chartData?.total_applied, chartData?.total_shortListed, chartData?.total_hired])
                })

            }

            {
                data[1] ?
                    <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={data}
                        options={options}
                    />
                    :

                    <div className='container mt-5 mb-5'>
                        <h5 className='display-6 text-center'>No Jobs Posted Yet</h5>
                    </div>

            }
        </>
        }
        </>
    )
}

export default DataChart