import React from "react";
import CandidateDashboardSidebar from "../../components/CandidateComponents/CandidateDashboardSideBar/CandidateDashboardSidebar";
import CandidateFollowedCompanies from "../../components/CandidateComponents/CandidateFollowedCompanies/CandidateFollowedCompanies";
import { Box, Grid } from "@mui/material";

function FollowedCompanies() {
  return (
<>
<Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, position: 'fixed', height: '70px', backgroundColor: '#0275D8', width: '100%', zIndex: 10 }}></Box>

<Grid container>
  <Grid item xs={0} sm={0} md={3} lg={2.5} xl={2} sx={{ zIndex: 11, display: { xs: 'none', sm: 'none', md: 'block' } }}>
    <CandidateDashboardSidebar />
  </Grid>
  <Grid item xs={12} sm={12} md={9} lg={9.5} xl={10} sx={{ zIndex: 11 }}>
  <CandidateFollowedCompanies />
  </Grid>
</Grid>
</>
  );
}

export default FollowedCompanies;
