import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material'

function UpdateDeleteConfirmationModal(props) {

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <center>
              <img src='/images/stop.png' style={{ height: '80px', width: '80px', marginBottom: '50px' }} />
            </center>
            Are you sure that you want to delete this item? It will be lost permanently!
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button
            variant='outlined'
            onClick={() => props.onClose()}
          >
            Cancel
          </Button>

          <Button
            variant='contained'
            color='error'
            onClick={() => props.deleteItem()}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default UpdateDeleteConfirmationModal