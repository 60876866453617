import React, { useContext, useState } from 'react'
import './GenerateResume.css'
import { Carousel } from 'react-bootstrap'
import { CandidateContext } from '../../../context/CandidateContext'
import { resumeGenerate } from '../../../../fetchAPI'
import { Link } from 'react-router-dom'

function GenerateResume() {

    const {candidateData} = useContext(CandidateContext)
    const [generateTemplateId, setGenerateTemplateId] = useState(2)

    const [displaySource, setDisplayeSource] = useState('/images/resume-templates/temp-2.jpg')
    /**
     * This function will download the generated Resume by a candidate info
     * @param {int} candidateId 
     * @param {int} templateId 
     * @param {string} name 
     */

    const generateResume = (candidateId, templateId, name) =>{
        resumeGenerate(candidateId, templateId)
        .then((response) => response.blob())
        .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
        new Blob([blob]),
        );const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `${name}.pdf`,
        );
    
        // Append to html link element page
        document.body.appendChild(link);
    
        // Start download
        link.click();
    
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });}

  return (
    <div className='bg-white shadow-sm mt-4 mt-md-0 card'>
        <div className='text-left resume-breadcrumb bg-white py-4 px-3 card-header'>
            <span className='prev-url'><Link to='/cv-manager'> Attach Resume </Link></span>
            <span className='middle-icon'> <i class="fa fa-angle-right" aria-hidden="true"></i> </span>
            <span className='crnt-url'> Generate Resume </span>
        </div>
        <div className='mt-4'>
            <center>
                <div class="row">
                    <div className='col-md-12 col-lg-12'>
                        <center>
                            <img className='resume-template-large' src={displaySource} style={{
                                maxWidth: '100%',
                                height: 'auto',
                                display: 'block',
                                margin: 'auto'
                            }} />
                        </center>
                    </div>
                </div>
                <div className='row resume-template-thumbnail-container'>
                    <div className='col-3'></div>
                    {/* <div className='col-3'>
                        <img 
                        className={`resume-template-thumbnail ${displaySource == '/images/resume-templates/temp-1.jpg' ? 'active-thumbnail' : ""}`} 
                        onClick={()=> [setDisplayeSource('/images/resume-templates/temp-1.jpg'), setGenerateTemplateId(1)]} src='/images/resume-templates/temp-1.jpg' />
                    </div> */}
                    <div className='col-6'>
                        <img 
                        className={`resume-template-thumbnail ${displaySource == '/images/resume-templates/temp-2.jpg' ? 'active-thumbnail' : ""}`} 
                        onClick={()=> [setDisplayeSource('/images/resume-templates/temp-2.jpg'), setGenerateTemplateId(2)]} src='/images/resume-templates/temp-2.jpg' />
                    </div>
                    <div className='col-3'></div>
                    <div className='col-lg-12 col-md-12 mb-5'>
                        <Link to={'#'} onClick={(e)=> generateResume(candidateData.id, generateTemplateId, candidateData.first_name)} className='site-button m-t30'>Generate</Link>
                    </div>
                </div>
            </center>
        </div>        
    </div>
  )
}

export default GenerateResume