import React, { useEffect, useState } from 'react'
import './HeroSection.css'

function HeroSection(props) {

  return (
    <div style={{ background: `url(${props.path})` }} className="background-container">
      <div class="overlay">
        <div className='applied-hero-section'>
          <div className='container text-area'>
            <center>
              <h1 className='section-text'>{props.text}.</h1>
              {/* <h3 className='section-text d-lg-none'>{props.text}.</h3> */}
            </center>
          </div>
        </div>
      </div>
      <div className='wave'>
        <img className='img-fluid d-none d-lg-block' src='/v2/icons/wave.png' />
      </div>
    </div>
  )
}

export default HeroSection