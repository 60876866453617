import React, { useContext, useState, useEffect } from 'react'
import { UserContext } from '../../context/UserContext';
import { useHistory } from 'react-router-dom';
import { Avatar, Box, Card, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import { adminLoginUser, loginUser } from '../../../fetchAPI';
import { LoadingButton } from '@mui/lab';
import ErrorAlert from '../../components/Alerts/ErrorAlert';


export default function AdminLogin() {

    const history = useHistory();
    const [loader, setLoader] = useState(false)
    const [loginInfo, setLoginInfo] = useState({ email: "", password: "" });
    const [errorMessage, setErrorMessage] = useState('')
    const [openErrorAlert, setOpenErrorAlert] = useState(false)

    // UseContext Declaration
    const { loginData, isLoggedin } = useContext(UserContext);

    // Route Declaration
    const redirectRoute = '/page-builder'

    // Redirect user to home page If already loggedin
    useEffect(() => {
        if (isLoggedin) {
            history.push(`${redirectRoute}`)
        }
    }, [isLoggedin])

    /**
    * Create a function that handle the input event and
    * save the value of that event on an state every time the component change
    * 
    * Setting login information in loginInfo state
    * 
    * @param {string} name
    * @param {event} event 
    */

    const handleChange = (name) => (event) => {
        setLoginInfo({ ...loginInfo, [name]: event.target.value });
    };

    function onLogin(e) {
        e.preventDefault();
        setLoader(true);

        /**
         * Calling login API 
         * 
         * @param {object} loginInfo - Sending Login Information
         * @return {object} Success or false json with User Data
         */

        adminLoginUser(loginInfo).then((response) => {
            if (response && response.success) {
                if (response.response.data?.user?.user_role_id === 1) {
                    loginData(response)
                    history.push(redirectRoute)
                    setLoader(false)
                }
                else {
                    setLoader(false)
                    setErrorMessage('Invalid User')
                    throw Error('Error: ' + 'Invalid User')
                }
            }
            else {
                setLoader(false)
                setErrorMessage(response?.errors[0].message[0])
                throw Error('Error: ' + response?.errors[0].message[0])
            }
        })
            .catch(err => {
                setLoader(false)
                setErrorMessage(err.message);
                setOpenErrorAlert(true);
            })
    }

    return (
        <>
            <div style={{ position: 'relative' }}>
                <Box
                    sx={{
                        backgroundImage: 'url(/v2/images/adminBackground.png)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        position: 'relative',
                        '@media (max-width: 600px)': {
                            height: '150vh',
                        },
                    }}
                />

                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        justifyContent: 'center',
                        maxWidth: '90%',
                        width: '800px',
                        boxShadow: '0px 4px 100px 0px rgba(0, 0, 0, 0.25)',
                        borderRadius: '22px',
                        margin: '10px',
                        zIndex: 1,
                        '@media (max-width: 600px)': {
                            width: '90%',
                            top: '50%',
                        },
                    }}
                >
                    <Card
                        sx={{
                            display: 'flex',
                            height: '100%',
                            width: '800px',
                            boxShadow: '0px 4px 100px 0px rgba(0, 0, 0, 0.25)',
                            borderRadius: '22px',
                            justifyContent: 'center',
                            margin: '10px',
                        }}
                    >
                        <Container maxWidth='sm'>
                            <Grid item md={12} xs={12}>
                                <Stack spacing={2} sx={{ display: 'flex', alignItems: 'center', margin: 7 }}>
                                    <Avatar
                                        alt="atB Jobs Logo"
                                        variant="square"
                                        src="/v2/images/atB-Jobs-logo-header.png"
                                        sx={{
                                            mr: 2,
                                            width: 80,
                                            height: 53
                                        }}
                                    />
                                    <Typography variant='h6' align='center' sx={{ fontWeight: 600 }}>Log in to your account</Typography>
                                </Stack>
                            </Grid>

                            <Grid item md={12} xs={12}>
                                <Box m={3} mb={8}>
                                    <form onSubmit={onLogin}>
                                        <Stack spacing={2} sx={{ marginBottom: 3 }}>
                                            <TextField
                                                required
                                                variant="outlined"
                                                type='email'
                                                label="Enter Your Email Address Or Phone Number"
                                                fullWidth
                                                onChange={handleChange("email_or_phone")}
                                            />
                                            <TextField
                                                required
                                                variant="outlined"
                                                type='password'
                                                label="Enter Your Password"
                                                fullWidth
                                                onChange={handleChange("password")}
                                            />
                                            <LoadingButton
                                                loading={loader}
                                                variant='contained'
                                                type='submit'
                                            >
                                                Log In
                                            </LoadingButton>
                                        </Stack>
                                    </form>
                                </Box>
                            </Grid>
                        </Container>
                    </Card>
                </Box>
                <ErrorAlert open={openErrorAlert} message={errorMessage} onHide={() => setOpenErrorAlert(false)} />
            </div>
        </>
    )
}