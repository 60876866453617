import React from 'react'
import EmployerDashboardSideBar from '../../components/EmployerComponents/EmployerDashboardSideBar/EmployerDashboardSideBar'
import ChangePassword from '../../components/ChangePassword'
import EmployerContextProvider from '../../context/EmployerContext'
import CCMail from '../../components/EmployerComponents/AccountSettings/CcMail'

function AccountSettings() {
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-lg-4 col-md-0'>
                <EmployerContextProvider>
                    <EmployerDashboardSideBar />
                </EmployerContextProvider>
                </div>
                <div className='col-lg-8 col-md-12'>
                    <ChangePassword />
                    <div style={{padding:'10px'}}></div>
                    <CCMail/>
                </div>
            </div>
        </div>
    )
}

export default AccountSettings