import React, { useContext, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import "./CandidateAppliedJobs.css";
import { appliedJobList } from "../../../../fetchAPI";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Grid,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { Card } from "react-bootstrap";
import NoResultsFound from "../../NoResultsFound";
import { CandidateContext } from "../../../context/CandidateContext";
import GlobalLoader from "../../GlobalLoader";
import JobsCard from "./JobsCard";

function CandidateAppliedJobs() {
  const location = useLocation();
  const { status_id } = location.state || {};
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [paginationInfo, setPaginationInfo] = useState({});
  const [loader, setLoader] = useState(false);
  const { candidateData, settingCandidateData } = useContext(CandidateContext);
  const [gridView, setGridView] = useState(true);
  const [selectedId, setSelectedId] = useState(status_id || "");
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setGridView(true);
  }, [window.innerWidth]);

  useEffect(() => {
    setLoader(true);
    appliedJobList(selectedId, currentPage)
      .then((response) => {
        if (response && response.success) {
          setAppliedJobs(response.response.data);
          setPaginationInfo(response.response.pagination);
        }
      })
      .finally(() => setLoader(false));
  }, [selectedId, currentPage]);

  return (
    <Card className="shadow-sm bg-white my-4 my-md-0 mb-md-4">
      <Card.Header className="bg-white pt-4 px-4 ">
        <div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center">
          <div style={{ fontFamily: 'Epilogue', fontSize: '24px', fontWeight: 700, color: '#424447' }}>
            Applied Jobs
          </div>
          
          <Box
            className=" d-none d-lg-block"
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                background: `${!selectedId ? "#0079C1" : "#E5F5FF"}`,
                fontWeight: `${!selectedId ? '700' : '400'}`,
                fontSize: "14px",
                fontFamily: 'Poppins, sans-serif',
                marginLeft: "0.5rem",
                marginBottom: "0.5rem",
                color: `${!selectedId ? "#FFFFFF" : "#424447"}`,
                ":focus": { outline: "none" },
                "&:hover": {
                  background: `${!selectedId ? "#0079C1" : "#E5F5FF"}`,
                  color: `${!selectedId ? "#FFFFFF" : "#424447"}`,
                },
              }}
              onClick={() => setSelectedId("")}
            >
              All
            </Button>
            {appliedJobs?.applied_status?.map((item) => (
              <Button
                variant="contained"
                sx={{
                  background: `${selectedId === item.id ? "#0079C1" : "#E5F5FF"
                    }`,
                  fontSize: "14px",
                  fontFamily: 'Poppins, sans-serif',
                  marginLeft: "0.5rem",
                  marginBottom: "0.5rem",
                  fontWeight: `${selectedId === item.id ? "700" : "400"}`,
                  color: `${selectedId === item.id ? "#FFFFFF" : "#424447"}`,
                  ":focus": { outline: "none" },
                  "&:hover": {
                    background: `${selectedId === item.id ? "#0079C1" : "#E5F5FF"
                      }`,
                    color: `${selectedId === item.id ? "#FFFFFF" : "#424447"}`,
                  },
                }}
                onClick={() => {
                  setSelectedId(item.id);
                  setCurrentPage(1);
                }}
              >
                {item.name} - [{item.job_count}]
              </Button>
            ))}
          </Box>
        </div>
      </Card.Header>
      <Card.Body className="bg-white">
        {loader ? (
          <GlobalLoader height="60vh" />
        ) : (
          <>
            <p
              className="font-poppins text-primary mb-3"
              style={{ fontSize: "14px", fontWeight: 700 }}
            >
              You have applied {!selectedId ? paginationInfo?.total : appliedJobs?.applied_status?.find((status) => status.id === selectedId).job_count} jobs
            </p>
            <Grid container spacing={2}>
              {appliedJobs?.applied_jobs?.length > 0 ? (
                appliedJobs?.applied_jobs?.map((item, index) => {
                  return (
                    <Grid item xs={12} md={gridView ? 6 : 12}>
                      <JobsCard
                        item={item}
                        index={index}
                        grid={gridView}
                        candidateData={candidateData}
                        settingCandidateData={settingCandidateData}
                      />
                    </Grid>
                  );
                })
              ) : (
                <div style={{ width: '100%', padding: '20px', marginTop: '20px' }}>
                  <NoResultsFound />
                </div>
              )}
            </Grid>
          </>
          
        )}
      </Card.Body>
      {
        appliedJobs?.applied_jobs?.length > 0 ?
          <Box className="p-4 d-flex justify-content-end card-footer">
            <Pagination
              page={currentPage}
              count={paginationInfo.last_page}
              color="primary"
              shape="rounded"
              boundaryCount={1}
              onChange={(e, v) => setCurrentPage(v)}
            />
          </Box>
          :
          ""
      }
    </Card>
  );
}

export default CandidateAppliedJobs;
