import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Style from '../../../css/lp3/lp3.module.css';
import BannerLine from '../../../images/lp3/icon/line.png';
import Vedio from '../../../images/lp3/vedio/vedio.mp4';
import Vivo from '../../../images/lp3/slider/vivo.png';
import Milvik from '../../../images/lp3/slider/milvik.png';
import Lalamove from '../../../images/lp3/slider/lalamove.png';
import Arogga from '../../../images/lp3/slider/argga.png';
import Backspace from '../../../images/lp3/slider/backspace.png';
import Shikho from '../../../images/lp3/slider/shikho.png';
import CrownCement from '../../../images/lp3/slider/crown.png';
import JobAboutImg from '../../../images/lp3/img/image.png';
import Chcek from '../../../images/lp3/icon/check.png';
import Money from '../../../images/lp3/icon/money.png';
import World from '../../../images/lp3/icon/world.png';
import Star from '../../../images/lp3/client/Star.png';
import Nowshin from '../../../images/lp3/client/noshin.png';
import Nazma from '../../../images/lp3/client/nazma.png';
import Masrafi from '../../../images/lp3/client/mashrafi.png';
import Tarequr from '../../../images/lp3/client/tarequr.png';
import Dot from '../../../images/lp3/icon/dot.png';

const BannerSection = () => {
    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            video.play().catch((error) => {
                console.error('Autoplay prevented: ', error);
            });
        }
    }, []);
    return (
        <section className={Style.bannerBg}>
            <div className={`${Style.bannerContent} ${Style.containers}`}>
                <div className={Style.bannerLeft}>
                    <p className={Style.activeContent}>চাকরী নিয়ে চিন্তিত?</p>
                    <h1 className={Style.titleText}>
                        <span
                            className={`${Style.titleTextColor} ${Style.numberJob}`}
                        >
                            ১৬,০০০+
                        </span>{' '}
                        চাকরী থেকে আপনার পছন্দের চাকরীটি পেতে এখনি রেজিস্ট্রেশান
                        করুন
                    </h1>
                    <div className={Style.lineImg}>
                        <img src={BannerLine} alt="" />
                    </div>
                    <div className={Style.bannerBtn}>
                        <Link
                            to="/candidate-register"
                            className={Style.btnsLink}
                        >
                            Get A Free Account{' '}
                        </Link>
                    </div>
                </div>
                <div className={Style.bannerRight}>
                    <div className={Style.videoContainer}>
                        <video
                            ref={videoRef}
                            src={Vedio}
                            controls
                            autoplay
                            muted
                            loop
                        >
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </section>
    );
};

const SliderSection = () => {
    const sliderImg = [
        {
            id: '1',
            img: Vivo,
            name: 'Vivo',
        },
        {
            id: '2',
            img: Milvik,
            name: 'Milvik',
        },
        {
            id: '3',
            img: Lalamove,
            name: 'Lalamove',
        },
        {
            id: '4',
            img: Arogga,
            name: 'Arogga',
        },
        {
            id: '5',
            img: Backspace,
            name: 'Backspace',
        },
        {
            id: '6',
            img: Shikho,
            name: 'Shikho',
        },
        {
            id: '7',
            img: CrownCement,
            name: 'CrownCement',
        },
    ];
    return (
        <section className={`${Style.sliderContainer} ${Style.containers}`}>
            <div className={Style.sliderWrapper}>
                {sliderImg?.map((data) => (
                    <div key={data?.id} className={Style.sliderImg}>
                        <img src={data?.img} alt={data?.name} />
                    </div>
                ))}
            </div>
        </section>
    );
};

const JobAboutSection = () => {
    const jobProtal = [
        {
            id: '1',
            name: 'Maximum No of Job Posting',
            desc: 'Job post 16,000+ / monthly',
            img: Chcek,
        },
        {
            id: '2',
            name: 'High Paid Jobs',
            desc: 'Salary 100,000 + BDT/ monthly',
            img: Money,
        },
        {
            id: '3',
            name: 'Overseas Job Opportunity',
            desc: 'Singapore, Hong Kong, Japan and More',
            img: World,
        },
    ];
    return (
        <div className={Style.aboutContainer}>
            <div className={`${Style.containers} ${Style.aboutFlex}`}>
                <div className={Style.aboutLeft}>
                    <img src={JobAboutImg} alt="image" />
                </div>
                <div className={Style.aboutRight}>
                    <h1 className={Style.titleText}>
                        Why atB Jobs is considered the,
                        <br />
                        <span className={Style.titleTextColor}>
                            No. 1 Job Portal!
                        </span>
                    </h1>
                    <div className={Style.aboutContent}>
                        {jobProtal?.map((data) => (
                            <div key={data?.id} className={Style.aboutCard}>
                                <div>
                                    <img
                                        className={Style.aboutCardImg}
                                        src={data?.img}
                                        alt={data?.name}
                                    />
                                </div>

                                <div className={Style.aboutCardContent}>
                                    <h4>{data?.name}</h4>
                                    <p>{data?.desc}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const ClientReviewSection = () => {
    const ClientReviewData = [
        {
            id: '1',
            name: 'Daliya Nowshin Nisha',
            deg: 'Executive - Corporate Sales',
            img: Nowshin,
            desc:
                'atB Jobs Portal transformed my Job hunt with its user-friendly Interface and vast job listings. I quickly found and secured my dream job within weeks of using the platform! Thank you, atB Jobs Portal, for simplifying my job hunt.',
        },
        {
            id: '2',
            name: 'Nazma Khatun',
            deg: 'Lead UI/UX Designer',
            img: Nazma,
            desc:
                'atB Job Portal surpassed my expectations with Its vast job database, Intuitive interface, and timely notifications. Thanks to them, landed my dream job stress-free. Highly recommended for all job seekers!',
        },
        {
            id: '3',
            name: 'Masrafi Anam',
            deg: 'Mobile App Developer',
            img: Masrafi,
            desc:
                'Thanks to atB Jobs, I secured my dream job effortlessly. Their efficient platform and regular updates kept me Informed about relevant opportunities, making the entire process stress-free. I could not be happler with the outcome.',
        },
        {
            id: '4',
            name: 'Md Tarequr Rahman',
            deg: 'Executive - Corporate Sales',
            img: Tarequr,
            desc:
                'Grateful to atB Jobs for guiding me to my dream Job effortlessly! Their streamlined platform and proactive approach simplified everything. Highly recommended for anyone seeking career advancement!',
        },
    ];
    return (
        <section className={Style.clinetContainer}>
            <div className={`${Style.containers} ${Style.clientFlex}`}>
                <div className={Style.clientLeft}>
                    <h1 className={Style.titleText}>
                        We do the best service,{' '}
                        <span className={Style.titleTextColor}>
                            this is what the say
                        </span>
                    </h1>
                    <p>
                        The following is the assessment of our user of our
                        platforms, what they experience, feel and the impact
                        after using atB.
                    </p>
                </div>

                <div className={`${Style.clientGird} ${Style.clientRight}`}>
                    {ClientReviewData?.map((data) => (
                        <div key={data?.id} className={Style.clientGirdCard}>
                            <p
                                className={`${Style.clientText} ${Style.contentText}`}
                            >
                                {data?.desc}
                            </p>
                            <div className={Style.clientCard}>
                                <div className={Style.clientImg}>
                                    <img src={data?.img} alt={data?.name} />
                                </div>

                                <div className={Style.reviewContent}>
                                    <h4 className={Style.clientName}>
                                        {data?.name}
                                    </h4>
                                    <p class={Style.clientDig}>{data?.deg}</p>
                                    <div className={Style.clientStart}>
                                        <img src={Star} alt="" />
                                        <img src={Star} alt="" />
                                        <img src={Star} alt="" />
                                        <img src={Star} alt="" />
                                        <img src={Star} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

const JobSearchSection = () => {
    const JobDescription = [
        'Free Account',
        '11,000+ Active Vacancies',
        'Top Companies',
    ];
    return (
        <section className={`${Style.containers} ${Style.jobContainer}`}>
            <div>
                <h1 className={`${Style.titleText} ${Style.jobTitle}`}>
                    Where Jobs Seeks You
                </h1>
                <div className={Style.jobContent}>
                    {JobDescription?.map((data, i) => (
                        <div key={i} className={Style.jobText}>
                            <img src={Dot} alt={data} />
                            <span style={{ color: '#fff' }}>{data}</span>
                        </div>
                    ))}
                </div>

                <div className={Style.jobBtn}>
                    <Link
                        to="/candidate-register"
                        className={Style.btnsLink}
                    >
                        Get A Free Account
                    </Link>
                </div>
            </div>
        </section>
    );
};

const FloatingSection = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const isScrollingDown = window.scrollY > 200;
            setIsVisible(isScrollingDown);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={isVisible ? `${Style.boxs}` : ''}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'center',
                }}
            >
                <Link to="/candidate-register">
                    <button className={Style.buttons}>Register (Free)</button>
                </Link>
            </div>
        </div>
    );
};

const LP3 = () => {
    return (
        <>
            <BannerSection />
            <SliderSection />
            <JobAboutSection />
            <ClientReviewSection />
            <JobSearchSection />
            <FloatingSection />
        </>
    );
};

export default LP3;
