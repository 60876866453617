import { Box, Button, Grid, Skeleton, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getCategoryData } from '../../../fetchAPI'
import { JobSearchContext } from '../../context/JobSearchContext'
import { useTranslation } from 'react-i18next'

const JobCardSkeleton = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          border: '1px solid #e0e0e0',
          padding: 3,
            width: {
            xs: '100%',
          }
        }}
      > 
        <Grid container spacing={2} columnGap={2}>
            <Grid item xs={3}>
                <Skeleton variant="rounded" width={50} height={50} animation="wave" />
            </Grid>
            <Grid item xs={8}>
                <Skeleton variant="text" width={"80%"} height={30} animation="wave" />
                <Box sx={{display: "flex", gap: 2}}>
                <Skeleton variant="text" width={"60%"} height={30} animation="wave" />
                <Skeleton variant="text" width={"60%"} height={30} animation="wave" />
                </Box>
            </Grid>
        </Grid>
      </Box>
    );
  };

function CategoryBox() {

    const [jobsCategory, setJobCategory] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const { setSearchDepartment, setDefaultSelectDepartment } = useContext(JobSearchContext)

    useEffect(() => {
        setIsLoading(true)
        settingCategoryData()
    }, [])

    const settingCategoryData = () => {

        getCategoryData().then((response) => {
            if (response && response.success) {
                setJobCategory(response?.response?.data)
                setIsLoading(false)
            }
            else {
                // console.log('error: ', response)

            }
        })
    }

    //translation
    const {t} = useTranslation(["homepage", "button"])

    return (
        <Box className='container' sx={{marginTop: "140px", '@media (max-width: 991px)': {marginTop: "20px"}, paddingX: "30px"}}>
            <div className='row'>
            <Grid className='p-3' container spacing={2} sx={{ justifyContent: {xs:'center',md: 'space-between'}, justifyItems: "center" }}>
                <Grid item>
                    {
                        isLoading ?
                        <>
                            <Grid container spacing={1} sx={{ display: { xs: 'none', md: 'flex' } }}>
                                <Grid item>
                                    <Skeleton animation='wave' variant="rounded" width={300} height={40} />
                                </Grid>
                                <Grid item>
                                    <Skeleton animation='wave' variant="rounded" width={40} height={40} />
                                </Grid>
                            </Grid>
                        </>
                        :
                        <>
                            <Typography variant='h3' className='d-none d-lg-block' sx={{ color: '#424447', fontWeight: 700, fontSize: {xs: '20px', sm: '20px', lg: '28px'}, marginTop: "-8px" }}>{t("homepage:explore-by-category.title")}</Typography> {/** Desktop View */}
                        </>
                    }
                    {
                        isLoading ?
                            <>
                                <Grid container spacing={1} sx={{ display: { xs: 'flex', md: 'none' }, m: 3 }}>
                                    <Grid item>
                                        <Skeleton animation='wave' variant="rounded" width={200} height={40} />
                                    </Grid>
                                    <Grid item>
                                        <Skeleton animation='wave' variant="rounded" width={40} height={40} />
                                    </Grid>
                                </Grid>
                            </>
                            :
                    
                            <Typography variant='h3' className='d-lg-none text-center mt-3 responsive_header' sx={{ color: '#424447', fontWeight: 700, fontSize: {xs: '20px', sm: '20px', lg: '28px'} }}>{t("homepage:explore-by-category.title")}</Typography> 
                            //  Mobile View
                    }
                </Grid>
                <Grid>
                    {
                        isLoading ?
                            <Skeleton animation='wave' variant="rounded" width={150} height={50} sx={{ display: { xs: 'none', md: 'flex' } }} />
                            :
                            // <Link to='/search/jobs' className='d-none d-lg-block' style={{ fontSize: '28px', color: '#2a9df4', padding: '10px 60px 10px 60px', border: '1px solid ', borderRadius: '10px' }}>EXPLORE ALL</Link>
                            // <div className='d-none d-lg-block'><Link to='/all-categories' style={{ fontSize: '28px', color: '#2a9df4', padding: '10px 60px 10px 60px', border: '1px solid ', float: 'right', borderRadius: '10px' }}>EXPLORE ALL</Link></div>
                            <Link to='/all-categories'><Button className='d-none d-lg-block' size='large' variant='outlined' style={{ float: 'right' }}>{t("button:explore-all.title")}</Button></Link>
                    }
                </Grid>
            </Grid>
            </div>

            {isLoading ? 
            <div className='row' style={{marginTop: "60px"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Box sx={{display: 'flex', flexDirection: "column", rowGap: 3}}>
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                        </Box>
                    </Grid>
                    <Grid item sm={6} md={4} lg={3} sx={{display: {xs: "none", sm: "block"}}}>
                        <Box sx={{display: 'flex', flexDirection: "column", rowGap: 3}}>
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                        </Box>
                    </Grid>
                    <Grid item md={4} lg={3} sx={{display: {xs: "none", md: "block"}}}>
                        <Box sx={{display: 'flex', flexDirection: "column", rowGap: 3}}>
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                        </Box>
                    </Grid>
                    <Grid item md={4} lg={3} sx={{display: {xs: "none", lg: "block"}}}>
                        <Box sx={{display: 'flex', flexDirection: "column", rowGap: 3}}>
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                        <JobCardSkeleton />
                        </Box>
                    </Grid>
                </Grid>    
            </div>
            :
            <Box className='row' sx={{marginTop: "20px", '@media (max-width: 991px)': {marginTop: "15px"}}}>
                {

                    jobsCategory ?
                        jobsCategory?.map((category, index) => {
                            return (
                                    <div key={index} className='col-lg-3 col-md-4 col-* m-b20'>
                <Link 
                to={{
                    pathname: '/search/jobs',
                    state: { fromHome: true }
                }} 
                onClick={() => [setSearchDepartment(category.id), setDefaultSelectDepartment({ label: category.name, value: category.id })]}>
                    <div className='category-card text-center' style={{ 
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '100%',
                            padding: '25px 20px',
                            border: '1px solid #1167b1',
                            borderRadius: '20px',
                            boxSizing: 'border-box',
                        }}>
                        {/* <div className='category-icon'>
                                                <img src={`/v2/icons/category/${category?.name}.png`} className="p-1" style={{ height: '80px' }} />
                                            </div>
                                            <div className='category-name m-t15'>
                                                <h4 style={{ color: '#03254c' }}>{category?.name}</h4>
                                                <p>{category?.total_job} Open Jobs <br />
                                                    {category?.total_vacancy} Vacancies</p>
                                            </div> */}   
                                            
                                            <Grid container spacing={3} alignItems="center">
                            <Grid item>
                                <img src={`/v2/icons/category/${category?.name}.png`} className="p-1" style={{ height: '50px' }} />
                            </Grid>
                            <Grid item>
                                <Grid container spacing={0}>
                                    <Box component="h4"
                                        sx={{ 
                                            color: '#03254c', 
                                            textAlign: 'left', 
                                            whiteSpace: 'break-spaces',
                                            width: { xs: 'auto', sm: 'auto', md: '170px', lg: '200px' }
                                        }}
                                    >
                                        {category?.name}
                                    </Box>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <div className="job-time mr-auto d-inline-block"><span style={{ fontSize: '0.7rem' }}>{category?.job_count} Open Jobs</span></div>
                                    </Grid>
                                    <Grid item>
                                        <div className="job-time mr-auto d-inline-block"><span style={{ fontSize: '0.7rem' }}>{category?.total_vacancy ?? 0} Vacancies</span></div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Link>
            </div>
                            )
                        })
                        :
                        <Box
                            component="span"
                            sx={{
                                width: 1500,
                                height: 70,
                                p: 3,
                                border: '1px dashed grey',
                                margin: 3
                            }}>

                            <Typography sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>No Jobs Available</Typography>
                        </Box>
                }

                {/* Mobile View */}
                {/* {

                    jobsCategory ?
                        jobsCategory?.slice(0, 4).map((category, index) => {
                            return (
                                <div key={index} className='col-lg-3 col-md-3 m-b20 d-lg-none'>
                                    <Link to='/search/jobs' onClick={() => [setSearchDepartment(category.id), setDefaultSelectDepartment({ label: category.name, value: category.id })]}>
                                        <div className='category-card text-center' style={{ padding: '60px 20px 60px 20px', border: '1px solid #1167b1', borderRadius: '20px' }}>
                                            <div className='category-icon'>
                                                <img src={`/v2/icons/category/${category?.name}.png`} className="p-1" style={{ height: '80px' }} />
                                            </div>
                                            <div className='category-name m-t15'>
                                                <h4 style={{ color: '#03254c' }}>{category?.name}</h4>
                                                <p>{category?.total_job} Open Jobs <br />
                                                    {category?.total_vacancy} Vacancies</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })
                        :
                        ""
                } */}

            </Box>
            }

            {/* Mobile View */}
            <div className='text-c'>
                {isLoading ?
                    <Skeleton animation='wave' variant="rounded" width={150} height={50} sx={{ display: { xs: 'none', md: 'flex' }, marginY: "30px" }} />
                    :
                    <Link to='/all-categories'><Button className='d-block d-lg-none text-center' size='large' variant='outlined' sx={{ marginTop: '20px !important' }}>{t("button:explore-all.title")}</Button></Link>
            }
            </div>
        </Box>
    )
}

export default CategoryBox