import React from "react";
import AddSVG from "./AddSVG";
import styles from '../CandidateUpdateProfile.module.css';
import { Box } from "@mui/material";

export default function AddButton(props)
{
    return (
        <div className={styles.addButton} onClick={props.onClickHandler}>
            <AddSVG/>
            <div className={styles.addButtonTitle}>
                <Box>Add&nbsp;</Box>
                <Box sx={{display:{xs:'none',sm:'none',md:'block'}}}>{props.title}</Box>
            </div>
        </div>
    )
}