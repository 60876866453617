import React from 'react'
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

function ErrorAlert(props) {

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={props.open}
                onClose={props.onHide}
                // key={vertical + horizontal}
                sx={{'& .MuiAlert-root': {boxShadow: '1px 1px 5px #bd87878f'}}}
                
            ><Alert severity="error">{props.message}</Alert></Snackbar>
        </div>
    )
}

export default ErrorAlert