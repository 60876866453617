/**Imports */

import React, { useState, useContext, useEffect } from 'react'
import EmployerDashboardSideBar from '../../components/EmployerComponents/EmployerDashboardSideBar/EmployerDashboardSideBar'
import Select from 'react-select'
import { Link, useHistory, useParams } from 'react-router-dom';
import SuccessAlert from '../../components/Alerts/SuccessAlert';
import FormGroup from '@mui/material/FormGroup';
import { updateJob, updateCustomQuestion, deleteCustomQuestion, getBenifits, getBenifitChild } from '../../../fetchAPI';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { DropdownContext } from '../../context/DropdownContext';
import { Modal } from 'react-bootstrap'
import Alert from '@mui/material/Alert';
import ErrorAlert from '../../components/Alerts/ErrorAlert';
import { Button } from '@mui/material';
import { getJobDetails } from '../../../fetchAPI';
import ReactQuill from 'react-quill';
import { LoadingButton } from '@mui/lab';

import { handleKeyDown } from '../../helpers/helperFunctions';
import PageNotFound from '../PageNotFound';
import { DeleteSVG } from './EmployerPostJob';
import JobPosstCustomQuestionUpdate from './component/JobPosstCustomQuestionUpdate';
import GlobalLoader from '../../components/GlobalLoader';
import RequiredAsterisk from '../../components/CandidateComponents/CandidateProfile/UpdateProfile/component/RequiredAsterisk';

// TODO: Fix Comments on this file

/**
 * Global Variables
 */

const experience = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]


/**
 * Component for Employers to be able to Post Jobs
 *  
 * @component
 */
function EmployerUpdatePostJob() {

    // UseState Declarations
    const [jobDescription, setJobDescription] = useState('')
    const [jobRequirement, setJobRequirement] = useState('')
    const [jobData, setJobData] = useState('');
    const [jobLoading, setJobLoading] = useState(true)

    const [openAlert, setOpenAlert] = useState(false)
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')

    const [updateQuestion, setUpdateQuestion] = useState([])
    const [customQuesDelRespModal, setCustomQuesDelRespModal] = useState(false)
    const [customQuesDelRespMessage, setCustomQuesDelRespMessage] = useState('')
    const [customQuestionLoading, setCustomQuestionLoading] = useState(false)
    const [updateCusQusList, setUpdateCusQusList] = useState({ custom_question: [] })
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [errors, setErrors] = useState('')
    const [headHuntingJob, setHeadHuntingJob] = useState(false)
    const [cxoJob, setCxoJob] = useState(false)
    const [updateJobPost, setUpdateJobPost] = useState('');
    const [buttonLoader, setButtonLoader] = useState(false)
    const [selectedSkill, setSelectedSkill] = useState(null);
    const [selectedSkillArray, setSelectedSkillArray] = useState([]);
    const [yearsOfExperience, setYearsOfExperience] = useState('');
    const [jobSkills, setJobSkills] = useState([]);

    const [isCxoJob, setIsCxoJob] = useState(false)

    
    const [customQuestionArray, setCustomQuestionArray] = useState([])

    useEffect( () => {setJobSkills(jobData?.skills)}, [jobData.skills])
    useEffect( () => {
        const formatSkills = jobSkills?.map((skill) => ({
            skill_id: skill.skill_id, 
            year_of_experience: parseInt(skill?.year_of_experience).toFixed(1), 
            skill_name: skill?.skill?.skill_name || skill?.skill_name
        }))
        setSelectedSkillArray(formatSkills)
    }, [jobSkills])


    // UseContext Declarations
    const { qualifications, types, levels, skills, departments, industries, positions, shifts, specializations, salary, currency, genders, district, apiCallingConditionSet, setApiCallingConditionSet } = useContext(DropdownContext)

    useEffect(() => {
        setApiCallingConditionSet({
            ...apiCallingConditionSet,
            qualification: true,
            type: true,
            level: true,
            department: true,
            industry: true,
            position: true,
            skill: true,
            shift: true,
            salaryType: true,
            district: true,
            currency: true
        })
    }, [])

    // Use History declaration
    const history = useHistory();

    // Initializing Job Data
    const settingJobData = () => {
        getJobDetails(job_id).then(response => {
            if(response.success){
                setJobData(response.response.data)
                setJobLoading(false)
                setUpdateQuestion(response.response.data?.questions.map(val => ({ 'question_id': val.id, 'question': val.question })))
                setCustomQuestionArray(response.response.data?.questions.map(val => ({ 'question_id': val.id, 'question': val.question, 'options':val?.options?.map((optionItem)=>({ id: optionItem.id,option:optionItem.option})) })))
            }else{
                setJobLoading(false)
                setJobData("")
            }
            // settingDropdownData()
        }).catch(error => {
            console.log("Data fetching error : ", error)
        })
    }

    useEffect(() => {
        settingJobData()
    }, [customQuestionLoading])

    // Use Effect Declarations
    useEffect(() => {
        setUpdateJobPost({
            ...updateJobPost,
            'post_id': jobData.post_id,
            'job_title': jobData.job_title,
            'industry': jobData.industry?.id,
            'department': jobData.department?.id,
            'position': jobData.position?.id,
            'job_deadline': jobData.job_deadline,
            'job_description': jobData.job_description,
            'job_requirement': jobData?.job_requirement,
            'qualification': jobData.qualification?.id,
            'qualification_prefer': jobData.qualification_prefer?.id,
            'job_type': jobData.job_type?.id,
            'salary_type': jobData.salary_type?.id,
            'maximum_salary': jobData.maximum_salary,
            'minimum_salary': jobData.minimum_salary,
            'minimum_experience': jobData.minimum_experience,
            'maximum_experience': jobData.maximum_experience,
            'job_shift': jobData.job_shift?.id,
            'job_skill': selectedSkillArray,
            'number_of_vacancy': jobData.number_of_vacancy,
            'job_address': jobData.job_address,
            'job_level': jobData.job_level?.id,
            'job_status': jobData.job_status,
            'head_hunting': jobData.head_hunting,
            'is_cxo': jobData.is_cxo,
            'job_id': jobData.post_id,
            'user_id': jobData.author_id,
            'benefit': jobData.benefits?.map(val => val.benefit_option_id),
            'gender': jobData.gender,
            'negotiable': jobData.negotiable,
            'not_specific': jobData.not_specific,
            'is_cxo' : jobData.is_cxo,
            district_id : jobData.district?.id
        })
        setHeadHuntingJob(jobData.head_hunting)
        setCxoJob(jobData.is_cxo)
    }, [jobData]);

    useEffect(() => {
        setUpdateJobPost({ ...updateJobPost, head_hunting: +headHuntingJob })
    }, [headHuntingJob])

    useEffect(() => {
        setUpdateJobPost({ ...updateJobPost, is_cxo: +cxoJob })
    }, [cxoJob])

    useEffect(() => {
        setUpdateJobPost({ ...updateJobPost, job_description: jobDescription, job_skill: jobSkills })
    }, [jobDescription, jobSkills])

    useEffect(() => {
        setUpdateJobPost({ ...updateJobPost, job_skill: jobSkills })
    }, [jobSkills])

    useEffect(() => {
        setUpdateCusQusList({ custom_question: updateQuestion })
    }, [updateQuestion])

    const [jobBenefitCats, setjobBenefitCats] = useState([])
    const [jobBenefitCatOptns, setjobBenefitCatOptns] = useState([])
    const [currentBenefit, setCurrentBenefit] = useState(2)
    const [checkedBenefitList, setCheckedBenefitList] = useState([])

    useEffect(() => {
        getBenifits()
            .then(response => {
                if (response && response.success) {
                    setjobBenefitCats(response.response.data)
                }
            })
    }, [])

    useEffect(() => {
        getBenifitChild(currentBenefit)
            .then(response => {
                if (response && response.success) {
                    setjobBenefitCatOptns(response.response.data?.child)
                }
            })
    }, [currentBenefit])

    useEffect(() => {
        setCheckedBenefitList(jobData.benefits?.map(val => val.benefit_option_id))
    }, [jobData])

    function defaultSelectedCheckBox(item) {
        if (checkedBenefitList.includes(item.id)) {
            return 'checked=checked'
        } else {
            return ''
        }
    }

    useEffect(() => {
        setUpdateJobPost({ ...updateJobPost, benefit: checkedBenefitList?.toString() })
    }, [checkedBenefitList])

    // Error Handling variable declaration
    let errorsObj = {
        job_title: '',
        industry: '',
        department: '',
        position: '',
        job_deadline: '',
        job_description: '',
        job_requirement: '',
        qualification: '',
        job_type: '',
        salary_type: '',
        minimum_salary: '',
        maximum_salary: '',
        negotiable: '',
        minimum_experience: '',
        maximum_experience: '',
        job_shift: '',
        job_level: '',
        job_skill: '',
        number_of_vacancy: '',
        job_address: '',
        district_id: ''
    };

    // Variables
    const { job_id } = useParams()


    /**
    * Create a function that handle the input event and
    * save the value of that event on an state every time the component change
    * 
    * Setting Sort By information in Status state
    * 
    * @param {string} name
    * @param {event} event 
    */

    const handleChange = (name) => (event) => {
        setUpdateJobPost({ ...updateJobPost, [name]: event.target.value })
    }

    const handleChangeSelect = (name) => (event) => {
        setUpdateJobPost({ ...updateJobPost, [name]: event.value })
    }

    const handleChangeMultiSelect = (name) => (event) => {
        setUpdateJobPost({ ...updateJobPost, [name]: event.map(val => val.value).toString() })
    }

    // Set custom question
    const handleChangeCustomQuestion = (id, index) => (event) => {
        let questions = [...updateQuestion]
        questions[index] = { 'question_id': id ? id : "", 'question': event.target.value }
        setUpdateQuestion(questions)

    }

    // handle click event of the Remove button of Custom Questions
    // const handleRemoveClick = (jobId, questionId, index) => {
    //     if (questionId) {
    //         setCustomQuestionLoading(true)
    //         removeCustomQuestion(jobId, questionId)
    //     } else {
    //         const list = [...updateQuestion];
    //         list.splice(index, 1);
    //         setUpdateQuestion(list);
    //         setCustomQuestionLoading(false)
    //     }

    // };

    // handle click event of the Add button of Custom Questions
    const handleAddClick = (index) => {
        setUpdateQuestion([...updateQuestion, { 'question_id': "", 'question': "" }]);
    };

    /**
     * Validations
     */

    const validatePostJob = (body) => {
        setButtonLoader(true)
        let error = false;

        const errorObj = { ...errorsObj };

        if (!body.job_title) {
            errorObj.job_title = 'Job Title is Required';
            error = true;
        }
        if (!body.industry) {
            errorObj.industry = 'Industry is Required';
            error = true;
        }
        if (!body.department) {
            errorObj.department = 'Department is Required';
            error = true;
        }
        if (!body.position) {
            errorObj.position = 'Position is Required';
            error = true;
        }
        if (!body.job_deadline) {
            errorObj.job_deadline = 'Deadline is Required';
            error = true;
        }
        if (!body.job_description) {
            errorObj.job_description = 'Description is Required';
            error = true;
        }
        if (body.job_requirement === "") {
            errorObj.job_requirement = 'Requirement is Required';
            error = true;
        }
        if (!body.qualification) {
            errorObj.qualification = 'Qualification is Required';
            error = true;
        }
        if (!body.salary_type) {
            errorObj.salary_type = 'Salary Type is Required';
            error = true;
        }
        if (!body.minimum_salary && body.negotiable == false) {
            errorObj.minimum_salary = 'Minimum Salary is Required';
            error = true;
        }else if (cxoJob && body.minimum_salary < 100000 && !body.negotiable){
            errorObj.minimum_salary = 'Cxo job minimum salary should not be less than 100000';
            error = true;
        }
        if (!body.maximum_salary && body.negotiable == false) {
            errorObj.maximum_salary = 'Maximum Salary is Required';
            error = true;
        }
        if (cxoJob && body.negotiable) {
            errorObj.negotiable = 'Cxo job salary should not be negotiable';
            error = true;
        }
        if (body.minimum_experience === '') {
            errorObj.minimum_experience = 'Minimum Experience is Required';
            error = true;
        }
        if (body.maximum_experience === '') {
            errorObj.maximum_experience = 'Maximum Experience is Required';
            error = true;
        }
        if (!body.job_shift) {
            errorObj.job_shift = 'Job Shift is Required';
            error = true;
        }
        if (!body.job_type) {
            errorObj.job_type = 'Job Type is Required';
            error = true;
        }
        if (!body.job_level) {
            errorObj.job_level = 'Level is Required';
            error = true;
        }
        if ((body.job_skill === undefined || body.job_skill.length === 0) && selectedSkillArray.length === 0) {
            errorObj.job_skill = 'Skill is Required';
            error = true;
        }
        if (!body.number_of_vacancy && body.not_specific == false) {
            errorObj.number_of_vacancy = 'Vacancies is Required';
            error = true;
        }
        if (!body.job_address) {
            errorObj.job_address = 'Address is Required';
            error = true;
        }
        if (body.district_id === undefined && jobData.district?.id === undefined) {
            errorObj.job_address = 'Location is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            // Do not call the Post API
            setButtonLoader(false)
        }
        else {
            setUpdateJobPost({ ...updateJobPost, job_status: 'published' })
            setConfirmationModal(true)
            setButtonLoader(false)
        }

    }


    /**
    * Calling Update Job API 
    * 
    * @param {object} body - Sending Job Information
    * @return {object} Success or false json with Active Job Data
    */
    const updateJobData = (body, customQuestions) => {

        // Performing the two CXO-specific checks
        if (cxoJob) {
            if(!body.minimum_salary && !body.negotiable){
                setErrors('The mimimum salary field is required');
                setResponseMessage('The mimimum salary field is required');
                setOpenErrorAlert(true)
            }else if (body.minimum_salary < 100000 && !body.negotiable) {
                setErrors('Cxo job minimum salary should not be less than 100000');
                setResponseMessage('Cxo job minimum salary should not be less than 100000');
                setOpenErrorAlert(true)
                return;
            } else if (body.negotiable) {
                setErrors('CXO job salary should not be negotiable');
                setResponseMessage('CXO job salary should not be negotiable');
                setOpenErrorAlert(true)
                return;
            }
        }

        setButtonLoader(true)
        updateJob(body).then((response) => {
            if (response && response.success) {
                updateCustomQuestion(jobData.post_id, customQuestions)
                    .then((response) => {
                        if (response && response.success) {
                            setConfirmationModal(false)
                            setTimeout(() => {
                                history.push("/employer-manage-jobs");
                            }, 1000)
                        } else {
                            setErrors(response.errors[0].message);
                            setResponseMessage(response.errors[0].message);
                            setOpenErrorAlert(true)
                        }
                    })
                setResponseMessage(response.response.message)
                setOpenAlert(true);
            }
            else {
                setErrors(response.errors[0].message);
                setResponseMessage(response.errors[0].message);
                setOpenErrorAlert(true)
                setConfirmationModal(false)
            }
        }).then(() => setButtonLoader(false))
    }

    const formatSkills = (data) => {
        return data.map(skill => ({
            label: skill.skill_name,
            value: skill.id
        }));
    }
    const skillsOption = formatSkills(skills);

    const handleSkillChange = (selectedOption) => {
        setSelectedSkill(selectedOption);
    };

    const addValueToArray = () => {
        if (selectedSkill && yearsOfExperience) {
            const newSkill = {
                skill_id: selectedSkill.value,
                skill_name: selectedSkill.label,
                year_of_experience: parseInt(yearsOfExperience)
            };
            const isExists = selectedSkillArray?.find((skill) => skill.skill_id == newSkill.skill_id)
            if(!isExists){
                setJobSkills([...selectedSkillArray, newSkill]);
            }else{
                setErrors({skill_exists: `${isExists.skill_name} already added`})
            }
            // Reset the fields after adding
            setSelectedSkill(null);
            setYearsOfExperience('');
        }
    };

    const handleDelete = (id) => {
        const currentSkills = selectedSkillArray?.filter((item) => item.skill_id !== id)
        setJobSkills(currentSkills);
        setSelectedSkillArray(currentSkills);
    }


    /**
   * Calling Delete Job API 
   * 
   * @param {object} TODO: Review - Sending Employer ID and Status
   * @return {object} Success or false json
   */

    // Remove Custom Question function
    // const removeCustomQuestion = (jobId, questionId) => {

    //     // Delete Custom Question API
    //     deleteCustomQuestion(jobId, questionId).then((response) => {
    //         // console.log(response)
    //         if (response && response.success) {
    //             setCustomQuesDelRespMessage(response.response.message)
    //             setCustomQuesDelRespModal(true)
    //         }
    //         else {
    //             setCustomQuesDelRespModal(true)
    //             setCustomQuesDelRespMessage(response.errors[0].message)
    //         }
    //     })
    // }


    // Headhunting Switch
    const IOSSwitch = styled((props) => (
        <Switch edge='start' focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    const todayDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        const formattedToday = yyyy + '-' + mm + '-' + dd;

        return formattedToday
    }

    if(!jobData && !jobLoading) return <PageNotFound />

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-lg-4 col-md-0 d-none d-lg-block'>
                    <EmployerDashboardSideBar />
                </div>
                <div className='col-lg-8 col-md-12'>
                    <div className='atb-card'>
                        {
                            <div className='container'>
                                <button type="button" class="btn btn-outline-primary btn-sm float-right d-lg-none"><i class="fa fa-bars" aria-hidden="true"></i> Menu</button>
                                <div className='row mb-1'>
                                    <div className='col-lg-8 col-md-0'>
                                        <div className='text-left'>
                                            <h2 className=''> Update Job</h2>
                                        </div>
                                    </div>
                                </div><hr />
                                {jobLoading ?
                                    <GlobalLoader height="85vh" />
                                    :
                                    <form>
                                        <div className="row text-left">
                                            <div className="col-lg-12 col-md-12">
                                                {/* <FormGroup style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '10px' }}>
                                                    <FormControlLabel
                                                        control={<IOSSwitch defaultChecked={headHuntingJob} sx={{ m: 1 }} />}
                                                        label="Head-Hunting Job"
                                                        labelPlacement="start"
                                                        onChange={() => setHeadHuntingJob(prevState => !prevState)}
                                                    />
                                                </FormGroup> */}
                                                {/* <FormGroup style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '10px' }}>
                                                    <FormControlLabel
                                                        control={<IOSSwitch sx={{ m: 1 }} />}
                                                        label="CXO Job"
                                                        labelPlacement="start"
                                                        onChange={() => setIsCxoJob(prevState => !prevState)}
                                                    />
                                                </FormGroup> */}
                                                <FormGroup style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '10px' }}>
                                                    <FormControlLabel
                                                        control={<IOSSwitch defaultChecked={cxoJob} sx={{ m: 1 }} />}
                                                        label="CXO Job"
                                                        labelPlacement="start"
                                                        onChange={() => setCxoJob(prevState => !prevState)}
                                                    />
                                                </FormGroup>
                                                <div className="form-group">
                                                    <label>Job Title <RequiredAsterisk/></label>
                                                    <input type="text" defaultValue={jobData.job_title} className="form-control atb-input-box" onChange={handleChange('job_title')} placeholder="Enter Job Title" required />
                                                    {errors.job_title && <Alert severity="error">{errors.job_title}</Alert>}
                                                </div>
                                            </div>
                                            {/* Industry */}
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Industry <RequiredAsterisk/></label>
                                                    <Select
                                                        defaultValue={{ label: jobData.industry?.name, value: jobData.industry?.id }}
                                                        options=
                                                        {industries.map(ind => {
                                                            return (
                                                                { label: ind.name, value: ind.id }
                                                            )
                                                        })
                                                        }
                                                        className='atb-input-box-container'
                                                        classNamePrefix='atb-input-box'
                                                        onChange={handleChangeSelect('industry')}
                                                    />
                                                </div>
                                                {errors.industry && <Alert severity="error">{errors.industry}</Alert>}
                                            </div>
                                            {/* Department  */}
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Department <RequiredAsterisk/></label>
                                                    <Select
                                                        defaultValue={{ label: jobData.department?.name, value: jobData.department?.id }}
                                                        options=
                                                        {departments.map(dept => {
                                                            return (
                                                                { label: dept.name, value: dept.id }
                                                            )
                                                        })
                                                        }
                                                        className='atb-input-box-container'
                                                        classNamePrefix='atb-input-box'
                                                        onChange={handleChangeSelect('department')}
                                                    />
                                                </div>
                                                {errors.department && <Alert severity="error">{errors.department}</Alert>}
                                            </div>
                                            {/* Position  */}
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Position <RequiredAsterisk/></label>
                                                    <Select
                                                        defaultValue={{ label: jobData.position?.name, value: jobData.position?.id }}
                                                        options=
                                                        {positions.map(item => {
                                                            return (
                                                                { label: item.name, value: item.id }
                                                            )
                                                        })
                                                        }
                                                        className='atb-input-box-container'
                                                        classNamePrefix='atb-input-box'
                                                        onChange={handleChangeSelect('position')}
                                                    />
                                                </div>
                                                {errors.position && <Alert severity="error">{errors.position}</Alert>}
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Application Deadline <RequiredAsterisk/></label>
                                                    <input type="date" defaultValue={jobData.job_deadline} className="form-control atb-input-box" min={todayDate()} onChange={handleChange('job_deadline')} required />
                                                </div>
                                                {errors.job_deadline && <Alert severity="error">{errors.job_deadline}</Alert>}
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label>Job Description <RequiredAsterisk/></label>

                                                    {/* Text Editor advanced */}
                                                    <ReactQuill
                                                        placeholder="Enter job description"
                                                        onChange={(description) => setJobDescription(description)}
                                                        defaultValue={jobData.job_description}
                                                    />

                                                </div>
                                                {errors.job_description && <Alert severity="error">{errors.job_description}</Alert>}
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Specific Gender</label>
                                                    <Select
                                                        defaultValue={{ label: jobData.gender === 1 ? 'Male' : jobData.gender === 2 ? 'Female' : null, value: jobData.gender }}
                                                        options=
                                                        {genders.map(item => {
                                                            return (
                                                                { label: item.name, value: item.id }
                                                            )
                                                        })
                                                        }
                                                        className='atb-input-box-container'
                                                        classNamePrefix='atb-input-box'
                                                        onChange={handleChangeSelect('gender')}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label>Job Requirements <RequiredAsterisk/></label>
                                                    {/* Text Editor advanced */}
                                                    <ReactQuill
                                                        placeholder="Enter job requirements"
                                                        onChange={(requirement) => setUpdateJobPost({...updateJobPost, job_requirement : requirement})}
                                                        defaultValue={jobData.job_requirement}
                                                    />
                                                </div>
                                                {errors.job_requirement && <Alert severity="error">{errors.job_requirement}</Alert>}
                                            </div>

                                            <div className='col-lg-12 col-md-12 m-b30 m-t30'>
                                                <h5 className="font-weight-700 pull-left text-uppercase">Details Information</h5> <hr />
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <h6>Academic Qualification</h6>
                                                <div className="form-group">
                                                    <label>Minimum <RequiredAsterisk/></label>
                                                    <Select
                                                        defaultValue={{ label: jobData.qualification?.name, value: jobData.qualification?.id }}
                                                        options=
                                                        {qualifications ? qualifications.map(item => {
                                                            return (
                                                                { label: item.name, value: item.id }
                                                            )
                                                        }) : " "
                                                        }
                                                        className='atb-input-box-container'
                                                        classNamePrefix='atb-input-box'
                                                        onChange={handleChangeSelect('qualification')}
                                                        required />
                                                </div>
                                                {errors.qualification && <Alert severity="error">{errors.qualification}</Alert>}
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <h6 style={{ visibility: "hidden" }}> Academic Qualification</h6>
                                                <div className="form-group">
                                                    <label>Prefer</label>
                                                    <Select
                                                        defaultValue={{ label: jobData.qualification_prefer?.name, value: jobData.qualification_prefer?.id }}
                                                        options=
                                                        {qualifications ? qualifications.map(item => {
                                                            return (
                                                                { label: item.name, value: item.id }
                                                            )
                                                        }) : " "
                                                        }
                                                        className='atb-input-box-container'
                                                        classNamePrefix='atb-input-box'
                                                        onChange={handleChangeSelect('qualification_prefer')}
                                                    />
                                                </div>
                                                {errors.job_type && <Alert severity="error">{errors.job_type}</Alert>}
                                            </div>
                                            {/* Job Type  */}
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Job Type <RequiredAsterisk/></label>
                                                    <Select
                                                        defaultValue={{ label: jobData.job_type?.name, value: jobData.job_type?.id }}
                                                        options=
                                                        {types ? types.map(item => {
                                                            return (
                                                                { label: item.name, value: item.id }
                                                            )
                                                        }) : " "
                                                        }
                                                        className='atb-input-box-container'
                                                        classNamePrefix='atb-input-box'
                                                        onChange={handleChangeSelect('job_type')}
                                                        required />
                                                </div>
                                            </div>

                                            {/* Salary Type  */}
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Salary Type <RequiredAsterisk/></label>
                                                    <Select
                                                        defaultValue={{ label: jobData.salary_type?.type, value: jobData.salary_type?.id }}
                                                        options=
                                                        {salary ? salary.map(item => {
                                                            return (
                                                                { label: item.type, value: item.id }
                                                            )
                                                        }) : " "
                                                        }
                                                        className='atb-input-box-container'
                                                        classNamePrefix='atb-input-box'
                                                        onChange={handleChangeSelect('salary_type')}
                                                        required />
                                                </div>
                                                {errors.salary_type && <Alert severity="error">{errors.salary_type}</Alert>}
                                            </div>

                                            <div className='col-lg-12 col-md-12'>
                                                <div className='row'>
                                                    {/* Minimum Salary  */}
                                                    <div className="col-lg-4 col-md-4">
                                                        <h6>Salary Range</h6>
                                                        <hr className='d-lg-none' />
                                                        <div className="form-group">
                                                            <label>Minimum <RequiredAsterisk/></label>
                                                            <input type="number" disabled={updateJobPost.negotiable} defaultValue={jobData.minimum_salary} min="0" onChange={handleChange('minimum_salary')} className="form-control atb-input-box mb-4" placeholder="e.g. 10000" />
                                                            {errors.minimum_salary && <Alert severity="error">{errors.minimum_salary}</Alert>}
                                                            
                                                            <div className="custom-control custom-checkbox m-t10">
                                                                <input onChange={(e) => setUpdateJobPost({ ...updateJobPost, negotiable: e.target.checked })} checked={updateJobPost.negotiable} type="checkbox" className="custom-control-input" id={`negotiable`} name="negotiable" />
                                                                <label className="custom-control-label" htmlFor={`negotiable`}>{'Negotiable'}</label>
                                                                {errors.negotiable && <Alert severity="error" sx={{marginLeft: "-24px", marginTop:'4px'}}>{errors.negotiable}</Alert>}
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    {/* Maximum Salary  */}
                                                    <div className="col-lg-4 col-md-4">
                                                        <h6 style={{ visibility: "hidden" }}>Salary Range</h6>
                                                        <div className="form-group">
                                                            <label>Maximum <RequiredAsterisk/></label>
                                                            <input type="number" disabled={updateJobPost.negotiable} defaultValue={jobData.maximum_salary} min="0" onChange={handleChange('maximum_salary')} className="form-control atb-input-box" placeholder="e.g. 20000" />
                                                        </div>
                                                        {errors.maximum_salary && <Alert severity="error">{errors.maximum_salary}</Alert>}
                                                    </div>
                                                    {/* Maximum Salary  */}
                                                    <div className="col-lg-4 col-md-4">
                                                        <h6 style={{ visibility: "hidden" }}>Salary Range</h6>
                                                        <div className="form-group">
                                                            <label>Currency</label>
                                                            <Select
                                                                defaultValue={{ label: jobData?.salary_currency?.name, value: jobData?.salary_currency?.id }}
                                                                isDisabled={updateJobPost.negotiable}
                                                                options={currency?.map((item, index) => {
                                                                    return (
                                                                        { label: item.name, value: item.id }
                                                                    )
                                                                })
                                                                }
                                                                className='atb-input-box-container'
                                                                classNamePrefix='atb-input-box'
                                                                onChange={handleChangeSelect('salary_currency')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            {/* Minimum Salary  */}
                                            {/* <div className="col-lg-6 col-md-6">
                                                <h6>Salary Range</h6>
                                                <div className="form-group">
                                                    <label>Minimum</label>
                                                    <input type="number" disabled={updateJobPost.negotiable} defaultValue={jobData.minimum_salary} min="0" onChange={handleChange('minimum_salary')} className="form-control atb-input-box" placeholder="e.g. 10000" />
                                                    <div className="custom-control custom-checkbox m-t10">
                                                        <input onChange={(e) => setUpdateJobPost({ ...updateJobPost, negotiable: e.target.checked })} checked={updateJobPost.negotiable} type="checkbox" className="custom-control-input" id={`negotiable`} name="negotiable" />
                                                        <label className="custom-control-label" htmlFor={`negotiable`}>{'Negotiable'}</label>
                                                    </div>
                                                </div>
                                                {errors.minimum_salary && <Alert severity="error">{errors.minimum_salary}</Alert>}
                                            </div> */}
                                            {/* Maximum Salary  */}
                                            {/* <div className="col-lg-6 col-md-6">
                                                <h6 style={{ visibility: "hidden" }}>Salary Range</h6>
                                                <div className="form-group">
                                                    <label>Maximum</label>
                                                    <input type="number" disabled={updateJobPost.negotiable} defaultValue={jobData.maximum_salary} min="0" onChange={handleChange('maximum_salary')} className="form-control atb-input-box" placeholder="e.g. 20000" />
                                                </div>
                                                {errors.maximum_salary && <Alert severity="error">{errors.maximum_salary}</Alert>}
                                            </div> */}

                                            {/*Minimum Experience  */}
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Minimum Experience <RequiredAsterisk/></label>
                                                    <Select
                                                        defaultValue={{ label: jobData.minimum_experience ? jobData.minimum_experience + ' Year' : '', value: jobData.minimum_experience ? jobData.minimum_experience : '' }}
                                                        options=
                                                        {experience.map(exp => {
                                                            return (
                                                                { label: exp + " Year", value: exp }
                                                            )
                                                        })
                                                        }
                                                        className='atb-input-box-container'
                                                        classNamePrefix='atb-input-box'
                                                        onChange={handleChangeSelect('minimum_experience')}
                                                    />
                                                </div>
                                                {errors.minimum_experience && <Alert severity="error">{errors.minimum_experience}</Alert>}
                                            </div>

                                            {/*Maximum Experience  */}
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Maximum Experience <RequiredAsterisk/></label>
                                                    <Select
                                                        defaultValue={{ label: jobData.maximum_experience ? jobData.maximum_experience + ' Year' : '', value: jobData.maximum_experience ? jobData.maximum_experience : '' }}
                                                        options=
                                                        {experience.map(exp => {
                                                            return (
                                                                { label: exp + " Year", value: exp }
                                                            )
                                                        })
                                                        }
                                                        className='atb-input-box-container'
                                                        classNamePrefix='atb-input-box'
                                                        onChange={handleChangeSelect('maximum_experience')}
                                                    />
                                                </div>
                                                {errors.maximum_experience && <Alert severity="error">{errors.maximum_experience}</Alert>}
                                            </div>
                                            {/* Shift  */}
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Job Shift <RequiredAsterisk/></label>
                                                    <Select
                                                        defaultValue={{ label: jobData.job_shift?.name, value: jobData.job_shift?.id }}
                                                        options=
                                                        {shifts ? shifts.map(item => {
                                                            return (
                                                                { label: item.name, value: item.id }
                                                            )
                                                        }) : " "
                                                        }
                                                        className='atb-input-box-container'
                                                        classNamePrefix='atb-input-box'
                                                        onChange={handleChangeSelect('job_shift')}
                                                        required />
                                                </div>
                                                {errors.job_shift && <Alert severity="error">{errors.job_shift}</Alert>}
                                            </div>
                                            {/* Level  */}
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Job Level <RequiredAsterisk/></label>
                                                    <Select
                                                        defaultValue={{ label: jobData.job_level?.candidate_level_name, value: jobData.job_level?.id }}
                                                        options=
                                                        {levels ? levels.map(item => {
                                                            return (
                                                                { label: item.candidate_level_name, value: item.id }
                                                            )
                                                        }) : " "
                                                        }
                                                        className='atb-input-box-container'
                                                        classNamePrefix='atb-input-box'
                                                        onChange={handleChangeSelect('job_level')}
                                                        required />
                                                </div>
                                                {errors.job_level && <Alert severity="error">{errors.job_level}</Alert>}
                                            </div>
                                            {/* Skill  */}
                                        
                                    <div className='d-flex justify-content-center align-items-center col-lg-12'>
                                    <div className="w-50">
                                        <div className="form-group">
                                            <label>Job Skills <RequiredAsterisk/></label>
                                            <Select
                                                options={skillsOption}
                                                className='atb-input-box-container'
                                                classNamePrefix='atb-input-box'
                                                onChange={handleSkillChange}
                                                value={selectedSkill}
                                                required  isClearable/>
                                        </div>
                                       
                                    </div>
                                    <div className="w-50 ml-4 mr-3">
                                        <div className="form-group">
                                            <label>Years of Experience</label>
                                            <input 
                                               className='form-control atb-input-box' 
                                               type="number" 
                                               placeholder='Number of Years' 
                                               onKeyDown={(e) => handleKeyDown(e)}
                                               value={yearsOfExperience}
                                               onChange={(e) => setYearsOfExperience(e.target.value)} 
                                            />
                                        </div>
                                    </div>
                                    <Button
                                      variant="outlined"
                                      fullWidth
                                      sx={{
                                        paddingY: "9px",
                                        backgroundColor: "#CBE7FF",
                                        maxWidth: "100px"
                                      }}
                                      onClick={addValueToArray}
                                    >
                                      Add New
                                    </Button>
                                    </div>
                                    <div className='col-12'>
                                        <div className="d-flex flex-row flex-wrap align-items-center">
                                            {selectedSkillArray?.length > 0 ? selectedSkillArray?.map((item, index) => {
                                                return (
                                                    <div className="d-flex align-items-center border mr-2 my-1 p-1" key={index}>
                                                        <div>
                                                            <span>{item?.skill_name}</span>
                                                            <span> - {item.year_of_experience} {item.year_of_experience > 1 ? "Years" : "Year"}</span>
                                                        </div>
                                                        <div style={{ marginLeft: '10px' }}>
                                                            <span style={{width: "20px" , height: "20px" }} onClick={() => handleDelete(item.skill_id)}>
                                                                <DeleteSVG />
                                                            </span>
                                                        </div>
                                                    </div>
                                                ) 
                                            }) : null}
                                        </div>
                                        {errors.job_skill ? <Alert severity="error">{errors.job_skill}</Alert> : null}
                                        {errors.skill_exists ? <Alert severity="error">{errors.skill_exists}</Alert> : null}
                                    </div>
                                            <div className="col-lg-12 col-md-6">
                                                <div className="form-group">
                                                    <label>Number of Vacancy <RequiredAsterisk/></label>
                                                    <input className='form-control atb-input-box' disabled={updateJobPost.not_specific} defaultValue={jobData.number_of_vacancy} type="number" onChange={handleChange('number_of_vacancy')} placeholder='Number of Vacant Positions' />
                                                    <div className="custom-control custom-checkbox m-t10">
                                                        <input onChange={(e) => setUpdateJobPost({ ...updateJobPost, not_specific: e.target.checked })} checked={updateJobPost.not_specific} type="checkbox" className="custom-control-input" id={`not_specific`} name="not_specific" />
                                                        <label className="custom-control-label" htmlFor={`not_specific`}>{'Not Specific'}</label>
                                                    </div>
                                                </div>
                                                {errors.number_of_vacancy && <Alert severity="error">{errors.number_of_vacancy}</Alert>}
                                            </div> 
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Address <RequiredAsterisk/></label>
                                                    <textarea type="text" defaultValue={jobData.job_address} className="form-control atb-input-box" onChange={handleChange('job_address')} placeholder="Location" />
                                                </div>
                                                {errors.job_address && <Alert severity="error">{errors.job_address}</Alert>}
                                            </div>
                                            {/* Location */}
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Location <RequiredAsterisk/></label>
                                                    <Select
                                                        defaultValue={{ label: jobData.district?.name, value: jobData.district?.id }}
                                                        options=
                                                        {district.map(ind => {
                                                            return (
                                                                { label: ind.name, value: ind.id }
                                                            )
                                                        })
                                                        }
                                                        className='atb-input-box-container'
                                                        classNamePrefix='atb-input-box'
                                                        onChange={handleChangeSelect('district_id')}
                                                    />
                                                </div>
                                                {errors.district_id && <Alert severity="error">{errors.district_id}</Alert>}
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Minimum Age</label>
                                                    <input type="number" defaultValue={jobData?.age_from} className="form-control atb-input-box" onChange={handleChange('age_from')} placeholder="Minimum Age" />
                                                </div>
                                                {/* {errors.district_id && <Alert severity="error">{errors.district_id}</Alert>} */}
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <label>Maximum Age</label>
                                                    <input type="number" defaultValue={jobData?.age_to} className="form-control atb-input-box" onChange={handleChange('age_to')} placeholder="Maximum Age" />
                                                </div>
                                                {/* {errors.district_id && <Alert severity="error">{errors.district_id}</Alert>} */}
                                            </div>

                                            <div className='col-lg-12 col-md-12 m-b20 m-t30'>
                                               <h5 className="font-weight-700 pull-left text-uppercase">Job Benefits</h5> <hr />
                                            </div>

                                            <div className='col-lg-6 col-md-6' style={{ borderRight: '1px solid grey' }}>
                                                <div class=" bg-white">
                                                    <ul class="list-unstyled">
                                                        {
                                                            jobBenefitCats?.map((item, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <div class="collapse show" id={`${index}-collapse`}>
                                                                            <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                                                                <li >
                                                                                    <h5>{item.title}</h5>
                                                                                    <ul>
                                                                                        {item.child ? item.child.map((child, childIndex) => {
                                                                                            return (
                                                                                                <li>
                                                                                                    <Link key={childIndex} style={currentBenefit == child.id ? { marginLeft: '20px', color: 'black' } : { marginLeft: '5px' }} to="#" onClick={() => setCurrentBenefit(child.id)} class="link-dark rounded">{child.title}</Link>
                                                                                                </li>
                                                                                            )
                                                                                        })
                                                                                            : " "
                                                                                        }
                                                                                    </ul>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6' >
                                                <div class=" bg-white">
                                                    <ul class="list-unstyled">
                                                        {
                                                            jobBenefitCatOptns?.map((item, index) => {
                                                                return (
                                                                    <div className="custom-control custom-checkbox" key={index}>
                                                                        <input onChange={(e) => {
                                                                            // add to list
                                                                            if (e.target.checked) {
                                                                                setCheckedBenefitList([...checkedBenefitList, item.id]);
                                                                            }
                                                                            else {
                                                                                // remove from list
                                                                                setCheckedBenefitList(
                                                                                    checkedBenefitList.filter((s) => s !== item.id),
                                                                                );
                                                                            }
                                                                        }}
                                                                            checked={defaultSelectedCheckBox(item)}
                                                                            value={item.id} type="checkbox" className="custom-control-input" id={`benefit-check${index}`} name="job_benefit" />
                                                                        <label className="custom-control-label" htmlFor={`benefit-check${index}`}>{item.title}</label>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className='col-lg-12 col-md-12 m-t30'>
                                                <h5 className="font-weight-700 pull-left text-uppercase">Custom Questions</h5> <hr />
                                            </div>

                                            {/* {customQuestionLoading ? ReactHtmlParser("<center><img style='' src ='/images/site_load.gif' /></center>") :
                                            updateQuestion?.map((question, index) => {
                                                return (
                                                    <>
                                                        <div className="col-lg-12 col-md-12">
                                                            <label>Question {index + 1}</label>
                                                        </div>
                                                        <div className="col-lg-10 col-md-10">
                                                            <div className="form-group">
                                                                <input type="text" id={`input-field-${index}`} onChange={handleChangeCustomQuestion(question.question_id, index)} defaultValue={question.question} className="form-control atb-input-box" placeholder="Enter your question" required />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-2 col-md-2">
                                                            <div className="form-group">
                                                                <div className="row">
                                                                    <div className="col-lg-6 col-md-6" style={{ marginTop: '0.25em' }}>
                                                                        {updateQuestion?.length - 1 === index && <i class="fa fa-plus-square-o fa-2x" aria-hidden="true" onClick={(e) => handleAddClick(index)}></i>}
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6">

                                                                        {updateQuestion?.length !== 1 && <i class="fa fa-trash-o fa-2x" aria-hidden="true" onClick={() => [handleRemoveClick(jobData.post_id, question.question_id, index)]}></i>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                );
                                            })}
                                    <hr /> */}
                                            {/* {
                                                customQuestionArray?.map((item, index) => {
                                                    return (
                                                        <>
                                                            <div key={item.question} className='col-10 m-b10'>
                                                                <label>Question {index + 1}</label>
                                                                <input type='text' className='form-control' onBlur={(e) => updateCustomQuArray(index, e, item.question_id)} defaultValue={item.question} />
                                                            </div>
                                                            <div className='col-2'>
                                                                <label style={{ visibility: 'hidden' }}>Button</label> <br />
                                                                <Button onClick={() => removeCustomQuestion(index, item.question_id)}>
                                                                    <i className="fa fa-trash-o fa-2x d-none d-lg-block" aria-hidden="true" ></i>
                                                                </Button>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                            <div className='col-10 m-b20 m-t20'>
                                                <form id='custom-question-add'>
                                                    <label>Add Question</label>
                                                    <input type='text' className='form-control' placeholder='Enter your question' onChange={(e) => setTempQuestion(e.target.value)} />
                                                </form>
                                            </div>
                                            <div className='col-2 m-b20 m-t20'>
                                                <label style={{ visibility: 'hidden' }}>Button</label> <br />
                                                <Button onClick={() => addCustomQuestion()} disabled={tempQuestion === "" ? true : false} ><i className="fa fa-plus-square-o fa-2x d-none d-lg-block" aria-hidden="true"></i></Button>
                                            </div> */}
                                            <div className='col-12'>
                                                <JobPosstCustomQuestionUpdate
                                                customQuestionArray={customQuestionArray}
                                                setCustomQuestionArray={setCustomQuestionArray}
                                                setErrors={setErrors}
                                                />
                                            </div>
                                        </div>
                                        <div className='text-right'>
                                            {jobData?.job_status == 'draft' ?
                                                <>
                                                    <LoadingButton loading={buttonLoader} variant='outlined' sx={{ marginBottom: '30px', marginRight: '30px', padding: '10px' }} type="button" className="site-button m-b30 m-r30" onClick={() => updateJobData(updateJobPost, customQuestionArray)}>Update</LoadingButton>
                                                    <LoadingButton variant='contained' sx={{ marginBottom: '30px', marginRight: '30px', padding: '10px' }} type="button" className="site-button m-b30" onClick={() => validatePostJob(updateJobPost)}>Publish</LoadingButton>
                                                </>
                                                :
                                                <button type="button" className="site-button m-b30" onClick={() => updateJobData(updateJobPost, customQuestionArray)}>Submit</button>
                                            }
                                        </div>
                                    </form>
                                }
                            </div>
                        }

                    </div>

                    <Modal show={confirmationModal} onHide={setConfirmationModal} className="modal fade modal-bx-info editor" >
                        <div className="modal-dialog my-0" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="EmploymentModalLongTitle">Confirm?</h5>
                                    <button type="button" className="close" onClick={() => setConfirmationModal(false)}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label>Are you sure you want to publish this job?</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <p>You can edit this job from your dashboard.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">

                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    {/* <button type="button" className="site-button" onClick={() => [setConfirmationModal(false), setUpdateJobPost({ ...updateJobPost, job_status: 'draft' })]} >Cancel</button> */}
                                    <Button variant='outlined' sx={{ marginBottom: '30px', marginRight: '30px', padding: '10px' }} type="button" className="site-button m-b30" onClick={() => [setConfirmationModal(false), setUpdateJobPost({ ...updateJobPost, job_status: 'draft' })]}>Cancel</Button>

                                    <LoadingButton loading={buttonLoader} variant='contained' sx={{ marginBottom: '30px', marginRight: '30px', padding: '10px' }} type="button" className="site-button m-b30" onClick={() => updateJobData(updateJobPost, customQuestionArray)}>Publish</LoadingButton>
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <Modal show={customQuesDelRespModal} onHide={setCustomQuesDelRespModal} className="modal fade modal-bx-info editor" >
                        <div className="modal-dialog my-0" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="EmploymentModalLongTitle">Response</h5>
                                    <button type="button" className="close" onClick={() => [setCustomQuesDelRespModal(false), setCustomQuestionLoading(false)]}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label>{customQuesDelRespMessage}</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    {/* <p>You can edit this job from your dashboard.</p> */}
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">

                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">

                                    <button type="button" className="site-button" onClick={() => [setCustomQuesDelRespModal(false), setCustomQuestionLoading(false)]} >Okay</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <SuccessAlert open={openAlert} message={responseMessage} onHide={() => setOpenAlert(false)} />

                    <ErrorAlert open={openErrorAlert} message={responseMessage} onHide={() => setOpenErrorAlert(false)} />

                </div>
            </div>
        </div>
    )
}

export default EmployerUpdatePostJob