import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import TuneSharpIcon from '@mui/icons-material/TuneSharp';

export default function DrawerComponent(props) {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: 320 }}
            role="presentation"
        // onClick={toggleDrawer(anchor, false)}
        // onKeyDown={toggleDrawer(anchor, false)}
        >
            {props.filterComponent}
        </Box>
    );

    return (
        <div>
            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <IconButton
                        // variant='contained'
                        onClick={toggleDrawer(anchor, true)}
                        // fullWidth
                        sx={{
                            width: ' 32px',
                            height: ' 32px',
                            marginLeft: ' 8px',
                            backgroundColor: ' #B6DDFF',
                            display: ' flex',
                            alignItems: ' center',
                            justifyContent: ' center',
                            borderRadius: ' 50%',
                            ':focus': {outline:'unset'}
                        }}
                    >
                        <TuneSharpIcon className="text-primary" />
                    </IconButton>
                    <SwipeableDrawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}
        </div>
    );
}