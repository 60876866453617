/**Imports */

import React, { useState, useContext, useEffect } from 'react'
import EmployerDashboardSideBar from '../../components/EmployerComponents/EmployerDashboardSideBar/EmployerDashboardSideBar'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { EmployerContext } from '../../context/EmployerContext';
import { getActiveJobs, deleteJob } from '../../../fetchAPI';
import { Link } from 'react-router-dom';
import SuccessAlert from '../../components/Alerts/SuccessAlert';
import { Typography, Pagination } from '@mui/material';
import { useQuery } from 'react-query';
import GlobalLoader from '../../components/GlobalLoader';


/**
 * Component for Employers to be able to see a list of all jobs posted
 *  
 * @component
 */
function EmployerManageJobs() {

    // UseState Declarations
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [activeJobs, setActiveJobs] = useState([])
    const [loading, setLoading] = useState(true)
    const [openAlert, setOpenAlert] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [tempData, setTempData] = useState('')
    const [tempIndex, setTempIndex] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [paginateInfo, setPaginateInfo] = useState({})

    // UseContext Declarations
    const { status, setStatus } = useContext(EmployerContext)

    const { data, isLoading, refetch } = useQuery(['EmployerManageJob', 0, status, currentPage], getActiveJobs, { staleTime: Infinity })

    useEffect(() => {
        if (data) {
            setActiveJobs(data.response.data)
            setPaginateInfo(data.response.pagination)
        }
        setLoading(isLoading)
    }, [data, isLoading])
    /**
   * Calling Delete Job API 
   * 
   * @param {object} TODO: Review - Sending Employer ID and Status
   * @return {object} Success or false json
   */

    const deleteJobData = (body, index) => {
        setLoading(true)

        deleteJob(body).then((response) => {
            if (response && response.success) {
                setResponseMessage(response.response.message)
                setOpenAlert(true)
                activeJobs.splice(index, 1);
                // settingActiveJobs()
                refetch()
                setLoading(false)
                // setSuccessModal(true);
            }
            else {
                setLoading(false)
                setOpenAlert(true)
                setResponseMessage(response.errors[0].message);
                // setErrorModal(true);
            }
        })

    }

    /**
     * Create a function that handle the input event and
     * save the value of that event on an state every time the component change
     * 
     * Setting Sort By information in Status state
     * 
     * @param {string} name
     * @param {event} event 
     */

    const handleChange = (event) => {
        setStatus(event.target.value)
    }

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMobileState = (body, index) => {
        setTempData(body)
        setTempIndex(index)
    }


    return (
        <div className='container'>
            <div className='row'>
                <div className='col-lg-4 col-md-0'>
                    <EmployerDashboardSideBar />
                </div>
                <div className='col-lg-8 col-md-12'>
                    <div className='atb-card-dashboard'>
                        <div className='container'>
                            <hr className='d-lg-none' />
                            <div className='row mb-1'>
                                <div className='col-lg-8 col-md-6'>
                                    <div className='text-left'>
                                        <h2 className='m-2 mt-4'> Manage Jobs</h2>
                                        <Typography className='ml-2' variant='caption'>{`Total: ${activeJobs.length}`}</Typography>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 float-right'>
                                    <div className='mt-3 d-none d-lg-block'>
                                        <span className="select-title">Sort by Status</span>
                                        <select defaultValue={status} className="form-select" aria-label="Default select example" onChange={handleChange} >
                                            <option value=''>All</option>
                                            <option value='draft'>Draft</option>
                                            <option value='published'>Published</option>
                                            <option value='expired'>Expired</option>
                                        </select>
                                    </div>
                                    <div className='m-2 mt-3 mb-4 d-lg-none'>
                                        <span className="select-title">Sort by Status</span>
                                        <select defaultValue={status} className="form-select btn-block" aria-label="Default select example" onChange={handleChange} >
                                            <option value=''>All</option>
                                            <option value='draft'>Draft</option>
                                            <option value='published'>Published</option>
                                            <option value='expired'>Expired</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {
                                loading ?
                                    <GlobalLoader height="60vh" />
                                    :
                                    activeJobs && activeJobs.length !== 0 ?
                                    <>
                                        <div className='table-responsive'>
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Job Title</th>
                                                        <th scope="col">New Applicants</th>
                                                        <th scope="col">Total Applicants</th>
                                                        <th scope="col">Like</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {activeJobs.map((item, index) => {
                                                        return (
                                                            <>
                                                                <tr key={index}>
                                                                    <th scope="row"><Link className='text-dark' to={item.is_cxo ? `/cxo-jobs/${item.post_id}` : `/jobs/${item.post_id}`}>{item.job_title}</Link></th>
                                                                    <td className="" style={{ textTransform: 'capitalize' }}>{item?.current_week_applicants_coun || "0"} </td>
                                                                    <td className="" style={{ textTransform: 'capitalize' }}>{item?.all_applicants_count || "0"} </td>
                                                                    <td className="" style={{ textTransform: 'capitalize' }}>{item?.like_count || "0"} </td>
                                                                    <td className="" style={{ textTransform: 'capitalize' }}>{item.job_status} </td>
                                                                    <td>
                                                                        <div className='row'>
                                                                            <div className='col-lg-4 d-lg-block'>
                                                                                <Link className='text-dark' to={`/employer-update-job/${item.post_id}`}><i className="fa fa-file-text"></i></Link>
                                                                            </div>
                                                                            <div className='col-lg-4 d-none d-lg-block'>
                                                                                <Link className='text-dark' to={"#"} onClick={() => deleteJobData({ "post_id": item.post_id, "user_id": item.author_id }, index)}><i className="fa fa-times"></i></Link>
                                                                            </div>
                                                                            {
                                                                                item.job_status !== 'draft' ?
                                                                                    <div className='col-lg-4 d-none d-lg-block'>

                                                                                        <Link className='text-dark' data-toggle="tooltip" data-placement="top" title="View applicants" to={`/applicants/job/${item.post_id}`}><i className="fa fa-eye"></i></Link>

                                                                                    </div>
                                                                                    :
                                                                                    ''
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>
                                        <div style={{ padding: '30px 20px', display: "flex", justifyContent: "end" }}>
                                                <Pagination page={currentPage} count={paginateInfo.last_page} color="primary" shape="rounded" boundaryCount={1} onChange={(e, v) => setCurrentPage(v)} />
                                        </div>
                                    </>
                                        :
                                        <center className='p-10'>
                                            <h5> You haven't posted any Job Yet!</h5>
                                        </center>
                            }


                            <SuccessAlert open={openAlert} message={responseMessage} onHide={() => setOpenAlert(false)} />
                        </div >
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmployerManageJobs