import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { UserContext } from '../../context/UserContext';
import { Link } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Divider } from '@mui/material';
import { updateProfileStrength } from '../../../fetchAPI';

function ProfileStrengthSuccessAlert(props) {

    const { applyAttemptJobInfo } = useContext(UserContext)

    const updatingProfileStrength = (profileStrength) => {

        // updating profile strength data
        updateProfileStrength(props?.candidateData?.id, profileStrength).then((response) => {
            if (response && response.success) {
                // console.log(response)
            }
            else {
                // console.log(response)

            }
        })
    }

    // const style = {
    //     position: 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     height: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: 10,
    //     bgcolor: 'background.paper',
    //     border: '2px solid #000',
    //     boxShadow: 24,
    //     p: 4,
    //     overflow: 'scroll',
    // };

    return (
        <div>
            <div>
                <Dialog
                    open={props.open}
                    // open={true}
                    onClose={props.onHandleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{`Congratulations, your profile is now complete at ${props.message}`}</DialogTitle>
                    <Divider />
                    {
                        props?.strength == process.env.REACT_APP_MINIMUM_STRENGTH_FOR_APPLY ?
                            <>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <p>You are now eligible to apply for the {applyAttemptJobInfo.job_title} position. Please follow the provided link to submit your application.</p>
                                    </DialogContentText>
                                </DialogContent>
                                <Divider />
                                <DialogActions>
                                    <Link className='text-light p-2' to={`/jobs/${applyAttemptJobInfo.job_id}/`}><Button variant='outlined'>Apply Now</Button></Link>
                                    <Button variant='outlined' onClick={() => props.onHandleClose()}> Cancel</Button>
                                </DialogActions>
                            </>
                            :
                            props.strength === 100 ?
                                <>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            <p>{applyAttemptJobInfo?.job_id ? ` You are now eligible to apply for the ${applyAttemptJobInfo.job_title} position. Please follow the provided link to submit your application.` : "This means you are ready to start applying for jobs and kickstarting your career. Click the button below to browse available job opportunities and take the first step towards a rewarding new career."}</p>
                                        </DialogContentText>
                                    </DialogContent>
                                    <Divider />
                                    {
                                        applyAttemptJobInfo?.job_id ?
                                            <DialogActions>
                                                <Link className='text-light p-2' to={`/jobs/${applyAttemptJobInfo.job_id}/`}><Button variant='outlined'>Apply Now</Button></Link>
                                                <Button variant='outlined' onClick={() => props.onHandleClose()}> Cancel</Button>
                                            </DialogActions>
                                            :
                                            <DialogActions>
                                                <Link className='text-light p-2' to={`/search/jobs`}><Button variant='outlined' onClick={() => updatingProfileStrength({ profile_strength_check: 1})}>Find Job</Button></Link>
                                                <Button variant='outlined' onClick={() => [props.onHandleClose(), updatingProfileStrength({ profile_strength_check: 1})]}> Cancel</Button>
                                            </DialogActions>
                                    }
                                </>
                                :
                                "null"
                    }
                </Dialog>
            </div>
        </div >

    )
}

export default ProfileStrengthSuccessAlert