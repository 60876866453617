import React, { useState, useEffect, useRef } from 'react'
import { Alert, Box, CircularProgress } from '@mui/material'
import { postHobby, updateHobby, deleteHobby } from '../../../../../fetchAPI'
import UpdateDeleteConfirmationModal from './UpdateDeleteConfirmationModal'
import JoditEditor from 'jodit-react';
import CandidateUpdateModal from './component/CandidateUpdateModal'
import customStyles from './CandidateUpdateProfile.module.css'
import EditSVG from './component/EditSVG'
import DeleteSVG from './component/DeleteSVG'
import AddButton from './component/AddButton'
import { useAlert } from '../../../../context/AlertContext'
import ReactHtmlParser from 'react-html-parser'
import RequiredAsterisk from './component/RequiredAsterisk';

function UpdateHobby(props) {


    const [hobbyAddData, setHobbyAddData] = useState({})
    const [updateHobbyData, setUpdateHobbyData] = useState({})

    const [loader, setLoader] = useState(false)

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addFormRef = useRef();

    const alertContext = useAlert();



    const enableUpdate = (item) => {
        setUpdateHobbyData({
            id: item.id,
            hobby: item.hobby,
            description: item.description
        })
    }

    const handleUpdateChange = (name) => (event) => {
        setUpdateHobbyData({
            ...updateHobbyData,
            [name]: event.target.value
        })
    }

    const handleChange = (name) => (event) => {
        setHobbyAddData({ ...hobbyAddData, [name]: event.target.value })
    }

    let errorsObj = { hobby: '' };

    const [errors, setErrors] = useState({})

    const insertCandidateHobby = (body) => {

        let error = false;
        const errorObj = { ...errorsObj };
        if (!body.hobby) {
            errorObj.hobby = 'Title is Required';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            setLoader(false);
        }

        else {
            setAddModalOpen(false);
            setHobbyAddData({});
            postHobby(body).then((response) => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    props.renderState()
                    setLoader(false);
                }
                else {
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                    setLoader(false)
                }
            })
        }
    }

    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

    const handleOpen = (itemId) => {
        setOpenDeleteModal(true);
        setDeleteItemId(itemId);
    };

    const handleClose = () => {
        setOpenDeleteModal(false);
    };

    const [deleteItemId, setDeleteItemId] = useState('')
    const deleteHobbyAndInterest = (id) => {
        setOpenDeleteModal(false)
        setLoader(true)
        deleteHobby(id).then((response) => {
            if (response.success) {
                alertContext.setResponseMessage(response.response.message)
                alertContext.setOpenAlert(true)
                props.renderState()
                setLoader(false)
            }
            else {
                console.log('errors: ', response);
                const message = response.errors?.map(msg => {
                    alertContext.setResponseMessage(msg.message)
                })
                alertContext.setOpenErrorAlert(true)
                setLoader(false);
            }
        })
    }

    const [updateErrors, setUpdateErrors] = useState({})
    const updateCandidateHobby = (body) => {

        let error = false;

        const errorObj = { ...errorsObj };

        if (!body.hobby) {
            errorObj.hobby = 'Title is Required';
            error = true;
        }

        setUpdateErrors(errorObj);
        if (error) {
            setLoader(false);
        }
        else {
            setUpdateModalOpen(false);
            updateHobby(body.id, body).then((response) => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    props.renderState()
                    setLoader(false);
                }
                else {
                    console.log('errors: ', response);
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                    setLoader(false);
                }
            })
        }
    }

    return (
        <div id='candidate_update_hobby_box'>
            <div className='card shadow-sm'>
                <div className={customStyles.cardHeader}>
                    <div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
                        <div className={customStyles.sectionHeader}>Hobbies and Interests</div>
                        <AddButton title="Hobbies and Interests" onClickHandler={() => setAddModalOpen(true)} />
                    </div>
                </div>
                <div className='card-body'>
                    {
                        loader ?
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                                <CircularProgress />
                            </Box>
                            :
                            props.hobbies?.map((item, index) => {
                                return (
                                    <div key={item.id}>
                                        {index > 0 ? <hr /> : ''}
                                        <div className={customStyles.singleHobby} >
                                            <div className={customStyles.hobbyTitle}>
                                                {item.hobby}
                                            </div>
                                            <div className={customStyles.actionContainer} style={{ marginLeft: '10px' }}>
                                                <span style={{ height: '32px' }} onClick={() => { enableUpdate(item); setUpdateModalOpen(true) }}>
                                                    <EditSVG />
                                                </span>
                                                <span style={{ height: '32px' }} onClick={() => handleOpen(item.id)}>
                                                    <DeleteSVG />
                                                </span>
                                            </div>
                                        </div>
                                        <div className={customStyles.hobbyDetails}>
                                            {ReactHtmlParser(item.description)}
                                        </div>
                                    </div>
                                )
                            })
                    }
                </div>
            </div>
            <CandidateUpdateModal form="candidateHobbyAddForm" open={addModalOpen} onClose={() => [setErrors({}), setAddModalOpen(false), setHobbyAddData({})]} title='New Hobby/Interest'>
                <div>
                    <div className='card-body'>
                        <form id='candidateHobbyAddForm' ref={addFormRef} onSubmit={(e) => { e.preventDefault(); setLoader(true); insertCandidateHobby(hobbyAddData); }}>
                            <div className={`row text-left`}>

                                <div className='col-md-12 col-lg-12'>
                                    <div className='form-group'>
                                        <label>Hobbies and interests <RequiredAsterisk/></label>
                                        <input className='form-control atb-input-box' onChange={handleChange('hobby')} placeholder='Enter your hobbies & interest' type='text' />
                                        {errors.hobby && <Alert severity="error">{errors.hobby}</Alert>}
                                    </div>
                                </div>

                                <div className='col-md-12 col-lg-12'>
                                    <div className='form-group'>
                                        <label>Description</label>
                                        <JoditEditor
                                            value={hobbyAddData?.description || ''}
                                            buttons={[
                                                'bold', '|',
                                                'italic', '|',
                                                'ul', '|', 'underline', '|']}
                                            className='m-t10'
                                            onChange={(content) => setHobbyAddData({ ...hobbyAddData, description: content })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </CandidateUpdateModal>

            <CandidateUpdateModal form="candidateHobbyUpdateForm" open={updateModalOpen} onClose={() => [setUpdateErrors({}), setUpdateModalOpen(false), setUpdateHobbyData({})]} title='Update Hobby/Interest'>
                <div>
                    <div className='card-body'>
                        <form id='candidateHobbyUpdateForm' onSubmit={(e) => { e.preventDefault(); setLoader(true); updateCandidateHobby(updateHobbyData); }}>
                            <div className='row'>
                                <div className='col-md-12 col-lg-12'>
                                    <label>Hobbies and interests <RequiredAsterisk/></label>
                                    <input
                                        value={updateHobbyData.hobby}
                                        onChange={handleUpdateChange('hobby')}
                                        className='form-control atb-input-box m-b10' type='text' />
                                    {updateErrors.hobby && <Alert severity="error">{updateErrors.hobby}</Alert>}
                                </div>

                                <div className='col-md-12 col-lg-12'>
                                    <label>Description</label>
                                    <JoditEditor
                                        value={updateHobbyData?.description || ''}
                                        buttons={[
                                            'bold', '|',
                                            'italic', '|',
                                            'ul', '|', 'underline', '|']}
                                        className='m-t10'
                                        onChange={(content) => setUpdateHobbyData({ ...updateHobbyData, description: content })}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </CandidateUpdateModal>
            <UpdateDeleteConfirmationModal open={openDeleteModal} onClose={handleClose} deleteItem={() => deleteHobbyAndInterest(deleteItemId)} />
        </div>
    )
}

export default UpdateHobby