import { Box, Breadcrumbs, Button, CircularProgress, Link } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Modal from "../Modal";
import { Link as RouterLink } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'
import { styled } from '@mui/material/styles';
import { changeApplyStatus, downloadResume } from "../../../fetchAPI";
import { DropdownContext } from "../../context/DropdownContext";
import Select from 'react-select'
import { useAlert } from "../../context/AlertContext";

const WhiteButton = styled(Button)(({ theme }) => ({
    color: '##0079C1',
    backgroundColor: 'white',
    '&:hover': {
        backgroundColor: 'white',
    },
    '&:focus': {
        outline: 'unset'
    }
}));

export default function AppliedCandidateStatusBar(props) {

    const [coverLetterModalOpen, setCoverLetterModalOpen] = useState(false);
    const alertContext = useAlert();


    const [status,setStatus] = useState({});
    useEffect(() => {
        setStatus({ label: props.applyInfo?.apply_status?.name, value: props.applyInfo?.apply_status?.id })
    }, [props.applyInfo])

    // get and populate status list
    const { statusList, apiCallingConditionSet, setApiCallingConditionSet } = useContext(DropdownContext)
    useEffect(() => {
        setApiCallingConditionSet({
            ...apiCallingConditionSet,
            statusList: true
        })
    }, [])

    const resumeDownload = (resumeId, resumeLink) => {

        let filename = 'resume'

        if (resumeLink) {
            let resumeReverse = resumeLink?.split('').reverse().join('');
            let reverseArray = resumeReverse.split("/")
            filename = reverseArray[0].split('').reverse().join('')
        }

        downloadResume(resumeId).then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `${filename}`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    const [disableSelectField, setDisableSelectField] = useState(false)
    const changeApplyStatusFunc = (name) => (event) => {
        setDisableSelectField(true)
        setStatus(event);
        const changeData = {
            candidate_id: props.candidateInfo.id,
            apply_status: event.value,
            post_id: props.jobId,
            user_id: props.userId
        }
        changeApplyStatus(props.jobId, changeData)
            .then(response => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                } else {
                    // console.log('errors', response)
                    alertContext.setResponseMessage(response.errors[0]?.message)
                    alertContext.setOpenErrorAlert(true)
                }
            }).finally(() => setDisableSelectField(false))


    }

    return (
        <Box sx={{ padding: {xs:'20px',sm:'20px',md: '50px 20px 10px 20px' }}}>
            <Box style={{ display: 'flex', justifyContent: 'space-between',flexWrap:'wrap' }}>
                <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom:'10px'}}>
                    <Link underline="hover" color="#d7d7d7" component={RouterLink} to={`/applicants/job/${props.jobId}`}>
                        Applied Candidates
                    </Link>
                    <Link
                        underline="hover"
                        color="white"
                        component={RouterLink}
                        to="#"
                        aria-current="page"
                    >
                        {props.candidateInfo.first_name}
                    </Link>
                </Breadcrumbs>

                <Box sx={{ display: 'flex',flexWrap:'wrap' }}>
                    <WhiteButton sx={{marginBottom:'10px',marginRight: '5px'}} variant="outlined" startIcon={
                        <svg width="25" height="25" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="16" fill="#DFF0FF" />
                            <path d="M15.3446 17.8008V9.08009C15.3446 8.03527 15.5642 7.63753 16.1579 7.61972C16.7515 7.60191 17.0009 7.99371 17.0009 9.04447V17.7533C17.2917 17.878 17.3748 17.6524 17.4936 17.5336C18.3722 16.6669 19.233 15.7942 20.1116 14.9275C20.6399 14.3992 21.1801 14.2983 21.5126 14.7019C21.9281 15.2125 21.7322 15.6399 21.3048 16.0614C19.8266 17.5277 18.3722 19.0296 16.8999 20.49C16.3656 21.0243 15.9857 21.0243 15.4455 20.49C13.9653 19.0177 12.4931 17.5435 11.0288 16.0673C10.5301 15.5687 10.4707 15.0581 10.8685 14.7197C11.2662 14.3814 11.7708 14.4823 12.1686 14.8978C13.1481 15.913 14.1454 16.9163 15.1368 17.9255L15.3446 17.8008Z" fill="#0079C1" />
                            <path d="M16.0776 23.7479H10.6873C9.74933 23.7479 9.5 23.5045 9.5 22.5606C9.5 21.9669 9.5 21.3733 9.5 20.7796C9.5 20.186 9.73746 19.8298 10.343 19.8476C10.9485 19.8654 11.1325 20.2572 11.1563 20.8034C11.2038 22.1272 11.2097 22.1272 12.5217 22.1272C15.1515 22.1272 17.7873 22.1272 20.4172 22.1272C21.0108 22.1272 21.183 21.955 21.1236 21.4029C21.085 21.0974 21.085 20.7883 21.1236 20.4828C21.1416 20.3018 21.2276 20.1343 21.3642 20.0142C21.5008 19.8941 21.6779 19.8303 21.8597 19.8357C21.9529 19.817 22.049 19.8178 22.1418 19.838C22.2347 19.8583 22.3223 19.8975 22.3993 19.9533C22.4762 20.009 22.5408 20.0801 22.5889 20.1621C22.6371 20.244 22.6677 20.335 22.679 20.4294C22.7918 21.3242 22.7918 22.2297 22.679 23.1245C22.6077 23.6469 22.1447 23.7182 21.6876 23.7182L16.0776 23.7479Z" fill="#0079C1" />
                        </svg>
                    }
                        onClick={(e) => resumeDownload(props.applyInfo?.candidate_resume_id, props.applyInfo?.applied_resume)}
                    >
                        Download CV
                    </WhiteButton>
                    <WhiteButton  sx={{ marginBottom:'10px', marginRight: '5px',display:'none' }} variant="outlined" onClick={() => setCoverLetterModalOpen(true)}>
                        See Cover Letter
                    </WhiteButton>
                    <Box sx={{marginBottom:'10px'}}>
                        <Select
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    borderRadius: '5px !important',
                                    minWidth: '150px',
                                }),
                            }}
                            options=
                            {statusList.map(item => {
                                return (
                                    { label: item.name, value: item.id }
                                )
                            })
                            }
                            isDisabled={disableSelectField}
                            value={status}
                            onChange={changeApplyStatusFunc('apply_status')}
                            placeholder='Change Apply Status'
                        />
                    </Box>


                </Box>

                {/* <Link to='#' onClick={(e) => resumeDownload(props.applyInfo?.candidate_resume_id, props.applyInfo?.applied_resume)} className="">
                    <Tooltip title="Download Resume" placement="bottom">
                        
                        <IconButton>
                            <SimCardDownloadTwoToneIcon style={{ fontSize: 28 }} />
                        </IconButton>
                    </Tooltip>
                </Link>
                <Select
                    value={currentStatus?.id}
                    onChange={handleApplyChange('apply_status')}
                    startAdornment={loading && (
                        <Box sx={{ position: 'absolute', top: '55%', left: 10, transform: 'translateY(-50%)' }}>
                            <CircularProgress color="inherit" size={20} />
                        </Box>
                    )}
                    style={{ backgroundColor: 'white' }}
                    fullWidth
                    disabled={loading ? true : false}
                >
                    {
                        statusList.map(item => {
                            return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                            )
                        })
                    }

                </Select> */}
            </Box>
            <Modal open={coverLetterModalOpen} onClose={() => setCoverLetterModalOpen(false)} title='Cover Letter'>
                <Box>
                    <hr />
                    <Box sx={{ padding: '15px' }}>
                        <div>
                            {ReactHtmlParser(props.applyInfo.cover_letter ? props.applyInfo.cover_letter : "Not Given")}
                        </div>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
}