import React from 'react'
import CandidateDashboardSidebar from '../../components/CandidateComponents/CandidateDashboardSideBar/CandidateDashboardSidebar'
import CandidateRecommendedJobs from '../../components/CandidateComponents/RecommendedJobs/CandidateRecommendedJobs'

function RecommendedJobs() {
    
  return (
    <div className='container'>
        <div className='row'>
            <div className='col-lg-4 col-md-0 d-none d-lg-block'>
                <CandidateDashboardSidebar />
            </div>
            <div className='col-lg-8 col-md-12'>
                <CandidateRecommendedJobs/>
            </div>
        </div>
    </div>
  )
}

export default RecommendedJobs