import React from "react";
import styles from './masking.module.css'

export default function MaskingRoundedImage(props)
{
    return (
        <div className={styles.roundedImage} {...props}>

        </div>
    )
}