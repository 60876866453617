import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { useTranslation } from "react-i18next";

export default function Footerv2() {

    const { isLoggedin } = useContext(UserContext)

    const history = useHistory()

    const handleOnClick = (routeName) => {
        history.push(`/${routeName}`)
    }

    const {t} = useTranslation(["footer", "button"])

    return (
        <>
            {
                isLoggedin ?
                    ''
                    :
                    <>
                        <footer className="container pt-5 pb-5">
                            <Grid container sx={{ alignItems: 'center' }} spacing={2}>
                                <Grid item md={6} sm={12}>
                                    <Stack spacing={2}>
                                        <Typography variant="h3" sx={{ fontSize: { xs: '16px', sm: '18px', md: '18px', lg: '20px' }, fontWeight: 700, fontFamily: 'Epilogue,sans-serif', color:'white' }}>{t("footer:top-section.title-1")}</Typography>
                                        <Typography variant="h3" sx={{ fontSize: { xs: '18px', sm: '20px', md: '20px', lg: '24px' }, fontWeight: 700, fontFamily: 'Epilogue,sans-serif', color:'white' }}>{t("footer:top-section.title-2")}</Typography>
                                        <Typography variant="h3" sx={{ fontSize: { xs: '16px', sm: '18px', md: '18px', lg: '20px' }, fontWeight: 400, color: '#0275D8', fontFamily: 'Epilogue,sans-serif' }}>{t("footer:top-section.title-3")}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item md={6} sm={12} sx={{width:{xs:'100%',sm:'unset'}}}>
                                    <Grid container spacing={2} className="justify-content-center" sx={{width:{xs:'100%',sm:'unset'}}}>
                                        <Grid item sx={{width:{xs:'100%',sm:'unset'}}}>
                                            <Button
                                                variant="outlined"
                                                onClick={() => handleOnClick('login')}
                                                sx={{
                                                    paddingTop: '10px',
                                                    paddingBottom: '10px',
                                                    paddingLeft: 5,
                                                    paddingRight: 5,
                                                    color: 'white',
                                                    borderColor: 'white',
                                                    textTransform: 'none',
                                                    fontFamily: 'Epilogue,sans-serif',
                                                    width:{xs:'100%',sm:'unset'},
                                                    '&:hover': {
                                                        borderColor: '#7c7c7c',
                                                    },
                                                }}
                                            >
                                                {t("button:footer.sign-in")}
                                            </Button>
                                        </Grid>
                                        <Grid item sx={{width:{xs:'100%',sm:'unset'}}}>
                                            <Button
                                                variant="contained"
                                                onClick={() => handleOnClick('candidate-register')}
                                                sx={{
                                                    paddingTop: '12px',
                                                    paddingBottom: '12px',
                                                    paddingLeft: 5,
                                                    paddingRight: 5,
                                                    fontFamily: 'Epilogue,sans-serif',
                                                    textTransform: 'none',
                                                    width:{xs:'100%',sm:'unset'},
                                                    backgroundColor: '#0275D8',
                                                    '&:hover': {
                                                        backgroundColor: '#075599',
                                                    },
                                                }}
                                            >
                                                {t("button:footer.candidate-register")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </footer>
                    </>
            }
        </>
    );
}