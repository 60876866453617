import React from "react"

export default function DeleteSVG() {
    return (


        <svg style={{cursor:'pointer'}} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="16" fill="#FFEFEF" />
            <path d="M11.8649 19.3916C11.0537 20.2029 10.2446 21.012 9.43335 21.8232C8.93564 22.3209 8.93125 22.9568 9.39827 23.3975C9.56116 23.5539 9.77199 23.6508 9.99675 23.6727C10.2215 23.6946 10.4471 23.6401 10.6371 23.5181C10.7741 23.4236 10.9018 23.3165 11.0186 23.198L15.7019 18.5146C16.0462 18.1704 15.9541 18.1704 16.3115 18.5234C17.8945 20.1064 19.4776 21.6895 21.0606 23.2725C21.578 23.79 22.2117 23.8097 22.659 23.3405C22.8166 23.1791 22.9152 22.9693 22.9391 22.745C22.963 22.5207 22.9106 22.2948 22.7905 22.1039C22.698 21.9654 22.5915 21.8368 22.4726 21.7202C20.8896 20.1371 19.3043 18.5519 17.7213 16.9688C17.5262 16.7737 17.524 16.675 17.7213 16.4821C19.3043 14.8991 20.8874 13.316 22.4726 11.7308C22.5851 11.6228 22.6865 11.5038 22.7752 11.3756C22.9031 11.1772 22.9592 10.9411 22.9341 10.7064C22.9091 10.4717 22.8044 10.2527 22.6376 10.0858C22.4707 9.91897 22.2517 9.8143 22.017 9.78925C21.7823 9.76421 21.5462 9.8203 21.3478 9.9482C21.2106 10.0422 21.0834 10.1502 20.9685 10.2705L16.3071 14.9319C15.9475 15.2915 16.0484 15.2915 15.6778 14.921C14.1255 13.3686 12.5717 11.8148 11.0164 10.2596C10.9084 10.1471 10.7894 10.0457 10.6612 9.95698C10.4626 9.82472 10.2243 9.76533 9.98686 9.78891C9.74942 9.81248 9.52747 9.91755 9.35874 10.0863C9.19002 10.255 9.08494 10.477 9.06137 10.7144C9.0378 10.9518 9.09718 11.1901 9.22944 11.3887C9.3202 11.5153 9.42221 11.6335 9.53421 11.7417C11.1173 13.3248 12.7025 14.91 14.2855 16.4931C14.4697 16.6772 14.4719 16.7759 14.2855 16.9579C13.4852 17.7713 12.674 18.5826 11.8649 19.3916Z" fill="#DB1616" />
        </svg>


    )
}