import { Avatar, Box, Button, Grid, IconButton, Stack, Typography, Skeleton } from "@mui/material";
import React from "react";
import { downloadResume, deleteResume } from "../../../../fetchAPI";

function DownloadIcon() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
                <g clip-path="url(#clip0_38_838)">
                    <path d="M5.00654 8.71684V1.25035C5.00654 0.355791 5.19461 0.0152549 5.70288 6.772e-06C6.21115 -0.0152413 6.42462 0.320214 6.42462 1.21985V8.67618C6.67367 8.78292 6.74483 8.58978 6.84649 8.48812C7.59873 7.74605 8.33571 6.99888 9.08795 6.25681C9.54031 5.80445 10.0028 5.71804 10.2875 6.06366C10.6433 6.50078 10.4755 6.86674 10.1096 7.22761C8.84399 8.48303 7.59873 9.76895 6.33822 11.0193C5.88077 11.4767 5.55547 11.4767 5.09295 11.0193C3.82905 9.75879 2.56855 8.49659 1.31143 7.23269C0.884479 6.80575 0.83365 6.36863 1.17419 6.07891C1.51473 5.7892 1.94676 5.87561 2.2873 6.2314C3.12595 7.10054 3.97984 7.95952 4.82865 8.82358L5.00654 8.71684Z" fill="#0275D8" />
                    <path d="M5.63652 13.8098H1.02142C0.218356 13.8098 0.00488281 13.6014 0.00488281 12.7932C0.00488281 12.2849 0.00488281 11.7767 0.00488281 11.2684C0.00488281 10.7601 0.20819 10.4552 0.726625 10.4704C1.24506 10.4857 1.40262 10.8211 1.42295 11.2887C1.46361 12.4222 1.4687 12.4222 2.59198 12.4222C4.84361 12.4222 7.10033 12.4222 9.35197 12.4222C9.86024 12.4222 10.0076 12.2748 9.95681 11.8021C9.92375 11.5405 9.92375 11.2758 9.95681 11.0143C9.97219 10.8593 10.0458 10.7159 10.1628 10.6131C10.2798 10.5102 10.4314 10.4556 10.5871 10.4603C10.6668 10.4443 10.7491 10.4449 10.8286 10.4622C10.9081 10.4796 10.9831 10.5132 11.049 10.5609C11.1149 10.6086 11.1702 10.6695 11.2114 10.7397C11.2526 10.8098 11.2788 10.8877 11.2885 10.9685C11.3851 11.7347 11.3851 12.5099 11.2885 13.2761C11.2275 13.7233 10.831 13.7843 10.4397 13.7843L5.63652 13.8098Z" fill="#0275D8" />
                </g>
                <defs>
                    <clipPath id="clip0_38_838">
                        <rect width="11.37" height="13.8148" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}

function DeleteIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M4.5 6V15C4.5 15.8284 5.17157 16.5 6 16.5H12C12.8284 16.5 13.5 15.8284 13.5 15V6H4.5Z" fill="#DB1616" />
            <path opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M10.5 3.375V3.25C10.5 2.69772 10.0523 2.25 9.5 2.25H8.5C7.94772 2.25 7.5 2.69772 7.5 3.25V3.375H4.25C3.97386 3.375 3.75 3.59886 3.75 3.875V4C3.75 4.27614 3.97386 4.5 4.25 4.5H13.75C14.0261 4.5 14.25 4.27614 14.25 4V3.875C14.25 3.59886 14.0261 3.375 13.75 3.375H10.5Z" fill="#DB1616" />
        </svg>
    )
}

export default function ResumeUploadCard({ resumeList, resumeListLoader, setResumeListLoader, getResumeList, setSelectedResumeId, setIsSelectedResume, selectedResumeId, setUploadedResume }) {
    function fileName(link) {
        if (link) {
            let resumeReverse = link.split('').reverse().join('');
            let reverseArray = resumeReverse.split("/")
            let filename = reverseArray[0].split('').reverse().join('')
            return filename
        } else {
            return ''
        }
    }

    // Resume download 
    const resumeDownload = (resumeId, resumeName) => {
        setResumeListLoader(true)
        downloadResume(resumeId).then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `${resumeName}`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            }).finally(() => setResumeListLoader(false))
            ;
    }

    const dateFromTimeStamp = (timestamp) => {
        if (timestamp) {
            var time = new Date(timestamp);
            return time.toISOString().substring(0, 10);
        } else {
            return ' '
        }

    }

    const handleDeleteClick = (candidateId, resumeId) => {
        setResumeListLoader(true)
        deleteResume(candidateId, resumeId).then((response) => {
            // console.log(response)
            getResumeList()
        })
    }

    return (
        <>

            <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                {
                    resumeListLoader ?
                        <div className='mt-3 col-lg-12 col-md-12'>
                            <Skeleton variant="text" height={80} />
                        </div>
                        :
                        resumeList?.map((item, index) => {
                            return (
                                <Stack spacing={2} sx={{ padding: 1, margin: 1 }}>
                                    <Grid item key={index}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box sx={{ minWidth: 35, marginRight: '5px' }}>
                                                <Avatar
                                                    alt="Resume Upload Image"
                                                    src="/v2/images/ResumeUploadImage.png"
                                                    sx={{ bgcolor: '#F3F6FF' }}
                                                />
                                            </Box>
                                            <Box sx={{ width: '100%', mr: 1 }}>

                                                <Stack spacing={0} sx={{
                                                    margin: 1,
                                                    maxWidth: '70%', 
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                }}>
                                                    <Typography
                                                        variant="body1"
                                                        color="#0275D8"
                                                    >
                                                        {fileName(item?.resume_link)}
                                                    </Typography>
                                                    <Typography variant="caption" color="text.secondary">Uploaded at - {dateFromTimeStamp(item?.created_at)}</Typography>
                                                </Stack>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Box sx={{ minWidth: 200 }}>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                sx={{
                                                    backgroundColor: '#4CAF50',
                                                    color: 'white',
                                                    marginLeft: 3,
                                                    '&:hover': {
                                                        backgroundColor: '#7d984a',
                                                    },
                                                }}
                                                onClick={() => [setSelectedResumeId(item?.id), setIsSelectedResume(true), setUploadedResume('')]}
                                            >
                                                {
                                                    item?.id === selectedResumeId ? 'Selected' : 'Select CV'
                                                }
                                            </Button>
                                            <IconButton
                                                aria-label="download"
                                                sx={{
                                                    backgroundColor: '#F3F6F9',
                                                    marginLeft: 1,
                                                    marginRight: 1
                                                }}
                                                onClick={() => resumeDownload(item?.id, fileName(item?.resume_link))}
                                            >
                                                <DownloadIcon />
                                            </IconButton>
                                            <IconButton
                                                aria-label="download"
                                                sx={{
                                                    backgroundColor: '#F3F6F9',
                                                }}
                                                onClick={() => handleDeleteClick(item?.candidate_id, item?.id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                </Stack>
                            )
                        })
                }
            </Grid>

        </>
    )
}