import React, {useState, useEffect} from 'react'
import { useParams, Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { resetPassword } from '../../fetchAPI'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Typography } from '@mui/material';
import { validatePassword } from '../helpers/helperFunctions';
import { useAlert } from '../context/AlertContext';

function PasswordReset() {
    const { token, email } = useParams();
    const [responseModal, setResponseModal] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [passwordInput, setPasswordInput] = useState({
        password: '',
        password_confirmation: '',
        email: email,
        token: token
    });

    const [validationMessages, setValidationMessages] = useState('');
    const [validationStatus, setValidationStatus] = useState({
        minLength: false,
        uppercase: false,
        lowercase: false,
        numbers: false,
        specialChar: false
    });

    const alertContext = useAlert();

    useEffect(() => {
        if (passwordInput.password) {
            const { status, messages } = validatePassword(passwordInput.password);
            setValidationStatus(status);
            setValidationMessages(messages);
        }
    }, [passwordInput.password]);

    const resetPassData = async (e) => {
        e.preventDefault();
        const allValid = Object.values(validationStatus).every(Boolean);
        if (!allValid) {
            alertContext.setOpenErrorAlert(true);
            alertContext.setResponseMessage(validationMessages);
            return;
        }

        if(passwordInput.password === passwordInput.password_confirmation){
            try {
                const response = await resetPassword(passwordInput);
                if (response.success) {
                    setResponseMessage(response.response.data)
                } else {
                    setResponseMessage(response.errors[0].message)
                }
            } catch (error) {
                setResponseMessage(error.errors[0].message)
            }
        }

    };

    const handlePasswordChange = (event) => {
        const { name, value } = event.target;
        setPasswordInput({
            ...passwordInput,
            [name]: value.trim()
        });
    };

  return (
    <div className="page-wraper">
			  <div className="page-content bg-white">
          <div className="dez-bnr-inr" style={{backgroundColor:'#0079C1'}}>
            <div className="container">
              <div className="dez-bnr-inr-entry">
                <h1 className="text-white">Reset Password</h1>
                <div className="breadcrumb-row">
                </div>                
              </div>
            </div>
          </div>
          <div className="content-block">
            <div className="section-full content-inner overlay-white-middle">
            <div className="container">
                <div className='row'>
                    <div className='col-lg-3 col-md-3'></div>
                    <div className="col-lg-6 col-md-6">
                        <div className="p-a30 m-b30 radius-sm bg-gray clearfix">
                            <h4>Reset Password</h4>
                            <div className="dzFormMsg"></div>
                            <form onSubmit={resetPassData}>
                            {/* <input type="hidden" value={hash_key} name="" />
                            <input type="hidden" value={user_id} name="" /> */}
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group" style={{position: "relative"}}>
                                        <label style={{color: "#6C757D", fontSize: "14px"}}>New password</label>
                                        <input type={showPassword ? "text" : "password"} value={passwordInput.password}  onChange={handlePasswordChange}  name="password" placeholder="Password" className="form-control" />
                                        {showPassword ? 
                                            <VisibilityOutlinedIcon sx={{position: "absolute", top: 32, right: 10, cursor: "pointer", color: "#666666"}} onClick={() => setShowPassword(!showPassword)} /> 
                                            : 
                                            <VisibilityOffOutlinedIcon sx={{position: "absolute", top: 32, right: 10, cursor: "pointer", color: "#666666"}} onClick={() => setShowPassword(!showPassword)}/>
                                        }
                                        <Typography variant='caption' sx={{ color: '#1D1E1B' }}>
                                            <li style={{ color: validationStatus.uppercase ? '#0079C0' : '#1D1E1B' }}>
                                              Uppercase letters (A - Z)
                                            </li>
                                            <li style={{ color: validationStatus.lowercase ? '#0079C0' : '#1D1E1B' }}>
                                              Lowercase letters (a - z)
                                            </li>
                                            <li style={{ color: validationStatus.numbers ? '#0079C0' : '#1D1E1B' }}>
                                              Numbers (0 - 9)
                                            </li>
                                            <li style={{ color: validationStatus.specialChar ? '#0079C0' : '#1D1E1B' }}>
                                              Special character (!#$%&@^'?)
                                            </li>
                                            <li style={{ color: validationStatus.minLength ? '#0079C0' : '#1D1E1B' }}>
                                              At least 8 characters in length
                                            </li>
                                        </Typography>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group" style={{position: "relative"}}>
                                        <label style={{color: "#6C757D", fontSize: "14px"}}>Confirm password</label>
                                        <input type={showConfirmPassword ? "text" : "password"} value={passwordInput.password_confirmation}  onChange={handlePasswordChange}  name="password_confirmation" placeholder="Password" className="form-control" />
                                        {showConfirmPassword ? 
                                            <VisibilityOutlinedIcon sx={{position: "absolute", top: 32, right: 10, cursor: "pointer", color: "#666666"}} onClick={() => setShowConfirmPassword(!showConfirmPassword)} /> 
                                            : 
                                            <VisibilityOffOutlinedIcon sx={{position: "absolute", top: 32, right: 10, cursor: "pointer", color: "#666666"}} onClick={() => setShowConfirmPassword(!showConfirmPassword)}/>
                                        }
                                        <p className="text-danger">{passwordInput.password_confirmation && (passwordInput.password !== passwordInput.password_confirmation) && "Password is not matched"}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        {/* <div className="recaptcha-bx">
                                            <div className="input-group">
                                                <div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
                                                <input className="form-control d-none" style={{display:"none"}} data-recaptcha="true" required data-error="Please complete the Captcha" />
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="col-lg-12">
                                        <button name="submit" type="submit" value="Submit" className="site-button forgot_pass_btn"> <span>Submit</span> </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-3'></div>
                    
                    <Modal show={responseModal} onHide={setResponseModal} className="modal fade modal-bx-info editor">
                        <div className="modal-dialog my-0" role="document" style={{width:'100%'}}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="ResumeheadlineModalLongTitle">Response</h5>
                                    <button type="button" className="close" onClick={() => setResponseModal(false)}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {responseMessage ? responseMessage : ""}
                                </div>
                                <div className="modal-footer">
                                    <Link to={'/login'} className="site-button forgot_pass_btn">Login</Link>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
              </div>
            </div>
        </div>
        </div>
      </div>
  )
}

export default PasswordReset