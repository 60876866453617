import React, { useState, useEffect, useRef } from 'react'
import { Alert, Box, CircularProgress } from '@mui/material'
import { getAllLanguages, postCandidateLanguage, deleteCandidateLanguage } from '../../../../../fetchAPI'
import UpdateDeleteConfirmationModal from './UpdateDeleteConfirmationModal'
import CandidateUpdateModal from './component/CandidateUpdateModal'
import customStyles from './CandidateUpdateProfile.module.css'
import EditSVG from './component/EditSVG'
import DeleteSVG from './component/DeleteSVG'
import AddButton from './component/AddButton'
import { useAlert } from '../../../../context/AlertContext'
import { languageProficiencies } from '../../../../../constants';
import Select from 'react-select'
import RequiredAsterisk from './component/RequiredAsterisk'

function UpdateLanguage(props) {


    const [languages, setLanguages] = useState([]);

    const [languageAddData, setLanguageAddData] = useState({})
    const [languageUpdateData, setLanguageUpdateData] = useState({})

    const [loader, setLoader] = useState(false)

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);

    const alertContext = useAlert();

    useEffect(() => {
        getAllLanguages().then((response) => {
            if (response.success) {
                setLanguages(response.response.data);
            }
        })
    }, [])

    const enableUpdate = (item) => {
        setLanguageUpdateData({
            id: item.id,
            language_id: item.language_id,
            proficiency: item.proficiency,
            language_label: item.language?.name
        })
    }

    const handleChangeSelect = (name) => (event) => {
        setLanguageAddData({ ...languageAddData, [name]: event.value })
    }

    const handleUpdateChangeSelect = (name) => (event) => {
        setLanguageUpdateData({ ...languageUpdateData, [name]: event.value })
    }

    let errorsObj = {};

    const [errors, setErrors] = useState({})

    const insertCandidateLanguage = (body) => {

        let error = false;
        const errorObj = { ...errorsObj };

        if (!body.language_id) {
            errorObj.language_id = 'Language title is required';
            error = true;
        }

        if (!body.proficiency) {
            errorObj.proficiency = 'Proficiency is required';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            setLoader(false);
        }

        else {
            setAddModalOpen(false);
            setLanguageAddData({});
            postCandidateLanguage(body).then((response) => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    props.renderState()
                    setLoader(false);
                }
                else {
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                    setLoader(false)
                }
            })
        }
    }

    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

    const handleOpen = (itemId) => {
        setOpenDeleteModal(true);
        setDeleteItemId(itemId);
    };

    const handleClose = () => {
        setOpenDeleteModal(false);
    };

    const [deleteItemId, setDeleteItemId] = useState('')
    const deleteLanguage = (id) => {
        setOpenDeleteModal(false)
        setLoader(true)
        deleteCandidateLanguage(id).then((response) => {
            if (response.success) {
                alertContext.setResponseMessage(response.response.message)
                alertContext.setOpenAlert(true)
                props.renderState()
                setLoader(false)
            }
            else {
                console.log('errors: ', response);
                const message = response.errors?.map(msg => {
                    alertContext.setResponseMessage(msg.message)
                })
                alertContext.setOpenErrorAlert(true)
                setLoader(false);
            }
        })
    }

    const [updateErrors, setUpdateErrors] = useState({})
    const updateCandidateLanguage = (body) => {

        let error = false;

        const errorObj = { ...errorsObj };

        if (!body.language_id) {
            errorObj.language_id = 'Language title is required';
            error = true;
        }

        if (!body.proficiency) {
            errorObj.proficiency = 'Proficiency is required';
            error = true;
        }

        setUpdateErrors(errorObj);
        if (error) {
            setLoader(false);
        }
        else {
            setUpdateModalOpen(false);
            postCandidateLanguage(body).then((response) => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    props.renderState()
                    setLoader(false);
                }
                else {
                    console.log('errors: ', response);
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                    setLoader(false);
                }
            })
        }
    }

    return (
        <div id='candidate_update_language_box'>
            <div className='card shadow-sm'>
                <div className={customStyles.cardHeader}>
                    <div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
                        <div className={customStyles.sectionHeader}>Languages</div>
                        <AddButton title="Languages" onClickHandler={() => setAddModalOpen(true)} />
                    </div>
                </div>
                <div className='card-body'>
                    {
                        loader ?
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                                <CircularProgress />
                            </Box>
                            :
                            <table className='table '>
                                <thead>
                                    <th>Languages</th>
                                    <th>Proficiency</th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    {
                                        props.candidateLanguages?.map((item, index) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>{item.language?.name}</td>
                                                    <td>{item.proficiency}</td>
                                                    <td className='text-r'>
                                                        <div className={customStyles.actionContainer} style={{ marginLeft: '10px', width: 'fit-content' }}>
                                                            <span style={{ height: '32px' }} onClick={() => { enableUpdate(item); setUpdateModalOpen(true) }}>
                                                                <EditSVG />
                                                            </span>
                                                            <span style={{ height: '32px' }} onClick={() => handleOpen(item.id)}>
                                                                <DeleteSVG />
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                    }
                </div>
            </div>
            <CandidateUpdateModal isContentScrollable={false} form="candidateLanguageAddForm" open={addModalOpen} onClose={() => [setErrors({}), setAddModalOpen(false), setLanguageAddData({})]} title='New Language'>
                <div>
                    <div className='card-body'>
                        <form id='candidateLanguageAddForm' onSubmit={(e) => { e.preventDefault(); setLoader(true); insertCandidateLanguage(languageAddData); }}>
                            <div className={`row text-left`}>

                                <div className="col-lg-6 col-md-12" style={{ zIndex: '5' }}>
                                    <div className="form-group">
                                        <label>Language <RequiredAsterisk/></label>
                                        <Select
                                            options={languages?.map((item, index) => {
                                                return (
                                                    { label: item.name, value: item.id }
                                                )
                                            })}
                                            className='atb-input-box-container'
                                            classNamePrefix='atb-input-box'
                                            onChange={handleChangeSelect('language_id')}
                                            placeholder=''
                                        />
                                        {errors.language_id && <Alert severity="error">{errors.language_id}</Alert>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12" style={{ zIndex: '4' }}>
                                    <div className="form-group">
                                        <label>Proficiency <RequiredAsterisk/></label>
                                        <Select
                                            options={languageProficiencies?.map((item, index) => {
                                                return (
                                                    { label: item.title, value: item.value }
                                                )
                                            })}
                                            className='atb-input-box-container'
                                            classNamePrefix='atb-input-box'
                                            onChange={handleChangeSelect('proficiency')}
                                            placeholder=''
                                        />
                                        {errors.proficiency && <Alert severity="error">{errors.proficiency}</Alert>}
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </CandidateUpdateModal>

            <CandidateUpdateModal isContentScrollable={false} form="candidateLanguageUpdateForm" open={updateModalOpen} onClose={() => [setUpdateErrors({}), setUpdateModalOpen(false), setLanguageUpdateData({})]} title='Update Language'>
                <div>
                    <div className='card-body'>
                        <form id='candidateLanguageUpdateForm' onSubmit={(e) => { e.preventDefault(); setLoader(true); updateCandidateLanguage(languageUpdateData); }}>
                            <div className='row'>
                                <div className="col-lg-6 col-md-12" style={{ zIndex: '5' }}>
                                    <div className="form-group">
                                        <label>Language <RequiredAsterisk/></label>
                                        <Select
                                            defaultValue={{ label: languageUpdateData.language_label, value: languageUpdateData.language_id }}
                                            options={languages?.map((item, index) => {
                                                return (
                                                    { label: item.name, value: item.id }
                                                )
                                            })}
                                            className='atb-input-box-container'
                                            classNamePrefix='atb-input-box'
                                            onChange={handleUpdateChangeSelect('language_id')}
                                            placeholder=''
                                        />
                                        {updateErrors.language_id && <Alert severity="error">{updateErrors.language_id}</Alert>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12" style={{ zIndex: '4' }}>
                                    <div className="form-group">
                                        <label>Proficiency <RequiredAsterisk/></label>
                                        <Select
                                            defaultValue={{ label: languageUpdateData.proficiency, value: languageUpdateData.proficiency }}
                                            options={languageProficiencies?.map((item, index) => {
                                                return (
                                                    { label: item.title, value: item.value }
                                                )
                                            })}
                                            className='atb-input-box-container'
                                            classNamePrefix='atb-input-box'
                                            onChange={handleUpdateChangeSelect('proficiency')}
                                            placeholder=''
                                        />
                                        {updateErrors.proficiency && <Alert severity="error">{updateErrors.proficiency}</Alert>}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </CandidateUpdateModal>
            <UpdateDeleteConfirmationModal open={openDeleteModal} onClose={handleClose} deleteItem={() => deleteLanguage(deleteItemId)} />
        </div>
    )
}

export default UpdateLanguage