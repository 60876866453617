import React, { useState } from 'react';
import { Typography } from '@mui/material';

const ShowMoreOrShowLessComponent = ({ text, maxLines }) => {
    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => {
        setShowMore((prev) => !prev);
    };

    return (
        <div>
            <Typography
                variant='body1'
                align='left'
                sx={{
                    marginTop: 2,
                    fontWeight: 400,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: showMore ? 'unset' : maxLines,
                    WebkitBoxOrient: 'vertical',
                }}
            >
                {text}
            </Typography>
            {text.length > maxLines * 30 && (
                <Typography
                    variant='body1'
                    align='left'
                    sx={{
                        marginTop: 1,
                        color: '#DB1616', 
                        cursor: 'pointer', 
                        textDecoration: 'underline'
                    }}
                    onClick={toggleShowMore}
                >
                    {showMore ? 'Show less' : 'Show more'}
                </Typography>
            )}
        </div>
    );
};

export default ShowMoreOrShowLessComponent;
