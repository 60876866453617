import React from 'react'
import EmployerDashboardSideBar from '../../components/EmployerComponents/EmployerDashboardSideBar/EmployerDashboardSideBar'
import EmployerContextProvider from '../../context/EmployerContext'
import EmployerNotificationsComponent from '../../components/EmployerComponents/EmployerNotificationComponent/EmployerNotificationComponent'
import EmployerMessage from '../../components/EmployerComponents/EmployerMessageComponent'


export default function EmployerMessagePage() {
    return (
        <div className='container'>
            <div className='row'>
                <div className='col-lg-4 col-md-0'>
                    <EmployerContextProvider>
                        <EmployerDashboardSideBar />
                    </EmployerContextProvider>
                </div>
                <div className='col-lg-8 col-md-12'>
                    <EmployerMessage />
                </div>
            </div>
        </div>
    )
}