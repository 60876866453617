import React, { useContext, useEffect, useState } from "react"
import styles from '../../css/v2/companyList.module.css'
import TuneSharpIcon from '@mui/icons-material/TuneSharp';
import { Card, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Pagination, Radio, RadioGroup, Skeleton, Stack, TextField } from "@mui/material";
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import Select from 'react-select'
import { DropdownContext } from "../context/DropdownContext";
import { useQuery } from "react-query";
import { searchCompany } from "../../fetchAPI";
import { Link } from 'react-router-dom'
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import makeStyles from '@material-ui/core/styles/makeStyles';
import GlobalLoader from "../components/GlobalLoader";



export default function CompanyList() {

    const urlParams = new URLSearchParams(window.location.search);
    const top_companies = urlParams.get("top_companies");

    const useStyles = makeStyles(() => ({
        ul: {
            '& .MuiPaginationItem-root': {
                '&.Mui-selected': {
                    background: '#DB1616',
                    color: 'white',
                },
            },
        },
        customTextField: {
            "& input::placeholder": {
                fontSize: "14px"
            }
        }
    }));

    const muiClasses = useStyles()

    const { departments, industries, apiCallingConditionSet, setApiCallingConditionSet } = useContext(DropdownContext);

    const [pageNumber, setPageNumber] = useState(1)

    const [employerName, setEmployerName] = useState('');
    const [employerType, setEmployerType] = useState('');
    const [employerLocation, setEmployerLocation] = useState('');
    const [defaultSelectDepartment, setDefaultSelectDepartment] = useState('')
    const [defaultSelectIndustry, setDefaultSelectIndustry] = useState('')
    const [businessType, setBusinessType] = useState('');
    const [topFeaturedCompanies, setTopFeaturedCompanies] = useState(false);

    const [companySearchData, setCompanySearchData] = useState({})

    const [sideBarOpen, setSideBarOpen] = useState(true);

    const handleBusinessTypeChange = (event) => {
        setBusinessType(event.target.value);
        setCompanySearchData({ ...companySearchData, nature_of_business: event.target.value })
    };

    const changePage = (event, value) => {
        setPageNumber(value);
    }

    const resetFilterForm = () => {
        setCompanySearchData({});
        setDefaultSelectDepartment('')
        setDefaultSelectIndustry('')
        setEmployerName('')
        setEmployerType('')
        setEmployerLocation('')
        setBusinessType('');
        setTopFeaturedCompanies('')
    }

    const handleResize = () => {
        if (window.matchMedia("(max-width: 768px)").matches) {
            setSideBarOpen(false);
        } else {
            setSideBarOpen(true);
        }
    };

    const handleWindowClick = (e) => {
        if (window.innerWidth <= 768 && !e.target.matches(`.${styles.filterFormSideBar},.${styles.filterFormSideBar} *,.sidebarOpenButton`)) {
            setSideBarOpen(false)
        }
    }

    const handleSearchButtonClick = (e) => {
        setCompanySearchData({ ...companySearchData });
        if (window.innerWidth <= 768) {
            setSideBarOpen(false)
        }
    }

    useEffect(() => {
        setTopFeaturedCompanies(Boolean(top_companies))
    }, [])

    useEffect(() => {
        setApiCallingConditionSet({
            ...apiCallingConditionSet,
            department: true,
            industry: true,
        })
    }, [])

    useEffect(() => {

        if (window.innerWidth < 768) {
            setSideBarOpen(false)
        } else {
            setSideBarOpen(true);
        }

        window.addEventListener('resize', handleResize);
        window.addEventListener('click', handleWindowClick)
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('click', handleWindowClick);
        };
    }, []);

    useEffect(() => {
        setCompanySearchData({
            employer_name: employerName,
            company_type: employerType,
            map_location: employerLocation,
            department: '',
            industry: '',
            nature_of_business: businessType,
            is_top_featured_companies: topFeaturedCompanies
        })
    }, [employerName, employerType, employerLocation, businessType, topFeaturedCompanies]);

    const { data, isLoading } = useQuery(['companyList', companySearchData, pageNumber], searchCompany, { staleTime: Infinity })

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [isLoading])

    return (
        <div className={styles.companyListPageRoot}>

            <div className={`${styles.filterFormSideBar} ${sideBarOpen ? styles.active : styles.inactive}`}>
                <div className={styles.sideBarCloseIcon} onClick={() => setSideBarOpen(false)}>
                    <CloseSharpIcon />
                </div>
                <div className={styles.filterFormContainer}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ color: 'black', fontSize: 18, fontFamily: 'Epilogue', fontWeight: '700', wordWrap: 'break-word' }}>
                            All Filters
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div onClick={resetFilterForm} style={{ cursor: 'pointer', color: '#0275D8', fontSize: 14, fontFamily: 'Epilogue', fontWeight: '600', wordWrap: 'break-word' }}>
                                Clear Filters
                            </div>
                            <div className={styles.fliterIconContainer}>
                                <TuneSharpIcon className="text-primary" />
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <form>
                            <Stack spacing={2}>
                                <TextField
                                    inputProps={{ style: { fontSize: 14, color: '#72737C', fontFamily: 'Poppins' } }}
                                    InputLabelProps={{ style: { fontSize: 14, color: '#72737C', fontFamily: 'Poppins' } }}
                                    label="Company Name"
                                    placeholder="Company Name"
                                    multiline
                                    sx={{ width: '100%' }}
                                    value={employerName} onChange={(e) => setEmployerName(e.target.value)} onBlur={(e) => setCompanySearchData({ ...companySearchData, employer_name: e.target.value })}
                                />
                                <TextField
                                    inputProps={{ style: { fontSize: 14, color: '#72737C', fontFamily: 'Poppins' } }}
                                    InputLabelProps={{ style: { fontSize: 14, color: '#72737C', fontFamily: 'Poppins' } }}
                                    label="Company type"
                                    placeholder="Company type"
                                    multiline
                                    sx={{ width: '100%' }}
                                    value={employerType} onChange={(e) => setEmployerType(e.target.value)} onBlur={(e) => setCompanySearchData({ ...companySearchData, company_type: e.target.value })}
                                />
                                <TextField
                                    inputProps={{ style: { fontSize: 14, color: '#72737C', fontFamily: 'Poppins' } }}
                                    InputLabelProps={{ style: { fontSize: 14, color: '#72737C', fontFamily: 'Poppins' } }}
                                    label="Location"
                                    placeholder="Location"
                                    multiline
                                    sx={{ width: '100%' }}
                                    value={employerLocation} onChange={(e) => setEmployerLocation(e.target.value)} onBlur={(e) => setCompanySearchData({ ...companySearchData, map_location: e.target.value })}
                                />
                                <Select
                                    menuPortalTarget={document.body}
                                    styles={{ control: base => ({ ...base, height: '56px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                    value={defaultSelectDepartment}
                                    options=
                                    {departments.map(item => {
                                        return (
                                            { label: item.name, value: item.id }
                                        )
                                    })
                                    }
                                    isClearable
                                    placeholder='Department'
                                    onChange={(e) => [setCompanySearchData({ ...companySearchData, department: e?.value }), setDefaultSelectDepartment({ label: e?.label, value: e?.value })]}
                                    className='react-select-container'
                                    classNamePrefix='react-select'
                                />
                                <Select
                                    menuPortalTarget={document.body}
                                    styles={{ control: base => ({ ...base, height: '56px' }), placeholder: base => ({ ...base, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }), menuPortal: base => ({ ...base, zIndex: 9999, fontSize: 14, color: '#72737C', fontFamily: 'Poppins' }) }}
                                    value={defaultSelectIndustry}
                                    options=
                                    {industries.map(item => {
                                        return (
                                            { label: item.name, value: item.id }
                                        )
                                    })
                                    }
                                    isClearable
                                    placeholder='Industry'
                                    onChange={(e) => [setCompanySearchData({ ...companySearchData, industry: e?.value }), setDefaultSelectIndustry({ label: e?.label, value: e?.value })]}
                                    className='react-select-container'
                                    classNamePrefix='react-select'
                                />

                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={topFeaturedCompanies} onChange={(e) => setTopFeaturedCompanies(e.target.checked)} />} label="Top Featured Companies" />
                                </FormGroup>

                                <FormControl>
                                    <FormLabel id="nature_of_business_label" sx={{ margin: '10px 0px !important', color: 'black', fontSize: 18, fontFamily: 'Epilogue', fontWeight: '700', wordWrap: 'break-word' }}>Nature of Business</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="nature_of_business_label"
                                        name="nature_of_business"
                                        value={businessType}
                                        onChange={handleBusinessTypeChange}
                                    >
                                        <FormControlLabel sx={{ color: 'black' }} value="b2b" control={<Radio />} label="B2B" />
                                        <FormControlLabel sx={{ color: 'black' }} value="b2c" control={<Radio />} label="B2C" />

                                    </RadioGroup>
                                </FormControl>

                                {/* <button type="button" class="btn btn-dark">Search By Benefit</button> */}
                                <button type="button" class="btn btn-primary mb-3" onClick={handleSearchButtonClick}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div>
                                            Search
                                        </div>
                                        <SearchSharpIcon sx={{ marginLeft: '10px' }} />
                                    </div>
                                </button>
                            </Stack>

                        </form>
                    </div>

                </div>
            </div>
            <div className={styles.listContainer}>
                <div className="text-c mb-3">
                    <button className={`btn btn-primary sidebarOpenButton ${sideBarOpen ? styles.inactive : styles.active}`} onClick={() => { setSideBarOpen(true) }}>Open Advanced Search Filter</button>
                </div>
                <div>
                    {
                        isLoading ?
                            <div className="container">
                                <GlobalLoader height='50vh' />
                            </div>
                            :
                            <div>
                                <h3 style={{ color: '#1D1E1B', fontSize: 20, fontFamily: 'Epilogue', fontWeight: '700' }}>Showing companies <span style={{ color: '#1976d2' }}>({data?.response?.pagination?.total})</span></h3>
                                <hr style={{ backgroundColor: 'black' }} />
                                <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                                    {
                                        data?.response?.data?.map((item, index) => {
                                            return (
                                                <div key={index} style={{ maxWidth: '270px' }}>
                                                    <Link to={`/employer/profile/${item.slug}`} >
                                                        <Card sx={{ margin: '20px 10px', height: '120px', width: '240px', display: 'flex', alignItems: 'center' }} key={index}>
                                                            <div className='text-c' style={{ width: '100%' }}>
                                                                {
                                                                    item.profile_pic_s3 ?
                                                                        <img style={{ maxWidth: '200px', maxHeight: '90px' }} src={item.profile_pic_s3} alt='Employer avatar' />
                                                                        :
                                                                        <img style={{ maxWidth: '200px', maxHeight: '90px' }} src='/images/company_placeholder_icon.png' alt='Employer avatar' />
                                                                }
                                                            </div>

                                                        </Card>
                                                    </Link>
                                                    <div style={{ textAlign: 'center', color: '#0275D8', fontSize: 15, fontFamily: 'Epilogue', fontWeight: '600', wordWrap: 'break-word' }}>
                                                        <Link to={`/employer/profile/${item.slug}`} >
                                                            {item.employer_name} - {item.published_jobs_count} Jobs
                                                        </Link>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <hr style={{ backgroundColor: 'black', marginTop: '50px' }} />
                                <div style={{ display: 'flex', justifyContent: 'end' }}>
                                    <Pagination page={pageNumber} count={data?.response?.pagination?.last_page} color="primary" shape="rounded" boundaryCount={1} onChange={changePage} />
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}