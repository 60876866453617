
/**Imports */

import React, { useContext } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { EmployerContext } from '../../../context/EmployerContext'
import { UserContext } from '../../../context/UserContext'
import SuccessAlert from '../../Alerts/SuccessAlert'
import ErrorAlert from '../../Alerts/ErrorAlert'

function EmployerDashboardSideBar() {


    // Employer Data Initialization
    const { employerData, employerDataLoading } = useContext(EmployerContext)
    const { logout, profilePic, setSuccessTwister, loading, successTwister, successMessage, setSelectedFile, notificationCounter, uploadErrorAlert, setUploadErrorAlert, uploadErrorMessage } = useContext(UserContext)

    return (
        <div className='atb-card'>
            <div className="canditate-des">
                {
                    loading ?
                        <center>
                            <img src='/v2/spinners/Spinner-1.gif' />
                        </center>
                        :
                        <>
                            <img className='employer-profile-image' src={profilePic ? profilePic : '/v2/images/employer_placeholder.png'} />
                            <div className="upload-link" title="update" data-toggle="tooltip" data-placement="right">
                                <input style={{ cursor: 'pointer',display:'none' }} type="file" onChange={(event) => setSelectedFile(event.target.files[0])} id='upload-pic' className="update-flie" />
                                <label htmlFor='upload-pic' style={{cursor:'pointer'}}>
                                    <img src='/v2/icons/photo-camera.png' height={'auto'} width={'100%'} />
                                </label>
                            </div>
                        </>
                }

            </div>

            <h3 className="mt-3 mb-2"><strong>{employerData.employer_name}</strong></h3>
            <p className="text-muted">Employer</p><hr />
            <div className='text-left'>
                <NavLink activeClassName='sidebarActiveTab' to={'/dashboard'}> <h5 className="mb-2"><img className='dashboard-icon' src='/v2/icons/dashboard.png' /><strong className='dashboard-menu-item'>Dashboard</strong></h5><hr /></NavLink>
                <NavLink activeClassName='sidebarActiveTab' to={'/employer-update-profile'}> <h5 className="mb-2"> <img className='dashboard-icon' src='/v2/icons/write.png' /><strong className='dashboard-menu-item'>Update Profile</strong></h5><hr /></NavLink>
                <NavLink activeClassName='sidebarActiveTab' to={`/employer/profile/${employerData?.slug}`} target='_blank'> <h5 className="mb-2"> <img className='dashboard-icon' src='/v2/icons/user.png' /><strong className='dashboard-menu-item'>View Profile</strong></h5><hr /></NavLink>
                <div className='row mb-2'>
                    <div className='col-8'>
                        <NavLink activeClassName='sidebarActiveTab' to={'/employer-notifications'}> <h5 className=""><img className='dashboard-icon' src='/v2/icons/bell.png' /><strong className='dashboard-menu-item'>Notifications</strong></h5></NavLink>
                    </div>
                    <div className='col-4 text-right'>
                        {
                            notificationCounter > 0 ? <span className="badge badge-info m-1">{notificationCounter}</span> : ""
                        }
                    </div>
                </div><hr />
                <NavLink activeClassName='sidebarActiveTab' to={'/employer-post-job'}> <h5 className="mb-2">  <img className='dashboard-icon' src='/v2/icons/cv.png' /><strong className='dashboard-menu-item'>Post A Job</strong></h5><hr /></NavLink>
                <NavLink activeClassName='sidebarActiveTab' to={'/employer-manage-jobs'}> <h5 className="mb-2"> <img className='dashboard-icon' src='/v2/icons/portfolio.png' /><strong className='dashboard-menu-item'>Manage Job</strong></h5><hr /></NavLink>
                {/*<NavLink activeClassName='sidebarActiveTab' to={'/employer-saved-profiles'}> <h5 className="mb-2"><img className='dashboard-icon' src='/v2/icons/add-friend.png' /><strong className='dashboard-menu-item'>Saved Profiles</strong></h5><hr /></NavLink>*/}
                {/* <NavLink activeClassName='sidebarActiveTab' to={'/employer-purchase-history'}> <h5 className="mb-2"><img className='dashboard-icon' src='/v2/icons/shopping-cart.png' /><strong> Purchase History</strong></h5><hr /></NavLink> */}
                <NavLink activeClassName='sidebarActiveTab' to={'/employer-message'}> <h5 className="mb-2"><img className='dashboard-icon' src='/v2/icons/send_message.png' /><strong className='dashboard-menu-item'>Messages</strong></h5><hr /></NavLink>
                <NavLink activeClassName='sidebarActiveTab' to={'/employer-account-settings'}> <h5 className="mb-2"><img className='dashboard-icon' src='/v2/icons/padlock.png' /><strong className='dashboard-menu-item'>Account Settings</strong></h5><hr /></NavLink>
                <NavLink to={'/'} onClick={logout}> <h5 className="mb-2"> <img className='dashboard-icon' src='/v2/icons/power-off.png' /><strong className='dashboard-menu-item'>Logout</strong></h5><hr /></NavLink>
            </div>
            <SuccessAlert open={successTwister} message={successMessage} onHide={() => setSuccessTwister(false)} />
            <ErrorAlert open={uploadErrorAlert} message={uploadErrorMessage} onHide={() => setUploadErrorAlert(false)} />

        </div>
    )
}

export default EmployerDashboardSideBar