import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { JobSearchContext } from '../../context/JobSearchContext'
import { Box, Button, Grid, InputAdornment, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

function HomePageSearchBox() {

    const { employerAddress, setEmployerAddress, keyword, setKeyword, } = useContext(JobSearchContext)

    const history = useHistory()

    const [tempKeyword, setTempKeyword] = useState('')
    const [tempEmployerAddress, setTempEmployerAddress] = useState('')

    const redirectToSearchPage = () => {
        history.push({
            pathname: '/search/jobs',
            state: { fromHome: true }
        });
    }

    const handleHomePageSearch = async () => {
        setKeyword(tempKeyword)
        setEmployerAddress(tempEmployerAddress)
        redirectToSearchPage()
    }

    function SearchIcon() {
        return (
<svg width="36" height="36" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="48" rx="24" fill="#DCEFFF"/>
<path d="M27.7955 27.8111L33 33M30 22.5C30 26.6421 26.6421 30 22.5 30C18.3579 30 15 26.6421 15 22.5C15 18.3579 18.3579 15 22.5 15C26.6421 15 30 18.3579 30 22.5Z" stroke="#0079C1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        )
    }

    function LocationIcon() {
        return (
<svg width="36" height="36" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="48" rx="24" fill="#DCEFFF"/>
<g clip-path="url(#clip0_4093_1111)">
<path d="M17.5385 25.249L17.5385 25.249C16.8805 24.1168 16.532 22.8212 16.532 21.5026C16.532 17.3861 19.8822 14.0359 23.9987 14.0359C28.1152 14.0359 31.4654 17.3861 31.4654 21.5026C31.4654 22.8211 31.1169 24.1168 30.4589 25.249C30.3053 25.5132 30.1335 25.7709 29.9485 26.0145L29.9485 26.0145L29.9453 26.0188L24.0028 33.9693H23.9946L18.0521 26.0187L18.0522 26.0187L18.0489 26.0144C17.8638 25.7708 17.6921 25.5131 17.5385 25.249ZM20.5904 21.5026C20.5904 23.3826 22.1187 24.9109 23.9987 24.9109C25.8787 24.9109 27.407 23.3826 27.407 21.5026C27.407 19.6226 25.8787 18.0943 23.9987 18.0943C22.1187 18.0943 20.5904 19.6226 20.5904 21.5026Z" stroke="#0079C1" stroke-width="1.4"/>
</g>
<defs>
<clipPath id="clip0_4093_1111">
<rect width="21.3333" height="21.3333" fill="white" transform="translate(13.3359 13.3359)"/>
</clipPath>
</defs>
</svg>
        )
    }

    const {t} = useTranslation(["homapge", "button"])

    return (
        <Box>
            <form className="index-search-container" onSubmit={() => handleHomePageSearch()} >
                <Grid container spacing={2}>
                    <Grid item md={5} xs={12}>
                        <TextField
                            className="form-control index-search-field"
                            variant='outlined'
                            placeholder={t("homepage:banner.keyword-box-label")}
                            fullWidth
                            // defaultValue={keyword}
                            onChange={(e) => setTempKeyword(e.target.value)}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <TextField
                            className="form-control index-search-field"
                            // defaultValue={employerAddress}
                            onChange={(e) => setTempEmployerAddress(e.target.value)}
                            variant='outlined'
                            placeholder={t("homepage:banner.location-box-label")}
                            fullWidth
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <LocationIcon />
                                    </InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <Button
                            type='submit'
                            variant='contained'
                            size='large'
                            disableElevation
                            fullWidth
                            sx={{
                                padding: 2,
                                borderRadius: '6px',
                                backgroundColor: '#0275D8',
                                '&:hover': {
                                    backgroundColor: '#155ead',
                                },
                            }}
                        >
                            {t("button:search-job.title")}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>

    )
}

export default HomePageSearchBox