import React, { useState, useEffect, useRef } from 'react'
import { deleteCandidateProjectByID, postCandidateProjectByID, updateCandidateProjectByID } from '../../../../../fetchAPI'
import { Alert, Box, CircularProgress } from '@mui/material'
import UpdateDeleteConfirmationModal from './UpdateDeleteConfirmationModal'
import Select from 'react-select'
import JoditEditor from 'jodit-react';
import customStyles from './CandidateUpdateProfile.module.css'
import AddButton from './component/AddButton'
import CandidateUpdateModal from './component/CandidateUpdateModal'
import SingleProjectBlock from './component/SingleProjectBlock'
import EditSVG from './component/EditSVG'
import DeleteSVG from './component/DeleteSVG'
import { useAlert } from '../../../../context/AlertContext'
import RequiredAsterisk from './component/RequiredAsterisk'

function UpdateProject(props) {

    const [loader, setLoader] = useState(false)

    const [updateProjectData, setUpdateProjectData] = useState('')
    const [projectData, setProjectData] = useState('')

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addFormRef = useRef();
    const [deleteItemId, setDeleteItemId] = useState('')

    const alertContext = useAlert();

    const handleUpdateChange = (name) => (event) => {
        setUpdateProjectData({
            ...updateProjectData,
            [name]: event.target.value
        })
    }

    const enableUpdate = (item) => {
        setUpdateProjectData({
            ...updateProjectData,
            id: item.id,
            title: item.title,
            description: item.description,
            start_date: item.start_date,
            end_date: item.end_date,
            currently_working: item.currently_working,
            employment: item.employment
        })
    }

    const handleChange = (name) => (event) => {
        setProjectData({ ...projectData, [name]: event.target.value })
    }

    const handleCheckBox = (name) => (event) => {
        const checked = event.target.checked;
        setProjectData({ ...projectData, [name]: +checked,  end_date: checked ? '' : projectData.end_date })
    }

    const handleUpdateCheckBox = (name) => (event) => {
        setUpdateProjectData({ ...updateProjectData, [name]: +(event.target.checked) })
    }

    function defaultSelectValue(item) {
        if (item.employment != null) {
            return { label: item.employment.organization_name, value: item.employment.id }

        } else {
            return {}
        }
    }


    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
    const handleOpen = (itemId) => {
        setOpenDeleteModal(true);
        setDeleteItemId(itemId);
    };
    const handleClose = () => {
        setOpenDeleteModal(false);
    };

    const deleteCandidateproject = (projectId) => {
        setOpenDeleteModal(false)
        setLoader(true)
        deleteCandidateProjectByID(projectId).then((response) => {
            if (response.success) {
                alertContext.setResponseMessage(response.response.message)
                alertContext.setOpenAlert(true)
                // props.projects.splice(index, 1);
                props.renderState()
                setLoader(false)
            }
            else {
                // console.log('errors: ', response);
                const message = response.errors?.map(msg => {
                    alertContext.setResponseMessage(msg.message)
                })
                alertContext.setOpenErrorAlert(true)
                setLoader(false)
            }
        })
    }

    let errorsObj = { title: '', start_date: '', end_date: '', description: '' };
    const [updateErrors, setUpdateErrors] = useState('')
    const [errors, setErrors] = useState({})
    const insertCandidateProject = (candId, body) => {

        let error = false;

        const errorObj = { ...errorsObj };

        if (!body.title) {
            errorObj.title = 'Project title is Required';
            error = true;
        }
        if (!body.start_date) {
            errorObj.start_date = 'Start Date is Required';
            error = true;
        }
        if (!body.end_date && !body.currently_working) {
            errorObj.end_date = 'End Date is Required';
            error = true;
        }
        if (!body.description) {
            errorObj.description = 'Description is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            // Do not call the Post API
            setLoader(false);
        }
        else {
            setAddModalOpen(false);
            setProjectData({});

            postCandidateProjectByID(candId, body).then((response) => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    props.renderState()
                    setLoader(false);
                }
                else {
                    // console.log('errors: ', response);
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                    setLoader(false)
                }
            })
        }
    }

    const updateCandidateProject = (candId, body) => {

        let error = false;

        const errorObj = { ...errorsObj };

        if (!body.title) {
            errorObj.title = 'Project title is Required';
            error = true;
        }
        if (!body.start_date) {
            errorObj.start_date = 'Start Date is Required';
            error = true;
        }
        if (!body.end_date && !body.currently_working) {
            errorObj.end_date = 'End Date is Required';
            error = true;
        }
        if (!body.description) {
            errorObj.description = 'Description is Required';
            error = true;
        }
        setUpdateErrors(errorObj);
        if (error) {
            // Do not call the Post API
            setLoader(false);
        }
        else {
            setUpdateModalOpen(false);
            updateCandidateProjectByID(candId, body.id, body).then((response) => {
                if (response && response.success) {
                    alertContext.setResponseMessage(response.response.message)
                    alertContext.setOpenAlert(true)
                    props.renderState()
                    setLoader(false)


                }
                else {
                    // console.log('errors: ', response);
                    const message = response.errors?.map(msg => {
                        alertContext.setResponseMessage(msg.message)
                    })
                    alertContext.setOpenErrorAlert(true)
                    setLoader(false);
                }
            })
        }
    }


    return (
        <div id='project_box'>
            <div className='card shadow-sm'>
                <div className={customStyles.cardHeader}>
                    <div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
                        <div className={customStyles.sectionHeader}>Projects</div>
                        <AddButton title="Project" onClickHandler={() => setAddModalOpen(true)} />
                    </div>
                </div>
                <div className='card-body'>
                    {
                        loader ?
                            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                                <CircularProgress />
                            </Box>
                            :
                            props.projects?.map((item, index) => {
                                return (
                                    <div key={item.id}>
                                        {index > 0 ? <hr /> : ''}
                                        <div className={customStyles.singleProject} >
                                            <div className={customStyles.projectTitle}>{item.title}</div>
                                            <div className={customStyles.actionContainer} style={{ marginLeft: '10px' }}>
                                                <span style={{ height: '32px' }} onClick={() => { enableUpdate(item); setUpdateModalOpen(true) }}>
                                                    <EditSVG />
                                                </span>
                                                <span style={{ height: '32px' }} onClick={() => handleOpen(item.id)}>
                                                    <DeleteSVG />
                                                </span>
                                            </div>
                                        </div>
                                        <SingleProjectBlock item={item} />
                                    </div>
                                )
                            })
                    }
                </div>
            </div>
            <CandidateUpdateModal form="candidateProjectAddForm" open={addModalOpen} onClose={() => [setErrors({}), setAddModalOpen(false), setProjectData({})]} title='New Project'>
                <div>
                    <div className='card-body'>
                        <form id='candidateProjectAddForm' ref={addFormRef} onSubmit={(e) => { e.preventDefault(); setLoader(true); insertCandidateProject(props.candId, projectData); }}>

                            <div className={`row text-left`}>

                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label>Project Title <RequiredAsterisk/></label>
                                        <input className='form-control atb-input-box' onChange={handleChange('title')} placeholder='Project Title' type='text' />
                                        {errors.title && <Alert severity="error">{errors.title}</Alert>}
                                    </div>
                                </div>
                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label >Project  Company / Client  Name</label>
                                        <Select
                                            placeholder='Select Company'
                                            options={props.experiences?.map(item => {
                                                return (
                                                    { label: item.organization_name, value: item.id }
                                                )
                                            })
                                            }
                                            onChange={(event) => setProjectData({ ...projectData, candidate_experience_id: event.value })}
                                            className='skill-update-container'
                                            classNamePrefix='skill-update'
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    borderRadius: '5px !important',
                                                    height: '51px'
                                                }),
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label>Start Date <RequiredAsterisk/></label>
                                        <input className='form-control atb-input-box' onChange={handleChange('start_date')} placeholder='Start Date' type='date' />
                                        {errors.start_date && <Alert severity="error">{errors.start_date}</Alert>}
                                    </div>
                                </div>
                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label>End Date {!projectData.currently_working ? <RequiredAsterisk/> : ''}</label>
                                        <input className='form-control atb-input-box' disabled={projectData.currently_working == 1 ? true : false} onChange={handleChange('end_date')} placeholder='End Date' type='date' value={projectData.end_date}/>
                                        <input className='m-t10' type='checkbox' onChange={handleCheckBox('currently_working')} /> Ongoing

                                        {errors.end_date && <Alert severity="error">{errors.end_date}</Alert>}
                                        {errors.dateValid && <Alert severity="error">{errors.dateValid}</Alert>}
                                    </div>
                                </div>
                                <div className='col-md-12 col-lg-12'>
                                    <div className='form-group'>
                                        <label>Project Description <RequiredAsterisk/></label>
                                        <JoditEditor
                                            value={projectData.description || ''}
                                            buttons={[
                                                'bold', '|',
                                                'italic', '|',
                                                'ul', '|', 'underline', '|']}
                                            className='m-t10'
                                            onChange={(content) => setProjectData({ ...projectData, description: content })}
                                        />
                                        {errors.description && <Alert severity="error">{errors.description}</Alert>}
                                    </div>
                                </div>


                            </div>
                        </form>
                    </div>
                </div>
            </CandidateUpdateModal>
            <CandidateUpdateModal form="candidateProjectUpdateForm" open={updateModalOpen} onClose={() => [setUpdateErrors({}), setUpdateModalOpen(false), setUpdateProjectData({})]} title='Update Project'>
                <div>
                    <div className='card-body'>
                        <form id='candidateProjectUpdateForm' onSubmit={(e) => { e.preventDefault(); setLoader(true); updateCandidateProject(props.candId, updateProjectData); }}>
                            <div className='row'>

                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label>Project Title <RequiredAsterisk/></label>
                                        <input
                                            value={updateProjectData.title}
                                            onChange={handleUpdateChange('title')}
                                            className='form-control atb-input-box m-b10' type='text' />
                                        {updateErrors.title && <Alert severity="error">{updateErrors.title}</Alert>}
                                    </div>
                                </div>

                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label >Project  Company / Client  Name</label>
                                        <Select
                                            placeholder='Select Employment'
                                            defaultValue={defaultSelectValue(updateProjectData)}
                                            options={props.experiences?.map(item => {
                                                return (
                                                    { label: item.organization_name, value: item.id }
                                                )
                                            })
                                            }
                                            onChange={(event) => setUpdateProjectData({ ...updateProjectData, candidate_experience_id: event.value })}
                                            className='skill-update-container'
                                            classNamePrefix='skill-update'
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    borderRadius: '5px !important',
                                                    height: '51px'
                                                }),
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label className='mt-2'>Start Date <RequiredAsterisk/></label>
                                        <input
                                            value={updateProjectData.start_date}
                                            onChange={handleUpdateChange('start_date')}
                                            className='form-control atb-input-box m-b10' type='date' />
                                        {updateErrors.start_date && <Alert severity="error">{updateErrors.start_date}</Alert>}
                                    </div>
                                </div>

                                <div className='col-md-6 col-lg-6'>
                                    <div className='form-group'>
                                        <label className='mt-2'>End Date {!updateProjectData.currently_working ? <RequiredAsterisk/> : ''}</label>
                                        <input
                                            value={updateProjectData.end_date}
                                            onChange={handleUpdateChange('end_date')}
                                            className='form-control atb-input-box m-b10' type='date' disabled={updateProjectData.currently_working == 1 ? true : false} />
                                        <input className='m-t10' type='checkbox' defaultChecked={updateProjectData.currently_working} onChange={handleUpdateCheckBox('currently_working')} /> Ongoing <br />

                                        {updateErrors.end_date && <Alert severity="error">{updateErrors.end_date}</Alert>}
                                        {updateErrors.dateValid && <Alert severity="error">{updateErrors.dateValid}</Alert>}
                                    </div>
                                </div>

                                <div className='col-md-12 col-lg-12'>
                                    <div className='form-group'>
                                        <label className='mt-2'>Project Description <RequiredAsterisk/></label>
                                        <JoditEditor
                                            value={updateProjectData.description || ''}
                                            buttons={[
                                                'bold', '|',
                                                'italic', '|',
                                                'ul', '|', 'underline', '|']}
                                            className='m-t10'
                                            onChange={(content) => setUpdateProjectData({ ...updateProjectData, description: content })}
                                        />
                                    </div>
                                </div>


                            </div>
                        </form>
                    </div>
                </div>
            </CandidateUpdateModal>

            <UpdateDeleteConfirmationModal open={openDeleteModal} onClose={handleClose} deleteItem={() => deleteCandidateproject(deleteItemId)} />
        </div>
    )
}

export default UpdateProject