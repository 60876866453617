import React from "react"

export default function AddSVG() {
    return (

        <svg style={{cursor:'pointer'}} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2517_9255)">
                <path d="M32.0016 16.9368V15.1879C31.9861 15.067 31.9613 14.9461 31.952 14.822C31.8907 13.9502 31.7558 13.0851 31.5489 12.236C30.8097 9.21833 29.2106 6.48002 26.9457 4.35327C24.6808 2.22652 21.8474 0.802717 18.7892 0.254601C18.1691 0.142973 17.5489 0.0840569 16.9287 0.000335693H15.1954C15.0874 0.0401001 14.9723 0.0569954 14.8574 0.0499477C13.976 0.107748 13.1014 0.242636 12.2435 0.453054C9.22587 1.19415 6.48781 2.79412 4.36078 5.05928C2.23376 7.32444 0.808985 10.1576 0.258975 13.2158C0.150447 13.836 0.0915334 14.4562 0.0078125 15.0763V16.8065C0.0233164 16.9275 0.0481237 17.0484 0.057426 17.1724C0.118765 18.0443 0.253617 18.9093 0.460532 19.7585C1.19978 22.7761 2.79885 25.5145 5.06375 27.6412C7.32865 29.768 10.162 31.1918 13.2202 31.7399C13.8404 31.8515 14.4605 31.9104 15.0807 31.9941H16.8295C16.9375 31.9544 17.0526 31.9375 17.1675 31.9445C18.0489 31.8867 18.9236 31.7518 19.7815 31.5414C22.7991 30.8003 25.5371 29.2004 27.6642 26.9352C29.7912 24.67 31.2159 21.8368 31.766 18.7786C31.859 18.174 31.9179 17.5538 32.0016 16.9368ZM29.8652 16.2205C29.822 18.9614 28.9671 21.6281 27.4087 23.8833C25.8503 26.1385 23.6583 27.8811 21.1098 28.8908C18.5612 29.9005 15.7705 30.132 13.0904 29.556C10.4103 28.9801 7.96114 27.6225 6.05244 25.6548C4.14374 23.6872 2.86122 21.1979 2.36697 18.5015C1.87272 15.8052 2.18893 13.0228 3.27564 10.5061C4.36236 7.98941 6.17079 5.85139 8.47235 4.36226C10.7739 2.87313 13.4653 2.09975 16.2063 2.13988C19.8841 2.19783 23.3885 3.71337 25.9496 6.35355C28.5107 8.99372 29.9191 12.5426 29.8652 16.2205Z" fill="#0079C1" />
                <path d="M11.1945 14.9459C10.0472 14.9459 8.90302 14.9459 7.75573 14.9459C7.05185 14.9459 6.59914 15.3924 6.61774 16.0343C6.62233 16.2601 6.70285 16.4777 6.8463 16.6521C6.98976 16.8265 7.18779 16.9475 7.40844 16.9955C7.57208 17.0256 7.73819 17.0402 7.90456 17.0389H14.5278C15.0146 17.0389 14.9495 16.9738 14.9526 17.4761C14.9526 19.7149 14.9526 21.9537 14.9526 24.1924C14.9526 24.9242 15.3867 25.3862 16.0348 25.3707C16.2604 25.368 16.4785 25.2894 16.6539 25.1477C16.8294 25.006 16.9521 24.8093 17.0022 24.5893C17.0348 24.426 17.0503 24.2597 17.0488 24.0932C17.0488 21.8544 17.0488 19.6126 17.0488 17.3738C17.0488 17.0978 17.117 17.0265 17.3929 17.0296C19.6317 17.0296 21.8705 17.0296 24.1123 17.0296C24.2682 17.0328 24.424 17.0203 24.5774 16.9924C24.8081 16.9426 25.0148 16.8153 25.163 16.6316C25.3112 16.448 25.3921 16.2191 25.3921 15.9831C25.3921 15.7471 25.3112 15.5182 25.163 15.3346C25.0148 15.151 24.8081 15.0236 24.5774 14.9738C24.4139 14.9432 24.2476 14.9297 24.0813 14.9335H17.4891C16.9805 14.9335 17.0518 15.0048 17.0518 14.4808C17.0518 12.2854 17.0518 10.088 17.0518 7.88854C17.055 7.73265 17.0425 7.57683 17.0146 7.42343C16.9677 7.18947 16.8412 6.97899 16.6567 6.82776C16.4721 6.67653 16.2409 6.59389 16.0022 6.59389C15.7636 6.59389 15.5324 6.67653 15.3478 6.82776C15.1632 6.97899 15.0367 7.18947 14.9898 7.42343C14.9645 7.57712 14.9531 7.73279 14.9557 7.88854C14.9557 10.1273 14.9557 12.3692 14.9557 14.6079C14.9557 14.8684 14.8875 14.9397 14.627 14.9366C13.486 14.9459 12.3387 14.9459 11.1945 14.9459Z" fill="#0079C1" />
            </g>
            <defs>
                <clipPath id="clip0_2517_9255">
                    <rect width="32" height="32" fill="white" transform="matrix(1 0 0 -1 0 32)" />
                </clipPath>
            </defs>
        </svg>

    )
}