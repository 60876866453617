import React, { useContext } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import EmployerContextProvider from '../context/EmployerContext';
import CandidateContextProvider from '../context/CandidateContext';

const AuthRoute = ({ component, ...rest }) => {
  const Component = component;
  const { isLoggedin, userData, setRedirectionLink } = useContext(UserContext)

  const history = useHistory()
  const redirectLink = window.location.pathname

  const redirectToLoginWithLink =()=>{
    setRedirectionLink(redirectLink)
    history.push('/login')
  }

  return (
    <Route {...rest}>
      {
        isLoggedin && userData.user_role_id == 2 ?
          <CandidateContextProvider>
            <Component />
          </CandidateContextProvider>
          :
          isLoggedin && userData.user_role_id == 3 ?
            <EmployerContextProvider>
              <Component />
            </EmployerContextProvider>
            :
            isLoggedin && userData.user_role_id == 1 ?
              <Component />
              :
              redirectToLoginWithLink()
      }
    </Route>
  );
};

export default AuthRoute;