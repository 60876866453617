import React from "react";
import { Link } from 'react-router-dom';
import HeroSection from '../components/HeroSection/HeroSection'

function RefundPolicyPage() {

    return (
        <div>
            <HeroSection text={"Refund Policy"} />
            <div className="page-wraper">
                <div className="page-content bg-white">
                    <div className="content-block">
                        <div className="section-full content-inner overlay-white-middle">
                            <div className="container">
                                <div className="row align-items-center m-b50">
                                    <div className="col-md-12 col-lg-12 m-b20">

                                        {/* All transactions for purchase of intangible products, pdf downloads, resource material, and online content are made through payment gateways such as PayPal or Stripe that use SSL encryption. These payment gateways are safe and secure for using all types of credit cards and debit cards in different countries and your details are not stored during this process.
                                    Since your purchase is a digital product, it is deemed “used” after download or opening, and all purchases made on www.physicalkitchness.com are non-refundable or exchangeable. Since the products made available here are intangible, there is a strict no refund policy.
                                    Physical Kitchness reserves the right to amend any information, including but not limited to prices, technical specifications, terms of purchase and product or service offerings without prior notice. */}

                                        <label className="m-b15">Please read these refund policy ("refund policy") carefully before using
                                            {<Link to={'/'}> www.atb-jobs.com</Link>} website (“website”, "service") operated by atBJobs ("us", 'we", "our").
                                        </label>

                                        <div className='m-b15 mt-4'>
                                            {/* <h5>Conditions of use</h5> */}

                                            <p> All transactions for purchase of intangible products, pdf downloads, resource material, and packages are made through payment gateways that use SSL encryption. These payment gateways are safe and secure for using all types of credit cards and debit cards in different countries and your details are not stored during this process.
                                                Since your purchase is a digital product/package, it is deemed “used” after transaction, and all purchases made on  {<Link to={'/'}> www.atb-jobs.com</Link>} are non-refundable or exchangeable. Since the products/packages made available here are intangible, there is a strict no refund policy.</p>



                                            <p> ATB Jobs reserves the right to amend any information, including but not limited to prices, technical specifications, terms of purchase and product or service offerings without prior notice.</p>

                                            {/* Revision Required */}
                                            {/* <h5>Age restriction</h5>
                                        <p> You  must be at  least  18  (eighteen)  years  of  age before  you  can  use this  website.  By  using this
                                            website,  you  warrant  that  you  are  at  least  18  years  of  age  and  you  may  legally  adhere  to  this
                                            Agreement. atBJobs assumes no responsibility for liabilities related to age misrepresentation.</p> */}

                                            {/* Till here */}

                                            {/* <h5>Intellectual property</h5> */}
                                            {/* Revise Here */}
                                            {/* <p> You agree that all materials, products, and services provided on this website are the property of
                                            atBJobs,  its  affiliates,  directors,  officers,  employees,  agents,  suppliers,  or  licensors  including  all
                                            copyrights,  trade  secrets,  trademarks,  patents,  and  other  intellectual  property.  You  also  agree
                                            that  you  will  not  reproduce  or  redistribute  the  atBJobs's  intellectual  property  in  any  way,
                                            including electronic, digital, or new trademark registrations.
                                            You  grant  atBJobs  a  royalty-free  and  non-exclusive  license  to  display,  use,  copy,  transmit,  and
                                            broadcast the content you upload and publish. For issues regarding intellectual property claims,
                                            you should contact the company in order to come to an agreement. </p>

                                        <h5>User accounts </h5>
                                        <p> As a user of this website, you may be asked to register with us and provide private information.
                                            You  are  responsible  for  ensuring  the  accuracy  of  this  information,  and  you  are  responsible  for
                                            maintaining the safety and security of your identifying information. You are also responsible for
                                            all activities that occur under your account or password.</p>

                                        <p> If you think there are any possible issues regarding the security of your account on the website,
                                            inform us immediately so we may address them accordingly.</p>

                                        <p> We reserve all rights to terminate accounts or remove content and cancel orders at our sole
                                            discretion.</p>

                                        <h5>Applicable law </h5>
                                        <p> By visiting this website, you agree that the laws of the People's Republic of Bangladesh (?), without regard to principles of
                                            conflict laws, will govern these terms and conditions, or any dispute of any sort that might come
                                            between atBJobs and you, or its business partners and associates.</p>

                                        <h5>Disputes </h5>
                                        <p> Any dispute related in any way to your visit to this website or to products you purchase from us
                                            shall  be arbitrated  by  state or  federal  court  [location] (?) and you consent  to  exclusive jurisdiction
                                            and venue of such courts.</p>

                                        <h5>Indemnification </h5>
                                        <p> You  agree  to  indemnify atBJobs and  its  affiliates and  hold  atBJobs  harmless  against  legal claims
                                            and  demands  that  may  arise  from  your  use  or  misuse  of  our  services.  We  reserve  the  right  to
                                            select our own legal counsel.</p>

                                        <h5>Limitation on liability  </h5>
                                        <p> atBJobs  is  not  liable  for  any  damages  that  may  occur  to  you  as  a  result  of  your  misuse  of  our
                                            website.</p>
                                        <label style={{ fontStyle: 'italic' }}> atBJobs  reserves  the  right to  edit,  modify, and change this Agreement  at any  time.  We shall  let
                                            our  users know  of  these  changes  through  electronic  mail. This Agreement  is an  understanding
                                            between atBJobs and the user, and this supersedes and replaces all prior agreements regarding
                                            the use of this website.</label> */}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RefundPolicyPage