import React, { useState } from 'react';
import CCMailActionIcon from './CCMailActionIcon';
import { updateEmployerCCMail } from '../../../../fetchAPI';

function SingleMail(props) {

    const [email, setEmail] = useState(props.email);
    const [updateEmail, setUpdateEmail] = useState(props.email);
    const [updateFormOpen, setUpdateFormOpen] = useState(false);

    const updateEmailHandler = (event) => {
        event.preventDefault();
        updateEmployerCCMail(props.id, { email: updateEmail }).then((response) => {
            if (response && response.success) {
                props.setResponseMessage(response.response.message);
                props.setOpenSuccessAlert(true);
                setEmail(updateEmail);
                setUpdateFormOpen(false);
                setTimeout(() => props.setOpenSuccessAlert(false), 3000);
            }
            else {
                props.setResponseMessage(response.errors[0].message);
                props.setOpenErrorAlert(true);
                setTimeout(() => props.setOpenErrorAlert(false), 3000);
            }
        })
    }

    return (
        <form onSubmit={updateEmailHandler}>
            <div className='row justify-content-between items-center' style={{ margin: 'unset' }}>
                {
                    updateFormOpen ?
                        <input style={{ width: '60%' }} type="email" required className="form-control" value={updateEmail} onChange={(e) => setUpdateEmail(e.target.value)} />
                        :
                        <div style={{ fontFamily: 'Poppins', fontSize: '16px', color: '#0275D8' }}>{email}</div>
                }

                <div className='row'>
                    {
                        updateFormOpen ?
                            <button type='submit' className='buttonWithoutBorderOutlineBgPad'>
                                <CCMailActionIcon bg="#D0FFE1" icon={<svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M18.824 0.34605C18.5148 0.295042 18.2012 0.397338 17.9876 0.618839L6.67973 11.8759L1.99771 5.98789C1.80484 5.69405 1.46014 5.52946 1.10541 5.56478C0.752839 5.5987 0.450136 5.82435 0.32508 6.14804C0.19967 6.47349 0.270813 6.84021 0.511922 7.09966L5.86451 13.8417C6.03321 14.0499 6.2857 14.1777 6.55508 14.1932C6.82661 14.2072 7.08902 14.1075 7.27708 13.9195L19.3502 1.9201C19.6059 1.67585 19.6958 1.31206 19.5843 0.977645C19.4725 0.644997 19.1777 0.40046 18.824 0.34605Z" fill="#15A449"/> </svg>} />
                            </button>
                            :
                            <CCMailActionIcon bg="#DCEFFF" clickHandler={() => { setUpdateFormOpen(true) }} icon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_2045_1185)">
                                    <path d="M21.5262 4.83126C21.5337 5.55204 21.2567 6.24819 20.7524 6.77647C20.6852 6.84851 20.6134 6.91695 20.5426 6.98585C16.2053 11.2032 11.8686 15.4208 7.53261 19.6387C7.35612 19.8156 7.13108 19.9396 6.88425 19.9958C5.57455 20.3056 4.26809 20.6262 2.96024 20.9459C2.27251 21.1129 1.8682 20.7207 2.03909 20.0552C2.37161 18.762 2.70305 17.4685 3.03341 16.1747C3.07791 15.9844 3.17699 15.8104 3.31916 15.6726C7.70057 11.4148 12.0811 7.15575 16.4606 2.89551C17.906 1.49154 20.2045 1.77881 21.1678 3.47412C21.4027 3.89 21.526 4.35677 21.5262 4.83126ZM3.58591 19.446C4.58069 19.2033 5.5287 18.9709 6.47763 18.7417C6.55132 18.7203 6.6173 18.6791 6.66797 18.6229C11.0105 14.3987 15.3535 10.1738 19.6969 5.9484C20.3365 5.32567 20.3388 4.36972 19.7219 3.765C19.0986 3.15352 18.1056 3.15397 17.4596 3.78166C13.1195 7.99778 8.78041 12.2157 4.44222 16.4354C4.3885 16.4881 4.33385 16.5367 4.31347 16.6155C4.07543 17.5454 3.83507 18.4738 3.58591 19.446Z" fill="#0275D8" />
                                    <path d="M16.9945 21.0001C15.4366 21.0001 13.879 21.0001 12.3216 21.0001C11.8794 21.0001 11.5885 20.7749 11.5547 20.4111C11.5365 20.249 11.5821 20.0862 11.6825 19.9556C11.7829 19.825 11.9306 19.7362 12.0956 19.7073C12.1866 19.6916 12.2788 19.6843 12.3712 19.6857C15.4633 19.6857 18.5555 19.6857 21.6479 19.6857C22.1138 19.6857 22.3857 19.8739 22.4515 20.2355C22.4679 20.3205 22.4664 20.4079 22.4471 20.4924C22.4278 20.5768 22.391 20.6566 22.3391 20.7268C22.2871 20.797 22.221 20.8563 22.1448 20.9009C22.0686 20.9456 21.9838 20.9747 21.8957 20.9866C21.7963 20.9979 21.6961 21.0023 21.5961 20.9996C20.0631 21.0002 18.5293 21.0004 16.9945 21.0001Z" fill="#0275D8" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2045_1185">
                                        <rect width="20.4615" height="19" fill="white" transform="translate(2 2)" />
                                    </clipPath>
                                </defs>
                            </svg>} />
                    }
                    <div style={{ width: '10px' }}></div>
                    <CCMailActionIcon bg="#FFDCDC" clickHandler={props.deleteHandler} icon={<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.86492 10.3916C2.05367 11.2029 1.24461 12.012 0.433352 12.8232C-0.064363 13.3209 -0.0687486 13.9568 0.39827 14.3975C0.56116 14.5539 0.771989 14.6508 0.996747 14.6727C1.2215 14.6946 1.44708 14.6401 1.63708 14.5181C1.77406 14.4236 1.9018 14.3165 2.01858 14.198L6.70194 9.51461C7.04617 9.17038 6.95408 9.17038 7.31147 9.52338C8.89451 11.1064 10.4776 12.6895 12.0606 14.2725C12.578 14.79 13.2117 14.8097 13.659 14.3405C13.8166 14.1791 13.9152 13.9693 13.9391 13.745C13.963 13.5207 13.9106 13.2948 13.7905 13.1039C13.698 12.9654 13.5915 12.8368 13.4726 12.7202C11.8896 11.1371 10.3043 9.55188 8.7213 7.96884C8.52616 7.7737 8.52397 7.67504 8.7213 7.48209C10.3043 5.89905 11.8874 4.31601 13.4726 2.73077C13.5851 2.62277 13.6865 2.50378 13.7752 2.37557C13.9031 2.17724 13.9592 1.94107 13.9341 1.70641C13.9091 1.47174 13.8044 1.25273 13.6376 1.08585C13.4707 0.91897 13.2517 0.814297 13.017 0.789253C12.7823 0.764209 12.5462 0.820302 12.3478 0.948203C12.2106 1.0422 12.0834 1.15022 11.9685 1.27051L7.30708 5.93194C6.9475 6.29152 7.04836 6.29152 6.67781 5.92098C5.12547 4.36863 3.57166 2.81482 2.01639 1.25955C1.90839 1.14709 1.7894 1.04572 1.66119 0.956975C1.46259 0.824718 1.22431 0.765333 0.986864 0.788906C0.749419 0.812477 0.527469 0.917552 0.358745 1.08628C0.190021 1.255 0.0849397 1.47696 0.0613678 1.7144C0.0377953 1.95185 0.0971805 2.19012 0.229438 2.38873C0.320201 2.51533 0.422208 2.63347 0.53421 2.74173C2.11725 4.32477 3.70249 5.91001 5.28553 7.49305C5.46971 7.67723 5.4719 7.7759 5.28553 7.95788C4.48524 8.77133 3.67398 9.58258 2.86492 10.3916Z" fill="#DB1616" />
                    </svg>} />
                </div>
            </div>
        </form>
    )
}

export default SingleMail;