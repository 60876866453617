import React from 'react'
import EmployerDashboardSideBar from '../../components/EmployerComponents/EmployerDashboardSideBar/EmployerDashboardSideBar'
import UpdateEmployer from '../../components/EmployerComponents/EmployerProfile/UpdateEmployer'

function EmployerUpdateProfile() {
  return (
    <div className='container'>
        <div className='row'>
            <div className='col-lg-4 col-md-0'>
                <EmployerDashboardSideBar />
            </div>
            <div className='col-lg-8 col-md-12'>
                <UpdateEmployer />
            </div>
        </div>
    </div>
  )
}

export default EmployerUpdateProfile