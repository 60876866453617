import React, { useContext, useEffect, useState } from 'react'
import { Box, Divider, Grid, IconButton, Stack, Typography } from '@mui/material'
import { CandidateContext } from '../../../context/CandidateContext'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { deleteCandidateSaveJobInfo, postCandidateSaveJobInfo } from '../../../../fetchAPI';
import { useHistory } from "react-router-dom";
import { UserContext } from '../../../context/UserContext';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom'

function SaveJobIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M14.6875 1.71289C12.3186 1.71289 10.7965 3.26301 10 4.65574C9.20348 3.26301 7.68145 1.71289 5.3125 1.71289C2.30613 1.71289 0 4.01543 0 7.0693C0 10.3807 2.88922 12.6357 7.20363 16.05C9.31621 17.7204 9.45195 17.8473 10 18.2879C10.4473 17.9284 10.6948 17.7117 12.7964 16.05C17.1108 12.6357 20 10.3807 20 7.0693C20 4.01547 17.6939 1.71289 14.6875 1.71289Z" fill="#DB1616" />
        </svg>
    )
}

export default function SimilarJobsComponent(props) {

    const { savedJobIds, candidateData, settingCandidateData } = useContext(CandidateContext)
    const { userData } = useContext(UserContext)
    const [loadingStates, setLoadingStates] = useState({});

    const history = useHistory()

    const unSaveCandidateJob = async (candId, jobId, index) => {
        setLoadingStates((prevLoadingStates) => ({ ...prevLoadingStates, [index]: true }));

        await deleteCandidateSaveJobInfo(candId, jobId)
            .then(response => {
                if (response && response.success) {
                    settingCandidateData();
                    setLoadingStates((prevLoadingStates) => ({ ...prevLoadingStates, [index]: false }));
                } else {
                    // Handle failure, show error message or take appropriate action
                    console.log(response)
                }
            })
            .catch(error => {
                // Handle errors, show error message or take appropriate action
                console.log(error)
            })
            .finally(() => {
                setLoadingStates((prevLoadingStates) => ({ ...prevLoadingStates, [index]: false }));
            });
    };

    const saveCandidateJob = async (candId, jobId, index) => {
        setLoadingStates((prevLoadingStates) => ({ ...prevLoadingStates, [index]: true }));

        await postCandidateSaveJobInfo(candId, jobId)
            .then(response => {
                if (response && response.success) {
                    settingCandidateData();
                    setLoadingStates((prevLoadingStates) => ({ ...prevLoadingStates, [index]: false }));
                } else {
                    // Handle failure, show error message or take appropriate action
                    if (!userData && response.errors[0].message === 'Unauthenticated.') {
                        history.push('/login')
                    }
                    else {
                        console.log(response)
                    }
                }
            })
            .catch(error => {
                // Handle errors, show error message or take appropriate action
                console.log(error)
            })
            .finally(() => {
                setLoadingStates((prevLoadingStates) => ({ ...prevLoadingStates, [index]: false }));
            });
    };

    return (
        <>
            <Stack spacing={2}
                sx={{
                    width: '100%',
                }}
            >
                {
                    props.data?.map((data, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Box>
                                    <Grid
                                        container
                                        spacing={2}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                        sx={{
                                            flexWrap: 'nowrap'
                                        }}
                                    >
                                        <Grid item>
                                            <Link to={`/jobs/${data?.post_id}`}>
                                                <Typography
                                                    variant='h6'
                                                    sx={{
                                                        fontSize: '15px',
                                                        fontWeight: 700,
                                                        color: '#0275D8'
                                                    }}
                                                >


                                                    {data.job_title}

                                                </Typography>
                                            </Link>
                                        </Grid>
                                        <Grid item>
                                            {candidateData.id !== 0 ?
                                                savedJobIds?.includes(data?.post_id) ?
                                                    <LoadingButton
                                                        loading={loadingStates[index]}
                                                        onClick={() => unSaveCandidateJob(candidateData.id, data?.post_id)}
                                                        aria-label="Unsave"
                                                        component={IconButton}
                                                    >
                                                        <SaveJobIcon />
                                                    </LoadingButton>
                                                    :
                                                    <LoadingButton
                                                        loading={loadingStates[index]}
                                                        onClick={() => saveCandidateJob(candidateData.id, { job_post_id: data.post_id }, index)}
                                                        aria-label="Save"
                                                        component={IconButton}
                                                    >
                                                        <FavoriteBorderIcon />
                                                    </LoadingButton>
                                                    : null
                                            }

                                        </Grid>
                                    </Grid>

                                    <Typography
                                        variant='h6'
                                        sx={{
                                            fontSize: '13px',
                                            fontWeight: 600,
                                            color: '#72737C'
                                        }}
                                    >
                                    { data.is_cxo ? (
                                        candidateData?.is_cxo_eligible || userData?.id === data.employer?.user_id ? (
                                            data.employer?.employer_name
                                        ) : (
                                            <img src="/images/cxo/blurred-text.png" style={{width : '100%'}} />
                                        )
                                        ) : (
                                            data.employer?.employer_name
                                    )}
                                    </Typography>
                                    <Typography
                                        variant='h6'
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: 700,
                                            marginTop: 1
                                        }}
                                    >
                                        Salary: {
                                            data.minimum_salary && data.negotiable === 0 ?
                                                `${data.minimum_salary} - ${data.maximum_salary} ${data.salary_currency ? data.salary_currency : 'Taka'}/${data.salary_type ? data.salary_type?.type : 'Monthly'}`
                                                :
                                                'Negotiable'
                                        }
                                    </Typography>
                                </Box>
                                <Divider />
                            </React.Fragment>
                        )
                    })
                }

            </Stack>
        </>
    )
}