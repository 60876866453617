import React, { useState, useEffect, useContext, useRef } from 'react'
import { DropdownContext } from '../../../../context/DropdownContext'
import { deleteCandidateSocialNetworkByID, updateCandidateSocialNetworkByID, postCandidateSocialNetworkByID } from '../../../../../fetchAPI'
import UpdateDeleteConfirmationModal from './UpdateDeleteConfirmationModal'
import customStyles from './CandidateUpdateProfile.module.css'
import AddButton from './component/AddButton'
import CandidateUpdateModal from './component/CandidateUpdateModal'
import EditSVG from './component/EditSVG'
import DeleteSVG from './component/DeleteSVG'
import { useAlert } from '../../../../context/AlertContext'
import { Box } from '@mui/material'
import RequiredAsterisk from './component/RequiredAsterisk'

function UpdateSocial(props) {

    const [updateSocialData, setUpdateSocialData] = useState({})
    const [socialData, setSocialData] = useState({})
    const [loader, setLoader] = useState(false)
    const { social } = useContext(DropdownContext)

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addFormRef = useRef();

    const alertContext = useAlert();

    const enableUpdate = (item) => {
        setUpdateSocialData({
            socialId: item.pivot?.id,
            social_network_id: item.id,
            links: item.pivot?.link
        })
    }

    const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

    const handleOpen = (itemId, index) => {
        setOpenDeleteModal(true);
        setDeleteItemId(itemId);
        setDeleteItemIndex(index);
    };

    const handleClose = () => {
        setOpenDeleteModal(false);
    };

    const [deleteItemId, setDeleteItemId] = useState('')
    const [deleteItemIndex, setDeleteItemIndex] = useState('')

    const deleteCandidateSocial = (candId, socialId, index) => {
        setOpenDeleteModal(false)
        setLoader(true)
        deleteCandidateSocialNetworkByID(candId, socialId).then((response) => {
            if (response.success) {
                alertContext.setResponseMessage(response.response.message)
                alertContext.setOpenAlert(true)
                props.social.splice(index, 1);
                props.renderState()
                setLoader(false)
            }
            else {
                // console.log('errors: ', response);
                const message = response.errors?.map(msg => {
                    alertContext.setResponseMessage(msg.message)
                })
                alertContext.setOpenErrorAlert(true)
            }
        })
    }

    let errorsObj = { links: '' };
    const insertCandidateSocial = (candId, body) => {

        if (!body.social_network_id) {
            alert('Select a Platform');
            return;
        }

        postCandidateSocialNetworkByID(candId, body).then((response) => {
            if (response && response.success) {
                alertContext.setResponseMessage(response.response.message)
                alertContext.setOpenAlert(true)
                setSocialData({})
                addFormRef.current.reset();
                props.renderState();
                setAddModalOpen(false)
            }
            else {
                // console.log('errors: ', response);
                const message = response.errors?.map(msg => {
                    alertContext.setResponseMessage(msg.message)
                })
                alertContext.setOpenErrorAlert(true)
            }
        })
    }

    const updateCandidateSocial = (candId, updatedData) => {

        updateCandidateSocialNetworkByID(candId, updatedData.socialId, updatedData).then((response) => {
            // console.log(updatedData)
            if (response && response.success) {
                alertContext.setResponseMessage(response.response.message)
                alertContext.setOpenAlert(true)
                setUpdateModalOpen(false);
                props.renderState()
            }
            else {
                const message = response.errors?.map(msg => {
                    alertContext.setResponseMessage(msg.message)
                })
                alertContext.setOpenErrorAlert(true)
            }
        })
    }

    return (
        <div id='social_box'>
            <div className='card shadow-sm'>
                <div className={customStyles.cardHeader}>
                    <div className='row justify-content-between align-items-center' style={{ margin: 'unset' }}>
                        <div className={customStyles.sectionHeader}>Social Profiles</div>
                        <AddButton title="Social Profiles" onClickHandler={() => setAddModalOpen(true)} />
                    </div>
                </div>
                <div className='card-body'>
                    {
                        props.social?.map((item, index) => {
                            return (
                                <div key={item.pivot.id} className={customStyles.singleSocialProfile}>
                                    <Box className={customStyles.singleSocialProfileContent} sx={{display:{xs:'none',sm:'none',md:'flex'}}}>
                                        <div className={customStyles.singleSocialProfilePlatformContainer}>
                                            <img width={25} height={25} src={`/v2/icons/${item.name.toLowerCase()}.png`} />
                                            <span className={customStyles.singleSocialProfilePlatform} style={{ marginLeft: '10px' }}>{item.name}</span>
                                        </div>
                                        <div className={customStyles.singleSocialProfileLink}>
                                            <a target='_blank' href={item.pivot?.link}>{item.pivot?.link}</a>
                                        </div>
                                    </Box>
                                    <Box className={customStyles.singleSocialProfileContent} sx={{display:{xs:'block',sm:'block',md:'none'}}}>
                                        <div className={customStyles.singleSocialProfilePlatformContainer}>
                                            <img width={25} height={25} src={`/v2/icons/${item.name.toLowerCase()}.png`} />
                                            <span className={customStyles.singleSocialProfilePlatform} style={{ marginLeft: '10px' }}>{item.name}</span>
                                        </div>
                                        <div className={customStyles.singleSocialProfileLink} style={{maxWidth:'unset',marginLeft:'33px',marginTop:'5px'}}>
                                            <a target='_blank' href={item.pivot?.link}>{item.pivot?.link}</a>
                                        </div>
                                    </Box>
                                    <div className={customStyles.actionContainer}>
                                        <div onClick={() => { enableUpdate(item); setUpdateModalOpen(true) }}>
                                            <EditSVG />
                                        </div>
                                        <div onClick={() => handleOpen(item.pivot?.id, index)}>
                                            <DeleteSVG />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <CandidateUpdateModal form="candidateSocialMediaUpdateForm" open={updateModalOpen} onClose={() => setUpdateModalOpen(false)} title='Social Profile'>
                <div className='card-body' style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <form style={{ width: '100%' }} id='candidateSocialMediaUpdateForm' onSubmit={(e) => { e.preventDefault(); updateCandidateSocial(props.candId, updateSocialData); }}>
                        <div className={`row text-left`}>
                            <div className='col-md-12 col-lg-6 mb-2'>
                                <label>Social Media Name <RequiredAsterisk/></label>

                                <select className={`form-control ${customStyles.platformSelect}`} value={updateSocialData?.social_network_id || -1} disabled>
                                    <option disabled selected value={-1}>Select a Platform</option>
                                    {
                                        social?.map((item, index) => {
                                            return (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col-md-12 col-lg-6'>
                                <label>URL <RequiredAsterisk/></label>
                                <input type='text' className='form-control'
                                    value={updateSocialData?.links || ''}
                                    onChange={(event) => setUpdateSocialData({ ...updateSocialData, links: event.target.value })}
                                    placeholder='Insert Link'
                                    required
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </CandidateUpdateModal>

            <CandidateUpdateModal form="candidateSocialMediaAddForm" open={addModalOpen} onClose={() => setAddModalOpen(false)} title='Social Profiles'>
                <div className='card-body' style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <form style={{ width: '100%' }} id='candidateSocialMediaAddForm' ref={addFormRef} onSubmit={(e) => { e.preventDefault(); insertCandidateSocial(props.candId, socialData); }}>
                        <div className={`row text-left`}>
                            <div className='col-md-12 col-lg-6 mb-2'>
                                <label>Social Media Name <RequiredAsterisk/></label>

                                <select className={`form-control ${customStyles.platformSelect}`} value={socialData?.social_network_id || -1} onChange={(event) => setSocialData({ ...socialData, social_network_id: event.target.value })}>
                                    <option disabled selected value={-1}>Select a Platform</option>
                                    {
                                        social?.map((item, index) => {
                                            return (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='col-md-12 col-lg-6'>
                                <label>URL <RequiredAsterisk/></label>
                                <input type='text' className='form-control'
                                    value={socialData?.links || ''}
                                    onChange={(event) => setSocialData({ ...socialData, links: event.target.value })}
                                    placeholder='Insert Link'
                                    required
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </CandidateUpdateModal>

            <UpdateDeleteConfirmationModal open={openDeleteModal} onClose={handleClose} deleteItem={() => deleteCandidateSocial(props.candId, deleteItemId, deleteItemIndex)} />
        </div>
    )
}

export default UpdateSocial