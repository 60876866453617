import React from "react";
import { Box } from "@material-ui/core";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

export default function AlreadyAppliedScreen() {

    const history = useHistory()

    const handleOnClick = (routeName) => {
        history.push(`/${routeName}`)
    }


    return (
        <>
            <Divider />
            <Stack spacing={2} sx={{ display: 'flex', alignItems: 'center', marginTop: 3 }}>
                <Box>
                    <img
                        style={{ width: '238px', height: '184px' }}
                        src="/v2/images/already_applied_job_icon.png"
                    />
                </Box>
                <Box>
                    <Typography variant="h6">You have already applied for this jobs</Typography>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        size="large"
                        fullWidth
                        onClick={() => handleOnClick('applied-jobs')}
                    >
                        Go to Dashboard
                    </Button>
                </Box>
            </Stack>
        </>
    );
}