import { Chip } from '@mui/material'
import React, { useEffect, useState } from 'react'

const ProfileMatchChipComponent = ({ percentage }) => {

    const [title, setTitle] = useState('')
    const [sx, setSx] = useState({
        fontSize : '10px',
        fontWeight : 500
    })

    useEffect(() => {
        if (0 <= percentage && percentage < 25) {
            setTitle('Low Match')
            setSx({...sx,
                backgroundColor: "#FFE3E3",
                color: '#DB1616'
            })
        }else if(25 <= percentage && percentage < 50){
            setTitle('Average Match')
            setSx({...sx,
                backgroundColor: "#FFF5E2",
                color: '#ED7200'
            })
        }else if(50 <= percentage && percentage < 75){
            setTitle('Good Match')
            setSx({...sx,
                backgroundColor: "#F2E9FF",
                color: '#8743DF'
            })
        }else if(75 <= percentage ){
            setTitle('Perfect Match')
            setSx({...sx,
                backgroundColor: "#EEFAF7",
                color: '#15A449'
            })
        }
    },[percentage])

    return <Chip label={title} sx={sx} />;
};

export default ProfileMatchChipComponent;