import React from "react";
import moment from 'moment'
import ReactHtmlParser from 'react-html-parser'
import customStyles from '../CandidateUpdateProfile.module.css'
import CommonMasking from "../../../../maskingcomponents/CommonMasking";

export default function SingleEducationBlock({ item, masking }) {
    return (
        <div>
            <div className={customStyles.eduInstitute}>
                {item.degree_institute}
            </div>
            <div className={customStyles.eduResult}>
                {moment(item.degree_start).format("MMM Do YYYY")} - {item.degree_end ? moment(item.degree_end).format("MMM Do YYYY") : 'Now'}      &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;     Result :  {item.degree_grade}
            </div>
            {
                masking ?
                    <CommonMasking>
                        <div className={customStyles.eduDetails}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nulla turpis, scelerisque sit amet scelerisque vitae, fermentum non odio. Nullam sit amet felis sit amet diam aliquam hendrerit in at lectus.
                        </div>
                    </CommonMasking>
                    :
                    <div className={customStyles.eduDetails}>
                        {ReactHtmlParser(item.degree_detail)}
                    </div>
            }
        </div>
    )
}