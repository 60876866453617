import React, { createContext, useEffect, useState } from "react";

export const ProfileStrengthContext = createContext();

const ProfileStrengthContextProvider = (props) => {

    const [strength, setStrength] = useState(null)
    const [profileStrengthAlert,setProfileStrengthAlert] = useState(null)

    useEffect(()=>{
    if (strength != null && strength < process.env.REACT_APP_MINIMUM_STRENGTH_FOR_APPLY){
        setProfileStrengthAlert(true)
        }else{
        setProfileStrengthAlert(false)
        }
    },[strength])


    return (
        <ProfileStrengthContext.Provider value={{
            strength,setStrength, profileStrengthAlert, setProfileStrengthAlert
        }}>
        {props.children}
        </ProfileStrengthContext.Provider>
    );

};

export default ProfileStrengthContextProvider;