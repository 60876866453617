import { Avatar, Box, LinearProgress, Stack } from '@mui/material'
import React from 'react'

export default function InitialLoader() {
    return (
        <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Stack spacing={2}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img src='/v2/images/init_loader_logo.png' width={'30%'} height={'auto'} />
                </Box>
                <LinearProgress />
            </Stack>
        </Box>
    )
}
