import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, DialogContent } from '@mui/material';
import styles from '../CandidateUpdateProfile.module.css';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({

    [theme.breakpoints.up("xs")]: {
        '& .MuiDialog-paper': {
            maxWidth: '100vw',
            maxHeight: '100vh',
            minWidth: '90vw',
            // minHeight: '50vh',
            overflowY: 'unset',
        },
    },

    [theme.breakpoints.up("sm")]: {
        '& .MuiDialog-paper': {
            maxWidth: '1100px',
            maxHeight: '100vh',
            minWidth: '50vw',
            // minHeight: '50vh',
            overflowY: 'unset',
        },
    },

    [theme.breakpoints.up("md")]: {
        '& .MuiDialogContent-root': {
            padding: theme.spacing(4)
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
        '& .MuiDialog-paper': {
            maxWidth: '1100px',
            maxHeight: '100vh',
            minWidth: '50vw',
            // minHeight: '50vh',
            overflowY: 'unset',
        },
    },
}));

export default function CandidateUpdateModal(props) {

    return (
        <BootstrapDialog
            onClose={props.onClose}
            aria-labelledby="customized-dialog-title"
            open={props.open}
            scroll={'paper'}
            className={styles.updateModal}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                {props.title}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={props.onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            {
                props.isContentScrollable != undefined && props.isContentScrollable == false ?
                    <DialogContent dividers={true} sx={{ overflowY: 'unset' }}>
                        {props.children}
                    </DialogContent>
                    :
                    <DialogContent dividers={true} >
                        {props.children}
                    </DialogContent>
            }

            <DialogActions sx={{ position: 'sticky', bottom: '0px', backgroundColor: 'white', zIndex: '1' }}>
                <Box m={2}>
                    <Button
                        variant='outlined'
                        onClick={props.onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        type='submit'
                        form={props.form || ''}
                        variant='contained'
                        onClick={() => {
                            if (props.onFormSubmit) {
                                props.onFormSubmit()
                            }
                        }}
                        sx={{
                            marginLeft: 1
                        }}
                    >
                        Submit
                    </Button>
                </Box>
            </DialogActions>
        </BootstrapDialog>
    );
}