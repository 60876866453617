import React from "react";
import styles from '../CandidateUpdateProfile.module.css'
import { Box } from "@mui/material";
import CommonMasking from "../../../../maskingcomponents/CommonMasking";

export default function SinglePersonalInfoBlock(props) {
    return (
        <>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }} className={styles.personalInfoBlock}>
                <div className={styles.personalInfoTitle}>
                    <div>{props.title}</div>
                    <div>:</div>
                </div>
                <div className={styles.personalInfoValue}>
                    {props.masking ? <CommonMasking>Personal Information</CommonMasking> : <>{props.value}</>}
                </div>
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, marginBottom: '10px' }}>
                <div className={styles.personalInfoTitleSmallDevice}>{props.title}</div>
                <div className={styles.personalInfoValueSmallDevice}>
                    {props.masking ? <CommonMasking>Personal Information</CommonMasking> : <>{props.value || '--'}</>}
                </div>
            </Box>
        </>
    )
}