import React from "react";
import styles from './masking.module.css'

export default function CommonMasking(props)
{
    return (
        <div className={styles.commonMasking} {...props}>
            {props.children}
        </div>
    )
}