import React, { useContext, useEffect, useRef, useState } from "react";
import styles from './MessageBox.module.css';
import { getMessages, readMessage, sendMessage } from "../../../messageFetchAPIs";
import { UserContext } from "../../context/UserContext";
import ErrorAlert from "../Alerts/ErrorAlert";
import { getSkillMatchPercentageInJob } from "../../../fetchAPI";
import ProfileMatchChipComponent from "../ProfileMatchChipComponent";
import { Box } from "@mui/material";

function MessageBox({ from_id, ownPic }) {

    const [messages, setMessages] = useState([]);
    const { userData } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState({ page: 1 });
    const [selectedFileName, setSelectedFileName] = useState('');
    const [messageInputValue, setMessageInputValue] = useState('');
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [paginateInfo,setPaginateInfo] = useState({});

    const [tarrgetUserInfo,setTargetUserInfo] = useState({});
    const [unseenMessages,setUnseenMessages] = useState([]);
    const [jobInfo,setJobInfo] = useState(null);

    const messageBody = useRef();
    const fileInput = useRef(null);

    const urlParams = new URLSearchParams(window.location.search);
    const job_id = urlParams.get("job_id");
    const candidate_id = urlParams.get("c_id");

    useEffect(()=>{
        if(job_id!=null && candidate_id!=null)
        {
            getSkillMatchPercentageInJob(job_id,candidate_id).then((response)=>{
                if(response.success)
                {
                    setJobInfo(response.response.data)
                }
            })
        }
    },[job_id,candidate_id])

    useEffect(() => {
        if(currentPage.page>1) setIsLoading(true);
        getMessages(from_id, currentPage.page).then((response) => {
            if (response.success) {
                setPaginateInfo(response.response.pagination);
                if (currentPage.page > 1) {
                    setMessages([...messages, ...response.response.data.messages]);
                    messageBody.current.scroll({
                        top: 50,
                        behavior: 'smooth'
                    });
                    
                } else {
                    setMessages(response.response.data.messages);
                    setTargetUserInfo(response.response.data.userInfo);

                    messageBody.current.scroll({
                        top: messageBody.current.scrollHeight,
                        behavior: 'smooth'
                    });
                }
                let incomingMessages = response.response.data.messages;
                let unseenMessageArray = [];
                for(let i in incomingMessages)
                {
                    if(incomingMessages[i].state==1 && incomingMessages[i].to_id == userData.id)
                    {
                        unseenMessageArray.push(incomingMessages[i].id);
                    }
                }
                setUnseenMessages(unseenMessageArray);

                setIsLoading(false);
            }
        })
    }, [from_id, currentPage]);

    useEffect(() => {
        const refreshMessageInterval = setInterval(() => {
            setCurrentPage({ page: 1 });
        }, 10000);

        return () => {
            clearInterval(refreshMessageInterval);
        };

    }, []);

    useEffect(()=>{
        if(unseenMessages.length>0)
        {
            let formData = new FormData();
            formData.append('message_ids', unseenMessages); 
            readMessage(formData).then((response)=>{
                if(response.success)
                {
                    setUnseenMessages([]);
                }
            });
        }
    },[unseenMessages])

    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (scrollTop == 0) {
            if(paginateInfo && currentPage.page < paginateInfo.last_page)
            setCurrentPage({ page: currentPage.page + 1 });
        }
    };

    const handleRefresh = (e) => {
        setCurrentPage({ page: 1 });
    }

    const handleFileClose = (e) => {
        setSelectedFileName('');
        fileInput.current.value = null;
    }

    const formSubmit = (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('content', messageInputValue);
        formData.append('to_id', from_id);
        if (fileInput.current.value != null && fileInput.current.value != '') {
            formData.append('attachment', fileInput.current.files[0]);
        }

        sendMessage(formData).then((response) => {
            if (response.success) {
                setSelectedFileName('');
                fileInput.current.value = null;
                setMessageInputValue('');
                // setMessages([{ ...response.response.data[0] }, ...messages]);
                setCurrentPage({page:1});

                messageBody.current.scroll({
                    top: messageBody.current.scrollHeight,
                    behavior: 'smooth'
                });

            } else {
                setResponseMessage(response.errors[0].message);
                setOpenErrorAlert(true);
                setTimeout(() => setOpenErrorAlert(false), 3000);
            }
        });
    }

    const attachmenSvg = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.2982 7.53531L8.67362 14.1599C7.86208 14.9715 6.76136 15.4275 5.61364 15.4275C4.46591 15.4275 3.36519 14.9715 2.55363 14.1599C1.74206 13.3484 1.28613 12.2476 1.28613 11.0999C1.28613 9.95217 1.74206 8.8515 2.55363 8.0399L9.17821 1.41531C9.71928 0.874266 10.4531 0.570312 11.2182 0.570312C11.9834 0.570312 12.7172 0.874266 13.2582 1.41531C13.7993 1.95635 14.1033 2.69017 14.1033 3.45531C14.1033 4.22046 13.7993 4.95427 13.2582 5.49532L6.62643 12.1199C6.35591 12.3904 5.989 12.5424 5.60643 12.5424C5.22385 12.5424 4.85695 12.3904 4.58643 12.1199C4.31591 11.8494 4.16392 11.4825 4.16392 11.0999C4.16392 10.7174 4.31591 10.3504 4.58643 10.0799L10.7064 3.96711" stroke="#72737C" strokeLinecap="round" strokeLinejoin="round" />
    </svg>;

    const attachmenSvgWhite = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.2982 7.53531L8.67362 14.1599C7.86208 14.9715 6.76136 15.4275 5.61364 15.4275C4.46591 15.4275 3.36519 14.9715 2.55363 14.1599C1.74206 13.3484 1.28613 12.2476 1.28613 11.0999C1.28613 9.95217 1.74206 8.8515 2.55363 8.0399L9.17821 1.41531C9.71928 0.874266 10.4531 0.570312 11.2182 0.570312C11.9834 0.570312 12.7172 0.874266 13.2582 1.41531C13.7993 1.95635 14.1033 2.69017 14.1033 3.45531C14.1033 4.22046 13.7993 4.95427 13.2582 5.49532L6.62643 12.1199C6.35591 12.3904 5.989 12.5424 5.60643 12.5424C5.22385 12.5424 4.85695 12.3904 4.58643 12.1199C4.31591 11.8494 4.16392 11.4825 4.16392 11.0999C4.16392 10.7174 4.31591 10.3504 4.58643 10.0799L10.7064 3.96711" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>;

    const renderMessages = () => {
        const reversedMessages = [];
        let r = 0;
        for (let m = messages.length - 1; m >= 0; m--) {
            reversedMessages[r++] = messages[m];
        }

        let groupMessages = [];
        let g = -1;
        let me = userData.id;
        let currentFromId = -1;


        for (let i = 0; i < reversedMessages.length; i++) {
            if (reversedMessages[i].from_id != currentFromId) {
                currentFromId = reversedMessages[i].from_id;
                g++;
                groupMessages[g] = {};
                if (currentFromId == me) {
                    groupMessages[g].direction = 'right';
                    groupMessages[g].messages = [];
                    groupMessages[g].initId = reversedMessages[i].id;
                } else {
                    groupMessages[g].direction = 'left';
                    groupMessages[g].messages = [];
                    groupMessages[g].initId = reversedMessages[i].id;
                }
            }

            groupMessages[g].messages.push(reversedMessages[i]);
        }

        return groupMessages.map((item) => {
            return item.direction == 'left' ?
                (
                    <div className={styles.messageSenderContainer} key={item.initId}>
                        <div className={styles.userPicContainer}>
                            <img src={tarrgetUserInfo?.pic ? tarrgetUserInfo?.pic : '/v2/images/candidate-default.png'} />
                        </div>
                        <div className={styles.senderMessages}>
                            {
                                item.messages.map((mitem) => {
                                    if (mitem.attachment != null) {
                                        let attachmentNameArray = mitem.attachment.split('/');
                                        let attachmentName = attachmentNameArray[attachmentNameArray.length - 1];
                                        return (
                                            <div key={mitem.id}>
                                            {
                                            mitem.content ?
                                                <div className={styles.senderSingleMessage}>{mitem.content}</div>
                                            :
                                                ""
                                            }

                                            <div className={styles.senderSingleMessage}><a href={mitem.attachment} target="_blank">{attachmenSvg} {attachmentName}</a></div>
                                            </div>
                                        )
                                    }
                                    return <div key={mitem.id} className={styles.senderSingleMessage}>{mitem.content}</div>;
                                })
                            }
                        </div>
                    </div>
                )
                :
                (
                    <div className={styles.messageOwnContainer} key={item.initId}>
                        <div className={styles.ownMessages}>
                            {
                                item.messages.map((mitem) => {
                                    if (mitem.attachment != null) {
                                        let attachmentNameArray = mitem.attachment.split('/');
                                        let attachmentName = attachmentNameArray[attachmentNameArray.length - 1];
                                        return (
                                            <div key={mitem.id}>
                                                {
                                                mitem.content ?
                                                    <div className={styles.ownSingleMessageContainer}>
                                                    <div className={styles.ownSingleMessage}>
                                                        {mitem.content}
                                                    </div>
                                                </div>
                                                :
                                                ''
                                                }
                                                
                                                <div className={styles.ownSingleMessageContainer}>
                                                    <div className={styles.ownSingleMessage}>
                                                        <a href={mitem.attachment} target="_blank">{attachmenSvgWhite} {attachmentName}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    return (
                                        <div key={mitem.id} className={styles.ownSingleMessageContainer}>
                                            <div className={styles.ownSingleMessage}>
                                                {mitem.content}
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div className={styles.userPicContainer}>
                            <img src={ownPic ? ownPic : "/v2/images/candidate-default.png"} />
                        </div>
                    </div>
                )
        });

    }

    return (
        <>
            <Box sx={{ padding: {xs:'2%',sm:'5%',md:'10%'} }}>
                {
                    jobInfo != null ? 
                    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',padding:'20px 0px'}}>
                        <div style={{fontFamily:'Epilogue',fontSize:'16px',fontWeight:'700',color:'#0079C1'}}>{jobInfo.job_title}</div>
                        <div> &nbsp;&nbsp;-&nbsp;&nbsp; </div>
                        <div><ProfileMatchChipComponent percentage={parseInt(jobInfo.skill_matching_percentage)} /></div>
                    </div>
                    :
                    null
                }
                <div className={styles.messageBox} style={{background:'white'}}>
                    <div className={styles.messageBoxHeader}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className={styles.userPicContainer}>
                                <img src={tarrgetUserInfo?.pic ? tarrgetUserInfo?.pic : '/v2/images/candidate-default.png'} />
                            </div>
                            <div className={styles.messageBoxHeaderName}>
                                {tarrgetUserInfo?.name}
                            </div>
                            <button className="btn btn-primary" style={{ marginLeft: 'auto' }} onClick={handleRefresh}>
                                <img src="/images/refresh.png" width={20}/>
                            </button>
                        </div>
                    </div>
                    <div className={styles.messageBoxBody} ref={messageBody} onScroll={handleScroll}>
                        {isLoading ? <span className={styles.loader}></span> : <span></span>}
                        {renderMessages()}
                    </div>
                    <hr />
                    <div className={styles.footer}>
                        {
                            selectedFileName != '' ?
                                <div className={styles.fileNameContainer}>
                                    <div>{selectedFileName}</div>
                                    <div onClick={handleFileClose} className={styles.fileNameClose}>x</div>
                                </div>
                                :
                                ''
                        }
                        <div className={styles.sendInputContainer}>
                            <form className={styles.sendInputForm} onSubmit={formSubmit}>
                                <input className={styles.sendInput} value={messageInputValue} onChange={(e) => setMessageInputValue(e.target.value)} placeholder="Type your message" />
                                <input id="message-file-input" style={{ display: 'none' }} type="file" ref={fileInput} onChange={(event) => { setSelectedFileName(event.target.files[0]['name']); }} />
                                <label htmlFor="message-file-input" className={styles.sendInputFormFileLabel}>
                                    {attachmenSvg}
                                </label>
                                <button type="submit" className="btn btn-primary">Send</button>
                            </form>

                        </div>
                    </div>
                </div>
            </Box>
            <ErrorAlert open={openErrorAlert} message={responseMessage} onHide={() => setOpenErrorAlert(false)} />
        </>
    )
}

export default MessageBox