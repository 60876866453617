import { Avatar, Box, Grid, Typography, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import JobCardComponentv2 from "../components/JobCard/JobCardComponentv2";
import { useParams } from 'react-router-dom'
import { getSpecialPageConfig, getSpecialPageFilteredJobs } from "../../fetchAPI";
import { LoadingButton } from "@mui/lab";
import { useHistory } from "react-router-dom";

function HeroSection({ headerBgImage, headerText }) {
    return (
        <Box
            sx={{
                width: '100%',
                height: 'auto',
                backgroundImage: `url("${headerBgImage}")`,
                display: 'flex',
                alignItems: 'center',
                padding: 20,
                '@media (max-width: 600px)': {
                    padding: 4,
                    backgroundSize: 'cover', // Add this line to prevent background image from repeating
                },
                backgroundSize: '100% auto'

            }}
        >

            <Grid container spacing={4} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <Grid item md={7} xs={12}>
                    <Box m={3} sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>
                        {/* <Typography variant="h4" sx={{ fontSize: '24px', color: 'white', fontWeight: 700 }}>Explore New Life</Typography> */}
                        <Typography variant="h2" sx={{ fontSize: '48px', color: 'white', fontWeight: 700 }}>{headerText}</Typography>
                    </Box>
                    <Box m={3} sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }}>
                        {/* <Typography align="center" variant="h4" sx={{ fontSize: '20px', color: 'white', fontWeight: 700 }}>Explore New Life</Typography> */}
                        <Typography align="center" variant="h2" sx={{ fontSize: '24px', color: 'white', fontWeight: 700 }}>{headerText}</Typography>
                    </Box>
                </Grid>
                <Grid item md={5} xs={12}>
                    {/* <Avatar
                        alt="placeholder Image"
                        variant="square"
                        src={headerBgImage ? headerBgImage : "/v2/images/Rectangle6413.png"}
                        sx={{
                            height: '400px',
                            width: '450px',
                            boxShadow: '17px 20px 20px 7px rgb(14 13 13 / 50%)',
                            '@media (max-width: 600px)': {
                                width: '100%', // Set the width to 100% on smaller screens
                                height: 'auto', // Allow the height to adjust proportionally
                            },
                        }}
                    /> */}
                </Grid>
            </Grid>

        </Box>
    )
}

function FooterSection({ footerBgImage, footerText }) {
    return (
        <Box
            sx={{
                width: '100%',
                height: 'auto',
                backgroundImage: `url("${footerBgImage}")`,
                display: 'flex',
                alignItems: 'center',
                padding: 20,
                '@media (max-width: 600px)': {
                    padding: 4,
                    backgroundSize: 'cover',
                },
                backgroundSize: '100% auto'
            }}
        >
            <Grid container spacing={4} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <Grid item md={7} xs={12}>
                    <Box m={3} sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block' } }}>
                        {/* <Typography variant="h4" sx={{ fontSize: '24px', color: '#0275D8', fontWeight: 700 }}>Explore New Life</Typography> */}
                        <Typography variant="h2" sx={{ fontSize: '48px', color: '#000', fontWeight: 700 }}>{footerText}</Typography>
                    </Box>
                    <Box m={3} sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }}>
                        {/* <Typography align="left" variant="h4" sx={{ fontSize: '20px', color: '#0275D8', fontWeight: 700 }}>Explore New Life</Typography> */}
                        <Typography align="center" variant="h2" sx={{ fontSize: '24px', color: '#000', fontWeight: 700 }}>{footerText}</Typography>
                    </Box>
                </Grid>
                <Grid item md={5} xs={12}>
                    {/* <Avatar
                        alt="placeholder Image"
                        variant="square"
                        size="large"
                        src={footerBgImage ? footerBgImage : "/v2/images/footerbgimg.png"}
                        sx={{
                            width: '80%', // Set the width to 100%
                            height: '100%', // Set the height to 100%
                            objectFit: 'cover', // Ensure the image covers the entire avatar
                            '@media (max-width: 600px)': {
                                width: '100%', // Set the width to 100% on smaller screens
                                height: 'auto', // Allow the height to adjust proportionally
                            },
                        }}
                    /> */}
                </Grid>
            </Grid>
        </Box>
    )
}

function EndIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="25" viewBox="0 0 14 25" fill="none">
            <path d="M2 2L12.5 12.5L2 23" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default function SpecialPage() {

    const { slug } = useParams()
    const [pageConfig, setPageConfig] = useState('')
    const [paginationInfo, setPaginationInfo] = useState('')
    const [specialJobs, setSpecialJobs] = useState([])
    const [pageNumber, setPageNumber] = useState(1)
    const [loading, setLoading] = useState(false)

    const history = useHistory()

    useEffect(() => {
        setLoading(true)
        getSpecialPageConfig(slug)
            .then(response => {
                if (response && response.success) {
                    setPageConfig(response.response?.data)
                }
                else {
                    history.push('/page_not_found')
                }
            })
    }, [])

    useEffect(() => {
        setLoading(true)
        if (pageConfig !== '') {
            getSpecialPageFilteredJobs(pageConfig?.param, slug, pageNumber)
                .then(response => {
                    if (response && response.success) {
                        setSpecialJobs([...specialJobs, ...response.response?.data])
                        setPaginationInfo(response.response.pagination)
                    }
                }).finally(() => setLoading(false))
        }
    }, [pageConfig, pageNumber])


    return (
        <>
            <HeroSection headerBgImage={pageConfig?.header_background_image} headerText={pageConfig?.header_text} />

            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    backgroundImage: pageConfig?.body_background_image
                        ? `url(${pageConfig.body_background_image})`
                        : 'url(/v2/images/BG.png)',
                    backgroundSize: 'cover',
                    backgroundPosition: `0% ${pageNumber}%`, // Adjust the background position
                    transition: 'background-position 0.5s ease',
                    padding: 15,
                    '@media (max-width: 600px)': {
                        padding: 1,
                    },
                }}
            >
                <Grid container spacing={2}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    {
                        specialJobs?.map((item, index) => {
                            return (
                                <Grid item md={6} key={index}>
                                    <JobCardComponentv2 item={item} />
                                </Grid>
                            )
                        })
                    }
                </Grid>
                {
                    loading ?
                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Grid item md={6}>
                                <div className='mt-3 col-lg-12 col-md-12'>
                                    <Skeleton variant="text" height={60} />
                                    <Skeleton variant="text" height={50} />
                                    <div className='text-left'>
                                        <Skeleton variant="text" />
                                    </div>
                                    <div className='mt-3 text-left'>
                                        <Skeleton variant="rounded" height={120} />
                                    </div>
                                    <div className='text-center'>
                                        <Skeleton variant="text" width={80} height={50} />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        :

                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, marginBottom: 8 }}>
                            {
                                paginationInfo.last_page === pageNumber ?
                                    ''
                                    :
                                    <LoadingButton
                                        loading={loading}
                                        variant="contained"
                                        size="large"
                                        endIcon={<EndIcon />}
                                        sx={{
                                            backgroundColor: '#DB1616',
                                            '&:hover': {
                                                backgroundColor: '#c0504d',
                                            },
                                        }}
                                        onClick={() => setPageNumber(prevValue => prevValue + 1)}
                                    >
                                        See More Jobs
                                    </LoadingButton>
                            }
                        </Grid>
                }
            </Box >

            <FooterSection footerBgImage={pageConfig?.footer_background_image} footerText={pageConfig?.footer_text} />
        </>
    )
}