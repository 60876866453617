import Cookies from 'js-cookie';

export const getMessages = async (target_user_id,page) => {
    try {
        const bearerToken = Cookies.get('token');
        let response = await fetch(`${process.env.REACT_APP_API_SERVER_BASE_URL}/message/full-thread-message?target_user_id=${target_user_id}&page=${page}`, {
            method: 'GET',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + bearerToken
            },
        });
        return await response.json();
    }
    catch (err) {
    }
};

export const sendMessage = async (body) => {
    try {
        const bearerToken = Cookies.get('token');
        let response = await fetch(`${process.env.REACT_APP_API_SERVER_BASE_URL}/message/send`, {
            method: 'POST',
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + bearerToken
            },
            body: body
        });
        return await response.json();
    } catch (error) {
        
    }
}

export const readMessage = async (body) => {
    try {
        const bearerToken = Cookies.get('token');
        let response = await fetch(`${process.env.REACT_APP_API_SERVER_BASE_URL}/message/read-message`, {
            method: 'POST',
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + bearerToken
            },
            body: body
        });
        return await response.json();
    } catch (error) {
        
    }
}