import React, { useContext, useEffect, useRef, useState } from 'react'
import { Avatar, Box, Button, Card, CardContent, Container, Divider, Grid, Skeleton, Stack, Tooltip, Typography } from '@mui/material'
import { getCounters } from '../../../fetchAPI';
import { UserContext } from '../../context/UserContext';
import { Link } from 'react-router-dom';
import EmployerImageSlider from '../../components/HomePageComponents/EmployerImageSlider';
import HighPaidJobs from '../../components/HomePageComponents/HighPaidJobs';
import LiveJobs from '../../components/HomePageComponents/LiveJobs';
import { CardActions } from '@material-ui/core';
import SliderComponent from '../../components/Slider/Slider';
import { DefaultParams } from '../../components/Slider/SliderFunctions'
import FaqAccordion from './FaqAccordion';
import NorthIcon from '@mui/icons-material/North';

function TickIcon() {
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 600);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            {isSmallScreen ? (
                <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 4C12.98 4 4 12.98 4 24C4 35.02 12.98 44 24 44C35.02 44 44 35.02 44 24C44 12.98 35.02 4 24 4ZM33.56 19.4L22.22 30.74C21.94 31.02 21.56 31.18 21.16 31.18C20.76 31.18 20.38 31.02 20.1 30.74L14.44 25.08C13.86 24.5 13.86 23.54 14.44 22.96C15.02 22.38 15.98 22.38 16.56 22.96L21.16 27.56L31.44 17.28C32.02 16.7 32.98 16.7 33.56 17.28C34.14 17.86 34.14 18.8 33.56 19.4Z" fill="#15A449" />
                </svg>
            ) : (
                <svg width="45" height="45" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 4C12.98 4 4 12.98 4 24C4 35.02 12.98 44 24 44C35.02 44 44 35.02 44 24C44 12.98 35.02 4 24 4ZM33.56 19.4L22.22 30.74C21.94 31.02 21.56 31.18 21.16 31.18C20.76 31.18 20.38 31.02 20.1 30.74L14.44 25.08C13.86 24.5 13.86 23.54 14.44 22.96C15.02 22.38 15.98 22.38 16.56 22.96L21.16 27.56L31.44 17.28C32.02 16.7 32.98 16.7 33.56 17.28C34.14 17.86 34.14 18.8 33.56 19.4Z" fill="#15A449" />
                </svg>
            )}
        </>
    );
}

function RegisterButtonIcon() {

    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 600);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (

        <>
            {isSmallScreen ? (
                <svg width="24" height="24" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1_640)">
                        <path d="M50.4981 43.1352L40.3489 32.9857L47.5895 28.8032C48.1553 28.4762 48.4847 27.8551 48.4375 27.2035C48.3906 26.5516 47.9755 25.9841 47.3685 25.7422L15.0802 12.8625C14.4471 12.6097 13.7245 12.7586 13.2425 13.2405C12.7605 13.7226 12.6116 14.4452 12.8642 15.0782L25.7419 47.3701C25.9841 47.9769 26.5516 48.3922 27.2034 48.4392C27.8553 48.4866 28.4764 48.1571 28.8032 47.5911L32.985 40.3505L43.1338 50.5002C43.4535 50.8202 43.8875 50.9999 44.3398 50.9999C44.7921 50.9999 45.2258 50.8202 45.5456 50.5002L50.4981 45.5471C51.1642 44.8811 51.1642 43.8013 50.4981 43.1352ZM44.3398 46.8826L33.8257 36.3675C33.5036 36.0454 33.069 35.8678 32.6197 35.8678C32.5459 35.8678 32.4714 35.8726 32.3974 35.8824C31.8714 35.9514 31.4078 36.2612 31.1428 36.7205L27.6082 42.8409L17.5028 17.5016L42.8395 27.6084L36.7186 31.1437C36.2595 31.4086 35.9497 31.8723 35.8806 32.3983C35.8114 32.9237 35.9905 33.4516 36.3656 33.8266L46.8802 44.3417L44.3398 46.8826Z" fill="white" />
                        <path d="M7.48876 5.07462C6.82249 4.40905 5.74308 4.40905 5.07647 5.07462C4.41038 5.74088 4.41038 6.82064 5.07647 7.4869L8.8661 11.2765C9.19906 11.6093 9.63574 11.7761 10.0722 11.7761C10.5086 11.7761 10.9449 11.6093 11.2782 11.2765C11.9441 10.6104 11.9441 9.53051 11.2782 8.86442L7.48876 5.07462Z" fill="white" />
                        <path d="M8.77286 16.8652C8.77286 15.9234 8.00906 15.1599 7.06719 15.1599H1.7079C0.7662 15.1599 0.00223064 15.9232 0.00223064 16.8652C0.00223064 17.8069 0.766029 18.5707 1.7079 18.5707H7.06719C8.00906 18.5706 8.77286 17.8069 8.77286 16.8652Z" fill="white" />
                        <path d="M8.08152 22.1291L4.29155 25.919C3.62529 26.5848 3.62529 27.6647 4.29155 28.3308C4.62451 28.6638 5.06067 28.8303 5.49752 28.8303C5.93386 28.8303 6.37019 28.6638 6.70315 28.3308L10.4933 24.541C11.1594 23.8751 11.1594 22.795 10.4933 22.1291C9.82754 21.4632 8.74779 21.4632 8.08152 22.1291Z" fill="white" />
                        <path d="M16.8664 8.77097C17.8081 8.77097 18.5719 8.00717 18.5719 7.0653V1.7055C18.5719 0.763798 17.8083 0 16.8664 0C15.9245 0 15.1611 0.763626 15.1611 1.7055V7.06548C15.1611 8.00717 15.9244 8.77097 16.8664 8.77097Z" fill="white" />
                        <path d="M23.3352 10.9908C23.772 10.9908 24.2083 10.8245 24.5413 10.4914L28.3308 6.70179C28.997 6.0357 28.997 4.95577 28.3308 4.28985C27.6648 3.62376 26.5846 3.62376 25.919 4.28985L22.1294 8.07948C21.4633 8.7454 21.4633 9.8255 22.1294 10.4914C22.4623 10.8245 22.8988 10.9908 23.3352 10.9908Z" fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1_640">
                            <rect width="51" height="51" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            ) : (
                <svg width="48" height="48" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1_640)">
                        <path d="M50.4981 43.1352L40.3489 32.9857L47.5895 28.8032C48.1553 28.4762 48.4847 27.8551 48.4375 27.2035C48.3906 26.5516 47.9755 25.9841 47.3685 25.7422L15.0802 12.8625C14.4471 12.6097 13.7245 12.7586 13.2425 13.2405C12.7605 13.7226 12.6116 14.4452 12.8642 15.0782L25.7419 47.3701C25.9841 47.9769 26.5516 48.3922 27.2034 48.4392C27.8553 48.4866 28.4764 48.1571 28.8032 47.5911L32.985 40.3505L43.1338 50.5002C43.4535 50.8202 43.8875 50.9999 44.3398 50.9999C44.7921 50.9999 45.2258 50.8202 45.5456 50.5002L50.4981 45.5471C51.1642 44.8811 51.1642 43.8013 50.4981 43.1352ZM44.3398 46.8826L33.8257 36.3675C33.5036 36.0454 33.069 35.8678 32.6197 35.8678C32.5459 35.8678 32.4714 35.8726 32.3974 35.8824C31.8714 35.9514 31.4078 36.2612 31.1428 36.7205L27.6082 42.8409L17.5028 17.5016L42.8395 27.6084L36.7186 31.1437C36.2595 31.4086 35.9497 31.8723 35.8806 32.3983C35.8114 32.9237 35.9905 33.4516 36.3656 33.8266L46.8802 44.3417L44.3398 46.8826Z" fill="white" />
                        <path d="M7.48876 5.07462C6.82249 4.40905 5.74308 4.40905 5.07647 5.07462C4.41038 5.74088 4.41038 6.82064 5.07647 7.4869L8.8661 11.2765C9.19906 11.6093 9.63574 11.7761 10.0722 11.7761C10.5086 11.7761 10.9449 11.6093 11.2782 11.2765C11.9441 10.6104 11.9441 9.53051 11.2782 8.86442L7.48876 5.07462Z" fill="white" />
                        <path d="M8.77286 16.8652C8.77286 15.9234 8.00906 15.1599 7.06719 15.1599H1.7079C0.7662 15.1599 0.00223064 15.9232 0.00223064 16.8652C0.00223064 17.8069 0.766029 18.5707 1.7079 18.5707H7.06719C8.00906 18.5706 8.77286 17.8069 8.77286 16.8652Z" fill="white" />
                        <path d="M8.08152 22.1291L4.29155 25.919C3.62529 26.5848 3.62529 27.6647 4.29155 28.3308C4.62451 28.6638 5.06067 28.8303 5.49752 28.8303C5.93386 28.8303 6.37019 28.6638 6.70315 28.3308L10.4933 24.541C11.1594 23.8751 11.1594 22.795 10.4933 22.1291C9.82754 21.4632 8.74779 21.4632 8.08152 22.1291Z" fill="white" />
                        <path d="M16.8664 8.77097C17.8081 8.77097 18.5719 8.00717 18.5719 7.0653V1.7055C18.5719 0.763798 17.8083 0 16.8664 0C15.9245 0 15.1611 0.763626 15.1611 1.7055V7.06548C15.1611 8.00717 15.9244 8.77097 16.8664 8.77097Z" fill="white" />
                        <path d="M23.3352 10.9908C23.772 10.9908 24.2083 10.8245 24.5413 10.4914L28.3308 6.70179C28.997 6.0357 28.997 4.95577 28.3308 4.28985C27.6648 3.62376 26.5846 3.62376 25.919 4.28985L22.1294 8.07948C21.4633 8.7454 21.4633 9.8255 22.1294 10.4914C22.4623 10.8245 22.8988 10.9908 23.3352 10.9908Z" fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1_640">
                            <rect width="51" height="51" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            )}
        </>

    )
}


function StatsComponent(props) {
    return (
        <Stack
            direction={'row'}
            spacing={0}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
                '@media (max-width: 600px)': {
                    margin: 2
                },
            }}
        >
            <img src={props.image} alt='stats_icon' height={'auto'} width={'25%'} />

            <Stack spacing={1}>
                <Typography
                    variant='body2'
                    sx={{
                        fontSize: '20px',
                        fontWeight: 700,
                        color: '#424447',
                        textTransform: 'uppercase',
                        '@media (max-width: 600px)': {
                            fontSize: '14px',
                        },
                    }}
                >
                    {props.title}
                </Typography>
                {
                    props.loading ?

                        <Skeleton animation='wave' variant="text" width={70} />
                        :
                        <Typography
                            variant='body2'
                            sx={{
                                fontSize: '45px',
                                fontWeight: 700,
                                color: '#0275D8',
                                textTransform: 'uppercase',
                                '@media (max-width: 600px)': {
                                    fontSize: '24px',
                                },
                            }}
                        >
                            {Number(props.value).toLocaleString()} +
                        </Typography>
                }
            </Stack>
        </Stack>
    )
}

function AvatarComponent() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Define the styles based on the window width
    const avatarStyles = {
        position: 'absolute',
        bottom: '0',
        right: '200px',
        height: 'auto',
        width: 'auto',
        maxWidth: '90%',
        maxHeight: '90%',
    };

    // Apply different styles for smaller screens
    if (windowWidth <= 600) {
        avatarStyles.maxWidth = '60%';
        avatarStyles.maxHeight = '55%';
        avatarStyles.bottom = '0px';
        avatarStyles.right = '0';
    }

    return (
        <img
            src='/v2/images/LPHeaderImageAvatar_Updated.png'
            alt='Header Avatar'
            style={avatarStyles}
        />
    );
}


function HeroSectionLP() {
    const [counters, setCounters] = useState('');
    const [isLoading, setIsLoading] = useState(false)

    const { userData, isLoggedin } = useContext(UserContext)

    useEffect(() => {
        setIsLoading(true)
        getCounters()
            .then(response => {
                if (response && response.success) {
                    setCounters(response.response.data)
                    setIsLoading(false);

                } else {
                    // console.log(response)
                }
            }).catch(error => console.log('Error on fetching data'))
    }, [])



    return (

        <Box sx={{
            padding: 8,
            backgroundColor: '#e9e9e9',
            backgroundImage: 'url(/v2/images/LP1HeaderImage.png)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '750px',
            position: 'relative',
            '@media (max-width: 600px)': {
                padding: 2,
                height: '350px',
            },
        }}
        >

            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                spacing={2}
            >
                <Stack
                    spacing={{ md: 4, xs: 2 }}
                    sx={{
                        maxWidth: '800px',
                        marginLeft: '150px',
                        '@media (max-width: 1500px)': {
                            marginLeft: 0
                        },
                        '@media (max-width: 600px)': {
                            maxWidth: '200px',
                            marginLeft: 0,
                        },
                    }}
                >

                    {/* Title text */}
                    <Typography
                        variant='h3'
                        sx={{
                            fontWeight: 700,
                            color: '#00457C',
                            textShadow: '1px 3px 0px rgb(255 255 255)',
                            '@media (max-width: 600px)': {
                                fontSize: '12px',
                                marginTop: 1
                            },
                        }}
                    >
                        Why atB Jobs is considered the
                    </Typography>
                    <Typography
                        variant='h1'
                        sx={{
                            fontWeight: 700,
                            color: '#00457C',
                            textShadow: '1px 3px 0px rgb(255 255 255)',
                            '@media (max-width: 600px)': {
                                fontSize: '25px'
                            },
                        }}
                    >
                        No.1 Job Portal!
                    </Typography>


                    {/* Pointer Text */}
                    <Stack
                        direction={'row'}
                        spacing={{ md: 2, xs: 1 }}
                        alignItems={'flex-start'}
                    >
                        <Box>
                            <TickIcon />
                        </Box>

                        <Stack spacing={0}>
                            <Typography
                                variant='body'
                                sx={{
                                    fontSize: '30px',
                                    fontWeight: 600,
                                    color: '#424447',
                                    '@media (max-width: 600px)': {
                                        fontSize: '14px'
                                    },
                                }}
                            >
                                Maximum no. of job posting
                            </Typography>
                            <Typography
                                variant='body'
                                sx={{
                                    fontSize: '20px',
                                    fontWeight: 600,
                                    color: '#00457C',
                                    '@media (max-width: 600px)': {
                                        fontSize: '10px'
                                    },
                                }}
                            >
                                Job post 15,000 + / monthly
                            </Typography>
                        </Stack>
                    </Stack>

                    {/* Pointer Text */}
                    <Stack
                        direction={'row'}
                        spacing={{ md: 2, xs: 1 }}
                        alignItems={'flex-start'}
                    >
                        <Box>
                            <TickIcon />
                        </Box>

                        <Stack spacing={0}>
                            <Typography
                                variant='body'
                                sx={{
                                    fontSize: '30px',
                                    fontWeight: 600,
                                    color: '#424447',
                                    '@media (max-width: 600px)': {
                                        fontSize: '14px'
                                    },
                                }}
                            >
                                High paid jobs
                            </Typography>
                            <Typography
                                variant='body'
                                sx={{
                                    fontSize: '20px',
                                    fontWeight: 600,
                                    color: '#00457C',
                                    '@media (max-width: 600px)': {
                                        fontSize: '10px'
                                    },
                                }}
                            >
                                Salary 100,000 + BDT/ monthly
                            </Typography>
                        </Stack>
                    </Stack>

                    {/* Pointer Text */}
                    <Stack
                        direction={'row'}
                        spacing={{ md: 2, xs: 1 }}
                        alignItems={'flex-start'}
                    >
                        <Box>
                            <TickIcon />
                        </Box>

                        <Stack spacing={0}>
                            <Typography
                                variant='body'
                                sx={{
                                    fontSize: '30px',
                                    fontWeight: 600,
                                    color: '#424447',
                                    '@media (max-width: 600px)': {
                                        fontSize: '14px'
                                    },
                                }}
                            >
                                Overseas job opportunity
                            </Typography>
                            <Typography
                                variant='body'
                                sx={{
                                    fontSize: '20px',
                                    fontWeight: 600,
                                    color: '#00457C',
                                    '@media (max-width: 600px)': {
                                        fontSize: '10px',
                                        maxWidth: '70%'
                                    },
                                }}
                            >
                                Singapore, Hong Kong, Japan and more!
                            </Typography>
                        </Stack>
                    </Stack>

                    <Link to={`/candidate-register`}>
                        <Button
                            className='ga_lp_home_candidate_register_button' //do not remove ga_ class
                            variant='contained'
                            endIcon={<RegisterButtonIcon />}
                            sx={{
                                background: 'linear-gradient( #FD802C 100%, #FF6B08 100%)',
                                textTransform: 'capitalize',
                                fontFamily: 'Poppins',
                                fontWeight: 700,
                                fontSize: '25px',
                                maxWidth: '500px',
                                padding: '12px 65px',
                                '@media (max-width: 600px)': {
                                    fontSize: '12px',
                                    padding: '6px 20px',
                                },
                            }}
                        >
                            Register (Free)
                        </Button>
                    </Link>
                </Stack>

                <AvatarComponent />

            </Stack>
        </Box >
    )
}

function WhyATBJobsSections() {

    const [counters, setCounters] = useState('');
    const [isLoading, setIsLoading] = useState(false)

    const { userData, isLoggedin } = useContext(UserContext)

    useEffect(() => {
        setIsLoading(true)
        getCounters()
            .then(response => {
                if (response && response.success) {
                    setCounters(response.response.data)
                    setIsLoading(false);

                } else {
                    // console.log(response)
                }
            }).catch(error => console.log('Error on fetching data'))
    }, [])


    return (
        <Stack
            spacing={{ md: 4, xs: 2 }}
            alignItems={'center'}
        >
            <Typography
                variant='h4'
                sx={{
                    fontWeight: 700,
                    fontSize: '36px',
                    color: '#424447',
                    '@media (max-width: 600px)': {
                        fontSize: '24px',
                    },
                }}
            >
                Why atB jobs?
            </Typography>

            <Container maxWidth='xl'>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        '@media (max-width: 600px)': {
                            justifyContent: 'flex-start',
                            marginLeft: 0
                        },
                    }}
                >
                    <Grid item md={4}>
                        <StatsComponent
                            loading={isLoading}
                            image={'/v2/images/JOB_VACANCIES.png'}
                            title={'JOB VANCANCIES'}
                            value={counters.number_of_vacancy}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <StatsComponent
                            loading={isLoading}
                            image={'/v2/images/header_companies.png'}
                            title={'COMPANIES'}
                            value={counters.number_of_companies}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <StatsComponent
                            loading={isLoading}
                            image={'/v2/images/LIVE JOBS.png'}
                            title={'LIVE JOBS'}
                            value={counters.live_jobs}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Stack>
    )
}

function GetStartedSection() {
    return (
        <Stack
            spacing={4}
            alignItems={'center'}
        >

            <Typography
                variant='h4'
                sx={{
                    fontWeight: 700,
                    color: '#424447',
                    fontSize: '36px',
                    '@media (max-width: 600px)': {
                        fontSize: '24px',
                    },
                }}
            >
                Get started
            </Typography>

            <Container maxWidth="xl">

                <Grid
                    container
                    spacing={4}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                        padding: 3,
                        '@media (max-width: 600px)': {
                            justifyContent: 'flex-start',
                        },
                    }}
                >
                    <Grid item md={4}>
                        <Stack
                            direction={'row'}
                            spacing={2}
                            alignItems={'center'}
                        >
                            <img src='/v2/images/create_Account.png' alt='Get Started Icons' height={'120px'} width={'120px'} />

                            <Stack spacing={0}>
                                <Typography
                                    variant='body'
                                    sx={{
                                        fontSize: '24px',
                                        fontWeight: 600,
                                        color: '#424447',
                                        '@media (max-width: 600px)': {
                                            fontSize: '16px'
                                        },
                                    }}
                                >
                                    Create account
                                </Typography>
                                <Typography
                                    variant='body'
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        color: '#72737C',
                                        '@media (max-width: 600px)': {
                                            fontSize: '12px',
                                        },
                                    }}
                                >
                                    It’s very easy to open an account and start your journey.
                                </Typography>
                            </Stack>

                        </Stack>
                    </Grid>
                    <Grid item md={4}>
                        <Stack
                            direction={'row'}
                            spacing={2}
                            alignItems={'center'}
                        >
                            <img src='/v2/images/Complete_profile.png' alt='Get Started Icons' height={'120px'} width={'120px'} />

                            <Stack spacing={0}>
                                <Typography
                                    variant='body'
                                    sx={{
                                        fontSize: '24px',
                                        fontWeight: 600,
                                        color: '#424447',
                                        '@media (max-width: 600px)': {
                                            fontSize: '16px'
                                        },
                                    }}
                                >
                                    Complete profile
                                </Typography>
                                <Typography
                                    variant='body'
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        color: '#72737C',
                                        '@media (max-width: 600px)': {
                                            fontSize: '12px',
                                        },
                                    }}
                                >
                                    Complete your profile with all the info to get attention of client.
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item md={4}>
                        <Stack
                            direction={'row'}
                            spacing={2}
                            alignItems={'center'}
                        >
                            <img src='/v2/images/get_hired.png' alt='Get Started Icons' height={'120px'} width={'120px'} />

                            <Stack spacing={0}>
                                <Typography
                                    variant='body'
                                    sx={{
                                        fontSize: '24px',
                                        fontWeight: 600,
                                        color: '#424447',
                                        '@media (max-width: 600px)': {
                                            fontSize: '16px'
                                        },
                                    }}
                                >
                                    Get hired
                                </Typography>
                                <Typography
                                    variant='body'
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        color: '#72737C',
                                        '@media (max-width: 600px)': {
                                            fontSize: '12px',
                                        },
                                    }}
                                >
                                    Apply & get your preferable jobs with all the requirements and get it.
                                </Typography>
                            </Stack>

                        </Stack>
                    </Grid>
                </Grid>
            </Container>

            <Link to={`/candidate-register`}>
                <Button
                    className='ga_lp_home_candidate_register_button' //do not remove ga_ class
                    variant='contained'
                    endIcon={<RegisterButtonIcon />}
                    fullWidth={{ xs: true }}
                    sx={{
                        background: 'linear-gradient( #FD802C 100%, #FF6B08 100%)',
                        textTransform: 'capitalize',
                        fontFamily: 'Poppins',
                        fontWeight: 700,
                        fontSize: '25px',
                        maxWidth: '500px',
                        padding: '6px 40px',
                        '@media (max-width: 600px)': {
                            fontSize: '16px',
                        },
                    }}
                >
                    Register (Free)
                </Button>
            </Link>
        </Stack>
    )
}

function SuggestedJobsSection() {
    return (
        <React.Fragment>
            <Box
                sx={{
                    backgroundColor: '#F6FBFF',
                }}
            >

                <Box
                    sx={{
                        padding: 9,
                        '@media (max-width: 600px)': {
                            padding: 0,
                            marginTop: 3
                        },
                    }}
                >
                    <Container maxWidth='xl'>
                        <Stack spacing={{ md: 1, xs: 1 }} sx={{ marginBottom: 3 }}>
                            <Typography
                                variant='body'
                                sx={{
                                    fontSize: '32px',
                                    fontWeight: 700,
                                    color: '#424447',
                                    '@media (max-width: 600px)': {
                                        fontSize: '24px'
                                    },
                                }}
                            >
                                Suggested jobs for you
                            </Typography>
                            <Typography
                                variant='body'
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: 600,
                                    color: '#72737C',
                                    '@media (max-width: 600px)': {
                                        fontSize: '12px',
                                    },
                                }}
                            >
                                Over all 30,000+ talented people registered in our platform.
                            </Typography>
                        </Stack>

                        <Divider />

                        <LiveJobs />
                        <HighPaidJobs />
                    </Container>
                </Box>
            </Box>
        </React.Fragment>

    )
}

function Testimonials() {

    const data = [
        {
            "profile_pic": "/v2/images/nazma.png",
            "name": "Nazma Khatun",
            "designation": "Lead UI-UX Designer",
            "testimonial": "atB Job Portal surpassed my expectations with its vast job database, intuitive interface, and timely notifications. Thanks to them, I landed my dream job stress-free. Highly recommended for all job seekers!"
        },
        {
            "profile_pic": "/v2/images/Rubel.png",
            "name": "Rubel Hosain",
            "designation": "Jr. SQA Engineer",
            "testimonial": "atB Job Portal simplified my job search immensely. With its user-friendly interface and extensive job listings, I quickly found the perfect match for my skills and aspirations. Highly recommended!"
        },
        {
            "profile_pic": "/v2/images/mashrafi.png",
            "name": "Masrafi Anam",
            "designation": "Mobile App Developer",
            "testimonial": "Thanks to atB Jobs, I secured my dream job effortlessly. Their efficient platform and regular updates kept me informed about relevant opportunities, making the entire process stress-free. I couldn't be happier with the outcome!"
        },
        {
            "profile_pic": "/v2/images/noshin.png",
            "name": "Daliya Nowshin Nisha",
            "designation": "Executive - Corporate Sales",
            "testimonial": "atB Jobs Portal transformed my job hunt with its user-friendly interface and vast job listings. I quickly found and secured my dream job within weeks of using the platform! Thank you, atB Jobs Portal, for simplifying my job hunt."
        },
        {
            "profile_pic": "/v2/images/ariful.png",
            "name": "Md. Ariful Islam",
            "designation": "Executive - Corporate Sales",
            "testimonial": "atB Jobs made finding my dream job a breeze! With their efficient platform and personalized updates, I quickly found the perfect opportunity. Highly recommend their services! Thanks to atB Jobs Portal, I landed my dream job!"
        },
        {
            "profile_pic": "/v2/images/tarequr.png",
            "name": "Md. Tarequr Rahman",
            "designation": "Executive - Corporate",
            "testimonial": "Grateful to atB Jobs for guiding me to my dream job effortlessly! Their streamlined platform and proactive approach simplified everything. Highly recommended for anyone seeking career advancement!"
        }
    ]

    const [sliderData, setSliderData] = useState([]);
    const [slider, setSlider] = useState(null);

    useEffect(() => {
        if (data) {
            let itemCountInSingleColumn = 1
            let dataRows = data
            let sliderA = [];
            for (let i = 0, k = 0; i < dataRows?.length; i = i + itemCountInSingleColumn, k++) {
                sliderA[k] = [];
                for (let j = 0; j < itemCountInSingleColumn; j++) {
                    if (dataRows[i + j])
                        sliderA[k][j] = dataRows[i + j];
                }
            }
            setSliderData(sliderA);
        }
    }, [])


    useEffect(() => {
        if (sliderData.length > 0) {
            let slidesComponent = sliderData.map((sitem, sindex) => {
                return (
                    <div key={sindex} className='text-c'>
                        {
                            sitem.map((item, index) => {
                                return (
                                    <Card
                                        key={index}
                                        sx={{
                                            padding: 3,
                                            margin: '30px 10px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            justifyContent: 'spaced-evenly',
                                            height: '270px'

                                        }}>
                                        <CardContent
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'flex-start'
                                            }}
                                        >
                                            <Typography
                                                variant='body'
                                                textAlign={'left'}
                                                sx={{
                                                    fontSize: '15px',
                                                    fontWeight: 400,
                                                    color: '#424447',
                                                    '@media (max-width: 600px)': {
                                                        fontSize: '12px'
                                                    },
                                                }}
                                            >
                                                {item.testimonial}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Stack
                                                direction={'row'}
                                                spacing={{ md: 2, xs: 1 }}
                                                alignItems={'center'}
                                            >
                                                <Avatar alt="User Profile" src={item.profile_pic ? item.profile_pic : '/images/company_placeholder_icon.png'} />

                                                <Stack
                                                    spacing={0}
                                                    alignItems={'flex-start'}
                                                >
                                                    <Typography
                                                        variant='body'
                                                        sx={{
                                                            fontSize: '16px',
                                                            fontWeight: 600,
                                                            color: '#424447',
                                                            '@media (max-width: 600px)': {
                                                                fontSize: '14px'
                                                            },
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Typography>
                                                    <Typography
                                                        variant='body'
                                                        sx={{
                                                            fontSize: '12px',
                                                            fontWeight: 600,
                                                            color: '#00457C',
                                                            '@media (max-width: 600px)': {
                                                                fontSize: '10px',
                                                            },
                                                        }}
                                                    >
                                                        {item.designation}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </CardActions>
                                    </Card>
                                )
                            })
                        }
                    </div>
                )
            });

            setSlider(<SliderComponent slides={slidesComponent} params={{
                ...DefaultParams, breakpoints: {
                    400: {
                        slidesPerView: 1
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    992: {
                        slidesPerView: 2,
                    },
                    1200: {
                        slidesPerView: 3,
                    },
                    1400: {
                        slidesPerView: 3,
                    },
                    3000: {
                        slidesPerView: 4,
                    },
                }
            }} />);
        }
    }, [sliderData])



    return (
        <Box sx={{
            // padding: 8,
            backgroundColor: '#e9e9e9',
            backgroundImage: 'url(/v2/images/testimonial_bg.png)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '500px',
            position: 'relative',
            '@media (max-width: 600px)': {
                padding: 2,
            },
        }}
        >
            <Container maxWidth='xl'>
                <Stack spacing={{ md: 1, xs: 1 }} sx={{ marginBottom: 3 }}>
                    <Typography
                        variant='body'
                        sx={{
                            fontSize: '32px',
                            fontWeight: 700,
                            color: '#424447',
                            '@media (max-width: 600px)': {
                                fontSize: '24px'
                            },
                        }}
                    >
                        User Voice
                    </Typography>
                    <Typography
                        variant='body'
                        sx={{
                            fontSize: '18px',
                            fontWeight: 600,
                            color: '#72737C',
                            '@media (max-width: 600px)': {
                                fontSize: '12px',
                            },
                        }}
                    >
                        What users says about our job portal.
                    </Typography>
                </Stack>
                <Divider />

                <div style={{ marginTop: '70px', marginBottom: '70px' }}>
                    {slider}
                </div>

            </Container>
        </Box>
    )
}

function TopFeatureCompanySection() {
    return (
        <Box
            sx={{
                backgroundColor: '#F6FBFF'
            }}
        >
            <EmployerImageSlider />
        </Box>
    )
}

function CallToActionSection() {
    return (
        <Box
            sx={{
                // padding: 9,
                '@media (max-width: 600px)': {
                    padding: 0,
                    marginTop: 3
                },
            }}
        >
            <Container maxWidth='xl'>
                <Stack spacing={{ md: 1, xs: 1 }} sx={{ marginBottom: 3 }}>
                    <Typography
                        variant='body'
                        sx={{
                            fontSize: '32px',
                            fontWeight: 700,
                            color: '#424447',
                            '@media (max-width: 600px)': {
                                fontSize: '24px'
                            },
                        }}
                    >
                        Explore a faster, easier and better job search
                    </Typography>
                    <Typography
                        variant='body'
                        sx={{
                            fontSize: '18px',
                            fontWeight: 600,
                            color: '#72737C',
                            '@media (max-width: 600px)': {
                                fontSize: '12px',
                            },
                        }}
                    >
                        Unlocking opportunities exploring careers with atB Jobs
                    </Typography>
                </Stack>
                <Divider />

                <Box mt={3}>
                    <Grid container spacing={{ md: 1, xs: 0 }}>
                        <Grid item md={6} xs={12}>
                            <Box p={{ md: 3, xs: 0 }}>
                                <img src='/v2/images/explore_faster_picture.png' alt='Explore easier picture' height={'100%'} width={'100%'} />
                            </Box>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Stack spacing={{ md: 4, xs: 2 }} m={5}>
                                {/* Highly Secured Section */}
                                <Box>
                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                        <Box>
                                            <img src='/v2/images/highly_secured_icon.png' alt='Explore easier picture' height={'100%'} width={'100%'} />
                                        </Box>

                                        <Box>
                                            <Typography
                                                variant='h5'
                                                sx={{
                                                    fontSize: '24px',
                                                    fontWeight: 700,
                                                    color: '#424447',
                                                    '@media (max-width: 600px)': {
                                                        fontSize: '16px',
                                                    },
                                                }}
                                            >
                                                Highly Secured
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Box>
                                <Box>
                                    <Typography
                                        variant='body'
                                        sx={{
                                            fontSize: '18px',
                                            fontWeight: 400,
                                            color: '#424447',
                                            '@media (max-width: 600px)': {
                                                fontSize: '14px',
                                            },
                                        }}
                                    >
                                        Find your ideal career path on our highly secured job portal. We ensure your privacy and safety while connecting you with top-notch opportunities.
                                    </Typography>
                                </Box>

                                {/* Authentic Score Section */}
                                <Box>
                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                        <Box>
                                            <img src='/v2/images/authentic_source_icon.png' alt='Explore easier picture' height={'100%'} width={'100%'} />
                                        </Box>

                                        <Box>
                                            <Typography
                                                variant='h5'
                                                sx={{
                                                    fontSize: '24px',
                                                    fontWeight: 700,
                                                    color: '#424447',
                                                    '@media (max-width: 600px)': {
                                                        fontSize: '16px',
                                                    },
                                                }}
                                            >
                                                Authentic Source
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </Box>
                                <Box>
                                    <Typography
                                        variant='body'
                                        sx={{
                                            fontSize: '18px',
                                            fontWeight: 400,
                                            color: '#424447',
                                            '@media (max-width: 600px)': {
                                                fontSize: '14px',
                                            },
                                        }}
                                    >
                                        We prioritize sourcing the most suitable and credible opportunities for each individual to embark on their career path with certainty.
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        marginTop: '80px !important',

                                    }}
                                >
                                    <Link to={`/candidate-register`}>
                                        <Button
                                            className='ga_lp_home_candidate_register_button' //do not remove ga_ class
                                            variant='contained'
                                            endIcon={<RegisterButtonIcon />}
                                            fullWidth={{ xs: true }}
                                            sx={{
                                                background: 'linear-gradient( #FD802C 100%, #FF6B08 100%)',
                                                textTransform: 'capitalize',
                                                fontFamily: 'Poppins',
                                                fontWeight: 700,
                                                fontSize: '25px',
                                                maxWidth: '500px',
                                                padding: '6px 40px !important',
                                                '@media (max-width: 600px)': {
                                                    fontSize: '16px',
                                                },
                                            }}
                                        >
                                            Register (Free)
                                        </Button>
                                    </Link>
                                </Box>

                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}

function AccordionSection() {
    return (
        <Box sx={{ backgroundColor: '#F6FBFF' }}>
            <Box
                sx={{
                    padding: 9,
                    '@media (max-width: 600px)': {
                        padding: 0,
                        marginTop: 3
                    },
                }}
            >
                <Container maxWidth='xl'>
                    <Stack spacing={2}>
                        <Typography
                            variant='body'
                            sx={{
                                fontSize: '32px',
                                fontWeight: 700,
                                color: '#424447',
                                '@media (max-width: 600px)': {
                                    fontSize: '24px'
                                },
                            }}
                        >
                            FAQ (Frequently Asked Questions)
                        </Typography>

                        <Box m={2}>
                            <FaqAccordion />
                        </Box>
                    </Stack>
                </Container>
            </Box>
        </Box>
    )
}

function FloatingSection() {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const isScrollingDown = window.scrollY > 200; // Adjust this threshold as needed
            setIsVisible(isScrollingDown);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Box
            sx={{
                position: 'fixed',
                display: isVisible ? 'block' : 'none',
                bottom: 0,
                width: '100%',
                height: '90px',
                backgroundColor: '#00457C',
                zIndex: 100000,
                color: 'white',
                transition: 'bottom 0.5s ease-in-out'
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center' }}>
                <Link to={`/candidate-register`}>
                    <Button
                        variant='contained'
                        className='ga_lp_home_candidate_register_button' //do not remove ga_ class
                        endIcon={<RegisterButtonIcon />}
                        fullWidth={{ xs: true }}
                        sx={{
                            background: 'linear-gradient( #FD802C 100%, #FF6B08 100%)',
                            textTransform: 'capitalize',
                            fontFamily: 'Poppins',
                            fontWeight: 700,
                            fontSize: '25px',
                            maxWidth: '500px',
                            padding: '6px 70px',
                            '@media (max-width: 600px)': {
                                fontSize: '12px',
                            },
                        }}
                    >
                        Register (Free)
                    </Button>
                </Link>
            </Box>
        </Box>
    );
}

export default function LP1() {

    return (
        <Stack spacing={{ md: 10, xs: 6 }}>
            <HeroSectionLP />
            <WhyATBJobsSections />
            <TopFeatureCompanySection />
            <GetStartedSection />
            <SuggestedJobsSection />
            <Testimonials />
            <CallToActionSection />
            <AccordionSection />
            <FloatingSection />

        </Stack>
    )
}