import { Chip, Avatar } from '@mui/material'
import React from 'react'

function typeIcon() {
    return (
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2133_1943)">
                <path d="M7.04841 8.03128C9.28286 8.03128 11.5181 8.03127 13.7534 8.02542C13.9951 8.02542 14.0687 8.08228 14.0645 8.33231C14.0503 9.37678 14.0645 10.4229 14.0578 11.4657C14.0578 12.4057 13.4214 13.0462 12.4882 13.0471C8.85331 13.0504 5.21873 13.0504 1.58442 13.0471C0.649498 13.0471 0.0139477 12.4057 0.0106027 11.4691C0.0106027 10.4246 0.0172861 9.37846 0.00390625 8.3365C0.00390625 8.09315 0.0624579 8.02375 0.311658 8.02542C2.55697 8.03545 4.80227 8.03128 7.04841 8.03128Z" fill="#15A449" />
                <path d="M7.04642 6.52522C4.80947 6.52522 2.57252 6.52521 0.335574 6.53022C0.0847011 6.53022 -0.0114668 6.49008 0.00107683 6.21328C0.0261641 5.68143 0.0069387 5.14708 0.00944743 4.6144C0.00944743 3.63934 0.641652 3.01635 1.62424 3.013C2.31414 3.013 3.00487 2.99543 3.69394 3.02135C3.98662 3.03222 4.04014 2.93773 4.02843 2.67097C4.00753 2.21187 4.01839 1.7511 4.02341 1.29117C4.03261 0.538552 4.55943 0.00836243 5.31038 0.00418121C6.46049 -0.00139374 7.61033 -0.00139374 8.75988 0.00418121C9.50832 0.00836243 10.0335 0.541901 10.0418 1.29536C10.0477 1.78707 10.0519 2.27877 10.0418 2.76964C10.0368 2.95863 10.087 3.02051 10.2844 3.02051C11.0052 3.00881 11.7269 3.01466 12.4486 3.01633C13.4295 3.01633 14.055 3.64353 14.0592 4.62193C14.0592 5.15545 14.0483 5.68897 14.065 6.22165C14.0734 6.46166 14.0123 6.53608 13.7598 6.53441C11.5203 6.52019 9.28337 6.52522 7.04642 6.52522ZM7.03472 3.013C7.45284 3.013 7.87097 3.00044 8.28909 3.018C8.50317 3.02637 8.55334 2.94944 8.54498 2.75125C8.53077 2.40755 8.53327 2.06301 8.54498 1.71848C8.55 1.55708 8.50066 1.50274 8.33425 1.50442C7.4679 1.51222 6.60155 1.51222 5.7352 1.50442C5.56795 1.50442 5.52447 1.56461 5.52865 1.72183C5.53785 2.07639 5.53952 2.4318 5.52865 2.78636C5.5228 2.96197 5.57464 3.02135 5.75527 3.01633C6.17841 3.00295 6.60657 3.013 7.03472 3.013Z" fill="#15A449" />
            </g>
            <defs>
                <clipPath id="clip0_2133_1943">
                    <rect width="14.0631" height="13.0496" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

const ChipComponent = ({ title }) => {
    let chipProps = {
        label: title,
        avatar: <Avatar sx={{ backgroundColor: 'transparent' }}>{typeIcon()}</Avatar>,
        sx: {
            backgroundColor: '',
            color: '',
            fontWeight: 600,
            fontSize: "12px",
            fontFamily: 'Poppins, sans-serif',
        }
    };

    switch (title) {
        case 'Full time':
            chipProps.sx.backgroundColor = "#EEFAF7"
            chipProps.sx.color = '#15A449';
            break;
        case 'Part-Time':
            chipProps.sx.backgroundColor = "#eefaf7"
            chipProps.sx.color = '#8743DF';
            break;
        case 'Freelance':
            chipProps.sx.backgroundColor = "#eefaf7"
            chipProps.sx.color = '#0275D8';
            break;
        case 'Contractual':
            chipProps.sx.backgroundColor = "#eefaf7"
            chipProps.sx.color = '#56CDAD';
            break;
        default:
            break;
    }

    return <Chip {...chipProps} />;
};

export default ChipComponent;