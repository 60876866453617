import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Style from '../../../css/lp2/lp2.module.css';
import BnIcon from '../../../images/lp2/icon/announcement.png';
import Vivo from '../../../images/lp2/slider/vivo.png';
import Milvik from '../../../images/lp2/slider/milvik.png';
import Lalamove from '../../../images/lp2/slider/lalamove.png';
import Arogga from '../../../images/lp2/slider/arogga.png';
import Backspace from '../../../images/lp2/slider/backspace.png';
import Shikho from '../../../images/lp2/slider/shikho.png';
import CrownCement from '../../../images/lp2/slider/crownCement.png';
import About from '../../../images/lp2/img/img.png';
import MilvikImg from '../../../images/lp2/client/milvik.png';
import GraahoImg from '../../../images/lp2/client/graaho.png';
import Clone from '../../../images/lp2/client/clone.svg';
import JobImg from '../../../images/lp2/jobs/image.png';
import ArrowRightIcon from '../../../images/lp2/icon/arrow-right.png';
const BannerSection = () => {
    return (
        <div className={`${Style.bannerContainer}`}>
            <div className={`${Style.containers} ${Style.bannerContent}`}>
                <p className="my-4">
                    <span className={`${Style.activeContent}`}>
                        <span
                            className={Style.vacancieNumber}
                            style={{ paddingRight: '6px' }}
                        >
                            11,000+
                        </span>
                        <span className={Style.vacancieText}>
                            Active Vacancies, Available Right Now!
                        </span>
                    </span>
                </p>
                <h1
                    className={`${Style.titleText}`}
                    style={{ marginTop: '2rem' }}
                >
                    Top Companies of Bangladesh <br /> Are Using{' '}
                    <span className={Style.titleTextColor}>atB</span> Jobs
                </h1>

                <div className={Style.bannerBtnWrpper}>
                    <Link
                        to="/candidate-register"
                        className={Style.btnsLink}
                    >
                        Get started. It’s Free
                    </Link>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '.6rem',
                    }}
                >
                    <div className={Style.bannerIcon}>
                        <img src={BnIcon} alt="announcement icon" />
                    </div>
                    <span className={Style.announcementText}>
                        2 Minute Registration, For a Lifetime of Opportunities!
                    </span>
                </div>
            </div>
        </div>
    );
};

const SliderSection = () => {
    const sliderImage = [
        {
            id: '1',
            img: Vivo,
            name: 'Vivo',
        },
        {
            id: '2',
            img: Milvik,
            name: 'Milvik',
        },
        {
            id: '3',
            img: Lalamove,
            name: 'Lalamove',
        },
        {
            id: '4',
            img: Arogga,
            name: 'Arogga',
        },
        {
            id: '5',
            img: Backspace,
            name: 'Backspace',
        },
        {
            id: '6',
            img: Shikho,
            name: 'Shikho',
        },
        {
            id: '7',
            img: CrownCement,
            name: 'Crown Cement',
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const sliderRef = useRef(null);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % sliderImage.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? sliderImage.length - 1 : prevIndex - 1
        );
    };

    useEffect(() => {
        const autoPlay = setInterval(nextSlide, 3000);
        return () => clearInterval(autoPlay);
    }, []);

    useEffect(() => {
        if (sliderRef.current) {
            sliderRef.current.style.transform = `translateX(-${currentIndex *
                150}px)`;
        }
    }, [currentIndex]);
    return (
        <div className={`${Style.containers} ${Style.sliderContainer}`}>
            <div className="position-relative overflow-hidden">
                <div className={Style.sliderWrapper} ref={sliderRef}>
                    {sliderImage.map((data) => (
                        <div key={data.id} className={Style.sliderImg}>
                            <img src={data.img} alt={data.name} />
                        </div>
                    ))}
                </div>
                <div className={Style.leftBtn}>
                    <button
                        className={`${Style.sliderBtn}`}
                        onClick={prevSlide}
                    >
                        <i className="fa-solid fa-angle-left"></i>
                    </button>
                </div>
                <div className={Style.rightBtn}>
                    <button className={Style.sliderBtn} onClick={nextSlide}>
                        <i className="fa-solid fa-angle-right"></i>
                    </button>
                </div>
            </div>
        </div>
    );
};

const MettingSection = () => {
    return (
        <div className={Style.containers}>
            <div className={Style.mettingContainer}>
                <div className={Style.mettingLeft}>
                    <h1 className={Style.titleText}>
                        Collaboration{' '}
                        <span className={Style.titleTextColor}>Meetings</span>{' '}
                        with Client
                    </h1>
                    <p
                        className={`${Style.mettingContnet} ${Style.contentText}`}
                    >
                        At atB Jobs, we prioritize quality and accuracy. Before
                        featuring any job listing, we meet with our client
                        companies to understand their needs and company culture.
                        These meetings ensure that our job descriptions are
                        personalized and compelling, leading to better
                        connections between employers and job seekers.
                    </p>

                    <div className="mt-4">
                        <Link
                            to="/candidate-register"
                            className={Style.btnsLink}
                        >
                            Get started. It’s Free
                        </Link>
                    </div>
                </div>
                <div className={Style.mettingRight}>
                    <img className="img-fluid" src={About} alt="about" />
                </div>
            </div>
        </div>
    );
};

const ClientReviewSection = () => {
    const ClientReview = [
        {
            id: '1',
            name: 'Soyed Zonaid Kabir',
            deg: 'Executive Director',
            img: GraahoImg,
            desc: `To all my fellow Top Management and HR person of the I.T Industry: atB Jobs is a game changer! We are being able to recruit efficiently even our high level tech team members. atB Jobs really does connect`,
        },
        {
            id: '2',
            name: 'Mr. Baharuddin',
            deg: 'HR, MILVIK',
            img: MilvikImg,
            desc: `Finding the right team member has always been a challenge. But with atB Jobs, it is a lot easier to scope out the right candidate, with their in-depth filtering features, who are a perfect fit for our team. We are using atB Jobs for our recruitment! Think you're the right person?`,
        },
    ];
    return (
        <div className={Style.clinetContainer}>
            <div className={Style.containers}>
                <h1 className={Style.titleText}>
                    Some Reviews From{' '}
                    <span className={Style.titleTextColor}>Clients</span>
                </h1>

                <div className={Style.clientGird}>
                    {ClientReview?.map((data) => (
                        <div key={data?.id} className={Style.clientCard}>
                            <div>
                                <div className="position-relative">
                                    <div className={Style.clientImg}>
                                        <img src={data?.img} alt={data?.name} />
                                    </div>
                                    <div className={Style.cloneIcon}>
                                        <img src={Clone} alt="clone" />
                                    </div>
                                </div>
                            </div>

                            <div className={Style.reviewContent}>
                                <h4 className={Style.clientName}>
                                    {data?.name}
                                </h4>
                                <p className={Style.clientDig}>{data?.deg}</p>
                                <div
                                    className={`d-flex align-items-center gap-1 ${Style.clientStart}`}
                                >
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                    <i className="fa-solid fa-star"></i>
                                </div>
                                <p
                                    className={`${Style.clientText} ${Style.contentText}`}
                                >
                                    {data?.desc}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const JobSection = () => {
    return (
        <div className={Style.containers}>
            <div className={Style.jobContainer}>
                <div className={Style.jobWrapper}>
                    <div className={Style.jobLeft}>
                        <h1 className={`${Style.titleText} text-white mb-0`}>
                            Where Jobs Seeks You
                        </h1>
                        <p className={`${Style.contentText} text-white`}>
                            Discover Your Dream Career Opportunities
                        </p>
                        <div className={Style.jobBtn}>
                            <Link to="/candidate-register">
                                Get started. It’s Free
                            </Link>
                        </div>
                        <div className={Style.jobTextContent}>
                            <div className={Style.textJob}>
                                <span>
                                    <i className="fa-solid fa-circle-dot"></i>
                                </span>
                                <span>Free Account</span>
                            </div>
                            <div className={Style.textJob}>
                                <span>
                                    <i className="fa-solid fa-circle-dot"></i>
                                </span>
                                <span>11,000+ Active Vacancies</span>
                            </div>
                            <div class={Style.textJob}>
                                <span>
                                    <i className="fa-solid fa-circle-dot"></i>
                                </span>
                                <span>Top Companies</span>
                            </div>
                        </div>
                    </div>
                    <div className={Style.jobRight}>
                        <div className={Style.jobImg}>
                            <img src={JobImg} alt="Job Image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const FloatingSection = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const isScrollingDown = window.scrollY > 200;
            setIsVisible(isScrollingDown);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={isVisible ? `${Style.boxs}` : ''}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'center',
                }}
            >
                <Link to="/candidate-register">
                    <button className={Style.buttons}>Register for Free <img src={ArrowRightIcon} alt="Arrow Right" style={{ marginLeft: '10px', height: '24px' }} /></button>
                </Link>
            </div>
        </div>
    );
};

const LP2 = () => {
    return (
        <>
            <BannerSection />
            <SliderSection />
            <MettingSection />
            <ClientReviewSection />
            <JobSection />
            <FloatingSection />
        </>
    );
};

export default LP2;
