import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import SuccessAlert from '../../Alerts/SuccessAlert';
import ErrorAlert from '../../Alerts/ErrorAlert';
import { Typography } from '@mui/material';
import { deleteEmployerCCMail, getEmployerCCMails, insertEmployerCCMail } from '../../../../fetchAPI';
import SingleMail from './SingleMail';

function CCMail() {

    const [mailList, setMailList] = useState([]);

    const user = JSON.parse(Cookies.get('user'));
    const user_email = user.email;

    const [email, setEmail] = useState('');
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');


    useEffect(() => {
        getEmployerCCMails().then((response) => {
            if (response && response.success) {
                setMailList(response.response.data);
            }
        })
    }, [])

    const addEmailAddress = (event) => {
        event.preventDefault();
        insertEmployerCCMail({ email: email }).then((response) => {
            if (response && response.success) {
                setResponseMessage(response.response.message);
                setOpenSuccessAlert(true);
                setEmail('');
                let data = response.response.data;
                setMailList([...mailList, {
                    id: data.id,
                    email: data.email,
                }]);
                setTimeout(() => setOpenSuccessAlert(false), 3000);
            }
            else {
                setResponseMessage(response.errors[0].message);
                setOpenErrorAlert(true);
                setTimeout(() => setOpenErrorAlert(false), 3000);
            }
        })
    }

    const deleteMailAddress = (id) => (event) => {
        event.preventDefault();
        if (!window.confirm('Are you sure to delete the email address?')) return;

        deleteEmployerCCMail(id).then((response) => {
            if (response && response.success) {
                setResponseMessage(response.response.message);
                setOpenSuccessAlert(true);
                let newMailList = mailList.filter(function (el) { return el.id != id });
                setMailList(newMailList);
                setTimeout(() => setOpenSuccessAlert(false), 3000);
            }
            else {
                setResponseMessage(response.errors[0].message);
                setOpenErrorAlert(true);
                setTimeout(() => setOpenErrorAlert(false), 3000);
            }
        })
    }

    return (
        <div className='atb-card-dashboard'>
            <div className='container p-4'>
                <div className='text-left'>
                    <Typography variant='h5'>Add Emails</Typography>
                    <hr />
                    <form onSubmit={addEmailAddress}>
                        <div class="form-group">
                            <Typography sx={{ fontFamily: 'Epilogue', color: '#1D1E1B', marginBottom: '10px' }}>Current Email</Typography>
                            <input type="email" className="form-control" value={user_email} disabled />
                        </div>
                        <div class="form-group">
                            <Typography sx={{ fontFamily: 'Epilogue', color: '#1D1E1B', marginBottom: '10px' }}>Add New Email</Typography>
                            <input type="email" required className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className='text-r'>
                            <button type="submit" class="btn btn-primary">Add Email</button>
                        </div>
                    </form>
                    <div>
                        {
                            mailList.map((item) => {
                                return (
                                    <div key={item.id}>
                                        <hr />
                                        <SingleMail
                                            email={item.email}
                                            id={item.id}
                                            setResponseMessage={setResponseMessage}
                                            setOpenErrorAlert={setOpenErrorAlert}
                                            setOpenSuccessAlert={setOpenSuccessAlert}
                                            deleteHandler={deleteMailAddress(item.id)}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                    <SuccessAlert open={openSuccessAlert} message={responseMessage} onHide={() => setOpenSuccessAlert(false)} />
                    <ErrorAlert open={openErrorAlert} message={responseMessage} onHide={() => setOpenErrorAlert(false)} />
                </div>
            </div>
        </div>
    )
}

export default CCMail;