import React, { useContext } from 'react'
import CandidateDashboardSidebar from '../components/CandidateComponents/CandidateDashboardSideBar/CandidateDashboardSidebar'
import MessageBox from '../components/Messages/MessageBox'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { UserContext } from '../context/UserContext';
import EmployerDashboardSideBar from '../components/EmployerComponents/EmployerDashboardSideBar/EmployerDashboardSideBar';
import { Box, Grid } from '@mui/material';

function Messages() {

  const { from_id } = useParams();

  const userContext = useContext(UserContext);

  return (
    <>
      {
        userContext.userData.user_role_id == 2 ?
          <div className='' >
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, position: 'fixed', height: '70px', backgroundColor: '#0275D8', width: '100%', zIndex: 10 }}></Box>

            <Grid container>
              <Grid item xs={0} sm={0} md={3} lg={2.5} xl={2} sx={{ zIndex: 11, display: { xs: 'none', sm: 'none', md: 'block' } }}>
                <CandidateDashboardSidebar />
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={9.5} xl={10} sx={{ zIndex: 11, display: { md: 'block', marginTop: "30px" } }}>
                <MessageBox from_id={from_id} ownPic={userContext.profilePic} />
              </Grid>
            </Grid>
          </div>
          :
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4 col-md-0 d-none d-lg-block'>
                <EmployerDashboardSideBar />
              </div>
              <div className='col-lg-8 col-md-12'>
                <MessageBox from_id={from_id} ownPic={userContext.profilePic} />
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default Messages