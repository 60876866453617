import React from 'react'
import './HeroSection.css'

function PublicPageHeroSections(props) {
  return (
    <div style={{background: `url(${props.path})`}} className="background-container">
        <div class="overlay">
            <div className='public-hero-section'>
                <div className='container text-area'>
                    <center>
                        <h1 className='public-section-text'>{props.text}.</h1>
                    </center>
                </div>
            </div>
        </div>
        <div className='wave d-none d-lg-block'>
            <img src='/v2/icons/wave.png' />
        </div>
    </div>
  )
}

export default PublicPageHeroSections