import React, { useEffect, useState } from 'react';

const StaticPage = () => {
    const [htmlContent, setHtmlContent] = useState('');

    useEffect(() => {
        fetch('/pages/candidate/index.html',{mode: 'cors'})
            .then(response => response.text())
            .then(data => setHtmlContent(data))
            .catch(error => console.error('Error loading the HTML file:', error));
    }, []);

    return (
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    );
};

export default StaticPage;



// // src/App.js
// import React from 'react';
// import HtmlLoader from './HtmlLoader';
//
// const StaticPage = () => {
//     return (
//             <HtmlLoader url="/lp/candidate/index.html" />
//         // <iframe src='/lp/candidate/index.html' width='100%' height='100%'></iframe>
//     );
// };
//
// export default StaticPage;