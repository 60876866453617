import { Box } from '@mui/material'
import React from 'react'
import PuffLoader from "react-spinners/PuffLoader";

function GlobalLoader({height=""}) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:`${height ? height : "10vh"}`}}>
      <PuffLoader color="#1976D2" />
    </Box>
  )
}

export default GlobalLoader