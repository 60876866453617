import { Box, CircularProgress } from "@mui/material";
import React, { useContext } from "react";
import { CandidateContext } from "../../../context/CandidateContext";
import { UserContext } from "../../../context/UserContext";
import { buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

export default function CandidateDashboardAvatar() {
  const { profileStrength } = useContext(CandidateContext);
  const {profilePic, loading, setSelectedFile, } = useContext(UserContext);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <Box
        sx={{
          width: "120px",
          height: "120px",
          position: "relative",
        }}
      >
        <CircularProgressbarWithChildren
          value={profileStrength}
          strokeWidth={4}
          styles={buildStyles({
            // textColor: "red",
            pathColor: "#15A449",
            trailColor: "#DADADA",
            rotation: 0.5,
          })}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <img
              style={{
                borderRadius: "50%",
                padding: "6px",
                width: "115px",
                height: "115px",
              }}
              src={profilePic ? profilePic : "/v2/images/candidate-default.png"}
              alt="avatar"
            />
          )}
        </CircularProgressbarWithChildren>
        <Box
          title="update"
          data-toggle="tooltip"
          data-placement="right"
          sx={{ position: "absolute", top: 10, right: -3 }}
        >
          <input
            style={{ display: "none" }}
            type="file"
            onChange={(event) => setSelectedFile(event.target.files[0])}
            id="upload-pic"
          />
          <label htmlFor="upload-pic">
            <img
              style={{
                cursor: "pointer",
                width: "40px",
                height: "40px",
              }}
              src="/v2/icons/dashboard/camera.svg"
              alt=""
            />
          </label>
        </Box>
        {profileStrength ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
              position: "relative",
            }}
          >
            <Box
              sx={{
                borderRadius: "25px",
                color: "#15A449",
                fontFamily: "Epilogue,sans-serif",
                fontSize: "18px",
                fontWeight: 700,
                // textAlign: "center",
                background: "#FFFFFF",
                padding: "3px 20px 0px 20px;",
                position: "absolute",
                bottom: "0px",
                boxShadow: "0 3px 7px 0px #b5b5b5",
              }}
            >
              {profileStrength} %
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
