import React, { useEffect, useState } from 'react';
import Style from '../../../css/lp6/lp6.module.css';
import googlePlay from '../../../images/lp6/Google_Play.png';
import DownloadNow from '../../../images/lp6/Download_Now.png';
import BannerImg from '../../../images/lp6/banner_img.png';
import user1 from '../../../images/lp6/user_1.png';
import user2 from '../../../images/lp6/user_2.png';
import user3 from '../../../images/lp6/user_3.png';
import Star from '../../../images/lp6/Star_1.png';
import Star2 from '../../../images/lp6/Star.png';
import appImg from '../../../images/lp6/app_img.png';
import Check from '../../../images/lp6/check.png';
import DownloadNow2 from '../../../images/lp6/Download_the_app.png';
import atbApp from '../../../images/lp6/atB_Apps.png';
import ArrowRightIcon from '../../../images/lp4/icon/arrow-right.png';

const BannerSection = () => {
    return (
        <div className={Style.bannerContainer}>
            <div className={`${Style.containers} ${Style.bannerFlex}`}>
                <div className={Style.bannerFlexLeft}>
                    <h1 className={Style.titleText}>
                        Easy way to get full control of your job applications
                    </h1>
                    <p className={Style.contentText}>
                        Elevate your job search with the atB Jobs app. Enjoy
                        personalized alerts and easy applications.
                    </p>
                    <div className={Style.bannerBtn}>
                        <a href="https://play.google.com/store/apps/details?id=com.candidate.atb_jobs" target="_blank" rel="noopener noreferrer">
                            <img
                                src={googlePlay}
                                className={Style.bannerBtnimg}
                                alt="google play"
                            />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.candidate.atb_jobs" target="_blank" rel="noopener noreferrer">
                            <img
                                src={DownloadNow}
                                className={Style.bannerBtnimg}
                                alt="download now"
                            />
                        </a>
                    </div>
                </div>
                <div className={Style.bannerFlexRight}>
                    <img
                        src={BannerImg}
                        style={{ width: '100%', borderRadius: '24px' }}
                        alt="banner-img"
                    />
                </div>
            </div>
        </div>
    );
};

const ClientSection = () => {
    return (
        <div className={`${Style.containers} ${Style.clientContainer}`}>
            <h2 className={Style.clinetTitle}>
                Trusted by Thousands <br />
                Rated 4.6 Stars by Our Users
            </h2>

            <div className={Style.clientContent}>
                <div>
                    <img src={user1} className={Style.userImg} alt="user-1" />
                    <img src={user2} className={Style.userImg} alt="user-1" />
                    <img src={user3} className={Style.userImg} alt="user-1" />
                </div>
                <div className={Style.clientContentLeft}>
                    <h3 className={Style.ratingNumber}>4.6</h3>
                    <div style={{ paddingRight: '50px' }}>
                        <h4
                            style={{
                                color: '#6A6A6A',
                                fontWeight: '600',
                                fontSize: '24px',
                                lineHeight: '30px',
                                marginBottom: '0px',
                            }}
                        >
                            Google
                        </h4>
                        <p
                            style={{
                                color: '#6A6A6A',
                                fontSize: '12px',
                                fontWeight: '400',
                                marginBottom: '0px',
                            }}
                        >
                            average rating
                        </p>
                        <p
                            style={{
                                marginBottom: '0px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '2px',
                            }}
                        >
                            <img
                                style={{ maxWidth: '30px' }}
                                src={Star}
                                alt="icon"
                            />
                            <img
                                style={{ maxWidth: '30px' }}
                                src={Star}
                                alt="icon"
                            />
                            <img
                                style={{ maxWidth: '30px' }}
                                src={Star}
                                alt="icon"
                            />
                            <img
                                style={{ maxWidth: '30px' }}
                                src={Star}
                                alt="icon"
                            />
                            <img
                                style={{ maxWidth: '30px' }}
                                src={Star2}
                                alt="icon"
                            />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const AppSection = () => {
    const AppData = [
        {
            id: 1,
            title: 'Featured Jobs',
            desc:
                'Explore top job listings from leading companies, curated to match your skills and career goals.',
            img: Check,
        },
        {
            id: 2,
            title: 'Better Job Recommendations',
            desc:
                'Receive job recommendations tailored to your unique qualifications and preferences,',
            img: Check,
        },

        {
            id: 3,
            title: 'Create Resume Instantly',
            desc:
                'Easily create a professional resume in just a minute with our intuitive tools.',
            img: Check,
        },

        {
            id: 4,
            title: 'Seamless Application Process',
            desc:
                'Experience a smooth and quick job application process with minimal effort.',
            img: Check,
        },
    ];
    return (
        <div className={Style.appContainer}>
            <div className={`${Style.containers} ${Style.appFlex}`}>
                <div className={Style.bannerFlexLeft}>
                    <img style={{ width: '100%' }} src={appImg} alt="image" />
                </div>
                <div className={Style.bannerFlexRight}>
                    <h1 className={Style.titleText}>App Features</h1>
                    <p className={Style.contentText}>
                        Discover the powerful tools and features that make atB
                        Jobs your ultimate job search companion.
                    </p>

                    <div className={Style.appContent}>
                        {AppData.map((data) => (
                            <div
                                key={data.id}
                                style={{ display: 'flex', gap: '0.6rem' }}
                            >
                                <div style={{ marginTop: '3px' }}>
                                    <img
                                        style={{ maxWidth: '30px' }}
                                        src={data.img}
                                        alt=""
                                    />
                                </div>
                                <div>
                                    <h3
                                        style={{
                                            fontSize: '20px',
                                            fontWeight: '600',
                                            lineHeight: '30px',
                                            color: '#605C78',
                                        }}
                                    >
                                        {data.title}
                                    </h3>
                                    <p
                                        className={Style.contentText}
                                        style={{ fontWeight: '400' }}
                                    >
                                        {data.desc}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div style={{ marginTop: '1rem' }}>
                        <a href="https://play.google.com/store/apps/details?id=com.candidate.atb_jobs" target="_blank" rel="noopener noreferrer">
                            <img
                                src={DownloadNow}
                                className={Style.bannerBtnimg}
                                alt="download now"
                            />
                        </a>
                    </div>
                </div>
            </div>

            <div className={`${Style.containers} ${Style.workContainer}`}>
                <h1 className={Style.titleText} style={{ textAlign: 'center' }}>
                    How it Works
                </h1>
                <p
                    className={Style.contentText}
                    style={{ textAlign: 'center' }}
                >
                    Follow these easy steps to kickstart your job search and
                    land your <br />
                    dream job with atB Jobs.
                </p>

                <div className={Style.workGird}>
                    <div className={Style.step}>
                        <h2>1</h2>
                        <h3>Download the App</h3>
                        <p>Get the aB Jobs app from Google Play.</p>
                    </div>
                    <div className={Style.step}>
                        <h2>2</h2>
                        <h3>Create Your Profile</h3>
                        <p>
                            Set up your profile and upload your resume in
                            minutes.
                        </p>
                    </div>
                    <div className={Style.step}>
                        <h2>3</h2>
                        <h3>Find Your Dream Job</h3>
                        <p>
                            Use filters & recommendations to find your perfect
                            job.
                        </p>
                    </div>
                    <div className={Style.step}>
                        <h2>4</h2>
                        <h3>Apply with Ease</h3>
                        <p>
                            Submit your applications effortlessly & track your
                            progress.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const LookInside = () => {
    return (
        <div className={Style.lookContainer}>
            <div className={`${Style.containers} ${Style.lookFlex}`}>
                <div className={Style.lookFlexLeft}>
                    <h1 className={Style.titleText} style={{ color: '#fff' }}>
                        Take a Look Inside
                    </h1>
                    <p className={Style.contentText} style={{ color: '#fff' }}>
                        Explore the app's sleek design and user-friendly
                        interface.
                    </p>
                    <div style={{ marginTop: '3rem' }}>
                        <a href="https://play.google.com/store/apps/details?id=com.candidate.atb_jobs" target="_blank" rel="noopener noreferrer">
                            <img
                                src={DownloadNow2}
                                className={Style.bannerBtnimg}
                                alt="download now"
                            />
                        </a>
                    </div>
                </div>
                <div className={Style.lookFlexRight}>
                    <img style={{ width: '100%' }} src={atbApp} alt="image" />
                </div>
            </div>
        </div>
    );
};

const FloatingSection = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const isScrollingDown = window.scrollY > 200;
            setIsVisible(isScrollingDown);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={isVisible ? `${Style.boxs}` : ''}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    justifyContent: 'center',
                }}
            >
                <a href="https://play.google.com/store/apps/details?id=com.candidate.atb_jobs" target="_blank" rel="noopener noreferrer">
                    <button className={Style.buttons}>
                        DOWNLOAD THE APP NOW!{' '}
                        <img
                            src={ArrowRightIcon}
                            alt="Arrow Right"
                            style={{ marginLeft: '10px', height: '24px' }}
                        />
                    </button>
                </a>
            </div>
        </div>
    );
};

const LP6 = () => {
    return (
        <>
            <BannerSection />
            <ClientSection />
            <AppSection />
            <LookInside />
            <FloatingSection />
        </>
    );
};

export default LP6;
