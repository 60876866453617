import React, {useContext, useEffect, useState} from 'react';
import Snackbar from '@mui/material/Snackbar';
import { ProfileStrengthContext } from '../../context/ProfileStrengthContext';
import { Link } from 'react-router-dom';
import { Alert, AlertTitle } from '@mui/material';

function ProfileStrengthAlert() {

    const {strength, profileStrengthAlert, setProfileStrengthAlert} = useContext(ProfileStrengthContext)

  const handleClose = () => {
    setProfileStrengthAlert(false);
  };

  return (
    <div>
      <Snackbar open={profileStrengthAlert} 
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal :'left'}}
        >
            <Alert severity="warning">
                <AlertTitle>Warning, your profile strength is {strength}%</AlertTitle>
                Please <Link to='/candidate-update-profile'><strong>update your profile</strong></Link> upto {process.env.REACT_APP_MINIMUM_STRENGTH_FOR_APPLY}% to apply jobs now!
            </Alert>
        </Snackbar>
    </div>
  );
}

export default ProfileStrengthAlert;