import React, { useContext, useState } from 'react'
import './EmployerProfile.css'
import { Link } from 'react-router-dom'
import { Avatar, Box, Button, Chip, Container, Divider, Paper, Skeleton, Stack, Typography } from '@mui/material'
import EmployerProfilePicture from '../EmployerProfilePicture'
import { LoadingButton } from '@mui/lab'
import SocialsComponent from '../../Job/JobDetails/SocialsComponent'
import { CandidateContext } from '../../../context/CandidateContext'
import { UserContext } from '../../../context/UserContext'
import { followCompany, unFollowCompany } from '../../../../fetchAPI'
import { useHistory } from "react-router-dom";
import { BriefcaseIcon, BuildingIcon, CalenderIcon, LocationIcon, PeopleIcon } from '../../IconsSVG'

function VisitWebsiteIcon() {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1785_487)">
                <path d="M9.44994 18.9917C7.34684 18.9917 5.24374 18.9917 3.14064 18.9917C1.20579 18.9917 0.040059 17.8584 0.0240354 15.9063C-0.00801181 11.6339 -0.00801181 7.36153 0.0240354 3.08912C0.0240354 1.12499 1.19377 0.023945 3.13663 0.0078125C4.99537 0.0078125 6.85408 0.0078125 8.71282 0.0078125C9.3818 0.0078125 10.0948 0.0723374 10.0788 0.935424C10.0628 1.79851 9.34171 1.83079 8.67673 1.83079C6.99425 1.83079 5.31183 1.83079 3.62935 1.83079C2.16719 1.83079 1.94689 2.04051 1.94689 3.44403C1.94689 7.47715 1.94689 11.5022 1.94689 15.5192C1.94689 16.8824 2.21128 17.1324 3.54925 17.1324C7.55515 17.1324 11.553 17.1324 15.5429 17.1324C16.7967 17.1324 17.0612 16.8501 17.0692 15.5474C17.0692 13.9341 17.0692 12.2967 17.0692 10.6754C17.0598 10.3927 17.0704 10.1096 17.1012 9.82843C17.1813 9.288 17.4417 8.87259 18.0386 8.87259C18.164 8.86766 18.289 8.88952 18.4054 8.93671C18.5218 8.98391 18.6271 9.05537 18.7141 9.14642C18.8011 9.23747 18.868 9.34604 18.9104 9.46498C18.9527 9.58392 18.9697 9.71053 18.9599 9.83651C18.9599 12.0588 19.048 14.2729 18.9078 16.5033C18.8157 18.0076 17.6219 18.9231 16.0836 18.9554C13.8724 19.0159 11.6612 18.9917 9.44994 18.9917Z" fill="#0275D8" />
                <path d="M16.7484 3.4234C15.8311 4.38328 14.9217 5.34722 13.9963 6.295C12.9147 7.40007 11.8251 8.49841 10.7275 9.59004C10.2108 10.0942 9.64991 10.8605 8.8928 10.078C8.13568 9.29561 8.89279 8.80359 9.38952 8.31155C11.2162 6.45632 13.0509 4.61318 14.8816 2.76199C15.1179 2.52403 15.3343 2.26591 15.7349 1.82226C14.8817 1.82226 14.2648 1.82226 13.6479 1.82226C13.0309 1.82226 12.5822 1.58432 12.5742 0.922887C12.5662 0.261456 13.0309 0.0154323 13.6238 0.0113992C14.9898 0.0113992 16.3558 0.0113992 17.7218 0.0113992C17.89 -0.00809347 18.0603 0.0105632 18.2203 0.0659979C18.3803 0.121433 18.5261 0.212238 18.6467 0.331754C18.7674 0.45127 18.8598 0.596464 18.9174 0.756678C18.9751 0.916893 18.9963 1.08805 18.9796 1.25762C19.0077 2.66517 19.0037 4.08082 18.9796 5.48837C18.9796 6.09334 18.7554 6.662 18.0423 6.66604C17.3293 6.67007 17.1209 6.10543 17.0929 5.50046C17.0648 4.89549 17.0929 4.24213 17.0929 3.61297L16.7484 3.4234Z" fill="#0275D8" />
            </g>
            <defs>
                <clipPath id="clip0_1785_487">
                    <rect width="19" height="19" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

function VisitWebsite(web) {
    const url = web ? web.toString() : '';

    if (url) {
        const hasHttp = url.startsWith('http://');
        const hasHttps = url.startsWith('https://');

        if (hasHttp || hasHttps) {
            // The string contains either 'http://' or 'https://'
            window.open(url, '_blank'); // Open in a new tab
        } else {
            // The string does not contain 'http://' or 'https://'
            window.open(`http://${url}`, '_blank'); // Open in a new tab
        }
    } else {
        console.error('Invalid URL: web is not defined');
    }
}

function EmployerInformationCard(props) {

    const { candidateData, settingCandidateData, followEmployerIds } = useContext(CandidateContext)
    const { userData } = useContext(UserContext)
    const [saveLoading, setSaveLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)

    const history = useHistory()

    const unSaveEmployer = (candId, empId) => {
        setSaveLoading(false);
        setLoading(true);

        unFollowCompany(candId, empId)
            .then(response => {
                if (response && response.success) {
                    settingCandidateData()
                    setResponseMessage(response.response.message)
                    setOpenAlert(true)
                } else {
                    // Handle failure, show error message or take appropriate action
                    console.log(response)
                }
            })
            .catch(error => {
                // Handle errors, show error message or take appropriate action
                console.log(error)
            })
            .finally(() => {
                // setLoading(false);
            });
    }

    const saveEmployer = (candId, empId) => {

        if (userData) {
            setLoading(false);
            setSaveLoading(true);

            followCompany(candId, empId)
                .then(response => {
                    if (response && response.success) {
                        settingCandidateData()
                        setResponseMessage(response.response.message)
                        setOpenAlert(true)
                    } else {
                        // Handle failure, show error message or take appropriate action
                        console.log(response)
                    }
                })
                .catch(error => {
                    // Handle errors, show error message or take appropriate action
                    console.log(error)
                })
                .finally(() => {
                    // setLoading(false);
                });
        }
        else {
            history.push('/login')
        }

    }

    return (
        <Paper
            sx={{
                width: '80%',
                borderRadius: '10px'
            }}
        >

            {/* Employer Information */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: 2,
                    paddingTop: 3
                }}
            >
                {
                    props.loading ?
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <Skeleton variant="circular" width={80} height={80} />
                            <Skeleton variant="text" width={200} sx={{ marginTop: 2 }} />
                            <Skeleton variant="text" width={200} />
                            <Skeleton variant="text" width={200} />
                            <Skeleton variant="text" height={50} width={200} />
                            <Skeleton variant="rounded" height={120} width={200} />
                        </Box>
                        :
                        <Stack
                            spacing={2}
                            alignItems={'center'}
                            width={'100%'}
                        >
                            <EmployerProfilePicture
                                profile_picture={props.info?.employerInfo?.profile_pic_base64}
                                height={150}
                                width={150}
                            />
                            <Typography
                                variant='h4'
                                align='center'
                                sx={{
                                    fontWeight: 700,
                                    fontSize: '22px',
                                    marginTop: '18px !important',
                                    color: '#0275D8'
                                }}
                            >
                                {props.info?.employerInfo.employer_name}
                            </Typography>

                            <Box mt={4} />
                            <Divider variant='fullWidth' sx={{ width: '100%' }} />

                            {/* Follow/Unfollow functionality */}
                            {
                                userData.user_role_id !== 3 ?
                                    followEmployerIds.includes(props.info?.employerInfo?.id) ?
                                        <React.Fragment>
                                            <Box mt={5}>
                                                <LoadingButton
                                                    loading={loading}
                                                    variant='contained'
                                                    onClick={() => unSaveEmployer(candidateData.id, props.info?.employerInfo?.id)}
                                                    sx={{
                                                        backgroundColor: '#00B19A',
                                                        borderRadius: '60px',
                                                        color: 'white',
                                                        paddingLeft: 8,
                                                        paddingRight: 8,
                                                        boxShadow: 0,
                                                        '&:hover': {
                                                            backgroundColor: '#008775',
                                                        },
                                                    }}
                                                >
                                                    Unfollow
                                                </LoadingButton>
                                            </Box>
                                            <Divider variant='fullWidth' sx={{ width: '100%' }} />
                                            <Box mt={4} />
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <Box mt={5}>
                                                <LoadingButton
                                                    loading={saveLoading}
                                                    variant='contained'
                                                    fullWidth
                                                    onClick={() => saveEmployer(candidateData.id, props.info?.employerInfo?.id)}
                                                    sx={{
                                                        backgroundColor: '#00B19A',
                                                        borderRadius: '60px',
                                                        color: 'white',
                                                        paddingLeft: 8,
                                                        paddingRight: 8,
                                                        boxShadow: 0,
                                                        '&:hover': {
                                                            backgroundColor: '#008775',
                                                        },
                                                    }}
                                                >
                                                    + Follow
                                                </LoadingButton>
                                            </Box>
                                            <Divider variant='fullWidth' sx={{ width: '100%' }} />
                                            <Box mt={4} />
                                        </React.Fragment>
                                    :
                                    ""
                            }


                            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                <Stack direction={'row'} spacing={2} alignItems={'center'} mb={2}>
                                    <Box sx={{whiteSpace: "nowrap"}}>
                                     <CalenderIcon />
                                    </Box>
                                    <Stack spacing={0}>
                                        <Typography
                                            variant='body1'
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: '16px'
                                            }}
                                        >
                                            Year Founded
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            sx={{
                                                fontWeight: 700,
                                                fontSize: '16px',
                                                color: '#0275D8'
                                            }}
                                        >
                                            {
                                                props.info?.employerInfo.established ?
                                                    new Date(props.info?.employerInfo.established).getFullYear()
                                                    : ''
                                            }
                                        </Typography>
                                    </Stack>
                                </Stack>

                                <Stack direction={'row'} spacing={2} alignItems={'center'} mb={2}>
                                    <Box sx={{whiteSpace: "nowrap"}}>
                                     <BriefcaseIcon />
                                    </Box>
                                    <Stack spacing={0}>
                                        <Typography
                                            variant='body1'
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: '16px'
                                            }}
                                        >
                                            Business Type
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            sx={{
                                                fontWeight: 700,
                                                fontSize: '16px',
                                                color: '#0275D8'
                                            }}
                                        >
                                            {props.info?.employerInfo?.company_type}
                                        </Typography>
                                    </Stack>
                                </Stack>

                                <Stack direction={'row'} spacing={2} alignItems={'center'} mb={2}>
                                    <Box sx={{whiteSpace: "nowrap"}}>
                                     <PeopleIcon />
                                    </Box>
                                    <Stack spacing={0}>
                                        <Typography
                                            variant='body1'
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: '16px'
                                            }}
                                        >
                                            Number of Employees
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            sx={{
                                                fontWeight: 700,
                                                fontSize: '16px',
                                                color: '#0275D8'
                                            }}
                                        >
                                            {props.info?.employerInfo.number_of_employee}
                                        </Typography>
                                    </Stack>
                                </Stack>

                                <Stack direction={'row'} spacing={2} alignItems={'center'} mb={2}>
                                    <Box sx={{whiteSpace: "nowrap"}}>
                                     <BuildingIcon />
                                    </Box>
                                    <Stack spacing={0}>
                                        <Typography
                                            variant='body1'
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: '16px'
                                            }}
                                        >
                                            Number of Offices
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            sx={{
                                                fontWeight: 700,
                                                fontSize: '16px',
                                                color: '#0275D8'
                                            }}
                                        >
                                            {props.info?.employerInfo.total_offices}
                                        </Typography>
                                    </Stack>
                                </Stack>

                                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                    <Box sx={{whiteSpace: "nowrap"}}>
                                      <LocationIcon />
                                    </Box>
                                    <Stack spacing={0}>
                                        <Typography
                                            variant='body1'
                                            sx={{
                                                fontWeight: 600,
                                                fontSize: '16px'
                                            }}
                                        >
                                            Address
                                        </Typography>
                                        <Typography
                                            variant='body2'
                                            sx={{
                                                fontWeight: 700,
                                                fontSize: '16px',
                                                color: '#0275D8'
                                            }}
                                        >
                                            {props.info?.employerInfo?.map_location}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Box>

                            <Box mt={4} />
                            <Divider variant='fullWidth' sx={{ width: '100%' }} />

                            <Button
                                variant='outlined'
                                endIcon={<VisitWebsiteIcon />}
                                component='a' // Use an anchor tag
                                onClick={() => VisitWebsite(props.info?.employerInfo.web)}
                                sx={{
                                    textTransform: 'capitalize'
                                }}
                            >
                                Visit Website
                            </Button>
                        </Stack>
                }

            </Box>
        </Paper>
    )
}

function EmployerInformationMobileView(props) {

    const { candidateData, settingCandidateData, followEmployerIds } = useContext(CandidateContext)
    const { userData } = useContext(UserContext)
    const [saveLoading, setSaveLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [openAlert, setOpenAlert] = useState(false)

    const history = useHistory()

    const unSaveEmployer = (candId, empId) => {
        setSaveLoading(false);
        setLoading(true);

        unFollowCompany(candId, empId)
            .then(response => {
                if (response && response.success) {
                    settingCandidateData()
                    setResponseMessage(response.response.message)
                    setOpenAlert(true)
                } else {
                    // Handle failure, show error message or take appropriate action
                    console.log(response)
                }
            })
            .catch(error => {
                // Handle errors, show error message or take appropriate action
                console.log(error)
            })
            .finally(() => {
                // setLoading(false);
            });
    }

    const saveEmployer = (candId, empId) => {

        if (userData) {
            setLoading(false);
            setSaveLoading(true);

            followCompany(candId, empId)
                .then(response => {
                    if (response && response.success) {
                        settingCandidateData()
                        setResponseMessage(response.response.message)
                        setOpenAlert(true)
                    } else {
                        // Handle failure, show error message or take appropriate action
                        console.log(response)
                    }
                })
                .catch(error => {
                    // Handle errors, show error message or take appropriate action
                    console.log(error)
                })
                .finally(() => {
                    // setLoading(false);
                });
        }
        else {
            history.push('/login')
        }

    }

    return (
        <React.Fragment>
            <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'} p={2}>

                {
                    props.loading ?

                        <React.Fragment>
                            <Skeleton variant="circular" width={80} height={80} />
                        </React.Fragment>
                        :
                        <Box
                            sx={{
                                backgroundColor: 'white',
                                borderRadius: '50%',
                                width: 100,
                                height: 100,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <EmployerProfilePicture
                                profile_picture={props.info?.employerInfo?.profile_pic_base64}
                                height={70}
                                width={70}
                            />
                        </Box>
                }

                <Stack spacing={0}>
                    {
                        props.loading ?
                            <Skeleton variant="text" width={200} />
                            :
                            <Typography
                                variant='h4'
                                align='left'
                                sx={{
                                    fontWeight: 700,
                                    fontSize: '18px',
                                    color: 'black',
                                }}
                            >
                                {props.info?.employerInfo.employer_name}
                            </Typography>
                    }

                    {/* Follow/Unfollow functionality */}
                    {
                        userData.user_role_id !== 3 ?
                            followEmployerIds.includes(props.info?.employerInfo?.id) ?
                                <React.Fragment>
                                    <LoadingButton
                                        loading={loading}
                                        variant='contained'
                                        onClick={() => unSaveEmployer(candidateData.id, props.info?.employerInfo?.id)}
                                        sx={{
                                            backgroundColor: '#00B19A',
                                            borderRadius: '60px',
                                            color: 'white',
                                            fontSize: '12px',
                                            fontWeight: 700,
                                            textTransform: 'capitalize',
                                            boxShadow: 0,
                                            marginTop: 1,
                                            '&:hover': {
                                                backgroundColor: '#008775',
                                            },
                                        }}
                                    >
                                        Unfollow
                                    </LoadingButton>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <LoadingButton
                                        loading={saveLoading}
                                        variant='contained'
                                        fullWidth
                                        onClick={() => saveEmployer(candidateData.id, props.info?.employerInfo?.id)}
                                        sx={{
                                            backgroundColor: '#00B19A',
                                            borderRadius: '60px',
                                            color: 'white',
                                            fontSize: '12px',
                                            fontWeight: 700,
                                            textTransform: 'capitalize',
                                            boxShadow: 0,
                                            marginTop: 1,
                                            '&:hover': {
                                                backgroundColor: '#008775',
                                            },
                                        }}
                                    >
                                        + Follow
                                    </LoadingButton>
                                </React.Fragment>
                            :
                            ""
                    }
                </Stack>
            </Stack>
        </React.Fragment >
    )
}

function MoreInformationMobileView(props) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 6,
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <Stack direction={'row'} spacing={2} alignItems={'center'} mb={2}>
                    <Box sx={{whiteSpace: "nowrap"}}>
                      <CalenderIcon />
                    </Box>
                    <Stack spacing={0}>
                        <Typography
                            variant='body1'
                            sx={{
                                fontWeight: 600,
                                fontSize: '16px'
                            }}
                        >
                            Year Founded
                        </Typography>
                        {
                            props.loading ?
                                <Skeleton variant="text" width={100} />
                                :
                                <Typography
                                    variant='body2'
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: '16px',
                                        color: '#0275D8'
                                    }}
                                >
                                    {
                                        props.info?.employerInfo.established ?
                                            new Date(props.info?.employerInfo.established).getFullYear()
                                            : ''
                                    }
                                </Typography>
                        }
                    </Stack>
                </Stack>

                <Stack direction={'row'} spacing={2} alignItems={'center'} mb={2}>
                    <Box sx={{whiteSpace: "nowrap"}}>
                       <BriefcaseIcon />
                    </Box>
                    <Stack spacing={0}>
                        <Typography
                            variant='body1'
                            sx={{
                                fontWeight: 600,
                                fontSize: '16px'
                            }}
                        >
                            Business Type
                        </Typography>
                        {
                            props.loading ?
                                <Skeleton variant="text" width={100} />
                                :
                                <Typography
                                    variant='body2'
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: '16px',
                                        color: '#0275D8'
                                    }}
                                >
                                    {props.info?.employerInfo?.company_type}
                                </Typography>
                        }
                    </Stack>
                </Stack>

                <Stack direction={'row'} spacing={2} alignItems={'center'} mb={2}>
                   <Box sx={{whiteSpace: "nowrap"}}>
                       <PeopleIcon />
                    </Box>
                    <Stack spacing={0}>
                        <Typography
                            variant='body1'
                            sx={{
                                fontWeight: 600,
                                fontSize: '16px'
                            }}
                        >
                            Number of Employees
                        </Typography>
                        {
                            props.loading ?
                                <Skeleton variant="text" width={100} />
                                :
                                <Typography
                                    variant='body2'
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: '16px',
                                        color: '#0275D8'
                                    }}
                                >
                                    {props.info?.employerInfo.number_of_employee}
                                </Typography>
                        }
                    </Stack>
                </Stack>

                <Stack direction={'row'} spacing={2} alignItems={'center'} mb={2}>
                    <Box sx={{whiteSpace: "nowrap"}}>
                       <BuildingIcon />
                    </Box>
                    <Stack spacing={0}>
                        <Typography
                            variant='body1'
                            sx={{
                                fontWeight: 600,
                                fontSize: '16px'
                            }}
                        >
                            Number of Offices
                        </Typography>
                        {
                            props.loading ?
                                <Skeleton variant="text" width={100} />
                                :
                                <Typography
                                    variant='body2'
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: '16px',
                                        color: '#0275D8'
                                    }}
                                >
                                    {props.info?.employerInfo.total_offices}
                                </Typography>
                        }
                    </Stack>
                </Stack>

                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                    <Box sx={{whiteSpace: "nowrap"}}>
                       <LocationIcon />
                    </Box>
                    <Stack spacing={0}>
                        <Typography
                            variant='body1'
                            sx={{
                                fontWeight: 600,
                                fontSize: '16px'
                            }}
                        >
                            Location
                        </Typography>
                        {
                            props.loading ?
                                <Skeleton variant="text" width={100} />
                                :
                                <Typography
                                    variant='body2'
                                    sx={{
                                        fontWeight: 700,
                                        fontSize: '16px',
                                        color: '#0275D8'
                                    }}
                                >
                                    {props.info?.employerInfo?.map_location}
                                </Typography>
                        }
                    </Stack>
                </Stack>
            </Box>

            <Box mt={4} />
            <Divider variant='fullWidth' sx={{ width: '100%' }} />
            <Box mt={2} />

            <Button
                variant='outlined'
                fullWidth
                endIcon={<VisitWebsiteIcon />}
                component='a' // Use an anchor tag
                onClick={() => VisitWebsite(props.info?.employerInfo.web)}
                sx={{
                    textTransform: 'capitalize'
                }}
            >
                Visit Website
            </Button>
            <Box mt={4} />
            <Divider variant='fullWidth' sx={{ width: '100%' }} />
            <Box mt={2} />
        </Box>
    )
}


export default function EmployerProfileSidebar(props) {

    return (
        <React.Fragment>

            {/* Desktop View */}
            <Box
                sx={{
                    display: { xs: 'none', md: 'block' }
                }}
            >
                <Stack spacing={2}>
                    <EmployerInformationCard info={props} loading={props.loading} />

                    <Paper
                        sx={{
                            width: { xs: '100%', md: '80%' },
                            borderRadius: '10px',
                            padding: 4
                        }}
                    >
                        <Stack spacing={2}>
                            <Typography
                                variant='body1'
                                align='left'
                                sx={{
                                    fontWeight: 600,
                                    fontSize: '16px'
                                }}
                            >
                                Connect with {props.employerInfo.employer_name}
                            </Typography>
                            <SocialsComponent socialLinks={props.employerInfo.social_networks} />
                        </Stack>
                    </Paper>
                </Stack>
            </Box>

            {/* Mobile View */}
            <Box
                sx={{
                    display: { xs: 'flex', md: 'none' },
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <EmployerInformationMobileView info={props} loading={props.loading} />
                <MoreInformationMobileView info={props} loading={props.loading} />
            </Box>
        </React.Fragment>

    )
}

