import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../context/UserContext';
import { useHistory } from 'react-router-dom';
import { Box, Button, Card, Checkbox, Container, Divider, Grid, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { Link } from 'react-router-dom';
import { newRegisterUser, registerUser } from '../../fetchAPI';
import ErrorAlert from '../components/Alerts/ErrorAlert';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from "@material-ui/core/styles";
import { validatePassword, validatePhoneNumber, handleKeyDown } from "../helpers/helperFunctions";
import { useAlert } from '../context/AlertContext';

const useStyles = makeStyles({
    input: {
      borderLeft: '1px solid #C4C4C4',
      paddingLeft: "14px",
      marginLeft: "7px"
    },
  });

export default function EmployerRegister() {
    const { isLoggedin } = useContext(UserContext);
    const history = useHistory();
    const [userRoleID, setUserRoleID] = useState(3)
    const [loader, setLoader] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [employerRegisterInfo, setEmployerRegisterInfo] = useState({
        user_role_id: 3 ,
        password: ""
      });
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [validationMessages, setValidationMessages] = useState([]);
    const [validationStatus, setValidationStatus] = useState({
        minLength: false,
        uppercase: false,
        lowercase: false,
        numbers: false,
        specialChar: false
      });
    const classes = useStyles();
    const alertContext = useAlert();


    const { loginData, newLoginData, redirectionLink } = useContext(UserContext)

    const handleChangeEmployer = (name) => (event) => {
        const value = event.target.value;
        setEmployerRegisterInfo((prevState) => ({
          ...prevState,
          [name]: value
        }));
    
        if (name === "password") {
            const { status, messages } = validatePassword(value);
            setValidationStatus(status);
            setValidationMessages(messages);
        }
    }

    const submitEmployerForm = (e) => {
        e.preventDefault();

        const phoneNumber = employerRegisterInfo?.mobile_number
        if (phoneNumber) {
            if(!validatePhoneNumber(phoneNumber)){
                alertContext.setOpenErrorAlert(true);
                alertContext.setResponseMessage("Please enter a valid phone number!");
              return;
            }
        }

        const allValid = Object.values(validationStatus).every(Boolean);
        if (!allValid) {
          alertContext.setOpenErrorAlert(true);
          alertContext.setResponseMessage(validationMessages);
          return;
        }

        const formData = new FormData();

        formData.append('name', employerRegisterInfo?.name);
        formData.append('mobile_number', phoneNumber);
        if (employerRegisterInfo?.email && employerRegisterInfo?.email !== "") {
            formData.append('email', employerRegisterInfo?.email);
        }
        formData.append('password', employerRegisterInfo?.password);
        formData.append('user_role_id', employerRegisterInfo?.user_role_id);

        setLoader(true)

        newRegisterUser(formData)
            .then(response => {
                if (response && response.success) {
                    loginData(response)
                    history.push('/dashboard')

                }
                else {
                    alertContext.setOpenErrorAlert(true);
                    alertContext.setResponseMessage(response?.errors[0].message[0]);
                }
            })
            .catch(err => {
                throw Error('Error: ' + err)
            }).finally(() => setLoader(false))

    }

    // Google Auth

    function handleCallback(response) {
        const userOb = jwtDecode(response.credential);

        axios.post(`${process.env.REACT_APP_API_SERVER_BASE_URL}/googleOauthLogin`, {
            credential: response.credential,
            user_role_id: userRoleID
        }).then((response) => {
            if (response && response.status == 200) {
                loginData(response.data)
                setLoader(false)
                history.push('/dashboard')
            }
            else {
                setLoader(false)
                // throw Error('Error: ' + response)
                console.log('Res API: ', response);
            }

        }).catch(function (error) {
            console.log("API err: ", error);
        });
    }

    useEffect(() => {
        /*global google*/
        google.accounts.id.initialize({
            client_id: '723908483827-aquc3ubglvopidh8dant7rt81pv183te.apps.googleusercontent.com',
            callback: handleCallback
        });

        google.accounts.id.renderButton(
            document.getElementById('gsign'),
            {
                theme: 'outline',
                type: 'standard',
                size: 'large',
                width: `${window.innerWidth >= 900 ? '200px' : '90px'}`,
                text: 'signup_with'
            }
        )

    }, [userRoleID])

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '350px',
                    backgroundImage: 'url(/v2/images/employerBackground.png)',
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                }}
            />
            <Box
                sx={{
                    position: 'relative',
                    top: '-300px',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '-250px'
                }}
            >
                <Card
                    sx={{
                        display: 'flex',
                        height: '100%',
                        width: '800px',
                        boxShadow: '0, 0, 0, 0.25',
                        borderRadius: '22px',
                        justifyContent: 'center',
                        margin: '10px'
                    }}
                >
                    <Container maxWidth='sm'>
                        <Grid item md={12} xs={12}>
                            <Stack spacing={1} sx={{ display: 'flex', alignItems: 'center', margin: 7 }}>
                                <Typography align='center' sx={{ fontWeight: 600 }}>Post your jobs easily</Typography>
                                <Typography variant='h6' sx={{ color: '#0275D8', textAlign: 'center', fontWeight: 700, fontSize: '18px' }}>The best platform for employers to find top talent and grow their teams.</Typography>
                                <Typography align='center' variant='h6' sx={{ fontWeight: 700 }}>Create an Employer Account</Typography>
                            </Stack>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <div className='row justify-content-center'>
                                <div className="App">
                                    <div id='gsign'></div>
                                </div>
                            </div>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <Box m={3}>
                                <Divider>OR</Divider>
                            </Box>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <Box m={3}>
                                <form id='employer-form' onSubmit={(e) => submitEmployerForm(e)}>
                                    <Stack spacing={2}>
                                        <TextField
                                            variant="outlined"
                                            label="Enter Company Name"
                                            fullWidth
                                            required
                                            inputProps={{ minLength: 4 }}
                                            onChange={handleChangeEmployer("name")}
                                        />
                                        <TextField
                                            variant="outlined"
                                            type='number'
                                            placeholder="Enter Mobile Number *"
                                            fullWidth
                                            required
                                            onChange={handleChangeEmployer("mobile_number")}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">+880</InputAdornment>
                                                  ),
                                                  onKeyDown: (e) => handleKeyDown(e),
                                                  classes: {
                                                    input: classes.input 
                                                  },
                                                  sx: {
                                                    '& input::placeholder': {
                                                      color: 'rgba(0, 0, 0, 0.6)',
                                                      opacity: 1,
                                                      fontWeight: 400
                                                    },
                                                  },
                                            }}
                                        />
                                        <TextField
                                            variant="outlined"
                                            type='email'
                                            label="Enter Your Email Address"
                                            fullWidth
                                            required
                                            onChange={handleChangeEmployer("email")}
                                        />
                                        <Box sx={{position: "relative"}}>
                                        <TextField
                                            variant="outlined"
                                            type={showPassword ? "text" : "password"}
                                            label="Create Your Password"
                                            fullWidth
                                            required
                                            // error={passError}
                                            // inputProps={{ minLength: 8 }}
                                            onChange={handleChangeEmployer("password")}
                                        />
                                        {showPassword ? 
                                          <VisibilityOutlinedIcon sx={{position: "absolute", top: 17, right: 10, cursor: "pointer", color: "#666666"}} onClick={() => setShowPassword(!showPassword)} /> 
                                        : 
                                          <VisibilityOffOutlinedIcon sx={{position: "absolute", top: 17, right: 10, cursor: "pointer", color: "#666666"}} onClick={() => setShowPassword(!showPassword)}/>
                                        }                                       
                                        </Box>

                                        <Typography variant='caption' sx={{ color: '#1D1E1B', paddingLeft: 4 }}>
                                            <li style={{ color: validationStatus.uppercase ? '#0079C0' : '#1D1E1B' }}>
                                              Uppercase letters (A - Z)
                                            </li>
                                            <li style={{ color: validationStatus.lowercase ? '#0079C0' : '#1D1E1B' }}>
                                              Lowercase letters (a - z)
                                            </li>
                                            <li style={{ color: validationStatus.numbers ? '#0079C0' : '#1D1E1B' }}>
                                              Numbers (0 - 9)
                                            </li>
                                            <li style={{ color: validationStatus.specialChar ? '#0079C0' : '#1D1E1B' }}>
                                              Special character (!#$%&@^'?)
                                            </li>
                                            <li style={{ color: validationStatus.minLength ? '#0079C0' : '#1D1E1B' }}>
                                              At least 8 characters in length
                                            </li>
                                        </Typography>
                                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'nowrap', alignItems: 'baseline' }}>
                                            <Grid item md={1}>
                                                <Checkbox required />
                                            </Grid>
                                            <Grid item md={11}>
                                                <Typography variant="caption">
                                                    By clicking "Create an account", you confirm that you
                                                    agree to ATB Jobs{" "}
                                                    <Link to={"/terms-conditions"} target={"_blank"}>
                                                        Terms and Conditions{" "}
                                                    </Link>{" "}
                                                    and{" "}
                                                    <Link to={"/privacy-policy"} target={"_blank"}> Privacy Policy. </Link>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <LoadingButton
                                            loading={loader}
                                            variant='contained'
                                            type='submit'
                                            sx={{textTransform: "none"}}
                                        >
                                            Create an account
                                        </LoadingButton>
                                    </Stack>
                                </form>
                            </Box>

                            <Grid item md={12} xs={12}>
                                <Box m={3} mt={2}>
                                    <Typography variant='h6' align='center' sx={{ fontSize: '16px', fontWeight: 700 }}>Already have an account? <Link to={'/login'}>
                                        <Typography variant='span' sx={{ color: '#0275D8', fontWeight: 700 }}> Log In </Typography></Link></Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Card>
                <ErrorAlert open={openErrorAlert} message={errorMessage} onHide={() => setOpenErrorAlert(false)} />
            </Box>
        </>
    )
}