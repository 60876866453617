import React from "react";
import { Box } from "@material-ui/core";
import { Button, Divider, Stack, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

export default function AppliedSuccessfully({ job_id }) {
  const history = useHistory();

  const handleOnClick = (routeName) => {
    window.location.href = `/${routeName}`;
  };

  return (
    <>
      <Divider />
      <Stack
        spacing={2}
        sx={{ display: "flex", alignItems: "center", marginTop: 3 }}
      >
        <Box>
          <img
            style={{ width: "238px", height: "184px" }}
            src="/v2/images/succefully_applied_job_icon.png"
          />
        </Box>
        <Box>
          <Typography align="center" variant="h4" sx={{ color: "#0275D8" }}>
            Congratulations!
          </Typography>
          <Typography align="center" variant="h6">
            You have successfully{" "}
            {job_id ? "applied for the job!" : "completed registration!"}
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={() => handleOnClick(job_id ? "applied-jobs" : "dashboard")}
          >
            Go to Dashboard
          </Button>
        </Box>
      </Stack>
    </>
  );
}
