import React from "react";
import moment from 'moment'
import ReactHtmlParser from 'react-html-parser'
import styles from "../CandidateUpdateProfile.module.css";
import CommonMasking from "../../../../maskingcomponents/CommonMasking";

export default function SingleProjectBlock({ item, masking }) {
    return (

        <>
            {
                masking ?
                    <CommonMasking>
                        <div>
                            <div className={styles.projectDuration}>Jan 2010 - Feb 2014</div>
                            <div className={styles.projectCompany}>
                                Associated with: Abcd Xyz
                            </div>
                            <div className={styles.projectDesc}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nulla turpis, scelerisque sit amet scelerisque vitae, fermentum non odio. Nullam sit amet felis sit amet diam aliquam hendrerit in at lectus.
                            </div>
                        </div>
                    </CommonMasking>
                    :
                    <div>
                        <div className={styles.projectDuration}>{moment(item.start_date).format("MMM Do YYYY")} - {item.end_date ? moment(item.end_date).format("MMM Do YYYY") : 'Present'}</div>
                        <div className={styles.projectCompany}>
                            {
                                item.employment ? <p className='small text-muted'>Associated with: <b>{item.employment.organization_name}</b></p> : ""
                            }
                        </div>
                        <div className={styles.projectDesc}>
                            {ReactHtmlParser(item.description)}
                        </div>
                    </div>
            }
        </>
    )
}